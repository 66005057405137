import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/inicio/services/api.service';

@Component({
  selector: 'app-valoraciones',
  templateUrl: './valoraciones.component.html',
  styleUrls: ['./valoraciones.component.css']
})
export class ValoracionesComponent implements OnInit {

  constructor(public api: ApiService) { }

  ngOnInit(): void {
  }

}
