<div class="hidden main-app">


	<div class="pizarra" id="pizarra">

		<div class="ruidometro hidden">
			<div class="estrellas draggable resizable recurso" style="width: 40%; height: 8vw;">
				<img src="assets/images/aplicativos/ruidometro/estrellas_0.png" alt="" id="contenedor-estellas" style="width: 100%; margin: 0 auto; position: relative; left: 1px; z-index: 1;">
				<!-- <img src="assets/images/aplicativos/ruidometro/radar_0.png" alt="" id="radar" style="width: 100%; margin: 0 auto; position: relative; left: 0px; margin-top: -6%;">
				<img src="assets/images/aplicativos/ruidometro/flecha.png" id="flecha" alt="" style="width: 50%; margin: 0 auto; position: relative; left: 25%; margin-top: -38%;"> -->
				<button class="hidden btn">↖↘</button>
			</div>

			<div class="radar draggable resizable recurso">
				<img src="assets/images/aplicativos/ruidometro/jiro_0.png" alt="" id="skin" style="width: 35%; margin: 0 auto; position: relative; left: 33%; z-index: 1;">
				<img src="assets/images/aplicativos/ruidometro/radar_0.png" alt="" id="radar" style="width: 100%; margin: 0 auto; position: relative; left: 0px; margin-top: -6%;">
				<img src="assets/images/aplicativos/ruidometro/flecha.png" id="flecha" alt="" style="width: 50%; margin: 0 auto; position: relative; left: 25%; margin-top: -38%;">
				<button class="hidden btn">↖↘</button>
			</div>


			<div class="opciones draggable resizable recurso">
				<div class="marco-opciones">
					<div style="width: 100%;">
						<p style="width: 60%; font-size: 1.5vw; padding: 1%; color: white;">Micrófono</p> 
						<img style="width: 20%;" src="assets/images/aplicativos/ruidometro/mic_icon.png" alt="">
					</div>
					<div class="slidecontainer">
						<input type="range" min=".1" max="5" step=".01" value="2.5" class="slider" id="myRange">
					</div>
					<div class="" style="width: 100%;">
						<input type="text" class="form-control" value="10" style="text-align: center; font-size: 1.5vw;">
					</div>
				</div>
				<!-- <img src="assets/images/aplicativos/ruidometro/estrellas_0.png" alt="" id="contenedor-estellas" style="width: 100%; margin: 0 auto; position: relative; left: 1px; z-index: 1;"> -->
				<!-- <img src="assets/images/aplicativos/ruidometro/radar_0.png" alt="" id="radar" style="width: 100%; margin: 0 auto; position: relative; left: 0px; margin-top: -6%;">
				<img src="assets/images/aplicativos/ruidometro/flecha.png" id="flecha" alt="" style="width: 50%; margin: 0 auto; position: relative; left: 25%; margin-top: -38%;"> -->
				<button class="hidden btn">↖↘</button>
			</div>

			


		</div>

		
		

		<!-- <div class="ruidometro hidden recurso">
			<div class="radar draggable resizable" style="width: 40%; height: 30%;">
				<img src="assets/images/aplicativos/ruidometro/jiro_0.png" alt="" id="skin" style="width: 35%; margin: 0 auto; position: relative; left: 33%; z-index: 1;">
				<img src="assets/images/aplicativos/ruidometro/radar_0.png" alt="" id="radar" style="width: 100%; margin: 0 auto; position: relative; left: 0px; margin-top: -6%;">
				<img src="assets/images/aplicativos/ruidometro/flecha.png" id="flecha" alt="" style="width: 50%; margin: 0 auto; position: relative; left: 25%; margin-top: -38%;">
				<button class="hidden btn">↖↘</button>
			</div>
		</div> -->




	</div>
	<div class="barra-principal" *ngIf="modoPizarra">
		<div class="contenedor-herramientas">
			<div *ngFor="let boton of botones" class="div-boton">
				<button class="boton-accion" *ngIf="boton!='SEPARADOR'">
					<img src="assets/images/aplicativos/board/{{ boton }}.png" alt="">
				</button>
				<span class="separador" *ngIf="boton==='SEPARADOR'"></span>
			</div>
		</div>
		<div class="contenedor-recursos">
			<div *ngFor="let boton of recursos" class="div-recursos">
				<button class="boton-recurso" *ngIf="boton!='SEPARADOR'" (click)="activarRecurso(boton)">
					<img src="assets/images/aplicativos/board/{{ boton }}.png" alt="">
				</button>
				<span class="separador" *ngIf="boton==='SEPARADOR'"></span>
			</div>
		</div>
		<div class="contenedor-fondos">
			<div *ngFor="let fondo of fondos" class="div-fondos">
				<button class="boton-fondos" *ngIf="fondo!='SEPARADOR'" (click)="activarFondo(fondo)">
					<img src="assets/images/aplicativos/ruidometro/{{ fondo }}.png" alt="">
				</button>
				<span class="separador" *ngIf="fondo==='SEPARADOR'"></span>
			</div>
		</div>
	</div>


    <!-- <div id="contenedor-principal" style="background: #5f0d50;">
		<div id="gameContainer" style=" background: #5f0d50;"></div>
	</div> -->
</div>