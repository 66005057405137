import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../inicio/services/global.service';
import Phaser from 'phaser';
import { ApiService } from 'src/app/inicio/services/api.service';
import { Router } from '@angular/router';
declare var Howl: any;
declare var $: any;

var ContenedorGeneralGlobal;
var PantallaPresentacionGlobal;
var Principal;
@Component({ 
  selector: 'app-temporizador',
  templateUrl: './temporizador.component.html',
  styleUrls: ['./temporizador.component.css']
})
export class TemporizadorComponent implements OnInit { 
  phaserGame: any;
  config: Phaser.Types.Core.GameConfig;
  constructor( public global:GlobalService, public api:ApiService,private router: Router){ 
    Principal = this;
    this.config = {
      type: Phaser.CANVAS,
      roundPixels: true,
      scale: {
        width: 1920,
        height: 900,
        parent: 'gameContainer',
        fullscreenTarget: 'gameContainer',
        mode: Phaser.Scale.FIT,
        autoCenter: Phaser.Scale.CENTER_BOTH,
      },
      dom: {
          createContainer: true
      },
      backgroundColor: '#ffffff',
      scene: [PantallaPresentacion,ContenedorGeneral]
    };
    this.iniciar();
  }
  enviar_atras(){
    window.parent.postMessage({ type: 'navigate', url: "RecursosApoyo", recurso: "Temporizador Numérico"}, '*');
  }
  atras_landing(){
    window.location.href = "/extras/temporizadores";
    // this.router.navigateByUrl("/extras/temporizadores", { skipLocationChange: false }).then(() => this.router.navigate(["/extras/temporizadores"]));
  }
  async iniciar(){
    setTimeout(()=>{
      $(".main-app").removeClass("hidden");
      $("#contenedor-principal").html(`<div id="gameContainer" style=" background: #f6bb69;"></div>`);
      this.phaserGame = new Phaser.Game(this.config);
    },1200)
    await this.global.offLoading(2500);
    $("body").css("background-color", "#f6bb69");
  }
  ngOnInit(): void {
    this.api.set_kpi_plataformas("Temporizador Numérico","Ingresó al módulo");
  }
}
class PantallaPresentacion extends Phaser.Scene {
  tiempo:any = {
    hora: 0,
    minutos: 3,
    segundos: 0
  };
  temporizador:any;
  Emitterconfetti:any;
  sonido_9:any;
  TERMINADO:any = false;
  audio_reloj = new Howl({
    src: "assets/audio/reloj_corto2.mp3",
    loop: true,
    volume: 1
  });
  constructor() {
    super({ key: 'PantallaPresentacion' });
    PantallaPresentacionGlobal = this;
  }
  preload() {
    this.load.image('fondo_temporizador', 'assets/images/aplicativos/temporizador/fondo_temporizador.png');

    this.load.audio('a_jugar_sonido','assets/recursos/click.mp3');

    this.load.image('base', 'assets/images/aplicativos/temporizador/fondo.png');
    this.load.image('a_jugar', 'assets/images/aplicativos/temporizador/a_jugar.png');

    this.load.image('logos', 'assets/images/aplicativos/ruidometro/logos.png');
    this.load.image('ayuda', 'assets/images/aplicativos/ruidometro/ayuda.png');
    this.load.image('salir', 'assets/images/aplicativos/ruidometro/salir.png');

    this.load.atlas('confetti','assets/assets/particles/confetti.png','assets/assets/particles/confetti.json')
    this.load.audio('sonido_9', 'assets/images/aplicativos/temporizador/sonido_9.mp3');
  }
  resize (gameSize, baseSize, displaySize, resolution)
  {
    $("canvas").css({marginLeft:0,width:"100%",height:"auto", marginBottom: "-5px"});
  }
  create(){
    $("body, #gameContainer").css("background-color", "#f6bb69");
    this.sonido_9 = this.sound.add('sonido_9');
    this.scale.on('resize', this.resize, this);
    this.scale.on('orientationchange', (e: string) => {
      switch(e) {
          case 'portrait-primary':
              break;
          case 'landscape-primary':
              break;
          default:  
      }
      $("canvas").css({marginLeft:0,width:"100%",height:"auto", marginBottom: "-5px"});
    });

    this.scene.moveBelow(this, ContenedorGeneralGlobal);
    

    this.add.image(-2,0,"base").setOrigin(0).setScale(1).setAlpha(.7);
    let aumento_lateral = 260;
    let inicio = this.add.sprite(this.scale.width/2+aumento_lateral+130,650,"a_jugar").setOrigin(0.5).setScale(1).setAlpha(1).setInteractive({ useHandCursor: true });
    this.add.text(0+aumento_lateral,150, "Temporizador" , {
      font: "bold 85px Arial",
      align: "center",
      color: "white",
      shadow: {offsetX: 5,offsetY: 5,color: 'black',blur: 1,stroke: true,fill: true},
      // backgroundColor: "red",
      fixedWidth: this.scale.width+aumento_lateral
    }).setVisible(true);

    this.add.text(0+aumento_lateral,300, "Gestiona el tiempo en tu sala\ny mantén la atención de tus estudiantes\nAtención: Si cambia de página cuando use un\ntemporizador, se detendrá." , {
      font: "bold 30px Arial",
      align: "center",
      color: "white",
      shadow: {offsetX: 2.5,offsetY: 2.5,color: 'black',blur: 1,stroke: true,fill: true},
      // backgroundColor: "red",
      fixedWidth: this.scale.width+aumento_lateral
    }).setVisible(true);

    let logos = this.add.sprite(this.scale.width/2+aumento_lateral+130,800,"logos").setOrigin(0.5).setScale(.5).setAlpha(1).setInteractive({ useHandCursor: true });
    let intLink =this.add.text(0+aumento_lateral,850, "Un producto con ❤ de integritic.cl" , {
      font: "20px Arial",
      align: "center",
      color: "white",
      shadow: {offsetX: 1.5,offsetY: 1.5,color: 'black',blur: 1,stroke: true,fill: true},
      // backgroundColor: "red",
      fixedWidth: this.scale.width+aumento_lateral
    }).setVisible(true).setInteractive({useHandCursor:true});

    intLink.on('pointerdown', function (pointer) {
      window.open("https://www.integritic.cl", '_blank');
    }, this);

    let ayuda = this.add.sprite(this.scale.width/2+aumento_lateral+130,490,"ayuda").setOrigin(0.5).setScale(.5).setAlpha(1).setInteractive({ useHandCursor: true });
    inicio.on('pointerdown', function (pointer) {
      this.sound.add('a_jugar_sonido').play();
      inicio.x = inicio.x - 5;
      inicio.setScale(.95);
      setTimeout(()=>inicio.setScale(1), 100);
      setTimeout(()=>inicio.x = inicio.x + 5, 100);
      this.scene.launch(ContenedorGeneralGlobal);
      setTimeout(()=>{
        this.scene.remove(PantallaPresentacionGlobal);
        $("body, #gameContainer").css("background-color", "#ffffff");
      }, 500);
    }, this);

    ayuda.on('pointerdown', function (pointer) {
      ayuda.x = ayuda.x - 5;
      ayuda.setScale(.48);
      setTimeout(()=>ayuda.setScale(.5), 100);
      setTimeout(()=>ayuda.x = ayuda.x + 5, 100);
      Principal.global.alerta.fire({
        width: "500",
        title: 'Instrucciones:',
        html: `<div style="font-size: 20px;">Podrás seleccionar el formato del temporizador que más te acomode y configurar sonidos, efectos y colores.</div>`,
        text: ``
      });
    }, this);


    let borde_fuera_temporizador = this.add.image(this.scale.width/2 - 550,this.scale.height/2 + 180,"fondo_temporizador").setScale(.8).setInteractive();    
    let borde_medio_temporizador = this.add.circle(this.scale.width/2 - 550,this.scale.height/2 + 180, 295, 0xEF5350, 1).setInteractive();
    //TORTAAAAA
    let borde_medio2_temporizador = this.add.circle(this.scale.width/2 - 550,this.scale.height/2 + 180, 255, 0xfac151, 1);
    let borde_interior_temporizador = this.add.circle(this.scale.width/2 - 550,this.scale.height/2 + 180, 250, 0xffffff, 1);
    let containerCronometro = this.add.container(0, 0);
    containerCronometro.add(borde_fuera_temporizador);
    containerCronometro.add(borde_medio_temporizador);
    //TORTAAAAA
    containerCronometro.add(borde_medio2_temporizador);
    containerCronometro.add(borde_interior_temporizador);

    this.temporizador = this.add.text(this.scale.width/2 - 550, 460 + 180, '00:03:00', { fontFamily: 'Arial', fontSize: '100px', color: '#000000' });
    this.temporizador.setOrigin(0.5);

    containerCronometro.add(this.temporizador);
    containerCronometro.angle = -20;
    this.time.addEvent({
      delay: 0,
      callback: this.updateTimer, // Quita los paréntesis
      callbackScope: this,
      loop: true
    });
  }
  efectoConfetti(){
    this.Emitterconfetti = this.add.particles('confetti').createEmitter({
      frame: ['efect_1.png','efect_2.png','efect_3.png','efect_4.png','efect_5.png','efect_6.png'],
      x: this.scale.width/2-400,
      y: -50,
      rotate: { start: 0, end: 360, ease: 'Back.easeOut' },
      alpha: {min:75, max: 100},
      lifespan: 3000,
      angle: {min: 0, max: 360},
      speed: { min : 100, max: 200},
      scale: {start: 0.8, end: 0},
      gravityY: 200
    });
    setTimeout(() => {
      this.Emitterconfetti.stop();
    }, 2000);
    $('#modal-temp').addClass('hidden');
  }
  updateTimeText() {
    var formattedTime = this.formato_tiempo(this.tiempo.hora, 2) + ':' + this.formato_tiempo(this.tiempo.minutos, 2) + ':' + this.formato_tiempo(this.tiempo.segundos, 2);
    this.temporizador.setText(formattedTime);
  }
  updateTimer() {
    if (this.tiempo.segundos > 0 || this.tiempo.minutos > 0 || this.tiempo.hora > 0) {
      this.tiempo.segundos--;
      if (this.tiempo.segundos < 0) {
        this.tiempo.segundos = 59;
        this.tiempo.minutos--;
        if (this.tiempo.minutos < 0) {
          this.tiempo.minutos = 59;
          this.tiempo.hora--;
          if (this.tiempo.hora < 0) {
            this.tiempo.hora = 0;
          }
        }
      }
      this.updateTimeText();
    }else{
      if(!this.TERMINADO){
        this.efectoConfetti();
        // this.audio_reloj.pause();
        this.TERMINADO = true;
        // this.sonido_9.play(); 
      }
      
    }
  }
  formato_tiempo(number, length) {
    var str = '' + number;
    while (str.length < length) {
        str = '0' + str;
    }
    return str;
  }
  enviar_atras(){
    window.parent.postMessage({ type: 'navigate', url: "RecursosApoyo", recurso: "Ruidómetro"}, '*');
  }
}
class ContenedorGeneral extends Phaser.Scene {

  constructor() {
    super({ key: 'ContenedorGeneral' });
    ContenedorGeneralGlobal = this;    
  }
  audio_conteo = new Howl({
    src: "assets/audio/conteo3.mp3",
    volume: 1
  });
  audio_reloj = new Howl({
    src: "assets/audio/reloj_corto2.mp3",
    loop: true,
    volume: 0
  });
  reproduciendoEfecto = false;
  skinTiempo:any="temporizador";
  selectEl:any;
  timer:any;
  tiempo:any = {
    hora: 0,
    minutos: 0,
    segundos: 0
  };
  isPaused:any = true;
  isStarted:any = false;
  temporizador:any;
  timer_1:any;
  timer_2:any;
  timer_3:any;
  timer_4:any;
  timer_5:any;
  timer_6:any;
  timer_7:any;
  timer_8:any;
  ColorTiempo:any = 0xEF5350;
  ColorFondo:any = 0xffffff;
  EstadoColorPicker: any = false;
  circuloTiempoColor:any;
  BotonEditarTiempo:any;
  circuloFondoColor:any;
  BotonEditarFondo:any;
  text_background:any;
  text_colores:any;
  ContenedorFondo:any;
  fondoMarco:any;
  borde_medio_temporizador:any;
  borde_fuera_temporizador:any;
  borde_medio2_temporizador:any;
  borde_interior_temporizador:any;
  pauseButton:any;
  playButton:any;
  restartButton:any;
  tiempoTotal:any;
  tortaProgreso: Phaser.GameObjects.Graphics; // Variable para el gráfico de la "torta"
  tiempoTotalSegundos: number = 0; // Tiempo total inicial en segundos
  tiempoRestanteSegundos: number = 0; // Tiempo restante en segundos
  tiempoTotalMilisegundos: number = 0; // Tiempo total inicial en segundos
  tiempoRestanteMilisegundos: number = 0; // Tiempo restante en segundos
  BotonMasHora:any;
  BotonMenosHora:any;
  BotonMasMinutos:any;
  BotonMenosMinutos:any;
  BotonMasSegundos:any;
  BotonMenosSegundos:any;
  BotonPlayPrev:any;
  sonidoClick:any;
  sonidoEncendido:any;
  sonidoMenu:any;
  sonido_1:any;
  sonido_2:any;
  sonido_3:any;
  sonido_4:any;
  sonido_5:any;
  sonido_6:any;
  sonido_7:any;
  sonido_8:any;
  sonido_9:any;
  sonido_10:any;
  sonido_11:any;
  sonido_12:any;
  sonido_13:any;
  sonido_14:any;
  sonido_15:any;
  sonido_16:any;
  sonido_17:any;
  sonidoBomba:any;
  BotonSonido:any;
  BotonPantalla:any;
  estadoPantalla:any = false;
  estadoSonido:any = true;
  estadoSonidoPrev:any = true;
  sonidoActual:any = 'sonido_1';
  efectoActual:any = 'confetti';
  Emitterconfetti:any;
  EmitterEmoji:any;
  BotonEfecto_1:any;
  BotonEfecto_2:any;
  BotonEfecto_3:any;
  BotonEfecto_4:any;
  BotonMenu:any;
  EstadoMenu:any = true;
  elementosMoviles:any;
  marcoFondo:any;
  marcoBomba:any;
  texto_tiempo:any;
  efecto:any;
  sonido:any;
  colorSelector:any;
  bordeSelector:any;
  btnCerrar:any;
  btncolorSelector:any;
  containerCambioColor:any;
  containerMenu:any;
  containerCronometro:any;
  bordeCurva:any;
  rellenoCurva:any;
  chispa:any;
  dibujoCurva:any;
  t = 0;
  bomba:any;
  text_temporizador:any;
  tipo_temporizador_1:any;
  tipo_temporizador_2:any;  
  preload() {
    this.load.image('sprite_1', 'assets/assets/particles/sprite_1.png');
    this.load.image('sprite_2', 'assets/assets/particles/sprite_2.png');
    this.load.image('sprite_3', 'assets/assets/particles/sprite_3.png');
    this.load.image('sprite_4', 'assets/assets/particles/sprite_4.png');
    this.load.image('sprite_5', 'assets/assets/particles/sprite_5.png');
    this.load.image('sprite_6', 'assets/assets/particles/sprite_6.png');
    this.load.image('sprite_7', 'assets/assets/particles/sprite_7.png');

    this.load.image('explosion_1', 'assets/assets/particles/explosion_1.png');
    this.load.image('explosion_2', 'assets/assets/particles/explosion_2.png');
    this.load.image('explosion_3', 'assets/assets/particles/explosion_3.png');
    this.load.image('explosion_4', 'assets/assets/particles/explosion_4.png');
    this.load.image('explosion_5', 'assets/assets/particles/explosion_5.png');
    this.load.image('explosion_6', 'assets/assets/particles/explosion_6.png');
    this.load.image('explosion_7', 'assets/assets/particles/explosion_7.png');
    this.load.image('explosion_8', 'assets/assets/particles/explosion_8.png');
    this.load.image('explosion_9', 'assets/assets/particles/explosion_9.png');

    this.load.image('sprite', 'assets/images/aplicativos/temporizador/btn-1.png');
    this.load.image('btn_1', 'assets/images/aplicativos/temporizador/btn-1.png');
    this.load.image('btn_2', 'assets/images/aplicativos/temporizador/btn-2.png');
    this.load.image('btn_3', 'assets/images/aplicativos/temporizador/btn-3.png');
    this.load.image('btn_4', 'assets/images/aplicativos/temporizador/btn-4.png');
    this.load.image('btn_5', 'assets/images/aplicativos/temporizador/btn-5.png');
    this.load.image('btn_6', 'assets/images/aplicativos/temporizador/btn-6.png');
    this.load.image('btn_7', 'assets/images/aplicativos/temporizador/btn-7.png');
    this.load.image('btn_8', 'assets/images/aplicativos/temporizador/btn-8.png');

    this.load.audio('sonido_1', 'assets/images/aplicativos/temporizador/sonido_1.mp3');
    this.load.audio('sonido_2', 'assets/images/aplicativos/temporizador/sonido_2.mp3');
    this.load.audio('sonido_3', 'assets/images/aplicativos/temporizador/sonido_3.mp3');
    this.load.audio('sonido_4', 'assets/images/aplicativos/temporizador/sonido_4.mp3');
    this.load.audio('sonido_5', 'assets/images/aplicativos/temporizador/sonido_5.mp3');
    this.load.audio('sonido_6', 'assets/images/aplicativos/temporizador/sonido_6.mp3');
    this.load.audio('sonido_7', 'assets/images/aplicativos/temporizador/sonido_7.mp3');
    this.load.audio('sonido_8', 'assets/images/aplicativos/temporizador/sonido_8.mp3');
    this.load.audio('sonido_9', 'assets/images/aplicativos/temporizador/sonido_9.mp3');
    this.load.audio('sonido_10', 'assets/images/aplicativos/temporizador/sonido_10.mp3');
    this.load.audio('sonido_11', 'assets/images/aplicativos/temporizador/sonido_11.mp3');
    this.load.audio('sonido_12', 'assets/images/aplicativos/temporizador/sonido_12.mp3');
    this.load.audio('sonido_13', 'assets/images/aplicativos/temporizador/sonido_13.mp3');
    this.load.audio('sonido_14', 'assets/images/aplicativos/temporizador/musica_pedro.mp3');
    this.load.audio('sonido_15', 'assets/images/aplicativos/temporizador/sonido_15.mp3');
    this.load.audio('sonido_16', 'assets/images/aplicativos/temporizador/sonido_16.mp3');
    this.load.audio('sonido_17', 'assets/images/aplicativos/temporizador/sonido_17.mp3');
    this.load.audio('sonidoBomba', 'assets/images/aplicativos/temporizador/efecto_bomba.mp3');
    this.load.audio('sonidoClick', 'assets/recursos/click.mp3');
    this.load.audio('sonidoEncendido', 'assets/images/aplicativos/temporizador/sonido_encendido.mp3');
    this.load.audio('sonidoMenu', 'assets/recursos/clic_ver_mas.mp3');

    this.load.image('img_sonido_1', 'assets/images/aplicativos/temporizador/sonido_1.png');
    this.load.image('img_sonido_2', 'assets/images/aplicativos/temporizador/sonido_2.png');
    this.load.image('img_sonido_3', 'assets/images/aplicativos/temporizador/sonido_3.png');
    this.load.image('img_sonido_4', 'assets/images/aplicativos/temporizador/sonido_4.png');
    this.load.image('img_sonido_5', 'assets/images/aplicativos/temporizador/sonido_5.png');
    this.load.image('img_sonido_6', 'assets/images/aplicativos/temporizador/sonido_6.png');
    this.load.image('img_sonido_7', 'assets/images/aplicativos/temporizador/sonido_7.png');

    this.load.image('bomb', 'assets/images/aplicativos/temporizador/bomb.png');

    this.load.image('skin_1', 'assets/images/aplicativos/temporizador/skin_1.png');
    this.load.image('skin_2', 'assets/images/aplicativos/temporizador/skin_2.png');

    this.load.image('efecto_1', 'assets/images/aplicativos/temporizador/efecto_1.png');
    this.load.image('efecto_2', 'assets/images/aplicativos/temporizador/efecto_2.png');
    this.load.image('efecto_3', 'assets/images/aplicativos/temporizador/efecto_3.png');
    this.load.image('efecto_4', 'assets/images/aplicativos/temporizador/efecto_4.png');
    
    this.load.image('borde', 'assets/images/aplicativos/temporizador/borde-estilo.png');
    this.load.image('editar', 'assets/images/aplicativos/temporizador/editar.png');
    this.load.image('fondo_temporizador', 'assets/images/aplicativos/temporizador/fondo_temporizador.png');
    this.load.image('menu_abierto', 'assets/images/aplicativos/temporizador/menu_abierto.png');
    this.load.image('menu_cerrado', 'assets/images/aplicativos/temporizador/menu_cerrado.png');

    this.load.image('bordeTiempo', 'assets/images/aplicativos/temporizador/borde-color-tiempo.png');
    this.load.image('bordeFondo', 'assets/images/aplicativos/temporizador/borde-color-fondo.png');

    this.load.image('btn_pausa', 'assets/images/aplicativos/temporizador/btn-pausa.png');
    this.load.image('btn_play', 'assets/images/aplicativos/temporizador/btn-play.png');
    this.load.image('btn_replay', 'assets/images/aplicativos/temporizador/btn-replay.png');
    this.load.image('btn_mas', 'assets/images/aplicativos/temporizador/btn-mas.png');
    this.load.image('btn_menos', 'assets/images/aplicativos/temporizador/btn-menos.png');
    this.load.image('btn_suave', 'assets/images/aplicativos/temporizador/suave.png');
    this.load.image('btn_fuerte', 'assets/images/aplicativos/temporizador/fuerte.png');
    this.load.image('colorSelector', 'assets/images/aplicativos/temporizador/colorSelector2.png');
    this.load.image('btnCerrar', 'assets/images/aplicativos/temporizador/salir.png');
    this.load.image('sonido_activo', 'assets/images/aplicativos/temporizador/sonido_activo.png');
    this.load.image('sonido_mute', 'assets/images/aplicativos/temporizador/sonido_mute.png');

    this.load.image('ampliar', 'assets/images/aplicativos/temporizador/ampliar.png');
    this.load.image('no_ampliar', 'assets/images/aplicativos/temporizador/no_ampliar.png');
    this.load.atlas('confetti','assets/assets/particles/confetti.png','assets/assets/particles/confetti.json')
    this.load.atlas('emojis','assets/assets/particles/emojis.png','assets/assets/particles/emojis.json')
    this.load.atlas('efecto_chispa','assets/assets/particles/chispa.png','assets/assets/particles/chispa.json')
    this.load.atlas('efecto_bomba','assets/assets/particles/bomba.png','assets/assets/particles/bomba.json')
  }
  CargaAudios(){    
    this.sonido_1 = this.sound.add('sonido_1');
    this.sonido_2 = this.sound.add('sonido_2');
    this.sonido_3 = this.sound.add('sonido_3');
    this.sonido_4 = this.sound.add('sonido_4');
    this.sonido_5 = this.sound.add('sonido_5');
    this.sonido_6 = this.sound.add('sonido_6');
    this.sonido_7 = this.sound.add('sonido_7');
    this.sonido_8 = this.sound.add('sonido_8');
    this.sonido_9 = this.sound.add('sonido_9');
    this.sonido_10 = this.sound.add('sonido_10');
    this.sonido_11 = this.sound.add('sonido_11');
    this.sonido_12 = this.sound.add('sonido_12');
    this.sonido_13 = this.sound.add('sonido_13');
    this.sonido_14 = this.sound.add('sonido_14');
    this.sonido_15 = this.sound.add('sonido_15');
    this.sonido_16 = this.sound.add('sonido_16');
    this.sonido_17 = this.sound.add('sonido_17');
    this.sonidoBomba = this.sound.add('sonidoBomba');
    this.sonidoClick = this.sound.add('sonidoClick');
    this.sonidoEncendido = this.sound.add('sonidoEncendido');
    this.sonidoMenu = this.sound.add('sonidoMenu');
  }
  formato_tiempo(number, length) {
    var str = '' + number;
    while (str.length < length) {
        str = '0' + str;
    }
    return str;
  }
  MasHora() {
    this.tiempo.hora++;
    if (this.tiempo.hora >= 24) {
        this.tiempo.hora = 0;
    }
    this.CambiarTiempoTotal();
  } 
  MenosHora() {
      this.tiempo.hora--;
      if (this.tiempo.hora < 0) {
          this.tiempo.hora = 23;
      }
      this.CambiarTiempoTotal();
  }
  MasMinuto() {
      this.tiempo.minutos++;
      if (this.tiempo.minutos >= 60) {
          this.tiempo.minutos = 0;
      }
      this.CambiarTiempoTotal();
  }
  MenosMinuto() {
    this.tiempo.minutos--;
    if (this.tiempo.minutos < 0) {
      this.tiempo.minutos = 59;
    }
    this.CambiarTiempoTotal();
  }
  MasSegundos() {
    this.tiempo.segundos++;
    if (this.tiempo.segundos >= 60) {
      this.tiempo.segundos = 0;
    }
    this.CambiarTiempoTotal();
  }
  MenosSegundos() {
    this.tiempo.segundos--;
    if (this.tiempo.segundos < 0) {
      this.tiempo.segundos = 59;
    }
    this.CambiarTiempoTotal();
  }
  updateTimeText() {
    var formattedTime = this.formato_tiempo(this.tiempo.hora, 2) + ':' + this.formato_tiempo(this.tiempo.minutos, 2) + ':' + this.formato_tiempo(this.tiempo.segundos, 2);
    this.temporizador.setText(formattedTime);
  }
  EstadoAudioReloj:any = false;
  EstadoAudioConteo:any = false;
  shakeContainer() {
    var moveDistance = 10; // Distance to move
    var moveSpeed = 5; // Speed of movement
    var startX = this.containerCronometro.x;
    var targetX = startX - moveDistance;
    var tween = this.tweens.add({
        targets: this.containerCronometro,
        x: targetX,
        duration: moveSpeed * moveDistance,
        ease: 'Linear',
        yoyo: true, // Move back to the original position
        repeat: -1 // Repeat indefinitely
    });
    this.time.delayedCall(1500, function() {
      tween.stop();
    }, [], this);
  }
  AudioActual:any;
  reproducirEfecto(sonido){
    if(this.estadoSonido){
      sonido.play(); 
      this.AudioActual = sonido;
    }
  }
  reproducirAudio(){
    console.log('reproducirAudio')
    if(ContenedorGeneralGlobal[this.sonidoActual]){
      if(this.estadoSonido && this.sonidoActual != 'efecto_1'){
        ContenedorGeneralGlobal[this.sonidoActual].play(); 
      }
    }   
  }
  resetTime() {
    this.tiempo.hora = 0;
    this.tiempo.minutos = 0;
    this.tiempo.segundos = 0;
  }
  CambiarFondo(color){
    this.ColorFondo = color;
    // this.ContenedorFondo.setTexture("fondo_"+numero)
    this.ContenedorFondo.clear();
    this.circuloFondoColor.setFillStyle(this.ColorFondo);
    this.ContenedorFondo.fillStyle(this.ColorFondo, 1);
    this.ContenedorFondo.fillRoundedRect(0, 0, this.scale.width, this.scale.height, 0);

  }
  CambiarTiempo(color){
    this.ColorTiempo = color;
    this.circuloTiempoColor.setFillStyle(this.ColorTiempo);
    if(this.skinTiempo == "temporizador"){
      this.borde_medio_temporizador.setFillStyle(this.ColorTiempo);
    }else{
      let numSegmentsToRemove = Math.floor(this.t * this.curveSegments);
      this.curveGraphics.clear(); // Borrar todos los segmentos
      for (let i = numSegmentsToRemove; i < this.curvePoints.length - 1; i++) {
        let startPoint = this.curvePoints[i];
        let endPoint = this.curvePoints[i + 1];
        this.curveGraphics.lineStyle(30, this.ColorTiempo, 1);
        this.curveGraphics.beginPath();
        this.curveGraphics.moveTo(startPoint.x, startPoint.y);
        this.curveGraphics.lineTo(endPoint.x, endPoint.y);
        this.curveGraphics.strokePath();
      }
    }
    
  }
  CambiarTiempoTotal(){
    this.tiempoTotalSegundos = this.tiempo.segundos + this.tiempo.minutos*60 + this.tiempo.hora * 3600;
    this.tiempoTotalMilisegundos = this.tiempo.segundos * 1000 + this.tiempo.minutos * 60 * 1000 + this.tiempo.hora * 3600 * 1000;

  }
  CambiarAutomatico(button){
    this.timer_1.setAlpha(1); 
    this.timer_2.setAlpha(1); 
    this.timer_3.setAlpha(1); 
    this.timer_4.setAlpha(1); 
    this.timer_5.setAlpha(1); 
    this.timer_6.setAlpha(1); 
    this.timer_7.setAlpha(1); 
    this.timer_8.setAlpha(1); 
    button.setScale(.5).setAlpha(.5);
    setTimeout(() => {
      button.setScale(.6)
    }, 200);
    this.CambiarTiempoTotal();
  }
  CambiarSonido(button,sonido){
    button.setScale(.25).setAlpha(.5);
    setTimeout(() => {
      button.setScale(.3)
    }, 200);
    this.sonidoActual = sonido;
  }
  CambiarEfecto(button,efecto){
    this.BotonEfecto_1.setAlpha(1); 
    this.BotonEfecto_2.setAlpha(1); 
    this.BotonEfecto_3.setAlpha(1); 
    this.BotonEfecto_4.setAlpha(1); 
    button.setScale(.25).setAlpha(.5);
    setTimeout(() => {
      button.setScale(.29)
    }, 200);
    this.efectoActual = efecto;
  }
  CambiarSkin(button,efecto){
    this.tipo_temporizador_1.setAlpha(1); 
    this.tipo_temporizador_2.setAlpha(1); 
    button.setScale(.1).setAlpha(.5);
    setTimeout(() => {
      button.setScale(.12)
    }, 200);
    this.reiniciarSkin();
    this.skinTiempo = efecto;
    if(this.skinTiempo == "temporizador"){
      this.crearCronometro();
    }else{
      this.crearBomba();
    }
    Principal.api.set_kpi_plataformas("Temporizador Numérico","Cambia tipo: "+this.skinTiempo);
    window.parent.postMessage({ type: 'kpi',nombre: "Temporizador Numérico", accion: "Cambia tipo: "+this.skinTiempo}, '*');
  }
  Reiniciar(){
    this.reiniciarSkin();
    if(this.skinTiempo == "temporizador"){
      this.crearCronometro();
    }else{
      this.crearBomba();
    }
  }
  CambiarPrevisualizarSonido(){
    if(this.estadoSonidoPrev){
      this.BotonPlayPrev.setTexture("sonido_activo");
      this.estadoSonidoPrev = false;
    }else{
      this.BotonPlayPrev.setTexture("sonido_activo");
      this.estadoSonidoPrev = true;
      ContenedorGeneralGlobal[this.sonidoActual].stop(); 
    }
  }
  colorActualCambio:any;
  abrirColor(tipo,value){
    if(tipo == "ColorTiempo"){
      this.bordeSelector.setTexture("bordeTiempo");
    }else{
      this.bordeSelector.setTexture("bordeFondo");
    }
    if(this.colorActualCambio == tipo){
      if(this.EstadoColorPicker){
        // this.containerCambioColor.setVisible(false);
        // this.EstadoColorPicker = false;
      }else{
        this.containerCambioColor.setVisible(true);
        this.EstadoColorPicker = true;
      }
    }else{
      this.containerCambioColor.setVisible(true);
      this.EstadoColorPicker = true;
    }
    this.colorActualCambio = tipo;
  }
  cerrarColor(){
    this.containerCambioColor.setVisible(false);
    this.EstadoColorPicker = false;
  }
  CambiarEstado(){
    if(this.estadoSonido){
      this.BotonSonido.setTexture("sonido_mute");
      this.estadoSonido = false;
    }else{
      this.BotonSonido.setTexture("sonido_activo");
      this.estadoSonido = true;
    }
    ContenedorGeneralGlobal[this.sonidoActual].stop(); 
  }
  CambiarPantalla(){
    if(this.estadoPantalla){
      this.BotonPantalla.setTexture("ampliar");
      this.estadoPantalla = false;
    }else{
      this.BotonPantalla.setTexture("no_ampliar");
      this.estadoPantalla = true;
    }
    const element = document.documentElement; // Elemento HTML raíz
    if(!document.fullscreenElement){
      if(element.requestFullscreen){
        element.requestFullscreen();
      }
    }else{
      if(document.exitFullscreen){
        document.exitFullscreen();
      }
    }
  }
  quitarAutomatico(){
    this.timer_1.setAlpha(1); 
    this.timer_2.setAlpha(1); 
    this.timer_3.setAlpha(1); 
    this.timer_4.setAlpha(1); 
    this.timer_5.setAlpha(1); 
    this.timer_6.setAlpha(1); 
    this.timer_7.setAlpha(1); 
    this.timer_8.setAlpha(1); 
  }
  resize (gameSize, baseSize, displaySize, resolution){
    $("canvas").css({marginLeft:0,width:"100%",height:"auto", marginBottom: "-5px"});
  }
  async create(){
    this.scale.on('resize', this.resize, this);
    this.input.setDefaultCursor('pointer');
    this.CargaAudios();

    let min = 1630;
    let max = this.scale.width - 30;

    let posC1 = 180;
    let tamanioMarco = 720;
    // fondo
    this.ContenedorFondo = this.add.graphics();
    this.ContenedorFondo.fillStyle(0xffffff, 1);
    this.ContenedorFondo.fillRoundedRect(0, 0, this.scale.width, this.scale.height, 0);
    this.marcoFondo = this.add.image(this.scale.width-158, 400,"borde").setInteractive();    
    this.containerMenu = this.add.container(0, 0);
    this.containerMenu.add(this.marcoFondo);
    const style = {
      fontSize: '20px',
      fontFamily: 'Arial',
      color: '#ffffff',
      backgroundColor: '#ff00ff'
    };
    let diferencia =  60;
    this.timer_1 = this.add.image(750 + diferencia,740,"btn_1").setScale(.65).setInteractive();
    this.timer_2 = this.add.image(850 + diferencia,740,"btn_2").setScale(.65).setInteractive();
    this.timer_3 = this.add.image(950 + diferencia,740,"btn_3").setScale(.65).setInteractive();
    this.timer_4 = this.add.image(1050 + diferencia,740,"btn_4").setScale(.65).setInteractive();
    this.timer_5 = this.add.image(750 + diferencia,840,"btn_5").setScale(.65).setInteractive();
    this.timer_6 = this.add.image(850 + diferencia,840,"btn_6").setScale(.65).setInteractive();
    this.timer_7 = this.add.image(950 + diferencia,840,"btn_7").setScale(.65).setInteractive();
    this.timer_8 = this.add.image(1050 + diferencia,840,"btn_8").setScale(.65).setInteractive();

    this.BotonSonido = this.add.sprite(48,240,"sonido_activo").setScale(.5).setInteractive();
    this.BotonSonido.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.CambiarEstado();
    });
    this.BotonPantalla = this.add.sprite(48,320,"ampliar").setScale(.5).setInteractive();
    this.BotonPantalla.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.CambiarPantalla();
    });
    this.timer_1.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.tiempo.hora = 0;
      this.tiempo.minutos = 0;
      this.tiempo.segundos = 15;
      this.updateTimeText();
      this.CambiarAutomatico(this.timer_1);
    });

    this.timer_2.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.tiempo.hora = 0;
      this.tiempo.minutos = 0;
      this.tiempo.segundos = 30;
      this.updateTimeText();
      this.CambiarAutomatico(this.timer_2);
    });

    this.timer_3.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.tiempo.hora = 0;
      this.tiempo.minutos = 1;
      this.tiempo.segundos = 0;
      this.updateTimeText();
      this.CambiarAutomatico(this.timer_3);
    });

    this.timer_4.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.tiempo.hora = 0;
      this.tiempo.minutos = 3;
      this.tiempo.segundos = 0;
      this.updateTimeText();
      this.CambiarAutomatico(this.timer_4);
    });

    this.timer_5.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.tiempo.hora = 0;
      this.tiempo.minutos = 5;
      this.tiempo.segundos = 0;
      this.updateTimeText();
      this.CambiarAutomatico(this.timer_5);
    });

    this.timer_6.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.tiempo.hora = 0;
      this.tiempo.minutos = 10;
      this.tiempo.segundos = 0;
      this.updateTimeText();
      this.CambiarAutomatico(this.timer_6);
    });

    this.timer_7.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.tiempo.hora = 0;
      this.tiempo.minutos = 30;
      this.tiempo.segundos = 0;
      this.updateTimeText();
      this.CambiarAutomatico(this.timer_7);
    });

    this.timer_8.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.tiempo.hora = 1;
      this.tiempo.minutos = 0;
      this.tiempo.segundos = 0;
      this.updateTimeText();
      this.CambiarAutomatico(this.timer_8);
    });
    this.text_background = this.add.text(this.scale.width - 212,130, "Colores", {
      font: "bold 24px Arial",
      align: "center",
      color: "#ce8a3b"
    });
    this.containerMenu.add(this.text_background);
    this.text_colores = this.add.text(this.scale.width - 270,190, "Fondo", {
      font: "bold 24px Arial",
      align: "center",
      color: "#ce8a3b"
    });
    this.containerMenu.add(this.text_colores);
    this.circuloFondoColor = this.add.circle(this.scale.width - 130,205, 25, 0xffffff, 1).setInteractive();
    this.circuloFondoColor.setStrokeStyle(5, 0x23a025); 
    this.containerMenu.add(this.circuloFondoColor);
    this.BotonEditarFondo = this.add.sprite(this.scale.width-60,205,"editar").setScale(.3).setInteractive();
    this.BotonEditarFondo.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.BotonEditarFondo.setScale(.25);
      setTimeout(() => {
        this.BotonEditarFondo.setScale(.3)
      }, 200);
      // this.CambiarFondo(this.background_2,0xd3bfff,2);
      this.abrirColor("ColorFondo",this.circuloFondoColor);
    });
    this.containerMenu.add(this.BotonEditarFondo);

    this.circuloTiempoColor = this.add.circle(this.scale.width - 130,285, 25, 0xEF5350, 1).setInteractive();
    this.circuloTiempoColor.setStrokeStyle(5, 0x23a025); 
    this.containerMenu.add(this.circuloTiempoColor);
    this.BotonEditarTiempo = this.add.sprite(this.scale.width-60,285,"editar").setScale(.3).setInteractive();
    this.BotonEditarTiempo.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.BotonEditarTiempo.setScale(.25);
      setTimeout(() => {
        this.BotonEditarTiempo.setScale(.3)
      }, 200);
      // this.CambiarTiempo(this.background_2,0xd3bfff);
      this.abrirColor("ColorTiempo",this.circuloTiempoColor);
    });
    this.containerMenu.add(this.BotonEditarTiempo);
    this.containerCambioColor = this.add.container(0, 0).setVisible(false);
    this.bordeSelector = this.add.image(this.scale.width-430, 190, 'bordeTiempo').setScale(.3);
    this.colorSelector = this.add.image(this.scale.width-430, 210, 'colorSelector').setScale(.22);
    
    this.colorSelector.setInteractive();
    this.colorSelector.on('pointerdown', function(pointer, localX, localY, event) {
        var color = this.textures.getPixel(localX, localY, 'colorSelector');
        color = Phaser.Display.Color.RGBToString(color.r, color.g, color.b, color.a);
        if(this.colorActualCambio == "ColorTiempo"){  
          this.CambiarTiempo(color.replace("#", "0x"));
        }else{
          $("body, #gameContainer").css("background-color", color);
          this.CambiarFondo(color.replace("#", "0x"));
        }
    }, this);
    this.btnCerrar = this.add.image(this.scale.width-430, 325, 'btnCerrar').setScale(.4).setInteractive();
    this.btnCerrar.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.cerrarColor();
    });
    this.containerCambioColor.add(this.bordeSelector);
    this.containerCambioColor.add(this.colorSelector);
    this.containerCambioColor.add(this.btnCerrar);
    
    this.texto_tiempo = this.add.text(this.scale.width - 270,270, "Tiempo", {
      font: "bold 24px Arial",
      align: "center",
      color: "#ce8a3b"
    });    

    this.containerMenu.add(this.texto_tiempo);
    this.sonido = this.add.text(this.scale.width - 205,340, "Sonido", {
      font: "bold 24px Arial",
      align: "center",
      color: "#ce8a3b"
    });   
    this.containerMenu.add(this.sonido); 
    
    let diff = 0;
    var select:any = document.createElement('select');

    var options = [{
      value: "sonido_1",
      text: "Mario Bross"
    },{
      value: "sonido_2",
      text: "Fornite Victory"
    },{
      value: "sonido_14",
      text: "Pedro Music"
    },{
      value: "sonido_3",
      text: "Campana"
    },{
      value: "sonido_4",
      text: "Triste"
    },{
      value: "sonido_5",
      text: "Arpa"
    },{
      value: "sonido_6",
      text: "Aplausos"
    },{
      value: "sonido_7",
      text: "Risa Bebé"
    },{
      value: "sonido_8",
      text: "Grillos"
    },{
      value: "sonido_9",
      text: "Niños"
    },{
      value: "sonido_10",
      text: "Horror"
    },{
      value: "sonido_11",
      text: "Bocina Reggaeton"
    },{
      value: "sonido_12",
      text: "Explosión Fuerte"
    },{
      value: "sonido_13",
      text: "Explosión Suave"
    },{
      value: "sonido_15",
      text: "Paz Mental"
    },{
      value: "sonido_16",
      text: "Ecos del alma"
    },{
      value: "sonido_17",
      text: "Armonía Interior"
    },{
      value: "efecto_1",
      text: "Ninguno"
    }];

    options.forEach(function(option) {
        var opt = document.createElement('option');
        opt.value = option.value;
        opt.innerHTML = option.text;
        select.appendChild(opt);
    });

    // Establecer otros atributos y estilos para el select
    select.id = "tipo_sonido";
    select.style.border = "none";
    select.style.borderRadius = "60px";
    select.style.height = '50px';
    select.style.width = '190px';
    select.style.fontSize = '20px';
    select.style.textAlign = 'center';
    select.style.background = '#eeae45';
    select.style.border = '4px solid #da8622';
    select.style.color = 'white';
    select.style.fontWeight = 'bold';
    // Agregar el evento 'input' para limitar la entrada a dígitos
    select.addEventListener("change", function() {
      if(!ContenedorGeneralGlobal.estadoSonidoPrev){
        ContenedorGeneralGlobal.CambiarPrevisualizarSonido();
      }     
      ContenedorGeneralGlobal.sonidoActual = this.value;
    });

    // Añadir el elemento al DOM
    document.body.appendChild(select);

    // Añadir el elemento al juego Phaser
    this.selectEl = this.add.dom(this.scale.width-285, 380+diff, select).setOrigin(0);    
    this.containerMenu.add(this.selectEl);
    this.BotonPlayPrev = this.add.sprite(this.scale.width-60,400+diff,"sonido_activo").setScale(.4).setInteractive();
    this.containerMenu.add(this.BotonPlayPrev);
    this.BotonPlayPrev.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.BotonPlayPrev.setScale(.3);
      setTimeout(() => {
        this.BotonPlayPrev.setScale(.4)
      }, 200);
      this.reproducirAudio();
      this.CambiarPrevisualizarSonido();
    });    
    this.efecto = this.add.text(this.scale.width - 205,590, "Efecto", {
      font: "bold 24px Arial",
      align: "center",
      color: "#ce8a3b"
    });
    
    this.containerMenu.add(this.efecto);
    this.BotonEfecto_1 = this.add.image(this.scale.width - 222,640,"efecto_1").setScale(.29).setInteractive();
    this.BotonEfecto_2 = this.add.image(this.scale.width - 93,640,"efecto_2").setScale(.29).setInteractive().setAlpha(.5);
    this.BotonEfecto_3 = this.add.image(this.scale.width - 222,690,"efecto_3").setScale(.29).setInteractive();
    this.BotonEfecto_4 = this.add.image(this.scale.width - 93,690,"efecto_4").setScale(.29).setInteractive();
    this.containerMenu.add(this.BotonEfecto_1);
    this.containerMenu.add(this.BotonEfecto_2);
    this.containerMenu.add(this.BotonEfecto_3);
    this.containerMenu.add(this.BotonEfecto_4);

    this.BotonEfecto_1.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.CambiarEfecto(this.BotonEfecto_1,'ninguno');
    });
    this.BotonEfecto_2.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.CambiarEfecto(this.BotonEfecto_2,'confetti');
    });
    this.BotonEfecto_3.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.CambiarEfecto(this.BotonEfecto_3,'emojis');
    });
    this.BotonEfecto_4.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.CambiarEfecto(this.BotonEfecto_4,'explosion');
    });
    this.BotonMenu = this.add.image(this.scale.width - 335,400,"menu_abierto").setScale(.5).setInteractive();
    this.BotonMenu.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoMenu);
      if(this.EstadoMenu){
        this.BotonMenu.setTexture("menu_cerrado");
        this.tweens.add({
          targets: this.BotonMenu,
          x: this.scale.width-30,
          duration: 200,
          ease: 'Linear'
        });
        this.tweens.add({
          targets: this.containerMenu,
          x: this.containerMenu.x + 320,
          duration: 200,
          ease: 'Linear'
        });
        this.EstadoMenu = false;
        this.cerrarColor();
      }else{
        this.BotonMenu.setTexture("menu_abierto");
        this.tweens.add({
          targets: this.BotonMenu,
          x: this.scale.width - 334,
          duration: 200,
          ease: 'Linear'
        });
        this.tweens.add({
          targets: this.containerMenu,
          x: 0,
          duration: 200,
          ease: 'Linear'
        });
        this.EstadoMenu = true;
      }
    });

    this.text_temporizador = this.add.text(this.scale.width - 240,460, "Temporizador", {
      font: "bold 24px Arial",
      align: "center",
      color: "#ce8a3b"
    });  
    this.containerMenu.add(this.text_temporizador);

    this.tipo_temporizador_1 = this.add.image(this.scale.width - 210,535,"skin_1").setScale(.12).setInteractive().setAlpha(.5);
    this.tipo_temporizador_2 = this.add.image(this.scale.width - 110,535,"skin_2").setScale(.12).setInteractive();
    this.containerMenu.add(this.tipo_temporizador_1);
    this.containerMenu.add(this.tipo_temporizador_2);
    this.tipo_temporizador_1.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.CambiarSkin(this.tipo_temporizador_1,'temporizador');
    });
    this.tipo_temporizador_2.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.CambiarSkin(this.tipo_temporizador_2,'bomba');
    });
    if(this.skinTiempo == "temporizador"){
      this.crearCronometro();
    }else{
      this.crearBomba();
    }
    this.time.addEvent({
      delay: 1000,
      callback: this.updateTimer, // Quita los paréntesis
      callbackScope: this,
      loop: true
    });
    this.time.addEvent({
      delay: 1000,
      callback: this.updateBomba, // Quita los paréntesis
      callbackScope: this,
      loop: true
    });
  }
  crearCronometro(){
    this.containerCronometro = this.add.container(0, 0);
    this.borde_fuera_temporizador = this.add.image(this.scale.width/2,this.scale.height/2-90,"fondo_temporizador").setScale(.8).setInteractive();
    
    this.borde_medio_temporizador = this.add.circle(this.scale.width/2,this.scale.height/2-90, 295, this.ColorTiempo, 1).setInteractive();
    this.tortaProgreso = this.add.graphics();
    
    this.dibujarTortaCompleta();
    this.borde_medio2_temporizador = this.add.circle(this.scale.width/2,this.scale.height/2-90, 255, 0xfac151, 1);
    this.borde_interior_temporizador = this.add.circle(this.scale.width/2,this.scale.height/2-90, 250, 0xffffff, 1);

    this.containerCronometro.add(this.borde_fuera_temporizador);
    this.containerCronometro.add(this.borde_medio_temporizador);
    this.containerCronometro.add(this.tortaProgreso);
    this.containerCronometro.add(this.borde_medio2_temporizador);
    this.containerCronometro.add(this.borde_interior_temporizador);

    this.temporizador = this.add.text(this.scale.width/2, 340, '00:00:00', { fontFamily: 'Arial', fontSize: '100px', color: '#000000' });
    this.temporizador.setOrigin(0.5);
    
    this.BotonMasHora = this.add.image(820,270,"btn_mas").setScale(.26).setInteractive();
    this.BotonMenosHora = this.add.image(820,400,"btn_menos").setScale(.26).setInteractive();

    this.BotonMasHora.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.quitarAutomatico();
      this.MasHora();
      this.updateTimeText();
    });

    this.BotonMenosHora.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.quitarAutomatico();
      this.MenosHora();
      this.updateTimeText();
    });
    
    this.BotonMasMinutos = this.add.image(960,270,"btn_mas").setScale(.26).setInteractive();
    this.BotonMenosMinutos = this.add.image(960,400,"btn_menos").setScale(.26).setInteractive();

    this.BotonMasMinutos.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.quitarAutomatico();
      this.MasMinuto();
      this.updateTimeText();
    });

    this.BotonMenosMinutos.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.quitarAutomatico();
      this.MenosMinuto();
      this.updateTimeText();
    });
    
    this.BotonMasSegundos = this.add.image(1100,270,"btn_mas").setScale(.26).setInteractive();
    this.BotonMenosSegundos = this.add.image(1100,400,"btn_menos").setScale(.26).setInteractive();

    this.BotonMasSegundos.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.quitarAutomatico();
      this.MasSegundos();
      this.updateTimeText();
    });

    this.BotonMenosSegundos.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.quitarAutomatico();
      this.MenosSegundos();
      this.updateTimeText();
    });
    let borde_tiempo = this.add.graphics();
    borde_tiempo.lineStyle(7, 0xfac151);
    borde_tiempo.strokeRoundedRect(this.scale.width/2-220, 240, this.scale.width/2 - 520, 190, 15);

    this.containerCronometro.add(this.temporizador);
    this.containerCronometro.add(this.BotonMasHora);
    this.containerCronometro.add(this.BotonMenosHora);
    this.containerCronometro.add(this.BotonMasMinutos);
    this.containerCronometro.add(this.BotonMenosMinutos);
    this.containerCronometro.add(this.BotonMasSegundos);
    this.containerCronometro.add(this.BotonMenosSegundos);
    this.containerCronometro.add(borde_tiempo);
    this.pauseButton = this.add.image(this.scale.width/2 - 80,this.scale.height - 400,"btn_pausa").setAlpha(.5).setScale(.45).setInteractive();
    this.playButton = this.add.image((this.scale.width/2),this.scale.height - 400,"btn_play").setAlpha(.5).setScale(.65).setInteractive();
    this.restartButton = this.add.image(this.scale.width/2 + 80,this.scale.height - 400,"btn_replay").setAlpha(.5).setScale(.45).setInteractive();

    this.playButton.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      if(!this.isStarted){
        this.CambiarTiempoTotal();
      }
      this.isPaused = false;        
      this.playButton.setScale(.55);
      setTimeout(() => {
        this.playButton.setScale(.65);
      },200);
      this.isStarted = true;
      this.EstadoBotones(false);
    });

    this.pauseButton.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.isPaused = true;
      this.pauseButton.setScale(.35);
      setTimeout(() => {
        this.pauseButton.setScale(.45);
      },200);
      this.EstadoBotones(true);
    });

    this.restartButton.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.isPaused = true;
      this.isStarted = false;
      this.resetTime();
      this.updateTimeText();
      this.restartButton.setScale(.35);
      setTimeout(() => {
        this.restartButton.setScale(.45);
      },200);
      this.EstadoBotones(true);
    });
    this.containerCronometro.add(this.playButton);
    this.containerCronometro.add(this.pauseButton);
    this.containerCronometro.add(this.restartButton);
  }
  crearBomba(){
    this.CambiarEfecto(this.BotonEfecto_4,'explosion');
    $("#tipo_sonido").val('sonido_13').trigger("change");
    this.sonidoActual = "sonido_13";
    this.containerCronometro = this.add.container(0, 0);
    this.bomba = this.add.image(this.scale.width/2,this.scale.height/2-60,"bomb").setScale(.7);
    this.containerCronometro.add(this.bomba);
    // Crear la línea curva
    this.bordeCurva = new Phaser.Curves.Spline([
      new Phaser.Math.Vector2(300, 300),
      new Phaser.Math.Vector2(300, 100),
      new Phaser.Math.Vector2(600, 300),
      new Phaser.Math.Vector2(620, 70),
      new Phaser.Math.Vector2(785, 160)
    ]);

    // Crear la línea curva
    this.rellenoCurva = new Phaser.Curves.Spline([
      new Phaser.Math.Vector2(300, 300),
      new Phaser.Math.Vector2(300, 100),
      new Phaser.Math.Vector2(600, 300),
      new Phaser.Math.Vector2(620, 70),
      new Phaser.Math.Vector2(785, 160)
    ]);
    
    const textureNames = ['sprite_1', 'sprite_2', 'sprite_3', 'sprite_4', 'sprite_5', 'sprite_6', 'sprite_7'];
    let currentTextureIndex = 0;
    function changeTexture() {
      if(this.skinTiempo == "bomba"){
        const nextTextureName = textureNames[currentTextureIndex];
        this.chispa.setTexture(nextTextureName);
        currentTextureIndex = (currentTextureIndex + 1) % textureNames.length;
      }        
    }
    const delayBetweenTextureChanges = 20;
    this.time.addEvent({
        delay: delayBetweenTextureChanges,
        callback: changeTexture,
        callbackScope: this,
        loop: true 
    });

    //CREAR EL CRONOMETRO
    this.temporizador = this.add.text(this.scale.width/2, 370, '00:00:00', { fontFamily: 'Arial', fontSize: '90px', color: '#ffffff' });
    this.temporizador.setOrigin(0.5);
    
    this.BotonMasHora = this.add.image(975 - 150,270+36,"btn_mas").setScale(.26).setInteractive();
    this.BotonMenosHora = this.add.image(975 - 150,400+32,"btn_menos").setScale(.26).setInteractive();

    this.BotonMasHora.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.quitarAutomatico();
      this.MasHora();
      this.updateTimeText();
    });

    this.BotonMenosHora.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.quitarAutomatico();
      this.MenosHora();
      this.updateTimeText();
    });
    
    this.BotonMasMinutos = this.add.image(1100 - 140,270+36,"btn_mas").setScale(.26).setInteractive();
    this.BotonMenosMinutos = this.add.image(1100 - 140,400+32,"btn_menos").setScale(.26).setInteractive();

    this.BotonMasMinutos.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.quitarAutomatico();
      this.MasMinuto();
      this.updateTimeText();
    });

    this.BotonMenosMinutos.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.quitarAutomatico();
      this.MenosMinuto();
      this.updateTimeText();
    });
    
    this.BotonMasSegundos = this.add.image(1225 - 140,270+36,"btn_mas").setScale(.26).setInteractive();
    this.BotonMenosSegundos = this.add.image(1225 - 140,400+32,"btn_menos").setScale(.26).setInteractive();

    this.BotonMasSegundos.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.quitarAutomatico();
      this.MasSegundos();
      this.updateTimeText();
    });

    this.BotonMenosSegundos.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.quitarAutomatico();
      this.MenosSegundos();
      this.updateTimeText();
    });
    let borde_tiempo = this.add.graphics();
    borde_tiempo.lineStyle(7, 0xffffff);
    borde_tiempo.strokeRoundedRect(this.scale.width/2-205, 280, 410, 180, 15);

    this.containerCronometro.add(this.temporizador);
    this.containerCronometro.add(this.BotonMasHora);
    this.containerCronometro.add(this.BotonMenosHora);
    this.containerCronometro.add(this.BotonMasMinutos);
    this.containerCronometro.add(this.BotonMenosMinutos);
    this.containerCronometro.add(this.BotonMasSegundos);
    this.containerCronometro.add(this.BotonMenosSegundos);
    this.containerCronometro.add(borde_tiempo);

    this.pauseButton = this.add.image(this.scale.width/2 - 80,this.scale.height - 350,"btn_pausa").setAlpha(.5).setScale(.45).setInteractive();
    this.playButton = this.add.image((this.scale.width/2),this.scale.height - 350,"btn_play").setAlpha(.5).setScale(.65).setInteractive();
    this.restartButton = this.add.image(this.scale.width/2 + 80,this.scale.height - 350,"btn_replay").setAlpha(.5).setScale(.45).setInteractive();

    this.playButton.on('pointerdown', () => {
      this.EstadoBotones(false);
      this.reproducirEfecto(this.sonidoClick);
      setTimeout(() => {
        setTimeout(() => {
          if(!this.isStarted){
            this.reproducirEfecto(this.sonidoEncendido);
            this.CambiarTiempoTotal();
          }
          this.isPaused = false;  
          this.isStarted = true;   
        }, 300); 
      }, 200);

      this.playButton.setScale(.55);
      setTimeout(() => {
        this.playButton.setScale(.65);
      },200);
    });

    this.pauseButton.on('pointerdown', () => {
      this.EstadoBotones(true);
      this.reproducirEfecto(this.sonidoClick);
      this.isPaused = true;
      this.pauseButton.setScale(.35);
      setTimeout(() => {
        this.pauseButton.setScale(.45);
      },200);
    });

    this.restartButton.on('pointerdown', () => {
      this.EstadoBotones(true);
      this.reproducirEfecto(this.sonidoClick);
      this.isPaused = true;
      this.isStarted = false;
      this.resetTime();
      this.updateTimeText();
      this.restartButton.setScale(.35);
      setTimeout(() => {
        this.restartButton.setScale(.45);
      },200);
    });
    this.containerCronometro.add(this.playButton);
    this.containerCronometro.add(this.pauseButton);
    this.containerCronometro.add(this.restartButton);

    this.curveGraphics = this.add.graphics();
    this.curveSegments = 100; // Número de segmentos de línea para aproximar la curva
    this.curvePoints = this.bordeCurva.getPoints(this.curveSegments); // Obtener puntos de la curva
    
    // Dibujar segmentos de línea
    for (let i = 0; i < this.curvePoints.length - 1; i++) {
        let startPoint = this.curvePoints[i];
        let endPoint = this.curvePoints[i + 1];
        this.curveGraphics.lineStyle(30, this.ColorTiempo, 1);
        this.curveGraphics.beginPath();
        this.curveGraphics.moveTo(startPoint.x, startPoint.y);
        this.curveGraphics.lineTo(endPoint.x, endPoint.y);
        this.curveGraphics.strokePath();
    }
    this.containerCronometro.add(this.curveGraphics);
    // CHISPA
    this.chispa = this.add.sprite(300 ,300, 'efecto_chispa').setVisible(false);
    this.chispa.setScale(1);
    this.containerCronometro.add(this.chispa);
  }
  curveGraphics:any;
  curvePoints:any;
  curveSegments:any;
  reiniciarSkin() {
    this.isPaused = true;
    this.isStarted = false;
    this.quitarAutomatico();
    this.containerCronometro.removeAll(true, true);
    this.tiempo = {
      hora: 0,
      minutos: 0,
      segundos: 0
    };
  }
  updateTimer() {
    if(!this.EstadoAudioReloj){
      this.reproducirEfecto(this.audio_reloj);
      this.EstadoAudioReloj = true;
    }
    if(this.skinTiempo == "temporizador"){
      if (!this.isPaused) {        
        if (this.tiempo.segundos > 0 || this.tiempo.minutos > 0 || this.tiempo.hora > 0) {
          this.tiempo.segundos--;
          if (this.tiempo.segundos < 0) {
            this.tiempo.segundos = 59;
            this.tiempo.minutos--;
            if (this.tiempo.minutos < 0) {
              this.tiempo.minutos = 59;
              this.tiempo.hora--;
              if (this.tiempo.hora < 0) {
                this.tiempo.hora = 0;
              }
            }
          }
          if(this.tiempo.segundos < 5 && this.tiempo.minutos == 0 && this.tiempo.hora == 0){
            this.audio_reloj.volume(1);      
          }
          this.updateTimeText();
        } else {
          this.ReproduceEfectos();
          this.audio_reloj.volume(0);    
          this.shakeContainer();
          this.audio_reloj.pause();
        }
      }else{
        this.audio_reloj.volume(0);        
      }
    }
  }
  ReproduceEfectos(){
    this.reproduciendoEfecto = true;
    this.isPaused = true;
    this.reproducirAudio();
    this.quitarAutomatico();
    console.log('TIEMPO AGOTADO');
    if(this.efectoActual == 'confetti'){
      this.efectoConfetti();
    }else if(this.efectoActual == 'emojis'){
      this.efectoEmoji();
    }else if(this.efectoActual == 'explosion'){
      this.explotar();
    }else{
      console.log('sin efecto')
    }
    Principal.api.set_kpi_plataformas("Temporizador Numérico","Reproduce efecto: "+this.efectoActual+" "+this.getNombreSonido(this.sonidoActual));
    window.parent.postMessage({ type: 'kpi',nombre: "Temporizador Numérico", accion: "Reproduce efecto: "+this.efectoActual+" "+this.getNombreSonido(this.sonidoActual)}, '*');
    setTimeout(() => {
      this.Reiniciar();
    }, 2500);
  }
  getNombreSonido(sonido){
    switch(sonido) {
      case 'sonido_14': return "Pedro Music" ;break;
      case 'sonido_1': return "Mario Bross" ;break;
      case 'sonido_2': return "Fornite Victory" ;break;
      case 'sonido_3': return "Campana" ;break;
      case 'sonido_4': return "Triste" ;break;
      case 'sonido_5': return "Arpa" ;break;
      case 'sonido_6': return "Aplausos" ;break;
      case 'sonido_7': return "Risa Bebé" ;break;
      case 'sonido_8': return "Grillos" ;break;
      case 'sonido_9': return "Niños" ;break;
      case 'sonido_10': return "Horror" ;break;
      case 'sonido_11': return "Bocina Reggaeton" ;break;
      case 'sonido_12': return "Explosión Fuerte" ;break;
      case 'sonido_13': return "Explosión Suave" ;break;
      case 'sonido_15': return "Paz Mental" ;break;
      case 'sonido_16': return "Ecos del alma" ;break;
      case 'sonido_17': return "Armonía Interior" ;break;
      case 'efecto_1': return "Ninguno" ;break;
      default: return sonido; break;
    }
  }
  updateBomba() {
    if(this.skinTiempo == "bomba"){
      if (!this.isPaused) {        
        this.reproducirEfecto(this.audio_conteo); 
        if (this.tiempo.segundos > 0 || this.tiempo.minutos > 0 || this.tiempo.hora > 0) {    
          this.tiempo.segundos--;
          if (this.tiempo.segundos < 0) {        
            this.tiempo.segundos = 59;
            this.tiempo.minutos--;
            if (this.tiempo.minutos < 0) {
              this.tiempo.minutos = 59;
              this.tiempo.hora--;
              if (this.tiempo.hora < 0) {
                this.tiempo.hora = 0;
              }
            }
          }  
          this.updateTimeText();
          if(this.tiempo.segundos < 5 && this.tiempo.minutos == 0 && this.tiempo.hora == 0){
            this.shakeBomba();
          }
        } else {
          this.ReproduceEfectos();
          var tween = this.tweens.add({
            targets: this.containerCronometro,
            alpha: 0, // Cambia la opacidad a 0
            duration: 50,
            ease: 'Linear',
            yoyo: false,
            repeat: 0,
            transformOrigin: { x: 0.5, y: 0.5 } // Establecer el punto de transformación en el centro del contenedor
          });
        }
      }
    }
  }
  shakeBomba() {
    var moveDistance = 10; 
    var moveSpeed = 5; 
    var startX = this.containerCronometro.x;
    var targetX = startX - moveDistance;
    var tween = this.tweens.add({
        targets: this.containerCronometro,
        x: targetX,
        duration: moveSpeed * moveDistance,
        ease: 'Linear',
        yoyo: true,
        repeat: -1 
    });
  }
  explotar(){
    const textureNames = ['explosion_1', 'explosion_2', 'explosion_3', 'explosion_4', 'explosion_5', 'explosion_6', 'explosion_7', 'explosion_8', 'explosion_9'];
    let currentTextureIndex = 0;
    let sprite_explosion = this.add.sprite(this.scale.width/2 ,this.scale.height/2-100, 'efecto_bomba').setScale(1.7);
    function changeTexture2() {
      const nextTextureName = textureNames[currentTextureIndex];
      sprite_explosion.setTexture(nextTextureName);
      currentTextureIndex++;
    }
    const delayBetweenTextureChanges = 200;
    const changeTextureEvent = this.time.addEvent({
      delay: delayBetweenTextureChanges,
      callback: changeTexture2,
      callbackScope: this,
      loop: true 
    });
    setTimeout(() => {
      this.reproduciendoEfecto = false;
    }, 2000);
  }
  efectoConfetti(){
    this.Emitterconfetti = this.add.particles('confetti').createEmitter({
      frame: ['efect_1.png','efect_2.png','efect_3.png','efect_4.png','efect_5.png','efect_6.png'],
      x: this.scale.width/2,
      y: -100,
      rotate: { start: 0, end: 360, ease: 'Back.easeOut' },
      alpha: {min:75, max: 100},
      lifespan: 3000,
      angle: {min: 0, max: 360},
      speed: { min : 100, max: 200},
      scale: {start: 0.8, end: 0},
      gravityY: 200
    });
    setTimeout(() => {
      this.Emitterconfetti.stop();
      this.reproduciendoEfecto = false;
    }, 2000);
  }
  efectoEmoji(){
    this.EmitterEmoji = this.add.particles('emojis').createEmitter({
      frame: ['em_10','em_3','em_11','em_12','em_2','em_4','em_6','em_8','em_9','em_7','em_5','em_1'],
      x: this.scale.width/2,
      y: -100,
      rotate: { start: 0, end: 360, ease: 'Back.easeOut' },
      alpha: {min:75, max: 100},
      lifespan: 3000,
      angle: {min: 0, max: 360},
      speed: { min : 100, max: 200},
      scale: {start: 0.4, end: 0},
      gravityY: 200
    });
    setTimeout(() => {
      this.EmitterEmoji.stop();
      this.reproduciendoEfecto = false;
    }, 2000);
  }
  dibujarTortaCompleta() {
    this.dibujarTorta(1, 1);
  }

  dibujarTorta(tiempoTotal: number, progresoTiempo: number) {
    this.tortaProgreso.clear();

    const centerX = this.scale.width / 2;
    const centerY = this.scale.height / 2-90;
    const radius = 295;

    const startAngle = -Math.PI / 2;

    this.tortaProgreso.fillStyle(0xfff8ee); 
    const endAngleRemaining = startAngle + (progresoTiempo * 2 * Math.PI);
    this.tortaProgreso.slice(centerX, centerY, radius, startAngle, endAngleRemaining, false);
    this.tortaProgreso.fill();

    this.tortaProgreso.fillStyle(0x00ff00);
    const endAngleTotal = startAngle + (tiempoTotal * 2 * Math.PI);
    this.tortaProgreso.slice(centerX, centerY, radius, endAngleRemaining, endAngleTotal, false);
    this.tortaProgreso.fill();
  }
  animarChispa(){
    this.t = 1 - Phaser.Math.Clamp(this.tiempoRestanteMilisegundos / this.tiempoTotalMilisegundos, 0, 1); //ORDEN 2
    const point = this.rellenoCurva.getPoint(this.t);  
    this.chispa.x = point.x;
    this.chispa.y = point.y;   
    
    let numSegmentsToRemove = Math.floor(this.t * this.curveSegments);
    this.curveGraphics.clear(); // Borrar todos los segmentos
    for (let i = numSegmentsToRemove; i < this.curvePoints.length - 1; i++) {
      let startPoint = this.curvePoints[i];
      let endPoint = this.curvePoints[i + 1];
      this.curveGraphics.lineStyle(30, this.ColorTiempo, 1);
      this.curveGraphics.beginPath();
      this.curveGraphics.moveTo(startPoint.x, startPoint.y);
      this.curveGraphics.lineTo(endPoint.x, endPoint.y);
      this.curveGraphics.strokePath();
    }
  }
  EstadoBotones(estado){
    this.BotonMasHora.setVisible(estado);
    this.BotonMenosHora.setVisible(estado);
    this.BotonMasMinutos.setVisible(estado);
    this.BotonMenosMinutos.setVisible(estado);
    this.BotonMasSegundos.setVisible(estado);
    this.BotonMenosSegundos.setVisible(estado);
  }
  update(){
    this.tiempoRestanteMilisegundos = this.tiempo.segundos * 1000 + this.tiempo.minutos * 60 * 1000 + this.tiempo.hora * 3600 * 1000 - 10;
    if (this.isPaused) {
    }else {
      if(this.skinTiempo == "bomba"){
        this.animarChispa();
      }
    }
    this.tiempoRestanteSegundos = this.tiempo.segundos + this.tiempo.minutos*60 + this.tiempo.hora * 3600;
    this.tiempoTotal = 1 - (this.tiempoRestanteSegundos / this.tiempoTotalSegundos);
    const progresoTiempo = 1 - (this.tiempoRestanteSegundos / this.tiempoTotalSegundos);
    if(this.skinTiempo == "temporizador"){
      this.dibujarTorta(this.tiempoTotal, progresoTiempo);
    }else{
      if(this.isStarted){
        this.chispa.setVisible(true);
      }else{
        this.chispa.setVisible(false);
      }
    }
    if(this.tiempo.segundos > 0 || this.tiempo.minutos > 0 || this.tiempo.hora > 0){
      if(this.isPaused){
        this.playButton.setInteractive().setAlpha(1);
        this.pauseButton.disableInteractive().setAlpha(.5);
      }else{
        this.playButton.disableInteractive().setAlpha(.5);
        this.pauseButton.setInteractive().setAlpha(1);
        this.restartButton.setInteractive().setAlpha(1);
      }
    }else{
      this.playButton.disableInteractive().setAlpha(.5);
      this.pauseButton.disableInteractive().setAlpha(.5);
      this.restartButton.disableInteractive().setAlpha(.5);
    }
    if(this.sonidoActual== "efecto_1"){
      this.BotonPlayPrev.disableInteractive().setAlpha(.5);
    }else{
      if(!this.isPaused){
        this.BotonPlayPrev.disableInteractive().setAlpha(.5);
      }else{
        this.BotonPlayPrev.setInteractive().setAlpha(1);
      }
    }
  }
}