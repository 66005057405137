<div class="hidden main-app">
	<img class="btn-hover-lsch pulso-css" *ngIf="api.ID_INSTITUCION != '' && global.isIframe" src="assets/images/abecedario/flecha.png" (click)="enviar_atras()" style="margin-top: 20px;margin-left: 10px;width: 40px;position: absolute;">
	<img class="btn-hover efecto-destacado" *ngIf="api.ID_INSTITUCION != '' && global.isIframe" src="assets/images/abecedario/comentarios.svg" (click)="api.enviar_sugerencia('Temporizador Sensorial','extras/temporizadorsensorial')" style="left: 8px;top: 65px;">
	<img class="btn-hover efecto-destacado" *ngIf="!global.isIframe" src="assets/images/abecedario/comentarios.svg" (click)="api.valoraciones('Temporizador Sensorial','extras/temporizadorsensorial')" style="top: 65px;">
  <img class="btn-hover-lsch pulso-css" *ngIf="!global.isIframe" src="assets/images/abecedario/flecha.png" (click)="atras_landing()" style="margin-top: 20px;margin-left: 10px;width: 40px;position: absolute;">
    <div id="contenedor-principal">
		<div id="gameContainer"></div>
	</div>
</div>
<app-valoraciones></app-valoraciones>
<div id="modal-temp" class="jquery-loading-modal jquery-loading-modal" style="z-index: 9899999;">
    <div id="bg-modal-cargando" class="jquery-loading-modal__bg bg-cargando-normal" style="opacity: 1;">
    </div>
    <div class="jquery-loading-modal__info-box">
      <div class="jquery-loading-modal__animation">
      </div>
      <div class="jquery-loading-modal__text" style="color: rgb(55, 57, 91);">
        <div><img draggable=false src="/assets/images/cargando_naranja.gif" style="width: 200px;"></div>
      </div>
    </div>
</div>