import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../inicio/services/global.service';
import { ApiService } from '../../inicio/services/api.service';
import { ActivatedRoute } from '@angular/router';

declare var $: any;
declare var pdfMake: any;
@Component({
  selector: 'app-saludo-cumpleanos',
  templateUrl: './saludo-cumpleanos.component.html',
  styleUrls: ['./saludo-cumpleanos.component.css']
})
export class SaludoCumpleanosComponent implements OnInit {

  Alumnos:any = [];
  ListadoCompleto:any = [];
  cantPorFecha:any = 0;
  cantHoy:any = 0;
  cantPorSemana:any = 0;
  cantPorMes:any = 0;
  fechaFormateada:any = "";
  Cursos:any=[];
  Letras:any=[];
  fechaActual:any="";
  tipoBusqueda:any="COLEGIO";
  nombreColegio:any="";
  plataforma_B2C:any="";
  constructor(public global:GlobalService, private route: ActivatedRoute, public api:ApiService) {
    this.route.params.subscribe((params2:any) => {
      // console.log(params)
      
      // this.api.getDatosZonaHoraria().then((datos:any)=>{
      //   console.log(datos)
        this.api.getCursosInstitucion(params2).then((reponse:any)=>{
          console.log(reponse)
          this.nombreColegio = reponse.NOMBRE+ " • "+reponse.COMUNA;

          this.Cursos = reponse.CURSOS.filter(A=> A.ESTADO.toString()==="1" && A.NOMBRE.toUpperCase() !== "FUNCIONARIOS" );
          this.Cursos = this.Cursos.sort((a,b)=>a.ORDEN-b.ORDEN);


        });
        let params = JSON.parse(JSON.stringify(params2));
        params["promocion"] = params.promocion;
        params["plataforma"] = params.plataforma;
        this.plataforma_B2C = params.plataforma;
        console.log(params)
        this.api.getEstudiantesCumpleaneros(params).then((reponse:any)=>{

          // reponse.ALUMNOS = alumnosOrdenados;
          // monthsUntilBirthday
          // daysUntilBirthday
          // nextBirthday
          // cuantosCumple

          console.log(reponse)
  
          reponse.ALUMNOS.map(alumno=>{
          //   var fechaNacimiento:any = new Date(alumno.FECHANACIMIENTO);
          //   // console.log(fechaNacimiento)
          //   // var nextBirthday:any = new Date(alumno.nextBirthday);
          //   // if(!alumno.nextBirthday){
            // alumno.age =  "as";
          //   //   nextBirthday = new Date();
          //   //   nextBirthday.setUTCHours(0, 0, 0, 0);
          //   //   nextBirthday.setMinutes(0);
          //   //   nextBirthday.setSeconds(0);
          //   //   nextBirthday.setMilliseconds(0);
          //   //   alumno.nextBirthday = new Date(nextBirthday);
  
          //   // }
          //   // var fechaNacimiento:any = new Date(alumno.FECHANACIMIENTO);
          //   // var diferencia = nextBirthday - fechaNacimiento;
          //   // alumno.cuantosCumple = new Date(diferencia).getUTCFullYear() - 1970;
          return alumno;
          });
  
  
          this.ListadoCompleto = reponse.ALUMNOS;//this.filtrarValoresUnicosPorDosClaves(reponse.ALUMNOS, "_id", "cuantosCumple");
  
          console.log(this.ListadoCompleto)
          
          let fechaActual:any = new Date();
          // fechaActual.setUTCHours(0, 0, 0, 0);
          // fechaActual.setHours(0);
          // fechaActual.setMinutes(0);
          // fechaActual.setSeconds(0);
          // fechaActual.setMilliseconds(0);  
          // fechaActual = new Date(fechaActual);
  
  
          const fechaMinima = fechaActual.toISOString().split('T')[0];
          const fechaMaxima = new Date(fechaActual);
          fechaMaxima.setDate(fechaActual.getDate() + 366);
          const fechaMaximaFormateada = fechaMaxima.toISOString().split('T')[0];
  

          this.fechaActual = fechaMinima;

          $("#fecha").attr("min", fechaMinima);
          $("#fecha").attr("max", fechaMaximaFormateada);
  
          $("#semana").attr("min", fechaMinima);
          $("#semana").attr("max", fechaMaximaFormateada);
  
          $("#mes").attr("min", fechaMinima);
          $("#mes").attr("max", fechaMaximaFormateada);
  
          const formatoFecha = {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric'
          };
          // console.error(fechaActual)
          const fechaFormateada = fechaActual.toLocaleDateString("es-CL", formatoFecha);
          this.fechaFormateada = `Cumpleaños Hoy\n${fechaFormateada}`;
  
          const manana = new Date();
          manana.setHours(0, 0, 0, 0);
          manana.setDate(fechaActual.getDate() );
          const fechaManana = manana.toISOString().split('T')[0];
          $("#fecha").val(fechaManana);
          this.mostrarListado("FECHA", true);
  
          const semanaInput = $("#semana");
          const semanaActual = fechaActual.getFullYear() + '-W' + String(this.getWeekNumber(fechaActual)).padStart(2, '0');
          semanaInput.val(semanaActual).trigger("change");
  
          this.cambiarSemana();
  
          const mesInput = $("#mes");
          const mesActual = `${fechaActual.getFullYear()}-${String(fechaActual.getMonth() + 1).padStart(2, '0')}`;
          mesInput.val(mesActual).trigger("change");
  
          this.cambiarMes();
          this.textoManana = "Mañana";
          this.mostrarListado("HOY", false);
  
        })
  
        this.iniciar();
      });
  }
  isHoy:any=true;
  cambiarCurso(){
    this.Letras = $("#curso option:selected").prop("class").split(",");
    this.cargarSelects();
  }
  cambiarMes(){

    const partes = $("#mes").val().split("-");
    const year: number = parseInt(partes[0]);
    const month: number = parseInt(partes[1]) - 1; // Restamos 1 ya que en JavaScript los meses van de 0 a 11
    const fechaInicio = new Date(year, month, 1);
    const fechaFin = new Date(year, month + 1, 0);
    this.fechaInicioM = fechaInicio;
    this.fechaFinM = fechaFin;

    if($("#rango").val() != "todos"){
      this.mostrarListado("MES",false);
    }else{
      this.mostrarListado("TODOS",false);
    }
    

  }
  getWeekNumber(d){
    // Copia de la fecha para evitar efectos secundarios
    const dateCopy:any = new Date(d.getTime());

    // Ajuste para que el año comience el 4 de enero
    dateCopy.setHours(0, 0, 0, 0);
    dateCopy.setDate(dateCopy.getDate() + 4 - (dateCopy.getDay() || 7));

    // Calcular el número de semana
    let yearStart:any = new Date(dateCopy.getFullYear(), 0, 1);
    let weekNumber = Math.ceil(((dateCopy - yearStart) / 86400000 + 1) / 7);

    return weekNumber;
  }

  rangoFechas:any = "";
  fechaInicio:any;
  fechaFin:any;

  fechaInicioM:any;
  fechaFinM:any;
  textoManana:any = "Mañana";
  cambiarSemana(){
    const semanaInput = $("#semana");
  // const rangoFechasOutput = $("#rangoFechas");
    console.log("menene")

  // semanaInput.on("input", function() {
    const semanaSeleccionada = semanaInput.val();
    const partes = semanaSeleccionada.split("-W");
    const year = partes[0]; 
    const weekNumber = partes[1]; 

    const fechaInicio = new Date(year, 0, 1 + (weekNumber - 1) * 7);
    const fechaFin = new Date(year, 0, 1 + (weekNumber - 1) * 7 + 6);

    const formatoFecha = { year: 'numeric', month: 'long', day: 'numeric' };

    this.fechaInicio = fechaInicio;
    this.fechaFin = fechaFin;
    const rangoFechasTexto = `${fechaInicio.toLocaleDateString("es-CL")} a ${fechaFin.toLocaleDateString("es-CL")}`;
    this.rangoFechas = rangoFechasTexto;


  

    this.mostrarListado("SEMANA", false);


  // });
  }
  Cancion:any = new Audio("assets/images/aplicativos/cumpleanos/cancion.mp3");
  detenerCancion(){
    $(".boton-detener").addClass("hidden");
    this.Cancion.pause();
    this.Cancion.currentTime = 0;
  }
  cantarCumpleanos(){
    this.Cancion.play();
    $(".boton-detener").removeClass("hidden");

  }
  cambiarFiltro(){
    this.tipoBusqueda = $("#tipo-busqueda").val();
    if($("#tipo-busqueda").val() === "COLEGIO"){
      $(".filtro-curso").addClass("hidden");

    }
    else{
      $(".filtro-curso").removeClass("hidden");
    }
    this.cargarSelects();
  }
  
  cargarSelects(){

    $(".select-indicado").addClass("hidden");
    
    if($("#rango").val() === "dia"){
      $(".dia").removeClass("hidden");
      $(".dia").trigger("change");
      this.mostrarListado("FECHA",false);
    }
    if($("#rango").val() === "semana"){
      $(".semana").removeClass("hidden");
      $(".semana").trigger("change")
      this.cambiarSemana();
      this.mostrarListado("SEMANA",false);
    }
    if($("#rango").val() === "mes"){
      $(".mes").removeClass("hidden");
      $(".mes").trigger("change")
      this.cambiarMes();
      this.mostrarListado("MES",false);
    }
    if($("#rango").val() === "todos"){
      $(".mes").removeClass("hidden");
      $(".mes").trigger("change")
      this.cambiarMes();
      this.mostrarListado("TODOS",false);
    }
    
  }
  AlumnoSeleccionado:any = {};
  Mensaje:any = "";
  preguntarRegalo(alumno){
    let self_ = this;
    this.global.alerta.fire({
      allowOutsideClick: false,
      allowEscapeKey: false,
      confirmButtonText: `<span style="font-size: 20px">Regalar</span>`,
      confirmButtonAriaLabel: "Regalar",
      cancelButtonText: `<span style="font-size: 20px">Cancelar</span>`,
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonAriaLabel: "Cancelar",
      title: '',
      html: `<div style="font-size: 18px; padding: 20px;">
        ¿Está segur@ que desea acceder al regalo si hoy no es el día de cumpleaños de <b>${alumno.NOMBRES.split(" ")[0]}</b>?</div>`,
      text: ``
    }).then(function (confirm) {
      if(confirm.isConfirmed) self_.regalar(alumno);
    });
  }
  fraseAnterior(){
    this.contadorFraseActual--;
    if(this.contadorFraseActual < 0 ) this.contadorFraseActual = this.mensajesCumpleanos.length - 1;
    this.Mensaje = this.mensajesCumpleanos[ this.contadorFraseActual ];
  }
  fraseSiguiente(){
    this.contadorFraseActual++;
    if(this.contadorFraseActual >= this.mensajesCumpleanos.length) this.contadorFraseActual = 0;
    this.Mensaje = this.mensajesCumpleanos[ this.contadorFraseActual ]; 
  }
  contadorFraseActual = -1;
  regalar(alumno){
    console.log(alumno)
    this.AlumnoSeleccionado = alumno

  //   var mensajesCumpleanosNina = [
  //     "¡Feliz cumpleaños! Que tu día esté lleno de diversión, risas y muchos regalos.",
  //     "En tu día especial, que recibas muchos abrazos y sonrisas. ¡Felicidades, princesa!",
  //     "Que tu cumpleaños esté lleno de magia y aventuras emocionantes. ¡Feliz día, pequeña soñadora!",
  //     "¡Feliz cumpleaños! Que el día de hoy sea tan dulce como los pasteles que vas a comer.",
  //     "Que en tu cumpleaños brilles como una estrella y te diviertas como nunca. ¡Felicidades!",
  //     "En este día tan especial, que los unicornios y hadas te acompañen para hacer realidad tus sueños. ¡Feliz cumpleaños!",
  //     "¡Feliz cumpleaños! Que este día sea tan brillante y colorido como tú eres.",
  //     "Que tu día esté lleno de aventuras emocionantes y momentos mágicos. ¡Felicidades, pequeña exploradora!",
  //     "En tu cumpleaños, que los superhéroes te acompañen para hacer de este día una gran aventura. ¡Feliz día, valiente princesa!",
  //     "¡Feliz cumpleaños! Que este año te traiga muchas risas, juegos y momentos inolvidables.",
  //     "Que en tu día especial recibas todo el amor y la alegría que mereces. ¡Felicidades, pequeña princesa!",
  //     "En tu cumpleaños, que los cuentos de hadas cobren vida y te lleven a tierras mágicas. ¡Feliz día, dulce niña!",
  //     "¡Feliz cumpleaños! Que tu día esté lleno de sorpresas y momentos felices.",
  //     "Que este año esté lleno de diversión, aventuras y sueños cumplidos. ¡Felicidades, pequeña soñadora!",
  //     "En tu cumpleaños, que los juegos y risas llenen tu día de alegría. ¡Feliz día, princesita!",
  //     "¡Feliz cumpleaños! Que cada momento de este día esté lleno de diversión y felicidad.",
  //     "Que en tu día especial recibas todo el cariño y los abrazos que mereces. ¡Felicidades, pequeña estrella!",
  //     "En tu cumpleaños, que los días sean tan llenos de alegría como los globos de colores. ¡Feliz día, pequeña aventurera!",
  //     "¡Feliz cumpleaños! Que tus sueños sean tan grandes como los castillos y tan brillantes como las estrellas.",
  //     "Que este día esté lleno de dulces momentos y grandes sorpresas. ¡Felicidades, pequeña princesa!",
  //     "En tu cumpleaños, que los amigos y la diversión llenen tu día de alegría. ¡Feliz día, pequeña bailarina!",
  //     "¡Feliz cumpleaños! Que este año esté lleno de alegría, amor y muchos abrazos.",
  //     "Que tu día especial esté lleno de magia y alegría. ¡Felicidades, pequeña soñadora!",
  //     "En tu cumpleaños, que los juegos y risas llenen tu día de alegría. ¡Feliz día, pequeña princesa!",
  //     "¡Feliz cumpleaños! Que tu día sea tan maravilloso como tú eres.",
  //     "Que en tu cumpleaños brilles como una estrella y te diviertas como nunca. ¡Felicidades, pequeña!",
  //     "En este día tan especial, que los unicornios y hadas te acompañen para hacer realidad tus sueños. ¡Feliz cumpleaños, pequeña princesa!",
  //     "¡Feliz cumpleaños! Que este día sea tan especial como tú eres.",
  //     "Que en tu día especial recibas todo el cariño y los abrazos que mereces. ¡Felicidades, pequeña estrella!",
  //     "En tu cumpleaños, que los amigos y la diversión llenen tu día de alegría. ¡Feliz día, pequeña exploradora!"
  // ];
  
  // var mensajesCumpleanosNino = [
  //     "¡Feliz cumpleaños! Que tu día esté lleno de diversión, risas y muchos regalos.",
  //     "En tu día especial, que recibas muchos abrazos y sonrisas. ¡Felicidades, campeón!",
  //     "Que tu cumpleaños esté lleno de aventuras emocionantes y momentos emocionantes. ¡Feliz día, pequeño aventurero!",
  //     "¡Feliz cumpleaños! Que el día de hoy sea tan emocionante como un día en el parque de diversiones.",
  //     "Que en tu cumpleaños brilles como una estrella y te diviertas como nunca. ¡Felicidades!",
  //     "En este día tan especial, que los dinosaurios y superhéroes te acompañen para hacer realidad tus sueños. ¡Feliz cumpleaños!",
  //     "¡Feliz cumpleaños! Que este día sea tan brillante y colorido como tú eres.",
  //     "Que tu día esté lleno de aventuras emocionantes y momentos mágicos. ¡Felicidades, pequeño explorador!",
  //     "En tu cumpleaños, que los superhéroes te acompañen para hacer de este día una gran aventura. ¡Feliz día, valiente campeón!",
  //     "¡Feliz cumpleaños! Que este año te traiga muchas risas, juegos y momentos inolvidables.",
  //     "Que en tu día especial recibas todo el amor y la alegría que mereces. ¡Felicidades, pequeño héroe!",
  //     "En tu cumpleaños, que los superhéroes cobren vida y te lleven a tierras mágicas. ¡Feliz día, pequeño luchador!",
  //     "¡Feliz cumpleaños! Que tu día esté lleno de sorpresas y momentos felices.",
  //     "Que este año esté lleno de diversión, aventuras y sueños cumplidos. ¡Felicidades, pequeño soñador!",
  //     "En tu cumpleaños, que los juegos y risas llenen tu día de alegría. ¡Feliz día, campeón!",
  //     "¡Feliz cumpleaños! Que cada momento de este día esté lleno de diversión y felicidad.",
  //     "Que en tu día especial recibas todo el cariño y los abrazos que mereces. ¡Felicidades, pequeña estrella!",
  //     "En tu cumpleaños, que los días sean tan llenos de alegría como los globos de colores. ¡Feliz día, pequeño aventurero!",
  //     "¡Feliz cumpleaños! Que tus sueños sean tan grandes como los castillos y tan brillantes como las estrellas.",
  //     "Que este día esté lleno de dulces momentos y grandes sorpresas. ¡Felicidades, pequeño héroe!",
  //     "En tu cumpleaños, que los amigos y la diversión llenen tu día de alegría. ¡Feliz día, pequeño bailarín!",
  //     "¡Feliz cumpleaños! Que este año esté lleno de alegría, amor y muchos abrazos.",
  //     "Que tu día especial esté lleno de magia y alegría. ¡Felicidades, pequeño soñador!",
  //     "En tu cumpleaños, que los juegos y risas llenen tu día de alegría. ¡Feliz día, campeón!",
  //     "¡Feliz cumpleaños! Que tu día sea tan maravilloso como tú eres.",
  //     "Que en tu cumpleaños brilles como una estrella y te diviertas como nunca. ¡Felicidades, pequeño!",
  //     "En este día tan especial, que los dinosaurios y superhéroes te acompañen para hacer realidad tus sueños. ¡Feliz cumpleaños, pequeño campeón!",
  //     "¡Feliz cumpleaños! Que este día sea tan especial como tú eres.",
  //     "Que en tu día especial recibas todo el cariño y los abrazos que mereces. ¡Felicidades, pequeña estrella!",
  //     "En tu cumpleaños, que los amigos y la diversión llenen tu día de alegría. ¡Feliz día, pequeño explorador!"
  // ];
  
    this.mensajesCumpleanos = [
      "Esperamos que tu día esté lleno de sorpresas y sonrisas. ¡Felicidades, [NOMBRE]!",
      "Que recibas muchos regalos y abrazos en tu cumpleaños. ¡Feliz cumpleaños, [NOMBRE]!",
      "Que tus deseos se hagan realidad en este día especial. ¡Feliz cumpleaños, [NOMBRE]!",
      "¡Hola, [NOMBRE]! Disfruta cada momento y come mucho pastel. ¡Feliz cumpleaños!",
      "Que tu cumpleaños esté lleno de aventuras y diversión. ¡Feliz día, [NOMBRE]!",
      "Que tengas un día lleno de risas y momentos inolvidables. ¡Te deseamos lo mejor, [NOMBRE]!",
      "¡Hola, [NOMBRE]! Esperamos que te diviertas mucho en tu cumpleaños. ¡Feliz día!",
      "Que este año sea aún mejor que el anterior. ¡Feliz cumpleaños, [NOMBRE]!",
      "Que tu cumpleaños esté lleno de dulces y alegría. ¡Feliz día, [NOMBRE]!",
      "¡Hola, [NOMBRE]! Que cada año te traiga más y más felicidad. ¡Feliz cumpleaños!",
      "Que el día de hoy esté lleno de sorpresas y regalos. ¡Feliz cumpleaños, [NOMBRE]!",
      "Que tu cumpleaños sea tan especial como tú. ¡Feliz día, [NOMBRE]!",
      "¡Hola, [NOMBRE]! Que te diviertas mucho y disfrutes cada momento. ¡Feliz cumpleaños!",
      "Que este día esté lleno de diversión y felicidad. ¡Felicidades, [NOMBRE]!",
      "Que tengas un cumpleaños lleno de magia y alegría. ¡Que lo disfrutes, [NOMBRE]!",
      "¡Hola, [NOMBRE]! ¡Feliz cumpleaños! Que todos tus sueños se hagan realidad.",
      "Que este día esté lleno de amor y abrazos. ¡Feliz cumpleaños, [NOMBRE]!",
      "Que tu cumpleaños sea tan brillante como tú. ¡Feliz día, [NOMBRE]!",
      "¡Hola, [NOMBRE]! Que este año esté lleno de grandes aventuras. ¡Feliz cumpleaños!",
      "Que este día esté lleno de momentos mágicos. ¡Felicidades, [NOMBRE]!",
      "Que tu cumpleaños esté lleno de risas y alegría contagiosa. ¡Feliz día, [NOMBRE]!",
      "¡Hola, [NOMBRE]! Que este año esté lleno de sonrisas y diversión. ¡Feliz cumpleaños!",
      "Que la pases genial con tus amigos celebrando tu día especial. ¡Feliz cumpleaños, [NOMBRE]!",
      "Que tengas un cumpleaños lleno de emociones y aventuras. ¡Feliz día, [NOMBRE]!",
      "Que este día esté lleno de sorpresas y felicidad. ¡Te deseamos un gran día, [NOMBRE]!",
      "Que este año te traiga mucha felicidad y amor. ¡Un gran abrazo, [NOMBRE]!",
      "Que tu cumpleaños sea un hermoso día para recordar. ¡Feliz día, [NOMBRE]!",
      "¡Hola, [NOMBRE]! Que este día esté lleno de momentos especiales. ¡Nuestros mejores deseos en tu cumpleaños!",
      "Que te rodeen de amor y felicidad en tu día y todo el año. ¡Feliz cumpleaños, [NOMBRE]!",
      "¡Feliz día, [NOMBRE]! Que tu cumpleaños esté lleno de magia y alegría. ¡Felicidades!",
      "Que tu día esté lleno de alegría y diversión. ¡Feliz cumpleaños, [NOMBRE]!",
      "¡Hola, [NOMBRE]! Esperamos que te diviertas mucho hoy. ¡Feliz día de cumpleaños!",
      "Que recibas muchos abrazos y regalos en tu día especial. ¡Feliz día, [NOMBRE]!",
      "¡Hola, [NOMBRE]! Que este año esté lleno de aventuras emocionantes. ¡Feliz cumpleaños!",
      "Que tu día esté lleno de risas y momentos inolvidables. ¡Feliz cumpleaños, [NOMBRE]!",
      "¡Hola, [NOMBRE]! Que este año te traiga muchas sorpresas y sonrisas. ¡Felicidades en tu cumpleaños!",
      "Que disfrutes al máximo tu día especial y crees recuerdos increíbles. ¡Feliz día, [NOMBRE]!",
      "¡Hola, [NOMBRE]! Que este día sea tan especial como tú. ¡Un abrazo y felicidades!",
      "Que la magia de este día te acompañe siempre. ¡Feliz cumpleaños, [NOMBRE]!",
      "¡Hola, [NOMBRE]! ¡Feliz día de cumpleaños! Que recibas mucho amor y cariño.",
      "Que este cumpleaños sea solo el comienzo de un año maravilloso. ¡Feliz día, [NOMBRE]!",
      "¡Hola, [NOMBRE]! Que hoy brilles más que nunca. ¡Feliz cumpleaños!",
      "Que este año esté lleno de nuevas aventuras y emociones. ¡Feliz cumpleaños, [NOMBRE]!",
      "¡Hola, [NOMBRE]! ¡Feliz día de cumpleaños! Que hoy te sientas especial y disfrutes cada momento.",
      "Que este año esté lleno de sueños hechos realidad. ¡Feliz día, [NOMBRE]!",
      "¡Hola, [NOMBRE]! ¡Feliz cumpleaños! Que hoy celebres con mucha alegría junto a tus seres queridos.",
      "Que en este día recibas muchos abrazos y buenos deseos. ¡Feliz cumpleaños, [NOMBRE]!",
      "¡Hola, [NOMBRE]! Que hoy sea un día lleno de sorpresas. ¡Feliz día de cumpleaños!",
      "Que este cumpleaños sea el mejor de todos. ¡Feliz día, [NOMBRE]!",
      "¡Feliz cumpleaños! Que hoy sea un día mágico y lleno de felicidad. ¡Hola, [NOMBRE]!"
  ];
  if(alumno.age>11){
    this.mensajesCumpleanos = [
      "Esperamos que tengas un día increíble y lleno de alegrías. ¡Feliz cumpleaños, [NOMBRE]!",
      "Que este año te traiga mucha alegría y nuevas aventuras. ¡Feliz día, [NOMBRE]!",
      "¡Hola, [NOMBRE]! Que tu día esté lleno de risas y buenos momentos. ¡Feliz cumpleaños!",
      "Que todos tus deseos se hagan realidad en este día especial. ¡Feliz cumpleaños, [NOMBRE]!",
      "Esperamos que tu cumpleaños esté lleno de sorpresas y felicidad. ¡Un abrazo, [NOMBRE]!",
      "Que este nuevo año te traiga muchas bendiciones. ¡Felicidades, [NOMBRE]!",
      "Que hoy sea el comienzo de un año fantástico para ti. ¡Un gran abrazo, [NOMBRE]!",
      "Que tu cumpleaños esté lleno de amor, risas y mucha diversión. ¡Feliz día, [NOMBRE]!",
      "¡Hola,[NOMBRE]! Que este año esté lleno de éxitos y logros para ti. ¡Feliz cumpleaños!",
      "Deseamos que disfrutes cada momento de este día especial. ¡Feliz cumpleaños, [NOMBRE]!",
      "Que este cumpleaños sea el mejor de todos hasta ahora. ¡Feliz día, [NOMBRE]!",
      "Que hoy sientas que te rodea mucho amor y felicidad. ¡Feliz cumpleaños, [NOMBRE]!",
      "Que este día esté lleno de celebración y buenos amigos. ¡Feliz cumpleaños, [NOMBRE]!",
      "Que cada momento de hoy sea memorable y lleno de alegría. ¡Feliz día, [NOMBRE]!",
      "¡Hola, [NOMBRE]! Que este nuevo año te traiga muchas alegrías y nuevas oportunidades. ¡Feliz cumpleaños!",
      "Que hoy sientas el cariño y amistad de todos quienes te quieren. ¡Feliz cumpleaños, [NOMBRE]!",
      "Que este año esté lleno de momentos inolvidables y grandes sorpresas. ¡Feliz día, [NOMBRE]!",
      "¡Hola, [NOMBRE]! Que este día esté lleno de regalos y sonrisas. ¡Feliz cumpleaños!",
      "Que este nuevo año te traiga mucha alegría y satisfacción. ¡Feliz cumpleaños, [NOMBRE]!",
      "Que tu cumpleaños esté lleno de diversión y momentos emocionantes. ¡Feliz día, [NOMBRE]!",
      "¡Hola, [NOMBRE]! ¡Feliz cumpleaños! Que este día esté lleno de amor y gratitud.",
      "¡Feliz cumpleaños, [NOMBRE]! Que este año esté lleno de nuevas experiencias y aprendizajes.",
      "Que este cumpleaños sea el mejor hasta ahora y el inicio de un gran año. ¡Feliz día, [NOMBRE]!",
      "¡Hola, [NOMBRE]! Que este cumpleaños esté lleno de buenos amigos y risas interminables. ¡Muchas felicidades!",
      "Que tu día sea de principio a fin de alegría y buenos deseos. ¡Feliz cumpleaños, [NOMBRE]!",
      "Que este año esté lleno de sueños cumplidos y momentos felices. ¡Feliz día, [NOMBRE]!",
      "¡Feliz cumpleaños! Que este día te traiga muchas sorpresas y momentos especiales. ¡Un gran abrazo, [NOMBRE]!",
      "Te deseamos un excelente día y un muy buen cumpleaños. ¡Felicidades, [NOMBRE]!",
      "Que este cumpleaños sea solo el comienzo de un año increíble para ti. ¡Feliz día, [NOMBRE]!",
      "¡Hola, [NOMBRE]! Que hoy celebres en grande y crees recuerdos para toda la vida. ¡Feliz cumpleaños!",
      "Que este año te traiga muchas bendiciones y alegrías. ¡Feliz cumpleaños, [NOMBRE]!",
      "¡Hola, [NOMBRE]! Que disfrutes de este día especial con tus seres queridos. ¡Feliz día de cumpleaños!",
      "¡Felicitaciones, [NOMBRE]! Que este año nuevo de vida esté lleno de éxitos y felicidad.",
      "¡Hola, [NOMBRE]! Que hoy celebres en abundante amor y buenos momentos. ¡Feliz cumpleaños!",
      "Que este día te traiga muchas sonrisas y gratas sorpresas. ¡Feliz cumpleaños, [NOMBRE]!",
      "¡Hola, [NOMBRE]! Que cada día de este nuevo año sea tan especial como hoy.",
      "¡Felicidades, [NOMBRE]! Que este cumpleaños marque el inicio de un año lleno de oportunidades y logros. ¡Feliz cumpleaños!",
      "¡Hola, [NOMBRE]! Que este nuevo ciclo esté lleno de momentos inolvidables. ¡Feliz día de cumpleaños!",
      "Que la vida te regale muchos motivos para sonreír en este nuevo año. ¡Feliz cumpleaños, [NOMBRE]!",
      "¡Hola, [NOMBRE]! Que hoy celebres recibiendo mucho cariño y afecto. ¡Feliz cumpleaños!",
      "Que este día especial esté lleno de amor, paz y gratitud. ¡Felicidades, [NOMBRE]!",
      "¡Hola, [NOMBRE]! Que este nuevo año te traiga muchas bendiciones y momentos de felicidad. ¡Felicidades en tu cumpleaños!",
      "Que este día esté lleno de alegría y celebración. ¡Feliz cumpleaños, [NOMBRE]!",
      "¡Hola, [NOMBRE]! Que cada momento de este día sea un recuerdo para atesorar. ¡Feliz cumpleaños!",
      "Que este año te traiga salud, amor y prosperidad en abundancia. ¡Felicidades, [NOMBRE]!",
      "¡Hola, [NOMBRE]! ¡Feliz día de cumpleaños! Que este nuevo año de vida te colme de felicidad y éxitos.",
      "Que la vida te regale muchos momentos de felicidad y plenitud. ¡Feliz cumpleaños, [NOMBRE]!",
      "¡Hola, [NOMBRE]! Que este día esté lleno de buenos deseos y alegrías compartidas. ¡Que sea un lindo cumpleaños!",
      "Que este día especial esté lleno de momentos memorables y sorpresas agradables. ¡Felicidades, [NOMBRE]!",
      "¡Hola, [NOMBRE]! Que este año te traiga todo lo que deseas y más. ¡Feliz cumpleaños!"
    ];
   }

   this.mensajesCumpleanos = this.mensajesCumpleanos.map(A=>A.replaceAll("[NOMBRE]",alumno.NOMBRES.split(" ")[0]))

    this.contadorFraseActual =  Math.floor(Math.random() * this.mensajesCumpleanos.length);
    this.Mensaje = this.mensajesCumpleanos[ this.contadorFraseActual ];
    $(".main-app").addClass("hidden");
    this.CantidadVelas = alumno.age;
    $(".second-app").removeClass("hidden");

    
  }
  mensajesCumpleanos = [];
  
  filtroPIEMostrar:any=false;
  filtroPIE(){
    if($("#flexCheckDefault").is(":checked")){
      console.log("muestra")
      this.filtroPIEMostrar = true;
    }
    else{
      this.filtroPIEMostrar = false;
    }
    this.cargarSelects();
  }


  enviar_atras(){
    window.parent.postMessage({ type: 'navigate', url: "RecursosApoyo", recurso: "Cumpleaños"}, '*');
  }
  volver(){
    this.detenerCancion();
    $(".main-app").removeClass("hidden");
    $(".second-app").addClass("hidden");
  }
  hoyEsCumpleanos:any=false;
  mostrarListado(tipo, onlyView = false){
    let alumnos = [];
    this.isHoy = false;
    this.hoyEsCumpleanos = false;
    $("#panel-tabla").removeClass("custom-border-HOY");
    $("#panel-tabla").removeClass("custom-border-FECHA");
    $("#panel-tabla").removeClass("custom-border-SEMANA");
    $("#panel-tabla").removeClass("custom-border-MES");
    $("#panel-tabla").addClass("custom-border-"+tipo);
    
    if(tipo === "FECHA" || tipo === "HOY"){
      console.log($("#fecha").val())
      
      let fI:any = new Date($("#fecha").val());
      this.isHoy = true;
      if(this.fechaActual === fI.toISOString().split('T')[0]){
        this.hoyEsCumpleanos = true;
      }
      alumnos = this.ListadoCompleto.filter(A=>A.nextBirthday === fI.toISOString().split('T')[0]);
      this.cantPorFecha = alumnos.length;
      this.textoManana = "el";
    }

    if(tipo === "SEMANA"){
      
      let fI:any = new Date(this.fechaInicio.toISOString());
      
      fI.setDate(fI.getDate() - 1);  
      let fechas = [];
      for(let i=0; i < 7;i++){
        fI.setDate(fI.getDate() + 1);  
        fechas.push(fI.toISOString().split('T')[0]);
      }
      alumnos = this.ListadoCompleto.filter(A=>{
        
        for(let i=0; i < fechas.length; i++){
          if(A.nextBirthday === fechas[i]){
            return true;    
          }
        }

        return false;
      })
      // alumnos = this.filtrarPorFecha(this.ListadoCompleto, manana, manana2);
      this.cantPorSemana = alumnos.length;
    }

    if(tipo === "MES"){
      const manana = new Date(this.fechaInicioM);
      alumnos = this.ListadoCompleto.filter(A=>{
        return (A.FECHANACIMIENTO.split('T')[0].split("-")[1] === manana.toISOString().split('T')[0].split("-")[1]) && (A.nextBirthday.split("-")[0].toString() === manana.toISOString().split('T')[0].split("-")[0].toString())
      })
      this.cantPorMes = alumnos.length;
    }

    if(tipo === "TODOS"){
      // console.log("mostrar todos los cumpleaños")
      // console.log(this.ListadoCompleto)
      const manana = new Date(this.fechaInicioM);
      alumnos = this.ListadoCompleto.filter(A=>{
        return (A.FECHANACIMIENTO.split('T')[0].split("-")[1] === manana.toISOString().split('T')[0].split("-")[1])
      })
    }


    if(onlyView) return; 
    this.Alumnos = alumnos;

    // if
    if(this.filtroPIEMostrar){
      this.Alumnos = this.Alumnos.filter(A=>A.DIAGNOSTICO)
    }

    if($("#tipo-busqueda").val() === "CURSO"){
      this.Alumnos = this.Alumnos.filter(A=>A.ALUMNOCURSO.ID_CURSO === $("#curso").val() && A.ALUMNOCURSO.DETALLE === $("#letras").val());
      // console.log(this.Alumnos)
    }
    // console.log("cargando")

  }


  
  filtrarPorFecha(arr_, fechaInicio, fechaFin) {
    // Filtrar por rango de fechas
    let arr = JSON.parse(JSON.stringify(arr_));
    const filtradoPorFecha = arr.filter((item) => {
      const fechaItem = new Date(item.nextBirthday); // Asegúrate de que "fecha" sea la propiedad correcta en tu objeto
      if (fechaInicio && fechaFin) {
        return fechaItem >= fechaInicio && fechaItem <= fechaFin;
      } else if (fechaInicio) {
        return fechaItem >= fechaInicio;
      } else if (fechaFin) {
        return fechaItem <= fechaFin;
      }
      return true; // Si no se proporcionan fechas, devolver todos los elementos
    });
    // Tomar los primeros 5 elementos
    const resultadoCortado = filtradoPorFecha.slice(0, 9999);
  
    return resultadoCortado;
  }
  filtrarValoresUnicosPorDosClaves(arr, idKey1, idKey2) {
    // Objeto para realizar un seguimiento de los elementos únicos basándose en dos claves
    const uniqueMap = {};
  
    // Filtrar los elementos del array
    const resultado = arr.filter((item) => {
      const itemId1 = item[idKey1];
      const itemId2 = item[idKey2];
      const combinedKey = `${itemId1}-${itemId2}`;
  
      // Si la combinación de identificadores no está en el objeto, añadirla y devolver true
      if (!uniqueMap[combinedKey]) {
        uniqueMap[combinedKey] = true;
        return true;
      }
  
      // Si la combinación de identificadores ya está en el objeto, devolver false
      return false;
    });
  
    return resultado;
  }
  obtenerMesEnEspanol(inputValue) {
    // Separar el año y el mes del valor de entrada
    const [anio, mes] = inputValue.split('-');
    
    // Convertir el mes a número y restar 1 ya que los meses en JavaScript van de 0 a 11
    const mesNumero = parseInt(mes, 10) - 1;
    
    // Crear un nuevo objeto de fecha con el año y el mes
    const fecha = new Date(anio, mesNumero);
    
    // Obtener el nombre del mes en español
    const opcionesMes:any = { month: 'long' };
    let nombreMes = fecha.toLocaleDateString('es-ES', opcionesMes);
    nombreMes = nombreMes.charAt(0).toUpperCase() + nombreMes.slice(1);
  
    return nombreMes+" de "+anio;
  }
  

  generarPdf(){
    // Datos de ejemplo para la tabla
    const datos = [
      { nombre: 'Juan', edad: 25, ciudad: 'Madrid' },
      { nombre: 'María', edad: 30, ciudad: 'Barcelona' },
      { nombre: 'Pedro', edad: 35, ciudad: 'Sevilla' },
      { nombre: 'Luisa', edad: 28, ciudad: 'Valencia' }
    ];

    // Definir la estructura de la tabla
    const tableData = [];
    // tableData.push(['Nombre', 'F. Nacimiento', 'Curso', "Cumple"]);
    tableData.push([
      { text: 'Nombre', style: 'tableHeader', alignment: 'left' }, 
      { text: 'F. Nacimiento', style: 'tableHeader', alignment: 'center' },
      { text: 'Curso', style: 'tableHeader', alignment: 'center' },
      { text: 'Cumple', style: 'tableHeader', alignment: 'center' }]);
    let self_ = this;
    this.Alumnos.forEach(dato => {
      let nombre:any = {
        stack: [
          { text: dato.NOMBRES+" "+dato.APELLIDOPATERNO+" "+dato.APELLIDOMATERNO, style: 'tableExample', alignment: 'left' }, // Primer formato
          { text: dato.DIAGNOSTICO,  style: 'diagnostico' } // Segundo formato
        ]
      };

      if(!self_.filtroPIEMostrar){
        nombre = { text: dato.NOMBRES+" "+dato.APELLIDOPATERNO+" "+dato.APELLIDOMATERNO, style: 'tableExample', alignment: 'left' } // Primer formato
      }
      tableData.push([
        nombre,
        { text: dato.FECHANACIMIENTO.toString().split('T')[0].split('-').reverse().join('-'), style: 'tableExample', alignment: 'center' },
        { text: (this.plataforma_B2C==="TBT_B2C"?dato.ESCOLARIDAD:dato.ALUMNOCURSO.CURSO+" "+dato.ALUMNOCURSO.DETALLE), style: 'tableExample', alignment: 'center' },
        { text: dato.age+" Años", style: 'tableExample', alignment: 'center' },
      ])
    });

    // Definir el documento
    const documentDefinition = {
      // header: {
      //   // text: "NOMBRE DEL COLEGIO",
      //   style: 'headerStyle'
      // },
      content: [
        { text: 'CUMPLEAÑOS ESTUDIANTES\n'+this.nombreColegio, style: 'header' },
        { text: '\n\n' }, // Salto de línea
        { text: 'Búsqueda: '+($("#tipo-busqueda").val()==="COLEGIO"?"Por Colegio":"Por Curso")+'\nRango: '+($("#rango").val()==="dia"?"Por Día":$("#rango").val()==="semana"?"Por Semana":"Por Mes")+'\nFecha: '+(
          ($("#rango").val()==="dia"?$(".dia").val().toString().split('T')[0].split('-').reverse().join('-'):$("#rango").val()==="semana"?("Semana del "+this.rangoFechas): this.obtenerMesEnEspanol($(".mes").val()))
        ), style: 'filtro' }, // Texto de los filtros
          { text: '\n' }, // Salto de línea
        {
          style: 'tableExample',
          table: {
            headerRows: 1,
            widths: [270, 80, 70,'*'],
            body: tableData
          },
          layout: {
            fillColor: function (i, node) { return (i === 0) ? '#CCCCCC' : null; }
          }
        }
      ],
      styles: {
        headerStyle: {
          bold: true,
          fontSize: 15,
          alignment: 'center',
          margin: [0, 20, 0, 20] // Márgenes: arriba, derecha, abajo, izquierda
        },
        header: {
          fontSize: 13,
          bold: true,
          margin: [0, 0, 0, 0],
          alignment: 'center'
          
        },
        tableHeader: {
          bold: true,
          fontSize: 11,
          color: 'white',
          fillColor: '#3f51b5'
        },
        tableExample: {
          margin: [2.5, 2.5, 2.5, 2.5],
          fontSize: 11,
          alignment: 'center'
        },
        diagnostico: {
          margin: [2.5, 2.5, 2.5, 2.5],
          fontSize: 9,
          alignment: 'left',
          bold: true,
          color: '#3f51b5'
        }
      }
    };

    // Generar el PDF
    pdfMake.createPdf(documentDefinition).download('Listado de Cumpleaños.pdf');
  }
  
  async iniciar(){

    setTimeout(()=>{
      $(".main-app").removeClass("hidden");
      // $(".second-app").removeClass("hidden");

      // console.log(this.CantidadVelas)

      
      
    },1200)

    await this.global.offLoading(2500);

    $("body").css("background-color", "#f9ccfe");
    $("body").css("overflow-x", "hidden");
  }

  
  ImagenesVelas:any = [0,1,2,3,4,5,6,0,1,2,3,4,5,6,0,1,2,3,4,5,6,0,1,2,3,4,5,6,0,1,2,3,4,5,6,0,1,2,3,4,5,6,0,1,2,3,4,5,6,0,1,2,3,4,5,6,0,1,2,3,4,5,6,0,1,2,3,4,5,6,0,1,2,3,4,5,6,0,1,2,3,4,5,6,0,1,2,3,4,5,6];

  Velas: any= [
    [[]], // 0
    [], // 1
    [[70, -10, 1], [20, -10, 1]], // 2
    [[70, -10, 1], [46, 5, 3], [20, -10, 1]], // 3
    [[70, -10, 1], [26, -20, 1], [66, -20, 1], [20, -10, 1]], // 4
    [[70, -10, 1], [46, 5, 3], [26, -20, 1], [66, -20, 1], [20, -10, 1]], // 5
    [[70, -10, 1], [26, -20, 1], [66, -20, 1], [20, -10, 1], [56, -20, 1], [30, -10, 1]], // 6
    [[70, -10, 1], [46, 5, 3], [26, -20, 1], [66, -20, 1], [20, -10, 1], [56, -20, 1], [30, -10, 1]], // 7
    [[70, -10, 2], [26, -20, 1], [34, -25, 1], [62, -27, 1], [66, -20, 1], [20, -10, 1], [56, -20, 1], [30, -10, 1]], // 8
    [[70, -10, 2], [46, 5, 3],  [26, -20, 1], [34, -25, 1], [62, -27, 1], [66, -20, 1], [20, -10, 1], [56, -20, 1], [30, -10, 1]], // 9
    [[70, -10, 2], [19, -20, 1], [76, -20, 1], [26, -20, 1], [24, -12, 1], [62, -27, 1], [66, -20, 1], [18, -5, 1], [56, -20, 1], [30, -5, 1]], // 10
  ]
  // Velas: any= [
    
  //   [74, -18, 1],
  //   [68, -24, 1],
  //   [60, -27, 1],
  //   [51, -29, 1],
  //   [42, -29, 1],
  //   [33, -28, 1],
  //   [25, -25, 1],
  //   [18, -20, 1],
    
  //   [25, -15, 1],

  //   [30, -12, 1],
    
  //   [62, -12, 1],

  //   [65, -15, 1],

  //   [13, -11, 3],
  //   [19, -4, 3],
  //   [26, 0, 3],
    
    
    
  //   [76, -8, 3],
  //   [70, -2, 3],
  //   [63, 2, 3],
  //   [55, 4, 3],
  //   [46, 4, 3],
  //   [36, 4, 3],

  // ];
  // CantidadVelas = this.Velas.length;
  CantidadVelas = 10;
  ngOnInit(): void {
    this.api.set_kpi_plataformas("Saludo de cumpleaños","Ingresó al módulo");
  }

}

