import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RuidometroComponent } from './aplicativos/ruidometro/ruidometro.component';
import { BoardComponent } from './aplicativos/board/board.component';
import { RespirometroComponent } from './aplicativos/respirometro/respirometro.component';
import { SelectorEstudiantesComponent } from './aplicativos/selector-estudiantes/selector-estudiantes.component';
import { SaludoCumpleanosComponent } from './aplicativos/saludo-cumpleanos/saludo-cumpleanos.component';
import { RecursosComponent } from './aplicativos/recursos/recursos.component';
import { ValoracionesComponent } from './aplicativos/valoraciones/valoraciones.component';
import { TemporizadorComponent } from './aplicativos/temporizador/temporizador.component';
import { TemporizadorVisualComponent } from './aplicativos/temporizador-visual/temporizador-visual.component';
import { TemporizadorEmojisComponent } from './aplicativos/temporizador-emojis/temporizador-emojis.component';
import { TemporizadorSensorialComponent } from './aplicativos/temporizador-sensorial/temporizador-sensorial.component';
import { SopaSilabasComponent } from './aplicativos/sopa-silabas/sopa-silabas.component';
import { LandingTemporizadoresComponent } from './aplicativos/landing-temporizadores/landing-temporizadores.component';
import { GeneradorHistoriasComponent } from './aplicativos/generador-historias/generador-historias.component';
import { NubePalabrasComponent } from './aplicativos/nube-palabras/nube-palabras.component';

@NgModule({
  declarations: [AppComponent, RuidometroComponent, BoardComponent, RespirometroComponent, SelectorEstudiantesComponent, SaludoCumpleanosComponent, RecursosComponent, ValoracionesComponent, TemporizadorComponent, TemporizadorVisualComponent, TemporizadorEmojisComponent, TemporizadorSensorialComponent, SopaSilabasComponent, LandingTemporizadoresComponent, GeneradorHistoriasComponent, NubePalabrasComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }