<div class="hidden second-app text-center">
    <!-- <button style="position: absolute; left: 10px; top: 10px;" class="btn" (click)="volver()">Volver</button> -->
    <img draggable="false" class="btn-hover-lsch pulso-css" src="assets/images/abecedario/flecha.png" (click)="volver()" style="left: 0; top: 0; margin-top: 20px; margin-left: 15px; width: 40px;position: absolute;">
    <img draggable="false" src="assets/images/aplicativos/cumpleanos/banderas.png" alt="" width="100%" >

    <div style="position: fixed; z-index: 2; right: .5%; bottom: 10%; cursor: pointer;">
        <img draggable="false" title="Detener" class="boton hidden btn-hover-lsch pulso-css boton-detener" (click)="detenerCancion()" draggable="false" src="assets/images/aplicativos/cumpleanos/detener.png" style="width: 50px; cursor: pointer;">
        <img draggable="false" class="boton btn-hover-lsch pulso-css" (click)="cantarCumpleanos()" draggable="false" src="assets/images/aplicativos/cumpleanos/boton.png" style="width: 150px; cursor: pointer;">
    </div>

    <div style="position: absolute; width: 100%; top: 20vh">
        <h1 style="font-size: 50px;">{{AlumnoSeleccionado.NOMBRES}} {{AlumnoSeleccionado.APELLIDOPATERNO}} {{AlumnoSeleccionado.APELLIDOMATERNO}}</h1>
        <div style="position: relative;">
            <img draggable="false" class="btn-hover-lsch pulso-css c1" src="assets/images/aplicativos/cumpleanos/anterior.png" (click)="fraseAnterior()" width="50px"  style="cursor: pointer;">
            <span style="color: transparent;">ss</span>
            <img draggable="false" class="btn-hover-lsch pulso-css c2" src="assets/images/aplicativos/cumpleanos/siguiente.png" (click)="fraseSiguiente()" width="50px"  style="cursor: pointer; padding-bottom: 2px;">
        </div>
        <h3 style="padding: 0 10%;">{{Mensaje}}</h3>
        <h3>¡Felices {{CantidadVelas}} Años!</h3>
        

        <!-- <button (click)="fraseSiguiente()">Siguiente</button> -->
    </div>
    <br>
    <div id="torta" style="text-align: center;">
        <img draggable="false" src="assets/images/aplicativos/cumpleanos/torta.png" width="100%">
        <img draggable="false" src="assets/images/aplicativos/cumpleanos/{{CantidadVelas}}.png" style="width: 100%; position: absolute; z-index: 2; left: 0%; top: -32%;">
        
        <!-- <img draggable="false" *ngFor="let vela of Velas[CantidadVelas]; index as i" src="assets/images/aplicativos/cumpleanos/vela_{{ImagenesVelas[i]}}.png" 
            alt="Vela de Cumpleaños" style="width: 8%; position: absolute; left: {{vela[0]}}%; top: {{vela[1]}}%; z-index: {{vela[2]}}; "> -->
            
        <!-- <img draggable="false" *ngFor="let vela of [].constructor(CantidadVelas); index as i" src="assets/images/aplicativos/cumpleanos/vela_{{ImagenesVelas[i]}}.png" 
            alt="Vela de Cumpleaños" style="width: 8%; position: absolute; z-index: {{Velas[i][2]}}; left: {{Velas[i][0]}}%; top: {{Velas[i][1]}}%;"> -->
 
    </div>
    
</div>
<div class="hidden main-app">
    <img draggable="false" class="btn-hover-lsch pulso-css" *ngIf="api.ID_INSTITUCION != '' && global.isIframe" src="assets/images/abecedario/flecha.png" (click)="enviar_atras()" style="left: 0; top: 0; margin-top: 20px; margin-left: 15px; width: 40px;position: absolute;">
    <img class="btn-hover efecto-destacado" *ngIf="api.ID_INSTITUCION != '' && global.isIframe" src="assets/images/abecedario/comentarios.svg" (click)="api.enviar_sugerencia('Cumpleaños','extras/saludo-cumpleanos/')">

    <div class="row text-center">
        <h1>Cumpleaños Estudiantes <img src="https://static.vecteezy.com/system/resources/previews/024/742/863/original/birthday-cake-vector-illustration-png.png" alt="" width="100px"></h1>
    </div>

    <br><br>
    
    <div class="row">
        <div class="col-sm-2 col-sm-offset-{{tipoBusqueda==='COLEGIO'?'3':'2'}}">
            <label>Búsqueda:</label>
            <select name="" id="tipo-busqueda" class="form-control" (change)="cambiarFiltro()">
                <option value="CURSO">Por Curso</option>
                <option value="COLEGIO" selected>Por Colegio</option>
            </select>
        </div>
        <div class="col-sm-1 filtro-curso hidden">
            <label>Curso:</label>
            <select name="" id="curso" class="form-control" (change)="cambiarCurso()">
                <option value="false" disabled selected>Seleccione...</option>
                <option *ngFor="let curso of Cursos" value="{{curso._id}}" class="{{curso.DETALLE.join(',')}}">{{curso.NOMBRE}}</option>
                
                <!-- <option value="">1 Basico</option>
                <option value="">Por Colegio</option> -->
            </select>
        </div>
        <div class="col-sm-1 filtro-curso hidden">
            <label>Letra:</label>
            <select name="" id="letras" class="form-control" (change)="cargarSelects()">
                <option value="false" disabled selected>Seleccione...</option>
                <option *ngFor="let letra of Letras" value="{{letra}}" class="{{letra}}">{{letra}}</option>
            </select>
        </div>
        <div class="col-sm-2 ">
            <label>Rango:</label>
            <select name="" id="rango" class="form-control" (change)="cargarSelects()">
                <option value="dia">Por Día</option>
                <option value="semana">Por Semana</option>
                <option value="mes">Por Mes</option>
                <option value="todos">Todos</option>
                <!-- <option value="">Por Año</option> -->
            </select>
        </div>
        <div class="col-sm-2">
            <label>Fecha:</label>
            <input id="fecha" class="form-control text-center dia select-indicado" placeholder="" type="date" (change)="mostrarListado('FECHA', false)"> 
            <input id="semana" class="form-control text-center semana select-indicado hidden" placeholder="" type="week" (change)="cambiarSemana()">
            <input id="mes" class="form-control text-center mes select-indicado hidden" placeholder="" type="month" (change)="cambiarMes()">
        </div>
        <div class="col-sm-1">
            <label for=""style="color: transparent">A</label><br>
            <button class="btn" (click)="generarPdf()">PDF</button>
        </div>

    </div><br>
    <div class="form-check text-center" style="position: absolute; left: 17%; margin-top: 20px;">
        <input class="form-check-input" type="checkbox" (change)="filtroPIE()" id="flexCheckDefault">
        <label class="form-check-label" style="margin-left: 15px; position: absolute; margin-top: 5px;" for="flexCheckDefault">P.I.E.</label>
    </div>
    <h2 class="text-center">{{Alumnos.length}} Estudiante{{Alumnos.length === 1 ? "":"s"}}</h2>
    <br>
    <div class="row">
        <div class="col-sm-offset-2 col-sm-8">
            <div class="panel-body custom-panel custom-border text-center" id="panel-tabla" style="min-height: 480px;">
                <div class="panel-flat" style="max-height: 450px; overflow-y: auto;">
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th class="text-left">Nombre Estudiante</th>
                                    <th class="text-center">Fecha Nacimiento</th>
                                    <th class="text-center">Curso</th>
                                    <th class="text-center">Cumple</th>
                                    <th *ngIf="!isHoy" class="text-center">F. Cumpleaños</th>
                                    <th class="text-center">Regalar</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngIf="Alumnos.length===0"><td colspan="4" class="text-center">No hay estudiantes en la fecha seleccionada.</td></tr>
                                <tr *ngFor="let alumno of Alumnos">
                                    <td class="text-left">
                                        {{alumno.NOMBRES}} {{alumno.APELLIDOPATERNO}} {{alumno.APELLIDOMATERNO}}
                                        <h6 *ngIf="filtroPIEMostrar" class="text-primary">{{alumno.DIAGNOSTICO}}</h6>

                                    </td>
                                    <td class="text-center">{{alumno.FECHANACIMIENTO.toString().split('T')[0].split('-').reverse().join('-')}}</td>
                                    <td class="text-center">
                                        {{ plataforma_B2C === 'TBT_B2C' ? alumno.ESCOLARIDAD : alumno.ALUMNOCURSO.CURSO + ' ' + alumno.ALUMNOCURSO.DETALLE }}
                                    </td>
                                    <td class="text-center" title="{{alumno.FECHANACIMIENTO.toString().split('T')[0].split('-').reverse().join('-')}}">{{alumno.age}} años</td>
                                    <td *ngIf="!isHoy" class="text-center">{{alumno.nextBirthday.toString().split('T')[0].split('-').reverse().join('-')}}</td>
                                    <td class="text-center" *ngIf="alumno.daysUntilBirthday===0"><button (click)="regalar(alumno)" class="btn bg-danger">Regalar</button></td>
                                    <td class="text-center" *ngIf="alumno.daysUntilBirthday>0"><button  (click)="preguntarRegalo(alumno)" class="btn">Regalar</button></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
               
            </div>
        </div>
    </div>

    <!-- <div class="row">
        <div class="col-sm-offset-1 col-sm-3">
            <div class="custom-panel panel-body custom-border-top text-center custom-border-HOY" style="min-height: 281px;" >
                <h2 class="no-margin text-semibold">{{fechaFormateada}}</h2>
                <br>
                <h2 class="no-margin text-semibold">{{ cantHoy }} Estudiante{{cantHoy===1?"":"s"}}</h2>
                <button class="btn" (click)="mostrarListado('HOY',false)">Ver Estudiantes</button>
               
            </div>
        </div>
        <div class="col-sm-6">
            <div class="panel-body custom-panel custom-border text-center" id="panel-tabla" style="min-height: 280px;">
                <div class="panel-flat" style="max-height: 250px; overflow-y: auto;">
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th class="text-left">Nombre Estudiante</th>
                                    <th class="text-center">Curso</th>
                                    <th class="text-center">Cumple</th>
                                    <th *ngIf="!isHoy" class="text-center">F. Cumpleaños</th>
                                    <th class="text-center">Regalar</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngIf="Alumnos.length===0"><td colspan="4" class="text-center">No hay estudiantes en la fecha seleccionada.</td></tr>
                                <tr *ngFor="let alumno of Alumnos">
                                    <td class="text-left">{{alumno.NOMBRES}} {{alumno.APELLIDOPATERNO}} {{alumno.APELLIDOMATERNO}}</td>
                                    <td class="text-center">{{alumno.ALUMNOCURSO.CURSO}} {{alumno.ALUMNOCURSO.DETALLE}}</td>
                                    <td class="text-center" title="{{alumno.FECHANACIMIENTO.toString().split('T')[0].split('-').reverse().join('-')}}">{{alumno.age}} años</td>
                                    <td *ngIf="!isHoy" class="text-center">{{alumno.nextBirthday.toString().split('T')[0].split('-').reverse().join('-')}}</td>
                                    <td class="text-center"><button (click)="regalar(alumno)" class="btn">Regalar</button></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
               
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-sm-offset-1 col-sm-3">
            <div class="custom-panel panel-body custom-border-top text-center custom-border-FECHA" style="min-height: 300px;">
                <h2 class="no-margin text-semibold">Cumpleaños {{ textoManana }} </h2>
                <h2 class="no-margin text-semibold"><input id="fecha" class="form-control text-center" placeholder="" type="date" (change)="mostrarListado('FECHA', true)"> </h2>
                <br>
                <h2 class="no-margin text-semibold">{{ cantPorFecha }} Estudiante{{cantPorFecha===1?"":"s"}}</h2>
                <button class="btn" (click)="mostrarListado('FECHA', false)">Ver Estudiantes</button>
                
            </div>
        </div>
        <div class="col-sm-3">
            <div class="custom-panel panel-body custom-border-top text-center custom-border-SEMANA" style="min-height: 300px;">
                <h2 class="no-margin text-semibold">Cumpleaños</h2>
                <h2 class="no-margin text-semibold"><input id="semana" class="form-control text-center" placeholder="" type="week" (change)="cambiarSemana()"> </h2>
                <h5>{{rangoFechas}}</h5>
                <br>
                <h2 class="no-margin text-semibold">{{ cantPorSemana }} Estudiante{{ cantPorSemana===1?"":"s"}}</h2>
                <button class="btn" (click)="mostrarListado('SEMANA',false)">Ver Estudiantes</button>
                
            </div>
        </div>

        <div class="col-sm-3">
            <div class="custom-panel panel-body custom-border-top text-center custom-border-MES" style="min-height: 300px;">
                <h2 class="no-margin text-semibold">Cumpleaños Mes de</h2>
                <h2 class="no-margin text-semibold"><input id="mes" class="form-control text-center" placeholder="" type="month" (change)="cambiarMes()"> </h2>
                <br>
                <h2 class="no-margin text-semibold">{{ cantPorMes }} Estudiante{{cantPorMes===1?"":"s"}}</h2>
                <button class="btn" (click)="mostrarListado('MES',false)">Ver Estudiantes</button>
                
            </div>
        </div>
        
    </div> -->
</div>