import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../inicio/services/global.service';
import Phaser from 'phaser';
import { ApiService } from 'src/app/inicio/services/api.service';
import { interval } from 'rxjs'; // Importa interval desde RxJS
declare var Howl: any;
declare var $: any;

var PantallaPresentacionGlobal;
var ContenedorGeneralGlobal;
var ContenedorCronometroGlobal;
var Principal;
@Component({
  selector: 'app-temporizador-sensorial',
  templateUrl: './temporizador-sensorial.component.html',
  styleUrls: ['./temporizador-sensorial.component.css']
})
export class TemporizadorSensorialComponent implements OnInit {
  phaserGame: any;
  config: Phaser.Types.Core.GameConfig;
  constructor( public global:GlobalService, public api:ApiService){ 
    Principal = this;
    this.config = {
      type: Phaser.CANVAS,
      roundPixels: true,
      scale: {
        width: 1920,
        height: 900,
        parent: 'gameContainer',
        fullscreenTarget: 'gameContainer',
        mode: Phaser.Scale.FIT,
        autoCenter: Phaser.Scale.CENTER_BOTH,
      },
      physics: {
        default: 'arcade',  // Cambiar a 'arcade'
        arcade: {
            gravity: { y: 1000 },
            debug: false
        }
      },
      dom: {
          createContainer: true
      },
      backgroundColor: '#ffffff',
      scene: [PantallaPresentacion,ContenedorGeneral]
    };
    this.iniciar();
  }
  enviar_atras(){
    window.parent.postMessage({ type: 'navigate', url: "RecursosApoyo", recurso: "Temporizador Sensorial"}, '*');
  }
  atras_landing(){
    window.location.href = "/extras/temporizadores";
    // this.router.navigateByUrl("/extras/temporizadores", { skipLocationChange: false }).then(() => this.router.navigate(["/extras/temporizadores"]));
  }
  async iniciar(){
    setTimeout(()=>{
      $(".main-app").removeClass("hidden");
      $("#contenedor-principal").html(`<div id="gameContainer" style=" background: #ff8f44;"></div>`);
      this.phaserGame = new Phaser.Game(this.config);
    },1200)

    await this.global.offLoading(2500);
    $("body").css("background-color", "#ff8f44");
  }

  ngOnInit(): void {
    Principal.api.set_kpi_plataformas("Temporizador Sensorial","Ingresó al módulo");
  }
}
class PantallaPresentacion extends Phaser.Scene {
  tiempo:any = {
    hora: 0,
    minutos: 3,
    segundos: 0
  };
  temporizador:any;
  Emitterconfetti:any;
  sonido_9:any;
  FondoCronometro:any;
  temporizadorGrafico:any;
  tiempoTranscurrido = 0;
  tiempoTotalSegundos:any = 10;
  ColorTiempo:any = '#e444bb';
  skinCambio:any;
  skinTiempo:any="skin_reloj";
  intervalos:any;
  audio_reloj = new Howl({
    src: "assets/audio/reloj_corto2.mp3",
    loop: true,
    volume: 1
  });
  Iniciado:any = false;
  constructor() {
    super({ key: 'PantallaPresentacion' });
    PantallaPresentacionGlobal = this;
  }
  preload() {
    this.load.image('maskImage1', 'assets/images/aplicativos/temporizador/reloj_color.png');
    this.load.image('mascaraReloj', 'assets/images/aplicativos/temporizador/mascara_reloj.png');
    this.load.image('fondo_temporizador', 'assets/images/aplicativos/temporizador/fondo_temporizador.png');

    this.load.audio('a_jugar_sonido','assets/recursos/click.mp3');
    this.load.audio('skinCambio','assets/images/aplicativos/temporizador/skinCambio.mp3');
    this.load.image('base', 'assets/images/aplicativos/temporizador/fondo6.png');
    this.load.image('a_jugar', 'assets/images/aplicativos/temporizador/a_jugar_4.png');

    this.load.image('logos', 'assets/images/aplicativos/ruidometro/logo-blanco.png');
    this.load.image('ayuda', 'assets/images/aplicativos/ruidometro/ayuda.png');
    this.load.image('salir', 'assets/images/aplicativos/ruidometro/salir.png');

    this.load.atlas('confetti','assets/assets/particles/confetti.png','assets/assets/particles/confetti.json')
    this.load.audio('sonido_9', 'assets/images/aplicativos/temporizador/sonido_9.mp3');
    this.load.audio('pop', 'assets/audio/pop_2.mp3');
    this.load.image('rampaAzul', 'assets/images/aplicativos/temporizador/rampa-azul.png');
  }
  resize (gameSize, baseSize, displaySize, resolution)
  {
    $("canvas").css({marginLeft:0,width:"100%",height:"auto", marginBottom: "-5px"});
  }
  create(){
    this.skinCambio = this.sound.add('skinCambio');
    $("body, #gameContainer").css("background-color", "#ff8f44");
    this.sonido_9 = this.sound.add('sonido_9');
    this.scale.on('resize', this.resize, this);
    this.scale.on('orientationchange', (e: string) => {
      switch(e) {
          case 'portrait-primary':
              break;
          case 'landscape-primary':
              break;
          default:  
      }
      $("canvas").css({marginLeft:0,width:"100%",height:"auto", marginBottom: "-5px"});
    });

    this.scene.moveBelow(this, ContenedorGeneralGlobal);
    

    this.add.image(-2,0,"base").setOrigin(0).setScale(1);
    let aumento_lateral = -260;
    let inicio = this.add.sprite(this.scale.width/2+aumento_lateral-130,650,"a_jugar").setOrigin(0.5).setScale(1).setAlpha(1).setInteractive({ useHandCursor: true });
    this.add.text(0+aumento_lateral,150, "Temporizador Sensorial" , {
      font: "bold 85px Arial",
      align: "center",
      color: "white",
      shadow: {offsetX: 5,offsetY: 5,color: 'black',blur: 1,stroke: true,fill: true},
      fixedWidth: this.scale.width+aumento_lateral
    }).setVisible(true);

    this.add.text(0+aumento_lateral,300, "Los cronómetros sensoriales pueden ser muy útiles para\nniños y niñas Autistas, TDAH, problemas de concentración sensorial,\nhiperactividad o cualquier persona que simplemente esté\ndistraída o ansiosa por los números de los cronómetros de tradicionales.\nAtención: Si cambia de página cuando use un temporizador, se detendrá." , {
      font: "bold 30px Arial",
      align: "center",
      color: "white",
      shadow: {offsetX: 2.5,offsetY: 2.5,color: 'black',blur: 1,stroke: true,fill: true},
      fixedWidth: this.scale.width+aumento_lateral
    }).setVisible(true);

    let logos = this.add.sprite(this.scale.width/2+aumento_lateral-130,800,"logos").setOrigin(0.5).setScale(.5).setAlpha(1).setInteractive({ useHandCursor: true });
    let intLink =this.add.text(0+aumento_lateral,850, "Un producto con ❤ de integritic.cl" , {
      font: "20px Arial",
      align: "center",
      color: "white",
      shadow: {offsetX: 1.5,offsetY: 1.5,color: 'black',blur: 1,stroke: true,fill: true},
      fixedWidth: this.scale.width+aumento_lateral
    }).setVisible(true).setInteractive({useHandCursor:true});

    intLink.on('pointerdown', function (pointer) {
      window.open("https://www.integritic.cl", '_blank');
    }, this);

    let ayuda = this.add.sprite(this.scale.width/2+aumento_lateral-130,520,"ayuda").setOrigin(0.5).setScale(.5).setAlpha(1).setInteractive({ useHandCursor: true });
    inicio.on('pointerdown', function (pointer) {
      if(!this.Iniciado){
        this.Iniciado = true;
        this.sound.add('a_jugar_sonido').play();
        inicio.x = inicio.x - 5;
        inicio.setScale(.95);
        setTimeout(()=>inicio.setScale(1), 100);
        setTimeout(()=>inicio.x = inicio.x + 5, 100);
        this.scene.launch(ContenedorGeneralGlobal);
        setTimeout(()=>{
          this.scene.remove(PantallaPresentacionGlobal);
          $("body, #gameContainer").css("background-color", "#ffffff");
        }, 500);
        
      }
      
    }, this);

    ayuda.on('pointerdown', function (pointer) {
      ayuda.x = ayuda.x - 5;
      ayuda.setScale(.48);
      setTimeout(()=>ayuda.setScale(.5), 100);
      setTimeout(()=>ayuda.x = ayuda.x + 5, 100);
      Principal.global.alerta.fire({
        width: "500",
        title: 'Instrucciones:',
        html: `<div style="font-size: 20px;">Podrás configurar, relojes líquidos, burbujas, entre otros.</div>`,
        text: ``
      });
    }, this);
    this.crearReloj();
    this.changeColorTween();
    this.time.addEvent({
      delay: 3000,
      callback: function (){
        this.CambiarEfecto();
      },
      callbackScope: this,
      loop:true
    });
    this.time.addEvent({
      delay: 400,
      callback: function (){
        this.CreaBolaTiempo();
      },
      callbackScope: this,
      loop:true
    });
    $('#modal-temp').addClass('hidden');
  }
  RectanguloArriba:any;
  RectanguloAbajo:any;
  EmitterReloj:any;
  MascaraReloj:any;
  BordeReloj:any;
  crearReloj(){
    this.RectanguloArriba = this.add.graphics();
    this.RectanguloArriba.fillStyle(this.getFormatoColor(this.ColorTiempo), 1);
    this.RectanguloArriba.fillRect(this.scale.width / 2-140+600, this.scale.height / 2 - 353 + 100, 280, 263).setDepth(5);

    this.RectanguloAbajo = this.add.graphics();
    this.RectanguloAbajo.fillStyle(this.getFormatoColor(this.ColorTiempo), 1);
    this.RectanguloAbajo.fillRect(this.scale.width / 2 - 140+600, this.scale.height / 2 + 210 + 100, 280, 0).setDepth(5);

    this.MascaraReloj = this.add.image(this.scale.width / 2+600, this.scale.height / 2 - 90 + 100, 'maskImage1').setScale(0.6).setDepth(6);
    let circleRadius = 5;
    this.CrearArena();
    let particles = this.add.particles('grayCircle');
    this.EmitterReloj = particles.createEmitter({
      x: 960,
      y: 365,
      speedY: { min: 50, max: 100 },
      lifespan: 1700,
      gravityY: Phaser.Math.Between(0, 100),
      angle: { min: -180, max: 180 },
      scale: { min: .5, max: 1.2 },
      quantity: 1,
      rotate: { start: 0, end: 360},
      frequency: 100,
      emitZone: { type: 'random', source: new Phaser.Geom.Line(-10, 0, 10, 0) },
      on: false,
      emitCallbackScope: this,
      deathCallback: function(particle) {
        let circle = ContenedorGeneralGlobal.createCircle(particle.x, particle.y, particle.scaleX * circleRadius);
        ContenedorGeneralGlobal.circles.push(circle);
      },
    });
    particles.setDepth(4);
    this.BordeReloj = this.add.image(this.scale.width / 2+600, this.scale.height / 2 - 90 + 100, 'mascaraReloj').setScale(0.6).setDepth(8);
  }
  CrearArena(){
    let graphics = this.add.graphics();
    let circleRadius = 5;
    graphics.fillStyle(this.getFormatoColor(this.ColorTiempo), 1);
    graphics.fillCircle(circleRadius, circleRadius, circleRadius);
    graphics.generateTexture('grayCircle', circleRadius * 2, circleRadius * 2);
    graphics.destroy();
  }
  update(tiempo, delta){
    this.tiempoTranscurrido += delta / 1000;
    let restante = this.tiempoTotalSegundos - Math.floor(this.tiempoTranscurrido);
    if(restante == 0){
      this.tiempoTotalSegundos = 10;
      this.tiempoTranscurrido = 0;
    }
    let progress = this.tiempoTranscurrido / this.tiempoTotalSegundos;
    let maxHeight = 300; 
    this.RectanguloArriba.clear();
    this.RectanguloAbajo.clear();
    
    let heightArriba = maxHeight * (1 - progress);
    let heightAbajo = maxHeight * progress;
    
    this.RectanguloArriba.fillStyle(this.getFormatoColor(this.ColorTiempo), 1);
    this.RectanguloArriba.fillRect(this.scale.width / 2 - 140+600, this.scale.height / 2 - 390 + (maxHeight - heightArriba) +100, 280, heightArriba);

    this.RectanguloAbajo.fillStyle(this.getFormatoColor(this.ColorTiempo), 1);
    this.RectanguloAbajo.fillRect(this.scale.width / 2 - 140+600, this.scale.height / 2 + 210 - heightAbajo +100, 280, heightAbajo);
  }
  eliminarTodasBolas() {
    for (let i = 0; i < this.arrayBolas.length; i++) {
      this.arrayBolas[i].destroy();
    }
    for (let i = 0; i < this.ramps.length; i++) {
      this.ramps[i].destroy();
    }
  }
  CambiarEfecto(){
    this.RectanguloArriba.setVisible(false);
    this.RectanguloAbajo.setVisible(false);
    this.MascaraReloj.setVisible(false);
    this.BordeReloj.setVisible(false);
    this.eliminarTodasBolas();
    if(this.MostrandoActual == "reloj"){
      this.physics.world.setBounds(1400,0,300,1000);
      this.crearAmbienteBolas();
      this.MostrandoActual = "bolas";
    }else{
      this.RectanguloArriba.setVisible(true);
      this.RectanguloAbajo.setVisible(true);
      this.MascaraReloj.setVisible(true);
      this.BordeReloj.setVisible(true);
      this.MostrandoActual = "reloj";
    }
  }
  MostrandoActual = "reloj";
  CrearTextura(){
    
    let graphics = this.add.graphics();
    graphics.fillStyle(this.getFormatoColor(this.ColorTiempo), 1);

    
    graphics.beginPath();
    graphics.arc(50, 100, 50, 0, Math.PI, false);  
    graphics.closePath();
    graphics.fillPath();

    
    graphics.beginPath();
    graphics.moveTo(0, 100);  
    graphics.lineTo(100, 100);  
    graphics.lineTo(50, 0);  
    graphics.closePath();
    graphics.fillPath();

    
    graphics.generateTexture('drop', 100, 150);  
    graphics.destroy();
  }
  lerpColor(color1, color2, t) {
    const colorObj1 = Phaser.Display.Color.ValueToColor(color1);
    const colorObj2 = Phaser.Display.Color.ValueToColor(color2);

    const r = Phaser.Math.Linear(colorObj1.red, colorObj2.red, t);
    const g = Phaser.Math.Linear(colorObj1.green, colorObj2.green, t);
    const b = Phaser.Math.Linear(colorObj1.blue, colorObj2.blue, t);

    // Convertir el color interpolado a una cadena hexadecimal en el formato 0xRRGGBB
    const hexString = Phaser.Display.Color.RGBToString(Math.round(r), Math.round(g), Math.round(b), 0);
    this.ColorTiempo = hexString;
    this.CrearTextura();
  }
  changeColorTween() {
    const startColorHex = this.ColorTiempo; // Color inicial en formato hexadecimal
    const endColor = Phaser.Display.Color.RandomRGB(); // Color final en formato de objeto de color
    const endColorHex = Phaser.Display.Color.RGBToString(endColor.red, endColor.green, endColor.blue); // Color final en formato hexadecimal
    const duration = 5000;
    this.tweens.addCounter({
        from: 0,
        to: 1,
        duration: duration,
        ease: 'Linear',
        repeat: 0,
        yoyo: false,
        onUpdate: function (tween) {
            const value = tween.getValue();
            PantallaPresentacionGlobal.lerpColor(startColorHex, endColorHex, value);
        },
        onUpdateScope: this,
        onComplete: this.changeColorTween, // Llamar de nuevo para un bucle infinito
        onCompleteScope: this
    });
  }
  ramps:any = [];
  crearAmbienteBolas(){
    this.ramps = [];
    const ramp2 = this.physics.add.sprite(1520, 400 , 'rampaAzul').setScale(0.5);
    this.physics.add.existing(ramp2);
    ramp2.body.allowGravity = false;
    ramp2.body.immovable = true;
    ramp2.setRotation(.05);
    this.ramps.push(ramp2);

    const ramp3 = this.physics.add.sprite(1720, 100 , 'rampaAzul').setScale(0.5);
    this.physics.add.existing(ramp3);
    ramp3.body.allowGravity = false;
    ramp3.body.immovable = true;
    ramp3.setRotation(-.05);
    this.ramps.push(ramp3);

    const ramp4 = this.physics.add.sprite(1620, 700 , 'rampaAzul').setScale(0.5);
    this.physics.add.existing(ramp4);
    ramp4.body.allowGravity = false;
    ramp4.body.immovable = true;
    ramp4.setRotation(0.03);
    this.ramps.push(ramp4);
  }
  KeyBola:any = 0;
  arrayKey80:any = [33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48];
  arrayKey100:any = [17,18,19,20,21,22,23,24,25,26,27,28,39,30,31,32];
  imagesPerSecond80x80:any = 0;
  imagesPerSecond100x100:any = 0;
  imagesPerSecond80x80_bruto:any = 0;
  variableTiempo:any = false;
  contadorTiempo:any = 0;
  bolasGrupo:any;
  arrayBolas:any = [];
  getFormatoColor(color){
    return color.replace("#", "0x");
  }
  CreaBolaTiempo() {
    this.bolasGrupo = this.physics.add.group();
    if(this.MostrandoActual == "bolas"){
      const circle = this.add.circle(Phaser.Math.Between(1400, 1920), 0, 15, this.getFormatoColor(this.ColorTiempo));
      const bola = this.physics.add.existing(circle);
      this.bolasGrupo.add(bola);
      this.arrayBolas.push(bola);
      this.bolasGrupo.children.iterate(bola => {
        bola.body.setCircle(10); 
        bola.body.setBounce(0.5, 0.5); 
        bola.body.setCollideWorldBounds(true); 
        bola.body.allowGravity = true; 
        bola.body.setVelocity(500, 500); 
      });  
      this.ramps.forEach(ramp => {
        this.physics.add.collider(this.bolasGrupo, ramp);
      });
      this.physics.world.gravity.y = 1000;
    }
    
  }
  calcularAlturaPorcentaje(tiempoTranscurrido) {
      
    const porcentaje = tiempoTranscurrido / this.tiempoTotalSegundos;
    return porcentaje;
  }
  shuffleArray(array: any[]) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  }
  enviar_atras(){
    window.parent.postMessage({ type: 'navigate', url: "RecursosApoyo", recurso: "Ruidómetro"}, '*');
  }
}
class ContenedorGeneral extends Phaser.Scene {

  constructor() {
    super({ key: 'ContenedorGeneral' });
    ContenedorGeneralGlobal = this;    
  }
  audio_conteo = new Howl({
    src: "assets/audio/conteo3.mp3",
    volume: 1
  });
  audio_reloj = new Howl({
    src: "assets/audio/reloj_corto2.mp3",
    loop: true,
    volume: 0
  });
  reproduciendoEfecto = false;
  skinTiempo:any="skin_reloj";
  selectEl:any;
  timer:any;
  tiempo:any = {
    hora: 0,
    minutos: 0,
    segundos: 0
  };
  isIniciado:any = false;
  isPaused:any = true;
  isStarted:any = false;
  timer_1:any;
  timer_2:any;
  timer_3:any;
  timer_4:any;
  timer_5:any;
  timer_6:any;
  timer_7:any;
  timer_8:any;
  timer_personalizado:any;
  ColorTiempo:any = '#50aefd';
  ColorFondo:any = '#ffffff';
  EstadoColorPicker: any = false;
  circuloTiempoColor:any;
  BotonEditarTiempo:any;
  circuloFondoColor:any;
  BotonEditarFondo:any;
  text_background:any;
  text_colores:any;
  ContenedorFondo:any;
  fondoMarco:any;
  borde_medio_temporizador:any;
  borde_fuera_temporizador:any;
  borde_medio2_temporizador:any;
  borde_interior_temporizador:any;
  pauseButton:any;
  playButton:any;
  restartButton:any;
  tortaProgreso: Phaser.GameObjects.Graphics;
  tiempoTotalSegundos: number = 0;
  tiempoRestanteSegundos: number = 0;
  BotonMasHora:any;
  BotonMenosHora:any;
  BotonMasMinutos:any;
  BotonMenosMinutos:any;
  BotonMasSegundos:any;
  BotonMenosSegundos:any;
  BotonPlayPrev:any;
  sonidoClick:any;
  sonidoEncendido:any;
  sonidoMenu:any;
  sonido_1:any;
  sonido_2:any;
  sonido_3:any;
  sonido_4:any;
  sonido_5:any;
  sonido_6:any;
  sonido_7:any;
  sonido_8:any;
  sonido_9:any;
  sonido_10:any;
  sonido_11:any;
  sonido_12:any;
  sonido_13:any;
  sonido_14:any;
  sonido_15:any;
  sonido_16:any;
  sonido_17:any;
  sonidoBomba:any;
  BotonSonido:any;
  BotonPantalla:any;
  estadoPantalla:any = false;
  estadoSonido:any = true;
  estadoSonidoPrev:any = true;
  sonidoActual:any = 'sonido_15';
  efectoActual:any = 'confetti';
  Emitterconfetti:any;
  EmitterEmoji:any;
  BotonEfecto_1:any;
  BotonEfecto_2:any;
  BotonEfecto_3:any;
  BotonEfecto_4:any;
  BotonMenu:any;
  EstadoMenu:any = true;
  elementosMoviles:any;
  marcoFondo:any;
  marcoBomba:any;
  texto_tiempo:any;
  efecto:any;
  sonido:any;
  colorSelector:any;
  bordeSelector:any;
  btnCerrar:any;
  btncolorSelector:any;
  containerCambioColor:any;
  containerMenu:any;
  containerCronometro:any;
  containerReloj:any;
  containerGotas:any;
  containerLava:any;
  containerLluvia:any;
  containerModal:any;
  bordeCurva:any;
  rellenoCurva:any;
  chispa:any;
  dibujoCurva:any;
  t = 0;
  bomba:any;
  text_temporizador:any;
  tipo_temporizador_1:any;
  tipo_temporizador_2:any;
  tipo_temporizador_3:any;
  tipo_temporizador_4:any;  
  tipo_temporizador_5:any;
  tipo_temporizador_6:any;
  tipo_temporizador_7:any;
  tipo_temporizador_8:any;
  tipo_temporizador_9:any;
  tipo_temporizador_10:any;
  temporizadorTexto;
  BordeCronometro;
  FondoCronometro;
  BordeCronometroTexto;
  temporizadorGrafico;
  manecillaReloj;
  tiempoTranscurrido = 0;
  temporizadorCorriendo = false;
  tiempoPausado = 0;
  modalTiempo:any;
  RelojArena:any;
  RelojArenaSuperior:any;
  RelojArenaInferior:any;
  estadoTiempo:any;
  backDrop:any;
  switch_fondo:any;
  switch_activado:any;
  switch_desactivado:any;
  switch_icono:any;
  EstadoCronometro:any = false;
  EstadoAudioReloj:any = false;
  EstadoAudioConteo:any = false;
  AudioActual:any;
  pop:any;
  preload() {
    this.load.image('sprite_1', 'assets/assets/particles/sprite_1.png');
    this.load.image('sprite_2', 'assets/assets/particles/sprite_2.png');
    this.load.image('sprite_3', 'assets/assets/particles/sprite_3.png');
    this.load.image('sprite_4', 'assets/assets/particles/sprite_4.png');
    this.load.image('sprite_5', 'assets/assets/particles/sprite_5.png');
    this.load.image('sprite_6', 'assets/assets/particles/sprite_6.png');
    this.load.image('sprite_7', 'assets/assets/particles/sprite_7.png');

    // this.load.image('switch_fondo', 'assets/images/aplicativos/temporizador/switch-fondo-4.png');
    this.load.image('switch_activado', 'assets/images/aplicativos/temporizador/switch-activado-4.png');
    this.load.image('switch_desactivado', 'assets/images/aplicativos/temporizador/switch-desactivado-4.png');
    this.load.image('switch_icono', 'assets/images/aplicativos/temporizador/switch-icono-4.png');

    this.load.image('explosion_1', 'assets/assets/particles/explosion_1.png');
    this.load.image('explosion_2', 'assets/assets/particles/explosion_2.png');
    this.load.image('explosion_3', 'assets/assets/particles/explosion_3.png');
    this.load.image('explosion_4', 'assets/assets/particles/explosion_4.png');
    this.load.image('explosion_5', 'assets/assets/particles/explosion_5.png');
    this.load.image('explosion_6', 'assets/assets/particles/explosion_6.png');
    this.load.image('explosion_7', 'assets/assets/particles/explosion_7.png');
    this.load.image('explosion_8', 'assets/assets/particles/explosion_8.png');
    this.load.image('explosion_9', 'assets/assets/particles/explosion_9.png');

    this.load.image('ball_1', 'assets/images/aplicativos/temporizador/ball_1.png');
    this.load.image('ball_2', 'assets/images/aplicativos/temporizador/ball_2.png');
    this.load.image('ball_3', 'assets/images/aplicativos/temporizador/ball_3.png');
    this.load.image('ball_4', 'assets/images/aplicativos/temporizador/ball_4.png');
    this.load.image('ball_5', 'assets/images/aplicativos/temporizador/ball_5.png');
    this.load.image('ball_6', 'assets/images/aplicativos/temporizador/ball_6.png');
    this.load.image('ball_7', 'assets/images/aplicativos/temporizador/ball_7.png');
    this.load.image('ball_8', 'assets/images/aplicativos/temporizador/ball_8.png');
    this.load.image('ball_9', 'assets/images/aplicativos/temporizador/ball_9.png');
    this.load.image('ball_10', 'assets/images/aplicativos/temporizador/ball_10.png');
    this.load.image('ball_11', 'assets/images/aplicativos/temporizador/ball_11.png');
    this.load.image('ball_12', 'assets/images/aplicativos/temporizador/ball_12.png');
    this.load.image('ball_13', 'assets/images/aplicativos/temporizador/ball_13.png');
    this.load.image('ball_14', 'assets/images/aplicativos/temporizador/ball_14.png');
    this.load.image('ball_15', 'assets/images/aplicativos/temporizador/ball_15.png');
    this.load.image('ball_16', 'assets/images/aplicativos/temporizador/ball_16.png');
    this.load.image('ball_17', 'assets/images/aplicativos/temporizador/ball_17.png');
    this.load.image('ball_18', 'assets/images/aplicativos/temporizador/ball_18.png');
    this.load.image('ball_19', 'assets/images/aplicativos/temporizador/ball_19.png');
    this.load.image('ball_20', 'assets/images/aplicativos/temporizador/ball_20.png');
    this.load.image('ball_21', 'assets/images/aplicativos/temporizador/ball_21.png');
    this.load.image('ball_22', 'assets/images/aplicativos/temporizador/ball_22.png');
    this.load.image('ball_23', 'assets/images/aplicativos/temporizador/ball_23.png');
    this.load.image('ball_24', 'assets/images/aplicativos/temporizador/ball_24.png');
    this.load.image('ball_25', 'assets/images/aplicativos/temporizador/ball_25.png');
    this.load.image('ball_26', 'assets/images/aplicativos/temporizador/ball_26.png');
    this.load.image('ball_27', 'assets/images/aplicativos/temporizador/ball_27.png');
    this.load.image('ball_28', 'assets/images/aplicativos/temporizador/ball_28.png');
    this.load.image('ball_29', 'assets/images/aplicativos/temporizador/ball_29.png');
    this.load.image('ball_30', 'assets/images/aplicativos/temporizador/ball_30.png');
    this.load.image('ball_31', 'assets/images/aplicativos/temporizador/ball_31.png');
    this.load.image('ball_32', 'assets/images/aplicativos/temporizador/ball_32.png');
    this.load.image('ball_33', 'assets/images/aplicativos/temporizador/ball_33.png');
    this.load.image('ball_34', 'assets/images/aplicativos/temporizador/ball_34.png');
    this.load.image('ball_35', 'assets/images/aplicativos/temporizador/ball_35.png');
    this.load.image('ball_36', 'assets/images/aplicativos/temporizador/ball_36.png');
    this.load.image('ball_37', 'assets/images/aplicativos/temporizador/ball_37.png');
    this.load.image('ball_38', 'assets/images/aplicativos/temporizador/ball_38.png');
    this.load.image('ball_39', 'assets/images/aplicativos/temporizador/ball_39.png');
    this.load.image('ball_40', 'assets/images/aplicativos/temporizador/ball_40.png');
    this.load.image('ball_41', 'assets/images/aplicativos/temporizador/ball_41.png');
    this.load.image('ball_42', 'assets/images/aplicativos/temporizador/ball_42.png');
    this.load.image('ball_43', 'assets/images/aplicativos/temporizador/ball_43.png');
    this.load.image('ball_44', 'assets/images/aplicativos/temporizador/ball_44.png');
    this.load.image('ball_45', 'assets/images/aplicativos/temporizador/ball_45.png');
    this.load.image('ball_46', 'assets/images/aplicativos/temporizador/ball_46.png');
    this.load.image('ball_47', 'assets/images/aplicativos/temporizador/ball_47.png');
    this.load.image('ball_48', 'assets/images/aplicativos/temporizador/ball_48.png');
    
    this.load.image('sprite', 'assets/images/aplicativos/temporizador/btn-1-4.png');
    this.load.image('btn_1', 'assets/images/aplicativos/temporizador/btn-1-4.png');
    this.load.image('btn_2', 'assets/images/aplicativos/temporizador/btn-2-4.png');
    this.load.image('btn_3', 'assets/images/aplicativos/temporizador/btn-3-4.png');
    this.load.image('btn_4', 'assets/images/aplicativos/temporizador/btn-4-4.png');
    this.load.image('btn_5', 'assets/images/aplicativos/temporizador/btn-5-4.png');
    this.load.image('btn_6', 'assets/images/aplicativos/temporizador/btn-6-4.png');
    this.load.image('btn_7', 'assets/images/aplicativos/temporizador/btn-7-4.png');
    this.load.image('btn_8', 'assets/images/aplicativos/temporizador/btn-8-4.png');
    this.load.image('timer_personalizado', 'assets/images/aplicativos/temporizador/btn-editar-4.png');
    this.load.audio('sonido_1', 'assets/images/aplicativos/temporizador/sonido_1.mp3');
    this.load.audio('sonido_2', 'assets/images/aplicativos/temporizador/sonido_2.mp3');
    this.load.audio('sonido_3', 'assets/images/aplicativos/temporizador/sonido_3.mp3');
    this.load.audio('sonido_4', 'assets/images/aplicativos/temporizador/sonido_4.mp3');
    this.load.audio('sonido_5', 'assets/images/aplicativos/temporizador/sonido_5.mp3');
    this.load.audio('sonido_6', 'assets/images/aplicativos/temporizador/sonido_6.mp3');
    this.load.audio('sonido_7', 'assets/images/aplicativos/temporizador/sonido_7.mp3');
    this.load.audio('sonido_8', 'assets/images/aplicativos/temporizador/sonido_8.mp3');
    this.load.audio('sonido_9', 'assets/images/aplicativos/temporizador/sonido_9.mp3');
    this.load.audio('sonido_10', 'assets/images/aplicativos/temporizador/sonido_10.mp3');
    this.load.audio('sonido_11', 'assets/images/aplicativos/temporizador/sonido_11.mp3');
    this.load.audio('sonido_12', 'assets/images/aplicativos/temporizador/sonido_12.mp3');
    this.load.audio('sonido_13', 'assets/images/aplicativos/temporizador/sonido_13.mp3');
    this.load.audio('sonido_14', 'assets/images/aplicativos/temporizador/musica_pedro.mp3');
        this.load.audio('sonido_15', 'assets/images/aplicativos/temporizador/sonido_15.mp3');
    this.load.audio('sonido_16', 'assets/images/aplicativos/temporizador/sonido_16.mp3');
    this.load.audio('sonido_17', 'assets/images/aplicativos/temporizador/sonido_17.mp3');
    this.load.audio('sonidoBomba', 'assets/images/aplicativos/temporizador/efecto_bomba.mp3');
    
    this.load.audio('sonidoClick', 'assets/recursos/click.mp3');
    this.load.audio('sonidoEncendido', 'assets/images/aplicativos/temporizador/sonido_encendido.mp3');
    this.load.audio('sonidoMenu', 'assets/recursos/clic_ver_mas.mp3');
    this.load.image('mask', 'assets/images/aplicativos/temporizador/mascara.png');
    this.load.image('img_sonido_1', 'assets/images/aplicativos/temporizador/sonido_1.png');
    this.load.image('img_sonido_2', 'assets/images/aplicativos/temporizador/sonido_2.png');
    this.load.image('img_sonido_3', 'assets/images/aplicativos/temporizador/sonido_3.png');
    this.load.image('img_sonido_4', 'assets/images/aplicativos/temporizador/sonido_4.png');
    this.load.image('img_sonido_5', 'assets/images/aplicativos/temporizador/sonido_5.png');
    this.load.image('img_sonido_6', 'assets/images/aplicativos/temporizador/sonido_6.png');
    this.load.image('img_sonido_7', 'assets/images/aplicativos/temporizador/sonido_7.png');
    this.load.image('maskImage', 'assets/images/aplicativos/temporizador/reloj_blanco.png');
    this.load.image('mascaraReloj', 'assets/images/aplicativos/temporizador/mascara_reloj.png');
    this.load.image('mascaraTubos', 'assets/images/aplicativos/temporizador/mascara_tubos.png');
    this.load.image('ball', 'assets/images/aplicativos/temporizador/sand.png');
    
    this.load.image('BordeCronometro', 'assets/images/aplicativos/temporizador/cronometro-4.png');
    // this.load.image('FondoCronometro', 'assets/images/aplicativos/temporizador/fondo-circulo-4.png');
    this.load.image('iniciar', 'assets/images/aplicativos/temporizador/iniciar-4.png');

    this.load.image('menu_reloj', 'assets/images/aplicativos/temporizador/reloj.png');
    this.load.image('menu_gotas', 'assets/images/aplicativos/temporizador/gotas.png');
    this.load.image('menu_lava', 'assets/images/aplicativos/temporizador/lava.png');
    this.load.image('menu_lluvia', 'assets/images/aplicativos/temporizador/lluvia.png');
    this.load.image('menu_bolas', 'assets/images/aplicativos/temporizador/bolas.png');
    this.load.image('menu_tubo', 'assets/images/aplicativos/temporizador/tubo.png');
    

    this.load.image('skin_reloj', 'assets/images/aplicativos/temporizador/skin_reloj.png');
    this.load.image('reloj_superior', 'assets/images/aplicativos/temporizador/reloj_superior.png');
    this.load.image('reloj_inferior', 'assets/images/aplicativos/temporizador/reloj_inferior.png');

    this.load.image('efecto_1', 'assets/images/aplicativos/temporizador/efecto_1_4.png');
    this.load.image('efecto_2', 'assets/images/aplicativos/temporizador/efecto_2_4.png');
    this.load.image('efecto_3', 'assets/images/aplicativos/temporizador/efecto_3_4.png');
    this.load.image('efecto_4', 'assets/images/aplicativos/temporizador/efecto_4_4.png');
    
    this.load.image('borde', 'assets/images/aplicativos/temporizador/borde-estilo-4.png');
    this.load.image('modalTiempo', 'assets/images/aplicativos/temporizador/modaltiempo-4.png');
    // this.load.image('editar', 'assets/images/aplicativos/temporizador/editar-4.png');
    this.load.image('fondo_temporizador', 'assets/images/aplicativos/temporizador/fondo_temporizador.png');
    this.load.image('menu_abierto', 'assets/images/aplicativos/temporizador/menu_abierto_4.png');
    this.load.image('menu_cerrado', 'assets/images/aplicativos/temporizador/menu_cerrado_4.png');

    this.load.image('bordeTiempo', 'assets/images/aplicativos/temporizador/borde-color-tiempo-4.png');
    this.load.image('bordeFondo', 'assets/images/aplicativos/temporizador/borde-color-fondo-4.png');

    this.load.image('rampaRoja', 'assets/images/aplicativos/temporizador/rampa-roja.png');
    this.load.image('rampaAzul', 'assets/images/aplicativos/temporizador/rampa-azul.png');
    this.load.image('tubo', 'assets/images/aplicativos/temporizador/tubo-limite.png');
    this.load.image('efectoLiquido', 'assets/images/aplicativos/temporizador/efecto-liquido.png');
    this.load.image('efectoLiquido2', 'assets/images/aplicativos/temporizador/efecto-liquido-2.png');

    this.load.image('btn_pausa', 'assets/images/aplicativos/temporizador/btn-pausa-4.png');
    this.load.image('btn_play', 'assets/images/aplicativos/temporizador/btn-play-4.png');
    this.load.image('btn_replay', 'assets/images/aplicativos/temporizador/btn-replay-4.png');
    this.load.image('btn_mas', 'assets/images/aplicativos/temporizador/btn-mas-4.png');
    this.load.image('btn_menos', 'assets/images/aplicativos/temporizador/btn-menos-4.png');
    this.load.image('btn_suave', 'assets/images/aplicativos/temporizador/suave.png');
    this.load.image('btn_fuerte', 'assets/images/aplicativos/temporizador/fuerte.png');
    this.load.image('colorSelector', 'assets/images/aplicativos/temporizador/colorSelector2.png');
    this.load.image('btnCerrar', 'assets/images/aplicativos/temporizador/salir-4.png');
    this.load.image('sonido_activo', 'assets/images/aplicativos/temporizador/sonido_activo_4.png');
    this.load.image('sonido_mute', 'assets/images/aplicativos/temporizador/sonido_mute_4.png');

    this.load.image('ampliar', 'assets/images/aplicativos/temporizador/ampliar-4.png');
    this.load.image('no_ampliar', 'assets/images/aplicativos/temporizador/no_ampliar_4.png');
    this.load.atlas('confetti','assets/assets/particles/confetti.png','assets/assets/particles/confetti.json')
    this.load.atlas('emojis','assets/assets/particles/emojis.png','assets/assets/particles/emojis.json')
    this.load.atlas('efecto_chispa','assets/assets/particles/chispa.png','assets/assets/particles/chispa.json')
    this.load.atlas('efecto_bomba','assets/assets/particles/bomba.png','assets/assets/particles/bomba.json')
    this.load.audio('pop', 'assets/audio/pop_2.mp3');
  }
  CargaAudios(){    
    this.pop = this.sound.add('pop');
    this.sonido_1 = this.sound.add('sonido_1');
    this.sonido_2 = this.sound.add('sonido_2');
    this.sonido_3 = this.sound.add('sonido_3');
    this.sonido_4 = this.sound.add('sonido_4');
    this.sonido_5 = this.sound.add('sonido_5');
    this.sonido_6 = this.sound.add('sonido_6');
    this.sonido_7 = this.sound.add('sonido_7');
    this.sonido_8 = this.sound.add('sonido_8');
    this.sonido_9 = this.sound.add('sonido_9');
    this.sonido_10 = this.sound.add('sonido_10');
    this.sonido_11 = this.sound.add('sonido_11');
    this.sonido_12 = this.sound.add('sonido_12');
    this.sonido_13 = this.sound.add('sonido_13');
    this.sonido_14 = this.sound.add('sonido_14');
    this.sonido_15 = this.sound.add('sonido_15');
    this.sonido_16 = this.sound.add('sonido_16');
    this.sonido_17 = this.sound.add('sonido_17');
    this.sonidoBomba = this.sound.add('sonidoBomba');
    this.sonidoClick = this.sound.add('sonidoClick');
    this.sonidoEncendido = this.sound.add('sonidoEncendido');
    this.sonidoMenu = this.sound.add('sonidoMenu');
  }
  formato_tiempo(number, length) {
    var str = '' + number;
    while (str.length < length) {
        str = '0' + str;
    }
    return str;
  }
  MasHora() {
    this.tiempo.hora++;
    if (this.tiempo.hora >= 24) {
        this.tiempo.hora = 0;
    }
    this.CambiarTiempoTotal();
  } 
  MenosHora() {
      this.tiempo.hora--;
      if (this.tiempo.hora < 0) {
          this.tiempo.hora = 23;
      }
      this.CambiarTiempoTotal();
  }
  MasMinuto() {
      this.tiempo.minutos++;
      if (this.tiempo.minutos >= 60) {
          this.tiempo.minutos = 0;
      }
      this.CambiarTiempoTotal();
  }
  MenosMinuto() {
    this.tiempo.minutos--;
    if (this.tiempo.minutos < 0) {
      this.tiempo.minutos = 59;
    }
    this.CambiarTiempoTotal();
  }
  MasSegundos() {
    this.tiempo.segundos++;
    if (this.tiempo.segundos >= 60) {
      this.tiempo.segundos = 0;
    }
    this.CambiarTiempoTotal();
  }
  MenosSegundos() {
    this.tiempo.segundos--;
    if (this.tiempo.segundos < 0) {
      this.tiempo.segundos = 59;
    }
    this.CambiarTiempoTotal();
  }
  updateTimeText() {
    var formattedTime = this.formatoTiempo(this.tiempoTotalSegundos - Math.floor(this.tiempoTranscurrido));
    this.temporizadorTexto.setText(formattedTime);
    this.BordeCronometroTexto.setText(formattedTime);
  }
  reproducirEfecto(sonido){
    if(this.estadoSonido){
      sonido.play(); 
      this.AudioActual = sonido;
    }
  }
  reproducirAudio(){
    if(ContenedorGeneralGlobal[this.sonidoActual]){
      if(this.estadoSonido && this.sonidoActual != 'efecto_1'){
        ContenedorGeneralGlobal[this.sonidoActual].play(); 
      }
    }   
  }
  resetTime() {
    this.tiempo.hora = 0;
    this.tiempo.minutos = 0;
    this.tiempo.segundos = 0;
    this.quitarAutomatico();
    this.tiempoTranscurrido = 0;
    this.eliminarCirculos();
    this.eliminarTodasBolas();
    this.waterGroup.clear(true, true);    
    ContenedorGeneralGlobal[this.sonidoActual].pause();

    if(this.skinTiempo == "skin_reloj"){
      this.RectanguloArriba.clear();
      this.RectanguloAbajo.clear();

      this.RectanguloArriba = this.add.graphics();
      this.RectanguloArriba.fillStyle(this.getFormatoColor(this.ColorTiempo), 1);
      this.RectanguloArriba.fillRect(this.scale.width / 2-140, this.scale.height / 2 - 353, 280, 263).setDepth(5);
  
      this.RectanguloAbajo = this.add.graphics();
      this.RectanguloAbajo.fillStyle(this.getFormatoColor(this.ColorTiempo), 1);
      this.RectanguloAbajo.fillRect(this.scale.width / 2 - 140, this.scale.height / 2 + 210, 280, 0).setDepth(5);

    }else if(this.skinTiempo == "skin_tubo"){
      this.TuboRelleno.clear();
      this.TuboRelleno = this.add.graphics();
      this.TuboRelleno.fillStyle(this.getFormatoColor(this.ColorTiempo), 1);
      this.TuboRelleno.fillRect(765, 614, 390, 0);
    }else if(this.skinTiempo == "skin_bolas"){
      this.RectanguloArribaBola.clear();
      this.RectanguloArribaBola = this.add.graphics();
      this.RectanguloArribaBola.fillStyle(this.getFormatoColor(this.ColorTiempo), 1);
      this.RectanguloArribaBola.fillRect(0, 0, 1920, 100).setDepth(5);
      this.RectanguloAbajoBola.clear();
      this.RectanguloAbajoBola = this.add.graphics();
      this.RectanguloAbajoBola.fillStyle(this.getFormatoColor(this.ColorTiempo), 1);
      this.RectanguloAbajoBola.fillRect(0, 900, 1920, 0).setDepth(5);
    }
  }
  CambiarFondo(color){
    this.ColorFondo = color;
    this.ContenedorFondo.clear();
    this.circuloFondoColor.setFillStyle(this.getFormatoColor(this.ColorFondo));
    this.ContenedorFondo.fillStyle(this.getFormatoColor(this.ColorFondo), 1);
    this.ContenedorFondo.fillRoundedRect(0, 0, this.scale.width, this.scale.height, 0);
  }
  CambiarTiempo(color){
    this.ColorTiempo = color;
    // this.circuloTiempoColor.setFillStyle(this.getFormatoColor(this.ColorTiempo));
    this.CrearTextura();
    this.CrearArena();
  }
  CambiarTiempoTotal(){
    this.tiempoTotalSegundos = this.tiempo.segundos + this.tiempo.minutos*60 + this.tiempo.hora * 3600;
    this.tiempoTranscurrido = 0;
  }
  CambiarAutomatico(button){
    this.timer_1.setAlpha(1); 
    this.timer_2.setAlpha(1); 
    this.timer_3.setAlpha(1); 
    this.timer_4.setAlpha(1); 
    this.timer_5.setAlpha(1); 
    this.timer_6.setAlpha(1); 
    this.timer_7.setAlpha(1); 
    this.timer_8.setAlpha(1); 
    button.setScale(.5).setAlpha(.5);
    setTimeout(() => {
      button.setScale(.6)
    }, 200);
    this.CambiarTiempoTotal();
  }
  CambiarSonido(button,sonido){
    button.setScale(.25).setAlpha(.5);
    setTimeout(() => {
      button.setScale(.3)
    }, 200);
    this.sonidoActual = sonido;
  }
  CambiarEfecto(button,efecto){
    this.BotonEfecto_1.setAlpha(1); 
    this.BotonEfecto_2.setAlpha(1); 
    this.BotonEfecto_3.setAlpha(1); 
    this.BotonEfecto_4.setAlpha(1); 
    button.setScale(.2).setAlpha(.5);
    setTimeout(() => {
      button.setScale(.25)
    }, 200);
    this.efectoActual = efecto;
  }
  CambiarSkin(button,efecto){
    this.tipo_temporizador_1.setAlpha(1); 
    this.tipo_temporizador_2.setAlpha(1); 
    this.tipo_temporizador_3.setAlpha(1); 
    this.tipo_temporizador_4.setAlpha(1); 
    this.skinTiempo = efecto;
    button.setAlpha(.5);
    this.MostrarOcultar();
    ContenedorGeneralGlobal[this.sonidoActual].pause();
    Principal.api.set_kpi_plataformas("Temporizador Sensorial","Cambia tipo: "+this.skinTiempo);
  }
  Reiniciar(){
    this.reiniciarSkin();
  }
  CambiarPrevisualizarSonido(){
    if(this.estadoSonidoPrev){
      this.BotonPlayPrev.setTexture("sonido_activo");
      this.estadoSonidoPrev = false;
    }else{
      this.BotonPlayPrev.setTexture("sonido_activo");
      this.estadoSonidoPrev = true;
      ContenedorGeneralGlobal[this.sonidoActual].stop(); 
    }
  }
  colorActualCambio:any;
  abrirColor(tipo,value){
    if(tipo == "ColorTiempo"){
      this.bordeSelector.setTexture("bordeTiempo");
    }else{
      this.bordeSelector.setTexture("bordeFondo");
    }
    if(this.colorActualCambio == tipo){
      if(this.EstadoColorPicker){
      }else{
        this.containerCambioColor.setVisible(true);
        this.EstadoColorPicker = true;
      }
    }else{
      this.containerCambioColor.setVisible(true);
      this.EstadoColorPicker = true;
    }
    this.colorActualCambio = tipo;
  }
  cerrarColor(){
    this.containerCambioColor.setVisible(false);
    this.EstadoColorPicker = false;
  }
  abrir_modal(button){
    if(this.estadoTiempo){
      this.estadoTiempo = false;
      this.containerModal.setVisible(false);
    }else{
      this.estadoTiempo = true;
      this.containerModal.setVisible(true);
    }
    button.setScale(.55)
    setTimeout(() => {
      button.setScale(.65)
    }, 200);
  }
  cerrarModal(){
    this.estadoTiempo = false;
    this.containerModal.setVisible(false);
  }
  CambiarEstado(){
    if(this.estadoSonido){
      this.BotonSonido.setTexture("sonido_mute");
      this.estadoSonido = false;
    }else{
      this.BotonSonido.setTexture("sonido_activo");
      this.estadoSonido = true;
    }
    ContenedorGeneralGlobal[this.sonidoActual].stop(); 
  }
  CambiarPantalla(){
    if(this.estadoPantalla){
      this.BotonPantalla.setTexture("ampliar");
      this.estadoPantalla = false;
    }else{
      this.BotonPantalla.setTexture("no_ampliar");
      this.estadoPantalla = true;
    }
    const element = document.documentElement;
    if(!document.fullscreenElement){
      if(element.requestFullscreen){
        element.requestFullscreen();
      }
    }else{
      if(document.exitFullscreen){
        document.exitFullscreen();
      }
    }
  }
  quitarAutomatico(){
    this.timer_1.setAlpha(1); 
    this.timer_2.setAlpha(1); 
    this.timer_3.setAlpha(1); 
    this.timer_4.setAlpha(1); 
    this.timer_5.setAlpha(1); 
    this.timer_6.setAlpha(1); 
    this.timer_7.setAlpha(1); 
    this.timer_8.setAlpha(1); 
  }
  resize (gameSize, baseSize, displaySize, resolution){
    $("canvas").css({marginLeft:0,width:"100%",height:"auto", marginBottom: "-5px"});
  }
  async create(){
    this.scale.on('resize', this.resize, this);
    this.input.setDefaultCursor('pointer');
    this.CargaAudios();
    let min = 1630;
    let max = this.scale.width - 30;
    let posC1 = 180;
    let tamanioMarco = 720;
    this.ContenedorFondo = this.add.graphics();
    this.ContenedorFondo.fillStyle(0xffffff,1);
    this.ContenedorFondo.fillRoundedRect(0, 0, this.scale.width, this.scale.height, 0);
    this.marcoFondo = this.add.image(this.scale.width-158, 460+35,"borde").setInteractive();    
    this.containerMenu = this.add.container(0, 0);
    this.containerMenu.add(this.marcoFondo);
    const style = {
      fontSize: '20px',
      fontFamily: 'Arial',
      color: '#ffffff',
      backgroundColor: '#ff00ff'
    };
    let diferencia =  70;
    this.timer_1 = this.add.image(570 + diferencia,830,"btn_1").setScale(.62).setInteractive().setDepth(10);
    this.timer_2 = this.add.image(660 + diferencia,830,"btn_2").setScale(.62).setInteractive().setDepth(10);
    this.timer_3 = this.add.image(750 + diferencia,830,"btn_3").setScale(.62).setInteractive().setDepth(10);
    this.timer_4 = this.add.image(840 + diferencia,830,"btn_4").setScale(.62).setInteractive().setDepth(10);
    this.timer_5 = this.add.image(930 + diferencia,830,"btn_5").setScale(.62).setInteractive().setDepth(10);
    this.timer_6 = this.add.image(1020 + diferencia,830,"btn_6").setScale(.62).setInteractive().setDepth(10);
    this.timer_7 = this.add.image(1110 + diferencia,830,"btn_7").setScale(.62).setInteractive().setDepth(10);
    this.timer_8 = this.add.image(1200 + diferencia,830,"btn_8").setScale(.62).setInteractive().setDepth(10);
    this.timer_personalizado = this.add.image(1200 + diferencia,830,"timer_personalizado").setScale(.62).setInteractive().setDepth(10);
    
    this.BotonSonido = this.add.sprite(this.scale.width - 200,57,"sonido_activo").setScale(.5).setInteractive().setDepth(10);
    this.BotonSonido.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.CambiarEstado();
    });
    this.BotonPantalla = this.add.sprite(this.scale.width - 120,57,"ampliar").setScale(.5).setInteractive().setDepth(10);
    this.BotonPantalla.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.CambiarPantalla();
    });
    this.timer_1.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.tiempo.hora = 0;
      this.tiempo.minutos = 0;
      this.tiempo.segundos = 15;
      this.CambiarAutomatico(this.timer_1);
    });

    this.timer_2.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.tiempo.hora = 0;
      this.tiempo.minutos = 0;
      this.tiempo.segundos = 30;
      this.CambiarAutomatico(this.timer_2);
    });

    this.timer_3.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.tiempo.hora = 0;
      this.tiempo.minutos = 1;
      this.tiempo.segundos = 0;
      this.CambiarAutomatico(this.timer_3);
    });

    this.timer_4.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.tiempo.hora = 0;
      this.tiempo.minutos = 3;
      this.tiempo.segundos = 0;
      this.CambiarAutomatico(this.timer_4);
    });

    this.timer_5.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.tiempo.hora = 0;
      this.tiempo.minutos = 5;
      this.tiempo.segundos = 0;
      this.CambiarAutomatico(this.timer_5);
    });

    this.timer_6.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.tiempo.hora = 0;
      this.tiempo.minutos = 10;
      this.tiempo.segundos = 0;
      this.CambiarAutomatico(this.timer_6);
    });

    this.timer_7.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.tiempo.hora = 0;
      this.tiempo.minutos = 30;
      this.tiempo.segundos = 0;
      this.CambiarAutomatico(this.timer_7);
    });

    this.timer_8.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.tiempo.hora = 1;
      this.tiempo.minutos = 0;
      this.tiempo.segundos = 0;
      this.CambiarAutomatico(this.timer_8);
    });
    this.timer_personalizado.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.abrir_modal(this.timer_personalizado);
    });
    this.circuloFondoColor = this.add.circle(this.scale.width - 160,650, 25, this.getFormatoColor(this.ColorFondo), 1).setInteractive();
    this.circuloFondoColor.setStrokeStyle(5, 0xd808ae);     
    this.circuloFondoColor.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.circuloFondoColor.setScale(.7);
      setTimeout(() => {
        this.circuloFondoColor.setScale(1)
      }, 200);
      this.abrirColor("ColorFondo",this.circuloFondoColor);
    });    
    // this.circuloTiempoColor = this.add.circle(this.scale.width - 120,650, 25, this.getFormatoColor(this.ColorTiempo), 1).setInteractive();
    // this.circuloTiempoColor.setStrokeStyle(5, 0xd808ae); 
    // this.circuloTiempoColor.on('pointerdown', () => {
    //   this.reproducirEfecto(this.sonidoClick);
    //   this.circuloTiempoColor.setScale(.7);
    //   setTimeout(() => {
    //     this.circuloTiempoColor.setScale(1)
    //   }, 200);
    //   this.abrirColor("ColorTiempo",this.circuloTiempoColor);
    // });
    this.containerMenu.add(this.circuloFondoColor);
    // this.containerMenu.add(this.circuloTiempoColor);
    this.containerCambioColor = this.add.container(0, 0).setVisible(false);
    let diff = 0;
    var select:any = document.createElement('select');

    var options = [
      {
        value: "sonido_15",
        text: "Paz Mental"
      },{
        value: "sonido_16",
        text: "Ecos del alma"
      },{
        value: "sonido_17",
        text: "Armonía Interior"
      },
      {
        value: "sonido_14",
        text: "Pedro Music"
      },
    {
      value: "sonido_5",
      text: "Arpa"
    },
    {
      value: "sonido_1",
      text: "Mario Bross"
    },{
      value: "sonido_2",
      text: "Fornite Victory"
    },{
      value: "sonido_3",
      text: "Campana"
    },{
      value: "sonido_4",
      text: "Triste"
    },{
      value: "sonido_6",
      text: "Aplausos"
    },{
      value: "sonido_7",
      text: "Risa Bebé"
    },{
      value: "sonido_8",
      text: "Grillos"
    },{
      value: "sonido_9",
      text: "Niños"
    },{
      value: "sonido_10",
      text: "Horror"
    },{
      value: "sonido_11",
      text: "Bocina Reggaeton"
    },{
      value: "sonido_12",
      text: "Explosión Fuerte"
    },{
      value: "sonido_13",
      text: "Explosión Suave"
    },{
      value: "efecto_1",
      text: "Ninguno"
    }];

    options.forEach(function(option) {
        var opt = document.createElement('option');
        opt.value = option.value;
        opt.innerHTML = option.text;
        select.appendChild(opt);
    });
    select.id = "tipo_sonido";
    select.style.border = "none";
    select.style.borderRadius = "60px";
    select.style.height = '40px';
    select.style.width = '150';
    select.style.fontSize = '16px';
    select.style.textAlign = 'center';
    select.style.background = 'white';
    select.style.border = '4px solid #d808ae';
    select.style.color = '#d808ae';
    select.style.fontWeight = 'bold';
    select.addEventListener("change", function() {
      if(!ContenedorGeneralGlobal.estadoSonidoPrev){
        ContenedorGeneralGlobal.CambiarPrevisualizarSonido();
      }     
      ContenedorGeneralGlobal.sonidoActual = this.value;
    });
    document.body.appendChild(select);
    this.selectEl = this.add.dom(this.scale.width-270, 630+diff+75, select).setOrigin(0);    
    this.containerMenu.add(this.selectEl);
    this.BotonPlayPrev = this.add.sprite(this.scale.width-75,650+diff+75,"sonido_activo").setScale(.3).setInteractive();
    this.containerMenu.add(this.BotonPlayPrev);
    this.BotonPlayPrev.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.BotonPlayPrev.setScale(.2);
      setTimeout(() => {
        this.BotonPlayPrev.setScale(.3)
      }, 200);
      this.reproducirAudio();
      this.CambiarPrevisualizarSonido();
    });    
    this.BotonEfecto_1 = this.add.image(this.scale.width - 216,640+150,"efecto_1").setScale(.25).setInteractive();
    this.BotonEfecto_2 = this.add.image(this.scale.width - 99,640+150,"efecto_2").setScale(.25).setInteractive().setAlpha(.5);
    this.BotonEfecto_3 = this.add.image(this.scale.width - 216,680+150,"efecto_3").setScale(.25).setInteractive();
    this.BotonEfecto_4 = this.add.image(this.scale.width - 99,680+150,"efecto_4").setScale(.25).setInteractive();
    this.containerMenu.add(this.BotonEfecto_1);
    this.containerMenu.add(this.BotonEfecto_2);
    this.containerMenu.add(this.BotonEfecto_3);
    this.containerMenu.add(this.BotonEfecto_4);

    this.BotonEfecto_1.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.CambiarEfecto(this.BotonEfecto_1,'ninguno');
    });
    this.BotonEfecto_2.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.CambiarEfecto(this.BotonEfecto_2,'confetti');
    });
    this.BotonEfecto_3.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.CambiarEfecto(this.BotonEfecto_3,'emojis');
    });
    this.BotonEfecto_4.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.CambiarEfecto(this.BotonEfecto_4,'explosion');
    });
    this.BotonMenu = this.add.image(this.scale.width - 335,400+50,"menu_abierto").setScale(.5).setInteractive().setVisible(false);
    this.BotonMenu.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoMenu);
      if(this.EstadoMenu){
        this.BotonMenu.setTexture("menu_cerrado");
        this.tweens.add({
          targets: this.BotonMenu,
          x: this.scale.width-30,
          duration: 200,
          ease: 'Linear'
        });
        this.tweens.add({
          targets: this.containerMenu,
          x: this.containerMenu.x + 320,
          duration: 200,
          ease: 'Linear'
        });
        this.EstadoMenu = false;
        this.cerrarColor();
      }else{
        this.BotonMenu.setTexture("menu_abierto");
        this.tweens.add({
          targets: this.BotonMenu,
          x: this.scale.width - 334,
          duration: 200,
          ease: 'Linear'
        });
        this.tweens.add({
          targets: this.containerMenu,
          x: 0,
          duration: 200,
          ease: 'Linear'
        });
        this.EstadoMenu = true;
      }
    });
    this.tipo_temporizador_1 = this.add.image(this.scale.width - 220,190+70,"menu_reloj").setScale(.5).setInteractive().setAlpha(.5);
    this.tipo_temporizador_2 = this.add.image(this.scale.width - 100,190+70,"menu_lluvia").setScale(.5).setInteractive();
    this.tipo_temporizador_3 = this.add.image(this.scale.width - 220,190+190,"menu_bolas").setScale(.5).setInteractive();
    this.tipo_temporizador_4 = this.add.image(this.scale.width - 100,190+190,"menu_tubo").setScale(.5).setInteractive();
    this.containerMenu.add(this.tipo_temporizador_1);
    this.containerMenu.add(this.tipo_temporizador_2);
    this.containerMenu.add(this.tipo_temporizador_3);
    this.containerMenu.add(this.tipo_temporizador_4);
    this.containerMenu.setDepth(10);
    this.tipo_temporizador_1.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.CambiarSkin(this.tipo_temporizador_1,'skin_reloj');
      this.tipo_temporizador_1.setScale(.4);
      setTimeout(() => {
        this.tipo_temporizador_1.setScale(.5);
      }, 200);
    });
    this.tipo_temporizador_2.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.CambiarSkin(this.tipo_temporizador_2,'skin_lluvia');
      this.tipo_temporizador_2.setScale(.4);
      setTimeout(() => {
        this.tipo_temporizador_2.setScale(.5);
      }, 200);
    });
    this.tipo_temporizador_3.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.CambiarSkin(this.tipo_temporizador_3,'skin_bolas');
      this.tipo_temporizador_3.setScale(.4);
      setTimeout(() => {
        this.tipo_temporizador_3.setScale(.5);
      }, 200);
    });
    this.tipo_temporizador_4.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.CambiarSkin(this.tipo_temporizador_4,'skin_tubo');
      this.tipo_temporizador_4.setScale(.3);
      setTimeout(() => {
        this.tipo_temporizador_4.setScale(.5);
      }, 200);
    });
    this.crearBotones();
    this.bordeSelector = this.add.image(this.scale.width-430, 190+40, 'bordeTiempo').setScale(.3).setDepth(10);
    this.colorSelector = this.add.image(this.scale.width-430, 210+40, 'colorSelector').setScale(.22).setDepth(10);
    
    this.colorSelector.setInteractive();
    this.colorSelector.on('pointerdown', function(pointer, localX, localY, event) {
        var color = this.textures.getPixel(localX, localY, 'colorSelector');
        color = Phaser.Display.Color.RGBToString(color.r, color.g, color.b, color.a);
        if(this.colorActualCambio == "ColorTiempo"){  
          this.CambiarTiempo(color);
        }else{
          $("body, #gameContainer").css("background-color", color);
          this.CambiarFondo(color);
        }
    }, this);
    this.btnCerrar = this.add.image(this.scale.width-430, 325+40, 'btnCerrar').setScale(.4).setInteractive();
    this.btnCerrar.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.cerrarColor();
    });
    this.Bolita = this.physics.add.group();
    this.containerCambioColor.add(this.bordeSelector);
    this.containerCambioColor.add(this.colorSelector);
    this.containerCambioColor.add(this.btnCerrar);
    this.crearReloj();
    this.crearLluvia();
    this.crearFluido();
    this.crearAmbienteBolas();
    this.crearTubos();
    this.time.addEvent({
      delay: 1000,
      callback: function (){
        let random = .5;
        let tipo = Phaser.Math.Between(0, 1);
        if(tipo == 1){
          this.nuevaAmplitud += random;
        }else{
          this.nuevaAmplitud -= random;
        }
      },
      callbackScope: this,
      loop:true
    });
    // Añadir el tween para cambiar de color
    this.changeColorTween();
    this.time.addEvent({
      delay: 400,
      callback: function (){
        this.CreaBolaTiempo();
      },
      callbackScope: this,
      loop:true
    });
  }
  getFormatoColor(color){
    return color.replace("#", "0x");
  }
  generarPuntosIntermediosYMostrar(puntos: [number, number][]): [number, number][] {
    const numPuntosIntermedios = 5; 

    
    function calcularPuntosIntermedios(p1: [number, number], p2: [number, number]): [number, number][] {
        const nuevosPuntos: [number, number][] = [];
        const diffX = p2[0] - p1[0];
        const diffY = p2[1] - p1[1];

        for (let j = 1; j <= numPuntosIntermedios; j++) {
            const nuevoX = p1[0] + (diffX / (numPuntosIntermedios + 1)) * j;
            const nuevoY = p1[1] + (diffY / (numPuntosIntermedios + 1)) * j;
            nuevosPuntos.push([nuevoX, nuevoY]);
        }

        return nuevosPuntos;
    }

    const nuevosPuntos: [number, number][] = [];

    
    for (let i = 0; i < puntos.length - 1; i++) {
        const puntoActual = puntos[i];
        const siguientePunto = puntos[i + 1];
        const puntosIntermedios = calcularPuntosIntermedios(puntoActual, siguientePunto);
        nuevosPuntos.push(...puntosIntermedios);
    }

    
    nuevosPuntos.push(...puntos);

    return nuevosPuntos;
  }
  createCircle(x, y, radius) {
    if(!this.isPaused){
      setTimeout(() => {
        let graphics = this.add.graphics();
        graphics.fillStyle(this.getFormatoColor(this.ColorTiempo), 1);
    
        graphics.fillCircle(Phaser.Math.Between(x-125, x+125), 620, radius);
        this.circles.push(graphics);
      }, 500);
    }
  }
  eliminarCirculos() {
    for (let i = 0; i < this.circles.length; i++) {
      if(this.circles[i]){
        this.circles[i].destroy(); 
      }
    }
    this.circles = [];
  }
  bolas = [];
  eliminarTodasBolas() {
    console.log(this.arrayBolas)
    if (this.bolasGrupo && this.bolasGrupo.getLength() > 0) {
      this.bolasGrupo.getChildren().forEach(bola => {
          bola.destroy();
      });
      this.bolasGrupo.clear(true, true);
    }
    for (let i = 0; i < this.arrayBolas.length; i++) {
      this.arrayBolas[i].destroy();
    }
  }
  Bolita: any; // Declaración de la variable Bolita
  physicsConfig = {
    key: 'ball',
    bounceX: .5,
    bounceY: .5,
    collideWorldBounds: true,
    velocityX: 500,
    velocityY: 500
  };
  Limite;
  Limite2;
  fondoLiquido;
  fondoLiquido2;
  RectanguloArribaBola:any;
  RectanguloAbajoBola:any;
  ramps:any = [];
  crearAmbienteBolas(){
    this.ramps = [];
    this.eliminarTodasBolas();
    // Cambiar los límites del mundo
    this.changeBounds(0, 0, this.scale.width, this.scale.height);
    // Crear rampas
    const ramp1 = this.physics.add.sprite(200, 200, 'rampaAzul').setScale(0.5);
    this.physics.add.existing(ramp1);
    ramp1.body.allowGravity = false;
    ramp1.body.immovable = true;
    ramp1.setRotation(0).setVisible(false);
    this.ramps.push(ramp1);

    const ramp2 = this.physics.add.sprite(500, 500 , 'rampaAzul').setScale(0.5);
    this.physics.add.existing(ramp2);
    ramp2.body.allowGravity = false;
    ramp2.body.immovable = true;
    ramp2.setRotation(.05).setVisible(false);
    this.ramps.push(ramp2);

    const ramp3 = this.physics.add.sprite(1720, 200 , 'rampaAzul').setScale(0.5);
    this.physics.add.existing(ramp3);
    ramp3.body.allowGravity = false;
    ramp3.body.immovable = true;
    ramp3.setRotation(0).setVisible(false);
    this.ramps.push(ramp3);

    const ramp4 = this.physics.add.sprite(1420, 500 , 'rampaAzul').setScale(0.5);
    this.physics.add.existing(ramp4);
    ramp4.body.allowGravity = false;
    ramp4.body.immovable = true;
    ramp4.setRotation(-.05).setVisible(false);
    this.ramps.push(ramp4);

    const ramp5 = this.physics.add.sprite(this.scale.width/2, 300, 'rampaAzul').setScale(0.5);
    this.physics.add.existing(ramp5);
    ramp5.body.allowGravity = false;
    ramp5.body.immovable = true;
    ramp5.setRotation(0).setVisible(false);
    this.ramps.push(ramp5);
    // Agregar colisiones entre la pelota y las rampas
    this.Limite = this.add.image(this.scale.width / 2, 600, 'tubo').setDepth(6).setVisible(false);
    this.Limite2 = this.add.image(this.scale.width / 2, 108, 'tubo').setDepth(6).setVisible(false);
    this.RectanguloArribaBola = this.add.graphics();
    this.RectanguloArribaBola.clear();
    this.RectanguloArribaBola.fillStyle(this.getFormatoColor(this.ColorTiempo), 1);
    this.RectanguloArribaBola.fillRect(0, 0, 1920, 100).setDepth(5).setVisible(false);

    this.RectanguloAbajoBola = this.add.graphics();
    this.RectanguloAbajoBola.clear();
    this.RectanguloAbajoBola.fillStyle(this.getFormatoColor(this.ColorTiempo), 1);
    this.RectanguloAbajoBola.fillRect(0, 900, 1920, 0).setDepth(5).setVisible(false);
    this.fondoLiquido = this.add.image(this.scale.width / 2, 0, 'efectoLiquido').setDepth(6).setAlpha(.3).setVisible(false);
    this.fondoLiquido2 = this.add.image(this.scale.width / 2, 760, 'efectoLiquido2').setDepth(6).setAlpha(.3).setVisible(false);
  }
  KeyBola:any = 0;
  arrayKey80:any = [33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48];
  arrayKey100:any = [17,18,19,20,21,22,23,24,25,26,27,28,39,30,31,32];
  imagesPerSecond80x80:any = 0;
  imagesPerSecond100x100:any = 0;
  imagesPerSecond80x80_bruto:any = 0;
  variableTiempo:any = false;
  contadorTiempo:any = 0;
  bolasGrupo:any;
  arrayBolas:any = [];
  CreaBolaTiempo() {
    this.bolasGrupo = this.physics.add.group();
    if (this.isStarted && !this.isPaused && this.skinTiempo == "skin_bolas") {
      const circle = this.add.circle(Phaser.Math.Between(10, 1920), 108, 10, this.getFormatoColor(this.ColorTiempo));
        
        // Agregar la bola al grupo de física
        const bola = this.physics.add.existing(circle);

        // Agregar la bola al grupo
        this.bolasGrupo.add(bola);
        this.arrayBolas.push(bola);
        // Configurar las propiedades de física para cada bola individualmente
        this.bolasGrupo.children.iterate(bola => {
          bola.body.setCircle(10); // Establecer el círculo de colisión
          bola.body.setBounce(0.1, 0.1); // Elasticidad en las direcciones X e Y
          bola.body.setCollideWorldBounds(true); // Colisionar con los límites del mundo
          bola.body.allowGravity = true; // Permitir la gravedad
          bola.body.setVelocity(200, 500); // Cambia los valores según sea necesario
          // this.tweens.add({
          //   targets: bola,
          //   radius: bola.radius +12, // Cambiar el radio del círculo
          //   ease: 'Linear',
          //   duration: 300,
          //   yoyo: false,
          //   repeat: 0
          // });
        });

        // Colisiones con rampas
        this.ramps.forEach(ramp => {
            this.physics.add.collider(this.bolasGrupo, ramp);
        });

        // Establecer la gravedad del mundo de física
        this.physics.world.gravity.y = 300;
    
    }
  }
  shuffleArray(array: any[]) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  }
  circles= [];
  RectanguloArriba:any;
  RectanguloAbajo:any;
  EmitterReloj:any;
  MascaraReloj:any;
  BordeReloj:any;
  crearReloj(){
    this.RectanguloArriba = this.add.graphics();
    this.RectanguloArriba.fillStyle(this.getFormatoColor(this.ColorTiempo), 1);
    this.RectanguloArriba.fillRect(this.scale.width / 2-140, this.scale.height / 2 - 353, 280, 263).setDepth(5);

    this.RectanguloAbajo = this.add.graphics();
    this.RectanguloAbajo.fillStyle(this.getFormatoColor(this.ColorTiempo), 1);
    this.RectanguloAbajo.fillRect(this.scale.width / 2 - 140, this.scale.height / 2 + 210, 280, 0).setDepth(5);

    this.MascaraReloj = this.add.image(this.scale.width / 2, this.scale.height / 2 - 90, 'maskImage').setScale(0.6).setDepth(6);
    this.MascaraReloj.setTint(this.getFormatoColor(this.ColorFondo));
    let circleRadius = 5;
    this.CrearArena();
    let particles = this.add.particles('grayCircle');
    this.EmitterReloj = particles.createEmitter({
      x: 960,
      y: 365,
      speedY: { min: 50, max: 100 },
      lifespan: 1700,
      gravityY: Phaser.Math.Between(0, 100),
      angle: { min: -180, max: 180 },
      scale: { min: .5, max: 1.2 },
      quantity: 1,
      rotate: { start: 0, end: 360},
      frequency: 100,
      emitZone: { type: 'random', source: new Phaser.Geom.Line(-10, 0, 10, 0) },
      on: false,
      emitCallbackScope: this,
      // deathZone: { type: 'onEnter', source: new Phaser.Geom.Rectangle(this.scale.width / 2, this.scale.height / 2 - 90, this.scale.width, 400) },
      deathCallback: function(particle) {
        let circle = ContenedorGeneralGlobal.createCircle(particle.x, particle.y, particle.scaleX * circleRadius);
        ContenedorGeneralGlobal.circles.push(circle);
      },
    });
    particles.setDepth(4);
  
    this.BordeReloj = this.add.image(this.scale.width / 2, this.scale.height / 2 - 90, 'mascaraReloj').setScale(0.6).setDepth(8);
  

    //      // Crear la línea curva
    // this.rellenoCurva = new Phaser.Curves.Spline([
    //   new Phaser.Math.Vector2(850, 110),
    //   new Phaser.Math.Vector2(850, 150)
    // ]);
    // // Crear un gráfico para dibujar la curva
    // var graphics = this.add.graphics();

    // // Establecer el color y el grosor del borde
    // graphics.lineStyle(2, 0x0000ff, 1); // Grosor de 2 píxeles, color azul

    // // Dibujar la curva
    // this.rellenoCurva.draw(graphics);


    // // Crear un gráfico para el rectángulo
    // var graphics = this.make.graphics({ x: 0, y: 0, add: false });
    // graphics.fillStyle(0x000220, 1); // Color negro, opacidad 1
    // graphics.fillRect(0, 0, 400, 300); // Tamaño del rectángulo

    // // Generar una textura a partir del gráfico
    // graphics.generateTexture('rectTexture', 400, 300);

    // // Crear una imagen del rectángulo a partir de la textura generada
    // const rect = this.add.image(this.scale.width / 2, this.scale.height / 2, 'rectTexture');

    // // Crear la imagen de la máscara
    // const maskImage = this.add.image(this.scale.width / 2, this.scale.height / 2 - 90, 'maskImage').setScale(0.6);

    // // Crear la máscara de mapa de bits a partir de la imagen de la máscara
    // const mask = new Phaser.Display.Masks.BitmapMask(this, maskImage);

    // // Aplicar la máscara a la imagen del rectángulo
    // rect.setMask(mask);
    // this.crearCurva();
    // let puntos: any = [
    //   [156.09756097560975, 2.6016260162601625 + 45],
    //   [154.79674796747966, 31.21951219512195 + 45],
    //   [154.79674796747966, 59.83739837398374 + 45],
    //   [156.09756097560975, 88.45528455284553 + 45],
    //   [163.90243902439025, 115.77235772357723 + 45],
    //   [178.21138211382114, 140.4878048780488 + 45],
    //   [199.02439024390245, 160 + 45],
    //   [219.83739837398375, 179.5121951219512 + 45],
    //   [239.34959349593495, 200.3252032520325 + 45],
    //   [254.95934959349592, 223.739837398374 + 45],
    //   [271.869918699187, 247.15447154471545 + 45],
    //   [279.6747967479675, 274.4715447154471 + 45],
    //   [282.2764227642276, 303.0894308943089 + 45],

    //   [156.09756097560975, -2.6016260162601625+652],
    //   [154.79674796747966, -31.21951219512195+652],
    //   [154.79674796747966, -59.83739837398374+652],
    //   [156.09756097560975, -88.45528455284553+652],
    //   [163.90243902439025, -115.77235772357723+652],
    //   [178.21138211382114, -140.4878048780488+652],
    //   [199.02439024390245, -160+652],
    //   [219.83739837398375, -179.5121951219512+652],
    //   [239.34959349593495, -200.3252032520325+652],
    //   [254.95934959349592, -223.739837398374+652],
    //   [271.869918699187, -247.15447154471545+652],
    //   [279.6747967479675, -274.4715447154471+652],
    //   [282.2764227642276, -303.0894308943089+652],
    // ];
    // const sides = 1;
    // const size = 2;
    // const distance = size * 2;
    // const stiffness = 0.1;
    // const lastPosition = new Phaser.Math.Vector2();
    // const options = { friction: 0.005, frictionAir: 0, restitution: 1 };
    // const pinOptions = { friction: 0, frictionAir: 0, restitution: 0, ignoreGravity: true, inertia: Infinity, isStatic: true };
    // let x_mas = 658;
    // let x_mas_2 = 1262;
    // let puntosIntermedios = this.generarPuntosIntermediosYMostrar(puntos);
    // for (let x = 0; x < puntosIntermedios.length; x++) {
    //   const punto = puntosIntermedios[x];
    //   this.matter.add.polygon(x_mas+punto[0], punto[1], sides, size, pinOptions);
    // }
    // let puntosReflejados = [];
    // for (let x = 0; x < puntosIntermedios.length; x++) {
    //     const punto = puntosIntermedios[x];
    //     const puntoReflejado = [-punto[0], punto[1]];
    //     puntosReflejados.push(puntoReflejado);
    // }
    // for (let x = 0; x < puntosReflejados.length; x++) {
    //     const punto = puntosReflejados[x];
    //     this.matter.add.polygon(x_mas_2+punto[0], punto[1], sides, size, pinOptions);
    // }
    // // let top = this.matter.add.rectangle(this.scale.width/2, 40, 425, 2, pinOptions);
    // let middle = this.matter.add.rectangle(this.scale.width/2, 348, 170, 2, pinOptions);
    // let bottom = this.matter.add.rectangle(this.scale.width/2, 657, 425, 2, pinOptions);
    // this.time.addEvent({
    //   delay: 100,
    //   callback: function (){
    //     const ball = this.matter.add.image(Phaser.Math.Between(850, 1100), Phaser.Math.Between(40, 45), 'ball');
    //     ball.setCircle();
    //     ball.setFriction(0.005).setBounce(1);
    //   },
    //   callbackScope: this,
    //   repeat: 50
    // });
    // this.matter.world.on('collisionstart', (event, bodyA, bodyB) => {
    //   // Verificar si uno de los cuerpos es la bola y el otro es el rectángulo middle
    //   if ((bodyA === middle && bodyB.gameObject instanceof Phaser.Physics.Matter.Image) ||
    //       (bodyB === middle && bodyA.gameObject instanceof Phaser.Physics.Matter.Image)) {
    //       // Llamamos a la función ColisionBorde solo si la colisión es entre la bola y el rectángulo middle
    //       this.ColisionBorde(bodyA, bodyB);
    //   }
    // });
  }
  Lluvia;
  EmitterLluvia;
  nuevaAmplitud:any = 10;
  lerpColor(color1, color2, t) {
    const colorObj1 = Phaser.Display.Color.ValueToColor(color1);
    const colorObj2 = Phaser.Display.Color.ValueToColor(color2);

    const r = Phaser.Math.Linear(colorObj1.red, colorObj2.red, t);
    const g = Phaser.Math.Linear(colorObj1.green, colorObj2.green, t);
    const b = Phaser.Math.Linear(colorObj1.blue, colorObj2.blue, t);

    // Convertir el color interpolado a una cadena hexadecimal en el formato 0xRRGGBB
    const hexString = Phaser.Display.Color.RGBToString(Math.round(r), Math.round(g), Math.round(b), 0);
    this.CambiarTiempo(hexString);
  }
  crearLluvia() {
    this.Lluvia = this.add.graphics();
    this.Lluvia.clear();
    this.Lluvia.fillStyle(this.getFormatoColor(this.ColorTiempo), 1);
    const amplitude = this.nuevaAmplitud;
    const frequency = 0.05;
    const rectWidth = this.scale.width + 1000;
    const rectHeight = 0;
    const rectX = 0 - 500;
    const rectY = this.scale.height;

    this.Lluvia.beginPath();

    for (let x = 0; x <= rectWidth; x++) {
        const y = Math.sin((x + 1 * 100) * frequency) * amplitude;
        if (x === 0) {
            this.Lluvia.moveTo(rectX + x, rectY + y);
        } else {
            this.Lluvia.lineTo(rectX + x, rectY + y);
        }
    }

    this.Lluvia.lineTo(rectX + rectWidth, rectY + rectHeight);
    this.Lluvia.lineTo(rectX, rectY + rectHeight);
    this.Lluvia.lineTo(rectX, rectY);
    this.Lluvia.closePath();
    this.Lluvia.fillPath();
    this.Lluvia.setVisible(false);
    this.tweens.add({
        targets: this.Lluvia,
        x: 100,
        ease: 'Linear',
        duration: 5000,
        yoyo: true,
        repeat: -1
    });
    this.CrearTextura();
    let particles = this.add.particles('drop');

    this.EmitterLluvia = particles.createEmitter({
        x: { min: 0, max: this.scale.width },
        y: 0,
        lifespan: 2000,
        speedY: { min: 100, max: 300 },
        scale: { start: .1, end: .4 },
        gravityY: 600,
        quantity: 1,
        frequency: 100,
        on: false,
    });
}
  changeColorTween() {
    const startColorHex = this.ColorTiempo; // Color inicial en formato hexadecimal
    const endColor = Phaser.Display.Color.RandomRGB(); // Color final en formato de objeto de color
    const endColorHex = Phaser.Display.Color.RGBToString(endColor.red, endColor.green, endColor.blue); // Color final en formato hexadecimal
    const duration = 5000;

    this.tweens.addCounter({
        from: 0,
        to: 1,
        duration: duration,
        ease: 'Linear',
        repeat: 0,
        yoyo: false,
        onUpdate: function (tween) {
            const value = tween.getValue();
            ContenedorGeneralGlobal.lerpColor(startColorHex, endColorHex, value);
        },
        onUpdateScope: this,
        onComplete: this.changeColorTween, // Llamar de nuevo para un bucle infinito
        onCompleteScope: this
    });
  }
  dibujarLluvia(porcentaje) {
    const amplitude = this.nuevaAmplitud;
    const frequency = 0.05;
    const rectWidth = this.scale.width + 1000;
    const alturaMaxima = 950; 
    const altura = porcentaje * alturaMaxima; 
    const rectX = 0 - 500;
    const rectY = this.scale.height - altura;

    
    this.Lluvia.clear();
    this.Lluvia.fillStyle(this.getFormatoColor(this.ColorTiempo), 1);
    this.Lluvia.beginPath();

    
    for (let x = 0; x <= rectWidth; x++) {
        const y = Math.sin((x + 1 * 100) * frequency) * amplitude;
        if (x === 0) {
            this.Lluvia.moveTo(rectX + x, rectY + y);
        } else {
            this.Lluvia.lineTo(rectX + x, rectY + y);
        }
    }

    
    this.Lluvia.lineTo(rectX + rectWidth, rectY + altura);
    this.Lluvia.lineTo(rectX, rectY + altura);
    this.Lluvia.lineTo(rectX, rectY);
    this.Lluvia.closePath();
    this.Lluvia.fillPath();
}

  Fluido;
  Liquido;
  EmitterFluido;
  nuevaAmplitudFluido:any = 4;
  crearFluido(){
    this.Fluido = this.add.graphics();
    this.Fluido.clear();
    this.Fluido.fillStyle(this.getFormatoColor(this.ColorTiempo), 1);
    const amplitude = this.nuevaAmplitudFluido; 
    const frequency = 0.05; 
    const rectWidth = this.scale.width+1000;
    const rectHeight = 50;
    const rectX = 0-500;
    const rectY = this.scale.height-10;
    this.Fluido.beginPath();
    for (let x = 0; x <= rectWidth; x++) {
        const y = Math.sin((x + 1 * 100) * frequency) * amplitude;
        if (x === 0) {
            this.Fluido.moveTo(rectX + x, rectY + y);
        } else {
            this.Fluido.lineTo(rectX + x, rectY + y);
        }
    }
    this.Fluido.lineTo(rectX + rectWidth, rectY + rectHeight);
    this.Fluido.lineTo(rectX, rectY + rectHeight);
    this.Fluido.lineTo(rectX, rectY);
    this.Fluido.closePath();
    this.Fluido.fillPath();
    this.Fluido.setVisible(false);
    this.tweens.add({
      targets: this.Fluido,
      x: 100,
      ease: 'Linear',
      duration: 5000,
      yoyo: true,
      repeat: -1
    });
    //LIQUIDO
    this.Liquido = this.add.graphics();
    this.Liquido.clear();
    this.Liquido.fillStyle(this.getFormatoColor(this.ColorTiempo), 1);

    const amplitude1 = this.nuevaAmplitudFluido; 
    const frequency1 = 0.05; 
    const rectWidth1 = 200;
    const rectHeight1 = 900;
    const rectX1 = this.scale.width / 2 - rectWidth1 / 2; // Ajusta el punto de inicio de X
    const rectY1 = this.scale.height - rectHeight1; // La parte inferior del rectángulo

    this.Liquido.beginPath();

    // Dibujar la parte superior del rectángulo
    this.Liquido.moveTo(rectX1, rectY1);
    this.Liquido.lineTo(rectX1 + rectWidth1, rectY1);

    // Dibujar la ola en las paredes derecha e izquierda
    const steps = 100; // Número de pasos para dividir la altura
    const stepSize = rectHeight1 / steps;
    for (let i = 0; i <= steps; i++) {
        // Calcular la posición Y para el paso actual
        const y = rectY1 + stepSize * i;
        // Calcular la posición X basada en la función seno y la frecuencia
        const xRight = Math.sin((y * 100) * frequency1) * amplitude1;
        const xLeft = -xRight; // Invierte la posición para la pared izquierda
        // Dibujar un segmento de línea en la pared derecha e izquierda
        this.Liquido.lineTo(rectX1 + rectWidth1 / 2 + xRight, y); // Pared derecha
        this.Liquido.lineTo(rectX1 + rectWidth1 / 2 + xLeft, y); // Pared izquierda
    }

    // Dibujar la parte inferior del rectángulo
    this.Liquido.lineTo(rectX1 + rectWidth1, rectY1 + rectHeight1);
    this.Liquido.lineTo(rectX1, rectY1 + rectHeight1);
    this.Liquido.closePath();
    this.Liquido.fillPath();  
    this.Liquido.setVisible(false);
  }
  dibujarFluido(porcentaje) {
    const amplitude = this.nuevaAmplitudFluido;
    const frequency = 0.05;
    const rectWidth = this.scale.width + 1000;
    const alturaMaxima = 950; 
    const altura = porcentaje * alturaMaxima; 
    const rectX = 0 - 500;
    const rectY = this.scale.height - altura;
    this.Fluido.clear();
    this.Fluido.fillStyle(this.getFormatoColor(this.ColorTiempo), 1);
    this.Fluido.beginPath();

    
    for (let x = 0; x <= rectWidth; x++) {
        const y = Math.sin((x + 1 * 100) * frequency) * amplitude;
        if (x === 0) {
            this.Fluido.moveTo(rectX + x, rectY + y);
        } else {
            this.Fluido.lineTo(rectX + x, rectY + y);
        }
    }
    this.Fluido.lineTo(rectX + rectWidth, rectY + altura);
    this.Fluido.lineTo(rectX, rectY + altura);
    this.Fluido.lineTo(rectX, rectY);
    this.Fluido.closePath();
    this.Fluido.fillPath();
}
  CrearTextura(){
    
    let graphics = this.add.graphics();
    graphics.fillStyle(this.getFormatoColor(this.ColorTiempo), 1);

    
    graphics.beginPath();
    graphics.arc(50, 100, 50, 0, Math.PI, false);  
    graphics.closePath();
    graphics.fillPath();

    
    graphics.beginPath();
    graphics.moveTo(0, 100);  
    graphics.lineTo(100, 100);  
    graphics.lineTo(50, 0);  
    graphics.closePath();
    graphics.fillPath();

    
    graphics.generateTexture('drop', 100, 150);  
    graphics.destroy();
  }
  CrearArena(){
    let graphics = this.add.graphics();
    let circleRadius = 5;
    graphics.fillStyle(this.getFormatoColor(this.ColorTiempo), 1);
    graphics.fillCircle(circleRadius, circleRadius, circleRadius);
    graphics.generateTexture('grayCircle', circleRadius * 2, circleRadius * 2);
    graphics.destroy();
  }
  calcularAlturaPorcentaje(tiempoTranscurrido) {
      
      const porcentaje = tiempoTranscurrido / this.tiempoTotalSegundos;
      return porcentaje;
  }
  changeBounds(x,y,w,h) {
    this.physics.world.setBounds(x,y,w,h);
  }
  waterGroup:any;
  addWaterDrop() {
    // Crear un nuevo círculo de agua
    const waterDrop = this.add.circle(Phaser.Math.Between(950, 970), -500, 5, this.getFormatoColor(this.ColorTiempo));
    this.physics.add.existing(waterDrop);

    // Añadir el círculo al grupo
    this.waterGroup.add(waterDrop);

    // Configurar las propiedades físicas del círculo
    const body = waterDrop.body;
    if (body && body instanceof Phaser.Physics.Arcade.Body) {
        body.setVelocity(Phaser.Math.Between(-10, 10), 0);
        body.setCollideWorldBounds(true);
        body.setBounce(0.1, 0.1);
    }
  }
  BordeTubos:any;
  containerTop:any;
  containerLeft:any;
  containerRight:any;
  TuboRelleno:any;
  initialHeight:any = 0;
  maxHeight:any = 0;
  fillDuration:any = 0;
  crearTubos(){
    this.changeBounds(765, 110, 390, 505);
    this.TuboRelleno = this.add.graphics();
    this.TuboRelleno.clear();
    this.TuboRelleno.fillStyle(this.getFormatoColor(this.ColorTiempo), 1);
    this.initialHeight = 0;
    this.maxHeight = 505;
    this.fillDuration = 10;
    this.TuboRelleno.fillRect(765, 110, 390, 0).setVisible(false).setDepth(7);
    this.BordeTubos = this.add.image(this.scale.width / 2, this.scale.height / 2 - 90, 'mascaraTubos').setScale(0.6).setDepth(8).setVisible(false);
    // Crear un grupo de partículas de agua
    this.waterGroup = this.physics.add.group({
      classType: Phaser.GameObjects.Ellipse, // Usamos la clase Ellipse para crear círculos
      maxSize: 1000
    });

    // Crear los bordes del contenedor
    // this.containerTop = this.add.rectangle(960, 110, 390, 20, 0x00ff00).setOrigin(0.5, 0).setAlpha(0);
    this.containerLeft = this.add.rectangle(765, 375, 20, 530, 0x00ff00).setOrigin(0.5, 0.5).setAlpha(0);
    this.containerRight = this.add.rectangle(1155, 375, 20, 530, 0x00ff00).setOrigin(0.5, 0.5).setAlpha(0);

    // Añadir los bordes del contenedor como objetos estáticos
    // this.physics.add.existing(this.containerTop, true);
    this.physics.add.existing(this.containerLeft, true);
    this.physics.add.existing(this.containerRight, true);

    // Configurar colisiones
    // this.physics.add.collider(this.waterGroup, this.containerTop);
    this.physics.add.collider(this.waterGroup, this.containerLeft);
    this.physics.add.collider(this.waterGroup, this.containerRight);
  }
  ColisionBorde(middle,ball){
    ball.y = ball.y + 50
  }
  grupoDeBolas:any=[];
  crearBotones(){
    this.containerCronometro = this.add.container(0, 0);
    this.containerModal = this.add.container(this.scale.width/2-480,this.scale.height/2-400).setScale(.5).setVisible(false);
    this.modalTiempo = this.add.image(this.scale.width/2,this.scale.height/2,"modalTiempo");   
    this.temporizadorTexto = this.add.text(this.scale.width/2, 400, '00:00:00', { fontFamily: 'Arial', fontSize: '240px', color: '#000000' });
    this.temporizadorTexto.setOrigin(0.5); 
    this.BordeCronometro = this.add.image(290, this.scale.height - 70,"BordeCronometro").setScale(.2).setVisible(false).setDepth(10);
    this.BordeCronometroTexto = this.add.text(195, this.scale.height - 95, '00:00:00', { fontFamily: 'Arial', fontSize: '50px', color: '#000000' }).setVisible(false).setDepth(10);
    this.BotonMasHora = this.add.image(625,250,"btn_mas").setScale(.65).setInteractive();
    this.BotonMenosHora = this.add.image(625,540,"btn_menos").setScale(.65).setInteractive();
    this.BotonMasHora.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.quitarAutomatico();
      this.MasHora();
    });
    this.BotonMenosHora.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.quitarAutomatico();
      this.MenosHora();
    }); 
    this.BotonMasMinutos = this.add.image(960,250,"btn_mas").setScale(.65).setInteractive();
    this.BotonMenosMinutos = this.add.image(960,540,"btn_menos").setScale(.65).setInteractive();
    this.BotonMasMinutos.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.quitarAutomatico();
      this.MasMinuto();
    });
    this.BotonMenosMinutos.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.quitarAutomatico();
      this.MenosMinuto();
    });
    this.BotonMasSegundos = this.add.image(1290,250,"btn_mas").setScale(.65).setInteractive();
    this.BotonMenosSegundos = this.add.image(1290,540,"btn_menos").setScale(.65).setInteractive();
    this.BotonMasSegundos.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.quitarAutomatico();
      this.MasSegundos();
    });
    this.BotonMenosSegundos.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.quitarAutomatico();
      this.MenosSegundos();
    });
    let iniciar = this.add.image(900,750,"iniciar").setInteractive();
    iniciar.on('pointerdown', () => {
      iniciar.setScale(.9);
      setTimeout(() => {
        iniciar.setScale(1);
      },200);
      this.CambiarTiempoTotal();
      this.cerrarModal();
    });
    let salir = this.add.image(1200,750,"btnCerrar").setInteractive();
    salir.on('pointerdown', () => {
      salir.setScale(.9);
      setTimeout(() => {
        salir.setScale(1);
      },200);
      this.cerrarModal();
    });
    this.containerModal.add(this.modalTiempo);
    this.containerModal.add(this.temporizadorTexto);
    this.containerModal.add(this.BotonMasHora);
    this.containerModal.add(this.BotonMenosHora);
    this.containerModal.add(this.BotonMasMinutos);
    this.containerModal.add(this.BotonMenosMinutos);
    this.containerModal.add(this.BotonMasSegundos);
    this.containerModal.add(this.BotonMenosSegundos);
    this.containerModal.add(iniciar);
    this.containerModal.add(salir);
    this.containerModal.setDepth(10);
    this.pauseButton = this.add.image(this.scale.width/2 - 100,this.scale.height - 170,"btn_pausa").setAlpha(.5).setScale(.55).setInteractive();
    this.playButton = this.add.image((this.scale.width/2)-5,this.scale.height - 170,"btn_play").setAlpha(.5).setScale(.75).setInteractive();
    this.restartButton = this.add.image(this.scale.width/2 + 90,this.scale.height - 170,"btn_replay").setAlpha(.5).setScale(.55).setInteractive();

    this.playButton.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      if(!this.isStarted){
        this.CambiarTiempoTotal();
      }
      this.isPaused = false;        
      this.playButton.setScale(.65);
      setTimeout(() => {
        this.playButton.setScale(.75);
      },200);
      if(this.skinTiempo == "skin_reloj"){
        this.EmitterReloj.start();
      }else if(this.skinTiempo == "skin_gotas"){

      }else if(this.skinTiempo == "skin_lava"){
  
      }else if(this.skinTiempo == "skin_bolas"){
      }else if(this.skinTiempo == "skin_lluvia"){
        this.EmitterLluvia.start();
      }else if(this.skinTiempo == "skin_tubo"){

      }else{
        
      }
      this.isStarted = true;
    });

    this.pauseButton.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.isPaused = true;
      this.pauseButton.setScale(.45);
      setTimeout(() => {
        this.pauseButton.setScale(.55);
      },200);
      if(this.skinTiempo == "skin_reloj"){
        this.EmitterReloj.stop();
      }else if(this.skinTiempo == "skin_gotas"){

      }else if(this.skinTiempo == "skin_lava"){
  
      }else if(this.skinTiempo == "skin_lluvia"){
        this.EmitterLluvia.stop();
      }else{
        
      }
    });

    this.restartButton.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.isPaused = true;
      this.isStarted = false;
      this.resetTime();
      this.restartButton.setScale(.45);
      setTimeout(() => {
        this.restartButton.setScale(.55);
      },200);
      if(this.skinTiempo == "skin_reloj"){
        this.EmitterReloj.stop();
      }else if(this.skinTiempo == "skin_gotas"){

      }else if(this.skinTiempo == "skin_lava"){
  
      }else if(this.skinTiempo == "skin_lluvia"){
        this.EmitterLluvia.stop();
      }else{
      }
    });
    this.containerCronometro.add(this.playButton);
    this.containerCronometro.add(this.pauseButton);
    this.containerCronometro.add(this.restartButton);
    this.containerCronometro.setDepth(10);
    this.switch_activado = this.add.image(530,this.scale.height - 70,"switch_desactivado").setScale(.15).setDepth(10).setVisible(false);
    this.switch_icono = this.add.image(500,this.scale.height - 70,"switch_icono").setScale(.2).setInteractive().setDepth(10).setVisible(false);
    this.switch_icono.on('pointerdown', () => {
      this.mostrarCronometro();
    });
    // this.RelojArena = this.add.image(this.scale.width / 2, this.scale.height / 2 - 100, 'skin_reloj').setScale(0.53);
    // this.containerReloj = this.add.container(0, 0);
    // this.containerGotas = this.add.container(0, 0);
    // this.containerLava = this.add.container(0, 0);
    // this.containerLluvia = this.add.container(0, 0);
    // this.containerReloj.add(this.RelojArena);
  }
  
  MostrarOcultar(){
    this.changeBounds(0, 0, this.scale.width, this.scale.height+100);
    this.RectanguloArriba.setVisible(false);
    this.RectanguloAbajo.setVisible(false);
    this.EmitterReloj.setVisible(false);
    this.MascaraReloj.setVisible(false);
    this.BordeReloj.setVisible(false);
    this.Lluvia.setVisible(false);
    this.Fluido.setVisible(false);
    this.Liquido.setVisible(false);
    this.BordeTubos.setVisible(false);
    this.TuboRelleno.setVisible(false);
    this.Limite.setVisible(false);
    this.Limite2.setVisible(false);
    this.RectanguloArribaBola.setVisible(false);
    this.RectanguloAbajoBola.setVisible(false);
    this.fondoLiquido.setVisible(false);
    this.fondoLiquido2.setVisible(false);
    this.eliminarCirculos();
    this.eliminarTodasBolas();

    this.ramps.forEach(ramp => {
      ramp.setVisible(false);
    });
    this.waterGroup.clear(true, true);
    if(this.skinTiempo == "skin_reloj"){
      this.RectanguloArriba.setVisible(true);
      this.RectanguloAbajo.setVisible(true);
      this.EmitterReloj.setVisible(true);
      this.MascaraReloj.setVisible(true);
      this.BordeReloj.setVisible(true);
    }else if(this.skinTiempo == "skin_gotas"){
      this.Fluido.setVisible(true);
      this.Liquido.setVisible(true);
    }else if(this.skinTiempo == "skin_lava"){

    }else if(this.skinTiempo == "skin_bolas"){
      this.ramps.forEach(ramp => {
        ramp.setVisible(true);
      });
      this.Limite.setVisible(true);
      this.Limite2.setVisible(true);
      this.RectanguloArribaBola.setVisible(true);
      this.RectanguloAbajoBola.setVisible(true);
      this.fondoLiquido.setVisible(true);
      this.fondoLiquido2.setVisible(true);
    }else if(this.skinTiempo == "skin_lluvia"){
      this.Lluvia.setVisible(true);
    }else if(this.skinTiempo == "skin_tubo"){
      this.crearTubos();
      this.BordeTubos.setVisible(true);
      this.TuboRelleno.setVisible(true);
    }else{
      
    }
  }
  mostrarCronometro(){
    this.reproducirEfecto(this.sonidoMenu);
    if(this.EstadoCronometro){
      this.tweens.add({
        targets: this.switch_icono,
        x: 500,
        duration: 100,
        ease: 'Linear'
      });
      setTimeout(() => {
        this.switch_activado.setTexture("switch_desactivado");
      }, 100);
      this.EstadoCronometro = false;
      this.BordeCronometro.setVisible(false);
      this.BordeCronometroTexto.setVisible(false);
    }else{
      this.tweens.add({
        targets: this.switch_icono,
        x: 550,
        duration: 100,
        ease: 'Linear'
      });
      setTimeout(() => {
        this.switch_activado.setTexture("switch_activado");
      }, 100);
      this.EstadoCronometro = true;
      this.BordeCronometro.setVisible(true);
      this.BordeCronometroTexto.setVisible(true);
    }
  }
  update(tiempo, delta) {
    this.updateTimeText();
    if (!this.isPaused) {
      this.tiempoTranscurrido += delta / 1000;
      this.BordeCronometroTexto.setText(this.formatoTiempo(this.tiempoTotalSegundos - Math.floor(this.tiempoTranscurrido)));
      let restante = this.tiempoTotalSegundos - Math.floor(this.tiempoTranscurrido);
      //TIEMPO TERMINADO
      if(this.isStarted && restante == 0){
        if(this.skinTiempo == "skin_reloj"){
          this.EmitterReloj.stop();
          this.eliminarCirculos();
        }else if(this.skinTiempo == "skin_gotas"){
  
        }else if(this.skinTiempo == "skin_lava"){
    
        }else if(this.skinTiempo == "skin_bolas"){
          this.eliminarTodasBolas();
        }else if(this.skinTiempo == "skin_lluvia"){
          this.EmitterLluvia.stop();
        }else if(this.skinTiempo == "skin_tubo"){   
          this.waterGroup.clear(true, true);    
        }else{
        }
        this.ReproduceEfectos();
        this.isStarted = false;
        this.isPaused = true;
      }
      //MANEJANDO EL TIEMPO TRANSCURRIENDO
      if(this.skinTiempo == "skin_reloj"){                 
         let progress = this.tiempoTranscurrido / this.tiempoTotalSegundos;
        let maxHeight = 300; 
          
         this.RectanguloArriba.clear();
         this.RectanguloAbajo.clear();
          
         let heightArriba = maxHeight * (1 - progress);
         let heightAbajo = maxHeight * progress;
          
         this.RectanguloArriba.fillStyle(this.getFormatoColor(this.ColorTiempo), 1);
         this.RectanguloArriba.fillRect(this.scale.width / 2 - 140, this.scale.height / 2 - 390 + (maxHeight - heightArriba), 280, heightArriba);
 
         this.RectanguloAbajo.fillStyle(this.getFormatoColor(this.ColorTiempo), 1);
         this.RectanguloAbajo.fillRect(this.scale.width / 2 - 140, this.scale.height / 2 + 210 - heightAbajo, 280, heightAbajo);
      }else if(this.skinTiempo == "skin_lluvia"){        
        const porcentajeAltura = this.calcularAlturaPorcentaje(this.tiempoTranscurrido);
        this.Lluvia.clear();
        this.dibujarLluvia(porcentajeAltura);
      }else if(this.skinTiempo == "skin_bolas"){
        this.RectanguloArribaBola.clear();
        this.RectanguloAbajoBola.clear();

        let tiempoPorcentaje = this.tiempoTranscurrido / this.tiempoTotalSegundos;
                        
        let nuevoAltoArriba = 102 * (1 - tiempoPorcentaje); // Invertido
        
        nuevoAltoArriba = Math.min(nuevoAltoArriba, 102);
        let nuevaPosYArriba = 102 - nuevoAltoArriba;
        
        let nuevoAltoAbajo = 292 * tiempoPorcentaje;
        
        nuevoAltoAbajo = Math.min(nuevoAltoAbajo, 292);
        let nuevaPosYAbajo = 900 - nuevoAltoAbajo;
        nuevaPosYAbajo = Math.min(Math.max(nuevaPosYAbajo, 600), 900 - nuevoAltoAbajo);        
        this.RectanguloArribaBola.fillStyle(this.getFormatoColor(this.ColorTiempo), 1);
        this.RectanguloArribaBola.fillRect(0, nuevaPosYArriba, 1920, nuevoAltoArriba).setDepth(5);

        this.RectanguloAbajoBola.fillStyle(this.getFormatoColor(this.ColorTiempo), 1);
        this.RectanguloAbajoBola.fillRect(0, nuevaPosYAbajo, 1920, nuevoAltoAbajo).setDepth(5);    

      }else if(this.skinTiempo == "skin_tubo"){
        let newHeight = Phaser.Math.Clamp(
          (this.tiempoTranscurrido / this.tiempoTotalSegundos) * this.maxHeight,
          0,
          this.maxHeight
        );
        // Dibujar el rectángulo con la nueva altura
        this.TuboRelleno.clear();
        this.TuboRelleno.fillStyle(this.getFormatoColor(this.ColorTiempo), 1);
        this.TuboRelleno.fillRect(765, 110 + (this.maxHeight - newHeight), 390, newHeight);
        this.addWaterDrop();
      }else{
        const porcentajeAltura = this.calcularAlturaPorcentaje(this.tiempoTranscurrido);
        this.Fluido.clear();
        this.dibujarFluido(porcentajeAltura);
      }
    }
    if(this.tiempo.segundos > 0 || this.tiempo.minutos > 0 || this.tiempo.hora > 0){
      if(this.isPaused){
        this.playButton.setInteractive().setAlpha(1);
        this.pauseButton.disableInteractive().setAlpha(.5);
      }else{
        this.playButton.disableInteractive().setAlpha(.5);
        this.pauseButton.setInteractive().setAlpha(1);
        this.restartButton.setInteractive().setAlpha(1);
      }
    }else{
      this.playButton.disableInteractive().setAlpha(.5);
      this.pauseButton.disableInteractive().setAlpha(.5);
      this.restartButton.disableInteractive().setAlpha(.5);
    }
    if(this.sonidoActual== "efecto_1"){
      this.BotonPlayPrev.disableInteractive().setAlpha(.5);
    }else{
      if(!this.isPaused){
        this.BotonPlayPrev.disableInteractive().setAlpha(.5);
      }else{
        this.BotonPlayPrev.setInteractive().setAlpha(1);
      }
    }

    if(this.isStarted){
      if(this.EstadoMenu){
        this.BotonMenu.emit('pointerdown');
      }
      this.timer_1.setVisible(false);
      this.timer_2.setVisible(false);
      this.timer_3.setVisible(false);
      this.timer_4.setVisible(false);
      this.timer_5.setVisible(false);
      this.timer_6.setVisible(false);
      this.timer_7.setVisible(false);
      this.timer_8.setVisible(false);
      this.timer_personalizado.setVisible(false);
      // this.switch_activado.setVisible(false);
      // this.switch_icono.setVisible(false);
    }else{
      if(!this.EstadoMenu){
        this.BotonMenu.emit('pointerdown');
      }
      this.timer_1.setVisible(true);
      this.timer_2.setVisible(true);
      this.timer_3.setVisible(true);
      this.timer_4.setVisible(true);
      this.timer_5.setVisible(true);
      this.timer_6.setVisible(true);
      this.timer_7.setVisible(true);
      this.timer_8.setVisible(true);
      this.timer_personalizado.setVisible(true);
      this.switch_activado.setVisible(true);
      this.switch_icono.setVisible(true);
    }
  }
  formatoTiempo(segundos) {
      var horas = Math.floor(segundos / 3600);
      var minutos = Math.floor((segundos % 3600) / 60);
      var segundosRestantes = segundos % 60;
      return this.pad(horas) + ":" + this.pad(minutos) + ":" + this.pad(segundosRestantes);
  }
  pad(numero) {
      return numero < 10 ? '0' + numero : numero;
  }
  getColorForHeight(height, maxHeight) {
    const greenThreshold = 0.6;
    const yellowThreshold = 0.2;
    let percentage = height / maxHeight;
    if (percentage > greenThreshold) {
      return 0x00ff00;
    } else if (percentage > yellowThreshold) {
      return 0xffff00;
    } else {
      return 0xff0000;
    }
  }
  reiniciarSkin() {
    this.quitarAutomatico();
  }
  DURACION:any = 2000;
  ReproduceEfectos(){
    this.DURACION = this.getTimeDuracion();
    this.reproduciendoEfecto = true;
    this.reproducirAudio();
    if(this.efectoActual == 'confetti'){
      this.efectoConfetti();
    }else if(this.efectoActual == 'emojis'){
      this.efectoEmoji();
    }else if(this.efectoActual == 'explosion'){
      this.explotar();
    }else{
      //sin efecto
    }
    Principal.api.set_kpi_plataformas("Temporizador Sensorial","Reproduce efecto: "+this.efectoActual+" "+this.getNombreSonido(this.sonidoActual));
    setTimeout(() => {
      this.Reiniciar();
    }, 2500);
  }
  getTimeDuracion(){
    if(ContenedorGeneralGlobal[this.sonidoActual]){
      return Math.round(ContenedorGeneralGlobal[this.sonidoActual].duration)*1000;
    }else{
      return 2000;
    }
  }
  getNombreSonido(sonido){
    switch(sonido) {
      case 'sonido_14': return "Pedro Music" ;break;
      case 'sonido_1': return "Mario Bross" ;break;
      case 'sonido_2': return "Fornite Victory" ;break;
      case 'sonido_3': return "Campana" ;break;
      case 'sonido_4': return "Triste" ;break;
      case 'sonido_5': return "Arpa" ;break;
      case 'sonido_6': return "Aplausos" ;break;
      case 'sonido_7': return "Risa Bebé" ;break;
      case 'sonido_8': return "Grillos" ;break;
      case 'sonido_9': return "Niños" ;break;
      case 'sonido_10': return "Horror" ;break;
      case 'sonido_11': return "Bocina Reggaeton" ;break;
      case 'sonido_12': return "Explosión Fuerte" ;break;
      case 'sonido_13': return "Explosión Suave" ;break;
      case 'sonido_15': return "Paz Mental" ;break;
      case 'sonido_16': return "Ecos del alma" ;break;
      case 'sonido_17': return "Armonía Interior" ;break;
      case 'efecto_1': return "Ninguno" ;break;
      default: return sonido; break;
    }
  }
  explotar(){
    const textureNames = ['explosion_1', 'explosion_2', 'explosion_3', 'explosion_4', 'explosion_5', 'explosion_6', 'explosion_7', 'explosion_8', 'explosion_9'];
    let currentTextureIndex = 0;
    let sprite_explosion = this.add.sprite(this.scale.width/2 ,this.scale.height/2-100, 'efecto_bomba').setScale(1.7).setDepth(10);
    function changeTexture2() {
      const nextTextureName = textureNames[currentTextureIndex];
      sprite_explosion.setTexture(nextTextureName);
      currentTextureIndex++;
    }
    const delayBetweenTextureChanges = 200;
    const changeTextureEvent = this.time.addEvent({
      delay: delayBetweenTextureChanges,
      callback: changeTexture2,
      callbackScope: this,
      loop: true 
    });
    
    setTimeout(() => {
      this.reproduciendoEfecto = false;
    }, this.DURACION);
  }
  efectoConfetti(){
    const particles = this.add.particles('confetti');
    this.Emitterconfetti = particles.createEmitter({
      frame: ['efect_1.png','efect_2.png','efect_3.png','efect_4.png','efect_5.png','efect_6.png'],
      x: this.scale.width/2,
      y: -100,
      rotate: { start: 0, end: 360, ease: 'Back.easeOut' },
      alpha: {min:75, max: 100},
      lifespan: 3000,
      angle: {min: 0, max: 360},
      speed: { min : 100, max: 200},
      scale: {start: 0.8, end: 0},
      gravityY: 200
    });
    particles.setDepth(10);
    setTimeout(() => {
      this.Emitterconfetti.stop();
      this.reproduciendoEfecto = false;
    }, this.DURACION);
  }
  efectoEmoji(){
    const particles = this.add.particles('emojis');
    this.EmitterEmoji = particles.createEmitter({
      frame: ['em_10','em_3','em_11','em_12','em_2','em_4','em_6','em_8','em_9','em_7','em_5','em_1'],
      x: this.scale.width/2,
      y: -100,
      rotate: { start: 0, end: 360, ease: 'Back.easeOut' },
      alpha: {min:75, max: 100},
      lifespan: 3000,
      angle: {min: 0, max: 360},
      speed: { min : 100, max: 200},
      scale: {start: 0.4, end: 0},
      gravityY: 200
    });
    particles.setDepth(10);
    setTimeout(() => {
      this.EmitterEmoji.stop();
      this.reproduciendoEfecto = false;
    }, this.DURACION);
  }
}