import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../inicio/services/global.service';
// import { NavigationEnd, NavigationStart } from '@angular/router';
import Phaser from 'phaser';
import { ApiService } from 'src/app/inicio/services/api.service';
// import { interval } from 'rxjs';
declare var $: any;

var ContenedorGeneralGlobal;
var PantallaPresentacionGlobal;
var Principal;
var GeneradorRespiracionCirculoGlobal;
var GeneradorRespiracionTrianguloGlobal;
var GeneradorRespiracionOchoGlobal;

@Component({
  selector: 'app-respirometro',
  templateUrl: './respirometro.component.html',
  styleUrls: ['./respirometro.component.css']
})
export class RespirometroComponent implements OnInit {

  phaserGame: any;
  config: Phaser.Types.Core.GameConfig;
  AudioMusica:any ;
  constructor(public global:GlobalService, public api:ApiService) {
    Principal = this;
    
    this.config = {
      type: Phaser.CANVAS,
      roundPixels: true,
      scale: {
        width: 1920,
        height: 900,
        parent: 'gameContainer',
        fullscreenTarget: 'gameContainer',
        mode: Phaser.Scale.FIT,
        autoCenter: Phaser.Scale.CENTER_BOTH,
      },
      dom: {
          createContainer: true
      },
      backgroundColor: '#fff8ed',
      scene: [PantallaPresentacion, ContenedorGeneral, GeneradorRespiracionCirculo, GeneradorRespiracionTriangulo, GeneradorRespiracionOcho]
    };
    this.iniciar();
   }
    enviar_atras(){
    window.parent.postMessage({ type: 'navigate', url: "RecursosApoyo", recurso: "Respiración Guiada"}, '*');
    }
   async iniciar(){
    setTimeout(()=>{
      $(".main-app").removeClass("hidden");
      $("#contenedor-principal").html(`<div id="gameContainer" style=" background: #5f0d50;"></div>`);
      this.phaserGame = new Phaser.Game(this.config);
    },1200)


    this.AudioMusica = new Audio("https://cdn-integritic.s3.amazonaws.com/aplicativos/paz.mp3")
    this.AudioMusica.volume = .8;
    this.AudioMusica.addEventListener('ended', function() {
      this.currentTime = 0;
      this.play();
  }, false);
    // this.load.audio('calma', 'https://cdn-integritic.s3.amazonaws.com/aplicativos/calma.mp3');
    // this.load.audio('paz', 'https://cdn-integritic.s3.amazonaws.com/aplicativos/paz.mp3');
    // this.load.audio('matinal', 'https://cdn-integritic.s3.amazonaws.com/aplicativos/matinal.mp3');


    await this.global.offLoading(2500);
    $("body").css("background-color", "#5f0d50");
    console.log("listo");
  }


  ngOnInit(): void {
    this.api.set_kpi_plataformas("Respirómetro","Ingresó al módulo");
  }

}


class PantallaPresentacion extends Phaser.Scene {

  constructor() {
    super({ key: 'PantallaPresentacion' });
    PantallaPresentacionGlobal = this;
  }
  preload() {

    for(let i=0; i <= 10; i++) this.load.image('e'+i, 'assets/images/aplicativos/ruidometro/estrellas_'+i+'.png');


    this.load.image('base', 'assets/images/aplicativos/respirometro/fondo.png');
    this.load.image('a_jugar', 'assets/images/aplicativos/respirometro/comenzar.png');

    let i  = 2;
    this.load.image('jiro_e', 'assets/images/aplicativos/ruidometro/jiro_'+i+'.png');
    this.load.image('fiu_e', 'assets/images/aplicativos/ruidometro/fiu_'+i+'.png');

    this.load.image('ayuda', 'assets/images/aplicativos/ruidometro/ayuda.png');
    this.load.image('logo', 'assets/images/aplicativos/respirometro/flor1.png');
    this.load.image('logos', 'assets/images/aplicativos/ruidometro/logo-blanco.png');
    this.load.audio('a_jugar_sonido','assets/recursos/click.mp3');
    this.load.image('_circulo_2', 'assets/images/aplicativos/respirometro/circulo3.png');
    this.load.image('_circulo_3', 'assets/images/aplicativos/respirometro/circulo2.png');
    


    this.load.atlas('flares', 'assets/assets/particles/flares.png', 'assets/assets/particles/flares.json');

  }
  resize (gameSize, baseSize, displaySize, resolution)
  {
    $("canvas").css({marginLeft:0,width:"100%",height:"auto", marginBottom: "-5px"});
  }
  PreCargaDeFuentes(){
    let array_fuentes = [
      "OpenDyslexic_3",
      "Integritic_lsch_1",
      "Integritic_lsch_2",
      "Integritic_lsch_3",
      "Escolar2",
      "Escolar1",
      "Mestra1",
      "Mestra2",
      "Mestra3",
      "Escolar_P",
      "Arial",
      "Verdana",
      "C_Arlon_Regular",
      "Jomhuria",

    ]
    for(let i = 0; i < array_fuentes.length; i++){
      this.add.text(-200, -200, "Carga de Fuente", { font: "35px "+array_fuentes[i]}).setColor("transparent");
    }
  }
  
  create(){

    this.sound.pauseOnBlur = false;

    this.PreCargaDeFuentes();
    this.scale.on('resize', this.resize, this);
    this.scale.on('orientationchange', (e: string) => {
      switch(e) {
          case 'portrait-primary':
              break;
          case 'landscape-primary':
              break;
          default:  
      }
      $("canvas").css({marginLeft:0,width:"100%",height:"auto", marginBottom: "-5px"});
    });

    

    this.scene.moveBelow(this, ContenedorGeneralGlobal);
    this.scene.moveBelow(this, GeneradorRespiracionCirculoGlobal);
    this.scene.moveBelow(this, GeneradorRespiracionTrianguloGlobal);
    this.scene.moveBelow(this, GeneradorRespiracionOchoGlobal);

    this.add.image(-2,0,"base").setOrigin(0).setScale(1).setAlpha(.7);


    this.add.image(this.scale.width/2, 125+30,"_circulo_3").setScale(.7);
    let img = this.add.image(this.scale.width/2, 125+30,"_circulo_2").setScale(.5);
    let logo = this.add.image(this.scale.width/2,130+30,"logo").setOrigin(0.5).setScale(.9).setAlpha(1);
    let tween1 = this.tweens.add({
      targets: img,
      angle: 360,
      ease: 'Sine.Power',
      duration: 5200,
      onRepeat: t=>{
        // ContenedorGeneralGlobal.cambiarJiro(0);
        // _circulo3_.setTexture("_circulo3_");
        // ContenedorGeneralGlobal.setTexto(texto);
        // this.sound.add("inhala").play();
        // respiro();
      },
      // yoyo: true,
      repeat: -1
    });
 
    // this.add.image(500,600,"fiu_e").setOrigin(0.5).setScale(.45).setAlpha(1);
    // this.add.image(1400,600,"jiro_e").setOrigin(0.5).setScale(.7).setAlpha(1);

    let inicio = this.add.sprite(this.scale.width/2,650,"a_jugar").setOrigin(0.5).setScale(1).setAlpha(1).setInteractive({ useHandCursor: true });
    
    let anterior = -1;

    this.add.text(0,330, "Respiración Guiada" , {
      font: "bold 85px Arial",
      align: "center",
      color: "white",
      shadow: {offsetX: 5,offsetY: 5,color: 'black',blur: 1,stroke: true,fill: true},
      // backgroundColor: "red",
      fixedWidth: this.scale.width
    }).setVisible(true);

    // this.add.text(0,300, "bla bla bla" , {
    //   font: "bold 30px Arial",
    //   align: "center",
    //   color: "white",
    //   shadow: {offsetX: 2.5,offsetY: 2.5,color: 'black',blur: 1,stroke: true,fill: true},
    //   // backgroundColor: "red",
    //   fixedWidth: this.scale.width
    // }).setVisible(true);


    let intLink =this.add.text(0,850, "Un producto con ❤ de integritic.cl" , {
      font: "20px Arial",
      align: "center",
      color: "white",
      shadow: {offsetX: 1.5,offsetY: 1.5,color: 'black',blur: 1,stroke: true,fill: true},
      // backgroundColor: "red",
      fixedWidth: this.scale.width
    }).setVisible(true).setInteractive({useHandCursor:true});

    intLink.on('pointerdown', function (pointer) {
      window.open("https://www.integritic.cl", '_blank');
    }, this);

    // ContenedorGeneralGlobal.micEncendido = false;

    let ayuda = this.add.sprite(this.scale.width/2,500,"ayuda").setOrigin(0.5).setScale(.5).setAlpha(1).setInteractive({ useHandCursor: true });

    inicio.on('pointerdown', function (pointer) {
      inicio.x = inicio.x - 5;
      inicio.setScale(.95);
      this.sound.add('a_jugar_sonido').play();
      setTimeout(()=>inicio.setScale(1), 100);
      setTimeout(()=>inicio.x = inicio.x + 5, 100);
      setTimeout(()=>this.scene.launch(ContenedorGeneralGlobal), 100);
      setTimeout(()=>this.scene.remove(PantallaPresentacionGlobal), 150);
    }, this);

    ayuda.on('pointerdown', function (pointer) {
      ayuda.x = ayuda.x - 5;
      ayuda.setScale(.48);
      setTimeout(()=>ayuda.setScale(.5), 100);
      setTimeout(()=>ayuda.x = ayuda.x + 5, 100);
      Principal.global.alerta.fire({
        width: "500",
        title: 'Instrucciones:',
        html: `<div style="font-size: 20px;">
        Esta aplicación le permitirá realizar respiraciones guiadas con sus estudiantes para calmar la ansiedad, el estrés y encontrar la anhelada paz y relajación para tener un excelente día.
        Puede elegir la opción que más le acomode y regular el tiempo para realizar la respiración.</div>`,
        text: ``
      });
    }, this);
  let logos = this.add.sprite(this.scale.width/2,780,"logos").setOrigin(0.5).setScale(.5).setAlpha(1).setInteractive({ useHandCursor: true });
  }
}
class ContenedorGeneral extends Phaser.Scene {

  constructor() {
    super({ key: 'ContenedorGeneral' });
    ContenedorGeneralGlobal = this;
    // PantallaInicialGlobal = this;
  }
  preload() {

    
    for(let i=0; i <= 5; i++) this.load.image('jiro'+i, 'assets/images/aplicativos/ruidometro/jiro_'+i+'.png');
    for(let i=0; i <= 5; i++) this.load.image('fiu'+i, 'assets/images/aplicativos/ruidometro/fiu_'+i+'.png');
    for(let i=0; i <= 6; i++) this.load.image('radar_'+i, 'assets/images/aplicativos/ruidometro/radar_'+i+'.png');

    this.load.image('estrella', 'assets/images/aplicativos/ruidometro/estrella.png');

    this.load.image('base', 'assets/images/aplicativos/ruidometro/fondo.png');
    this.load.image('base2', 'assets/images/aplicativos/respirometro/fondo2.jpg');
    this.load.image('base3', 'assets/images/aplicativos/respirometro/fondo3.jpg');


    this.load.audio('inhala', 'assets/images/aplicativos/respirometro/inhala.mp3');
    this.load.audio('reten', 'assets/images/aplicativos/respirometro/reten.mp3');
    this.load.audio('exhala', 'assets/images/aplicativos/respirometro/exhala.mp3');

    this.load.audio('3_2_1', 'assets/images/aplicativos/respirometro/3_2_1.mp3');
    this.load.audio('cuenta', 'assets/images/aplicativos/respirometro/cuenta.mp3');


    
    // this.load.audio('calma', 'https://cdn-integritic.s3.amazonaws.com/aplicativos/calma.mp3');
    // this.load.audio('paz', 'https://cdn-integritic.s3.amazonaws.com/aplicativos/paz.mp3');
    // this.load.audio('matinal', 'https://cdn-integritic.s3.amazonaws.com/aplicativos/matinal.mp3');


    
    
    
    /*+ **/
    this.load.image('_circulo_', 'assets/images/aplicativos/respirometro/circulo.png');
    this.load.image('_circulo2_', 'assets/images/aplicativos/respirometro/circulo2.png');
    this.load.image('_circulo3_', 'assets/images/aplicativos/respirometro/circulo3.png');

    this.load.image('_circulo4_', 'assets/images/aplicativos/respirometro/circulo4.png');
    this.load.image('_circulo5_', 'assets/images/aplicativos/respirometro/circulo5.png');

    this.load.image('c1', 'assets/images/aplicativos/respirometro/c1.png');
    this.load.image('c2', 'assets/images/aplicativos/respirometro/c2.png');
    this.load.image('c3', 'assets/images/aplicativos/respirometro/c3.png');

    this.load.image('velocidad', 'assets/images/aplicativos/respirometro/velocidad.png');
    this.load.image('mic', 'assets/images/aplicativos/ruidometro/mic.png');
    this.load.image('alerta', 'assets/images/aplicativos/ruidometro/alerta.png');

    this.load.image('mic_icon', 'assets/images/aplicativos/ruidometro/mic_icon.png');
    this.load.image('mic_off', 'assets/images/aplicativos/ruidometro/mic_off.png');

    this.load.image('circulo', 'assets/images/aplicativos/ruidometro/circulo.png');

    this.load.image('check', 'assets/images/aplicativos/respirometro/voz.png');

    this.load.image('jiro', 'assets/images/aplicativos/ruidometro/jiro.png');
    this.load.image('fiu', 'assets/images/aplicativos/ruidometro/fiu.png');


    this.load.image('play', 'assets/images/aplicativos/respirometro/play.png');
    this.load.image('pausa', 'assets/images/aplicativos/respirometro/pausa.png');
    this.load.image('reiniciar', 'assets/images/aplicativos/respirometro/reiniciar.png');

    this.load.image('speaker', 'assets/images/aplicativos/respirometro/speaker.png');


    this.load.image('reinicio', 'assets/images/aplicativos/ruidometro/reinicio.png');

    this.load.image('menu_abierto', 'assets/images/aplicativos/respirometro/menu_abierto.png');
    this.load.image('menu_cerrado', 'assets/images/aplicativos/respirometro/menu_cerrado.png');


  }
  
  
  flecha:any;
  porcentaje:any;
  sensibilidad:any = 14000; // de .1 hasta 2
  micEncendido:any = false;

  skinSeleccionada:any = "fiu";
  formaSeleccionada:any = "Triangulo";
  escenaActual:any;
  async create(){

    this.sound.pauseOnBlur = false;
    let fondo = this.add.sprite(-2,-0,"base3").setOrigin(0).setScale(1.2).setAlpha(1);

    this.add.image(150,150,"logo").setOrigin(0.5).setScale(.8).setAlpha(1);
    let contenedor_total = this.add.container();

    let jiro2 = this.add.sprite(0,0,this.skinSeleccionada+"0").setOrigin(0).setScale(.5).setDepth(6).setVisible(false);
    contenedor_total.add(jiro2);
    

    jiro2.x = 200;
    jiro2.y = 230;

    let min = 1630;
    let max = this.scale.width - 30;

    let posC1 = 120;

    /** marco */
    let tamanioMarco = 830;
    var marcoFondo = this.add.graphics();
    contenedor_total.add(marcoFondo);
    marcoFondo.fillStyle(0xFF1B88, .3);
    marcoFondo.fillRoundedRect(min-25, 45, 310, tamanioMarco+10, 20);

    var fondoMarco = this.add.graphics();
    contenedor_total.add(fondoMarco);
    fondoMarco.fillStyle(0x1f002c, .5);
    fondoMarco.fillRoundedRect(min-20, 50, 300, tamanioMarco, 15);

    let diff = -330 ;
   

    let linea = this.add.graphics();linea.fillStyle(0xffffff);linea.fillRect(min, posC1-2,  max-min, 5);
    let linea6 = this.add.graphics();linea6.fillStyle(0xffffff);linea6.fillRect(min-19, 170,  299, 1);
    let linea2 = this.add.graphics();linea2.fillStyle(0xffffff);linea2.fillRect(min-19, 330,  299, 1);
    let linea3 = this.add.graphics();linea3.fillStyle(0xffffff);linea3.fillRect(min-19, 470,  299, 1);
    let linea5 = this.add.graphics();linea5.fillStyle(0xffffff);linea5.fillRect(min-19, 570,  299, 1);
    let linea7 = this.add.graphics();linea7.fillStyle(0xffffff);linea7.fillRect(min-19, 690,  299, 1);

    contenedor_total.add(linea);
    contenedor_total.add(linea6);
    contenedor_total.add(linea2);
    contenedor_total.add(linea3);
    contenedor_total.add(linea5);
    contenedor_total.add(linea7);

    const circulo = this.add.image(min + (max - min) / 2, posC1, 'circulo');
    contenedor_total.add(circulo);
    circulo.setInteractive({ draggable: true, useHandCursor: true });

    let pos_mic = 55;
    
    // this.add.image(min+40, posC1-65,"velocidad").setOrigin(0).setScale(.8).setAlpha(1);

    let c1 = this.add.image(min+20, posC1+75,"c1").setOrigin(0).setScale(1).setAlpha(.5).setInteractive({ useHandCursor: true });;
    let c2 = this.add.image(min+100, posC1+75,"c2").setOrigin(0).setScale(1).setAlpha(1).setInteractive({ useHandCursor: true });;
    let c3 = this.add.image(min+180, posC1+75,"c3").setOrigin(0).setScale(1).setAlpha(.5).setInteractive({ useHandCursor: true });;

    contenedor_total.add(c1);
    contenedor_total.add(c2);
    contenedor_total.add(c3);

    contenedor_total.add(this.add.text(this.scale.width-160,posC1-39, "Velocidad" , {
      font: "25px C_Arlon_Regular",
      align: "center",
      color: "#FAF4E4",
      // shadow: {offsetX: 5,offsetY: 5,color: 'black',blur: 1,stroke: true,fill: true},
      // backgroundColor: "red",
      fixedWidth: 400
    }).setVisible(true).setOrigin(.5).setDepth(4));

    
    contenedor_total.add(this.add.text(this.scale.width-160,290, "Seleccione Tipo\nde Respiración" , {
      font: "25px C_Arlon_Regular",
      align: "center",
      color: "#FAF4E4",
      // shadow: {offsetX: 5,offsetY: 5,color: 'black',blur: 1,stroke: true,fill: true},
      // backgroundColor: "red",
      fixedWidth: 400
    }).setVisible(true).setOrigin(.5).setDepth(4));

    contenedor_total.add(this.add.text(this.scale.width-160,440, "Seleccione Fondo" , {
      font: "25px C_Arlon_Regular",
      align: "center",
      color: "#FAF4E4",
      // shadow: {offsetX: 5,offsetY: 5,color: 'black',blur: 1,stroke: true,fill: true},
      // backgroundColor: "red",
      fixedWidth: 400
    }).setVisible(true).setOrigin(.5).setDepth(4));

    // this.add.text(this.scale.width-160, 720, "Instrucciones Audibles" , {
      
    //   font: "25px C_Arlon_Regular",
    //   align: "center",
    //   color: "#FAF4E4",
    //   // shadow: {offsetX: 5,offsetY: 5,color: 'black',blur: 1,stroke: true,fill: true},
    //   // backgroundColor: "red",
    //   fixedWidth: 400
    // }).setVisible(true).setOrigin(.5).setDepth(4);


    contenedor_total.add(this.add.text(this.scale.width-160, 590, "Música de Fondo" , {
      font: "22px C_Arlon_Regular",
      align: "center",
      color: "#FAF4E4",
      // shadow: {offsetX: 5,offsetY: 5,color: 'black',blur: 1,stroke: true,fill: true},
      // backgroundColor: "red",
      fixedWidth: 400
    }).setVisible(true).setOrigin(.5).setDepth(4));


    var musica = document.createElement('select');
    musica.id = "text-cant2";
    musica.style.border = "none";
    musica.style.borderRadius = "10px";
    musica.style.height = '60px';
    musica.style.width = '250px';
    musica.style.fontSize = '21px';
    musica.style.textAlign = 'center';
    musica.style.backgroundColor = 'white';
    musica.style.border = 'solid';
    musica.style.borderColor = 'white';
    musica.style.color = 'black';

    let option = document.createElement('option');
    option.value = "ninguna";
    option.text = "Ninguna";
    // option.selected = true;
    musica.add(option);


    let option2 = document.createElement('option');
    option2.value = "calma";
    option2.text = "Universo en Calma";
    musica.add(option2);

    let option3 = document.createElement('option');
    option3.value = "matinal";
    option3.text = "Relajación Matinal";
    
    musica.add(option3);

    let option4 = document.createElement('option');
    option4.value = "paz";
    option4.text = "Paz Mental";
    option4.selected = true;
    
    musica.add(option4);
    
    let sonido;
    // sonido = ContenedorGeneralGlobal.sound.add($(musica).val());
    // }
    // catch(){
    // }
    
    musica.addEventListener("change", function() {

      
      // this.load.audio('calma', 'https://cdn-integritic.s3.amazonaws.com/aplicativos/calma.mp3');
      // this.load.audio('paz', 'https://cdn-integritic.s3.amazonaws.com/aplicativos/paz.mp3');
      // this.load.audio('matinal', 'https://cdn-integritic.s3.amazonaws.com/aplicativos/matinal.mp3');
  
      if($(musica).val()==="false") return;
      

      if(Principal.AudioMusica) Principal.AudioMusica.pause();
      try{
        Principal.AudioMusica.src = "https://cdn-integritic.s3.amazonaws.com/aplicativos/"+$(musica).val()+".mp3"
        Principal.AudioMusica.play();
        // this.AudioMusica.volume = .8;
        // sonido = ContenedorGeneralGlobal.sound.add($(musica).val());
        // sonido.play();
        // sonido.loop = true;
      }
      catch(ex){}

      

      // cuenta
      // paz
      // matinal

      // musica.innerHTML = '';
      
      // let option1 = document.createElement('option');
      // option1.value = "false";
      // option1.text = "Seleccione...";
      // option1.selected = true;
      // option1.disabled = true;
      // letra.add(option1);

      // if($(curso).val()==="false") return;
      // let seleccionado = Principal.cursos.filter(a=>a._id ===  $(curso).val() )[0];
      // seleccionado.DETALLE.map(a=>{
      //   let option1 = document.createElement('option');
      //   option1.value = a;
      //   option1.text = a;
      //   option1.selected = false;
      //   letra.add(option1);
      // })
    });
    document.body.appendChild(musica);
    var musicaEl:any = this.add.dom(this.scale.width-280, 610, musica).setOrigin(0);
    contenedor_total.add(musicaEl);
    
    Principal.AudioMusica.src = "https://cdn-integritic.s3.amazonaws.com/aplicativos/paz.mp3"
    Principal.AudioMusica.play();
    
    
    Principal.AudioMusica.volume = .8
    this.sound.volume = .8;
    
    let self___ = this;

        // Variables para la posición de los elementos
        let x_sonidos = this.scale.width - 240;
        let y_sonidos = 670;

        function createRoundedRectangle(x, y, width, height, radius, color, visible = true) {
            let graphics = self___.add.graphics({ fillStyle: { color: color } });
            graphics.fillRoundedRect(x - width / 2, y - height / 2, width, height, radius);
            if(!visible){
              graphics.setVisible(false);
            }
            return graphics;
        }

        function createVolumeButtons(x, y, volumeKey) {
            // Create - button
            let minusButton = self___.add.text(x - 80, y - 10, '-', { fontSize: '20px', color: '#ffffff' }).setInteractive({ useHandCursor: true });
            contenedor_total.add(minusButton);
            minusButton.on('pointerdown', () => {
                adjustVolume(volumeKey, -0.1);
            });

            // Create + button
            let plusButton = self___.add.text(x + 130, y - 10, '+', { fontSize: '20px', color: '#ffffff' }).setInteractive({ useHandCursor: true });
            contenedor_total.add(plusButton);
            plusButton.on('pointerdown', () => {
                adjustVolume(volumeKey, 0.1);
            });
        }

        let volume1 = 0.8;
        let volume2 = 0.8;

        function adjustVolume(volumeKey, amount) {

          if (volumeKey === 'volume1') {
            console.log(Principal.AudioMusica.volume)
            try{
              Principal.AudioMusica.muted = false;
              speaker.setAlpha(1);
              if(amount<0){
                if(Principal.AudioMusica.volume < 0.2){
                  volumeBar1Fill.clear();
                  volumeBar1Fill = createRoundedRectangle(x_sonidos + 10 + (volume1 * 180) / 2, y_sonidos + 60, volume1 * 180, 20, 10, 0x2ec62e, false);
                  contenedor_total.add(volumeBar1Fill);
                  Principal.AudioMusica.volume = 0;
                  volume1 = 0;
                  return;
                }
              }else{
                if(Principal.AudioMusica.volume >= 1) return;
              }
              Principal.AudioMusica.volume += amount;
            }
            catch(ex){}
            
              volume1 = Phaser.Math.Clamp(volume1 + amount, 0, 1);
              volumeBar1Fill.clear();
              volumeBar1Fill = createRoundedRectangle(x_sonidos + 10 + (volume1 * 180) / 2, y_sonidos + 60, volume1 * 180, 20, 10, 0x2ec62e);
              contenedor_total.add(volumeBar1Fill);
          } else if (volumeKey === 'volume2') {
              instrucciones_audibles.setAlpha(1);
              self___.sound.mute = false; 
              if(amount<0){
                if(self___.sound.volume < 0.2){
                  volumeBar2Fill.clear();
                  volumeBar2Fill = createRoundedRectangle(x_sonidos + 10 + (volume2 * 180) / 2, y_sonidos + 120, volume2 * 180, 20, 10, 0x2ec62e, false);
                  contenedor_total.add(volumeBar2Fill);
                  self___.sound.volume = 0;
                  volume2 = 0;
                  return;
                }
              }else{
                if(self___.sound.volume >= 1) return;
              }
              self___.sound.volume += amount;
              // self___.sound.volume
              volume2 = Phaser.Math.Clamp(volume2 + amount, 0, 1);
              volumeBar2Fill.clear();
              volumeBar2Fill = createRoundedRectangle(x_sonidos + 10 + (volume2 * 180) / 2, y_sonidos + 120, volume2 * 180, 20, 10, 0x2ec62e);
              contenedor_total.add(volumeBar2Fill);
          }
      }

      // Add speaker icon
      let speaker  = self___.add.image(x_sonidos - 30, y_sonidos + 60, 'speaker').setDisplaySize(30, 30).setInteractive({ useHandCursor: true });;
      contenedor_total.add(speaker);

      // Add volume bar 1
      let volumeBar1Bg = createRoundedRectangle(x_sonidos + 100, y_sonidos + 60, 180, 20, 10, 0x000000);
      let volumeBar1Fill = createRoundedRectangle(x_sonidos + 10 + (volume1 * 180) / 2, y_sonidos + 60, volume1 * 180, 20, 10, 0x2ec62e);
      contenedor_total.add(volumeBar1Bg);
      contenedor_total.add(volumeBar1Fill);

      // Add + and - buttons for volume control 1
      createVolumeButtons(x_sonidos + 70, y_sonidos + 60, 'volume1');

      // Add speaker icon for the second bar
      let instrucciones_audibles = self___.add.image(x_sonidos - 30, y_sonidos + 120, 'check').setDisplaySize(30, 30).setInteractive({ useHandCursor: true });
      contenedor_total.add(instrucciones_audibles);

      // Add volume bar 2
      let volumeBar2Bg = createRoundedRectangle(x_sonidos + 100, y_sonidos + 120, 180, 20, 10, 0x000000);
      let volumeBar2Fill = createRoundedRectangle(x_sonidos + 10 + (volume2 * 180) / 2, y_sonidos + 120, volume2 * 180, 20, 10, 0x2ec62e);
      contenedor_total.add(volumeBar2Bg);
      contenedor_total.add(volumeBar2Fill);

      // Add + and - buttons for volume control 2
      createVolumeButtons(x_sonidos + 70, y_sonidos + 120, 'volume2');



          // Add question mark icon
          // self___.add.circle(x_sonidos, y_sonidos + 180, 15, 0xffffff);
          // self___.add.text(x_sonidos - 5, y_sonidos + 170, '?', { fontSize: '20px', color: '#000000' });

          

    // let instrucciones_audibles = this.add.sprite(this.scale.width-180,740,"check").setOrigin(0).setScale(.6).setDepth(6).setVisible(true).setInteractive({ useHandCursor: true });

    instrucciones_audibles.on('pointerdown', function (pointer) {
      
      if(this.sound.mute){
        this.sound.mute = false;
        instrucciones_audibles.setAlpha(1);
        volumeBar2Fill.clear();
        volumeBar2Fill = createRoundedRectangle(x_sonidos + 10 + (volume2 * 180) / 2, y_sonidos + 120, volume2 * 180, 20, 10, 0x2ec62e, true);

        contenedor_total.add(volumeBar2Fill);
      }else{
        instrucciones_audibles.setAlpha(.5);
        this.sound.mute = true;
        volumeBar2Fill.clear();
        volumeBar2Fill = createRoundedRectangle(x_sonidos + 10 + (volume2 * 180) / 2, y_sonidos + 120, volume2 * 180, 20, 10, 0x2ec62e, false);

        contenedor_total.add(volumeBar2Fill);
      }
      
      
    },this);

    speaker.on('pointerdown', function (pointer) {
      
      console.log(Principal.AudioMusica)

      if(Principal.AudioMusica.muted){
        Principal.AudioMusica.muted = false;
        speaker.setAlpha(1);
        volumeBar1Fill.clear();
        volumeBar1Fill = createRoundedRectangle(x_sonidos + 10 + (volume1 * 180) / 2, y_sonidos + 60, volume1 * 180, 20, 10, 0x2ec62e, true);
        contenedor_total.add(volumeBar1Fill);
      }else{
        speaker.setAlpha(.5);
        Principal.AudioMusica.muted = true;
        volumeBar1Fill.clear();
        volumeBar1Fill = createRoundedRectangle(x_sonidos + 10 + (volume1 * 180) / 2, y_sonidos + 60, volume1 * 180, 20, 10, 0x2ec62e, false);
        contenedor_total.add(volumeBar1Fill);
      }
      
      
    },this);

    


    let logo1 = this.add.image(this.scale.width-220, 380,"base").setOrigin(0.5).setScale(.05).setAlpha(.3).setInteractive({ useHandCursor: true });
    let logo2 = this.add.image(this.scale.width-160, 380,"base2").setOrigin(0.5).setScale(.038).setAlpha(.3).setInteractive({ useHandCursor: true });
    let logo3 = this.add.image(this.scale.width-80, 380,"base3").setOrigin(0.5).setScale(.042).setAlpha(1).setInteractive({ useHandCursor: true });

    contenedor_total.add(logo1);
    contenedor_total.add(logo2);
    contenedor_total.add(logo3);

    logo1.setCrop(0, 0, logo1.width-700, logo1.height);
    logo2.setCrop(0, 0, logo2.width-100, logo2.height);
    // fondo



    // logo1.setAlpha(.5)
    // logo2.setAlpha(.5)
    // logo3.setAlpha(.5)

    // logo1.setAlpha(1)

    logo1.on('pointerdown', function (pointer) {

      logo1.setAlpha(.3)
      logo2.setAlpha(.3)
      logo3.setAlpha(.3)

      logo1.setAlpha(1)
      
      $("body").css("background-color", "#bb2b45");
      fondo.y = -200;
      fondo.setScale(1.1);
      fondo.setTexture("base");
    },this);

    logo2.on('pointerdown', function (pointer) {

      logo1.setAlpha(.3)
      logo2.setAlpha(.3)
      logo3.setAlpha(.3)

      logo2.setAlpha(1)
      
      $("body").css("background-color", "#041e3f");
      fondo.y = -300;
      fondo.setScale(1.1);
      fondo.setTexture("base2");
    },this);

    logo3.on('pointerdown', function (pointer) {

      logo1.setAlpha(.3)
      logo2.setAlpha(.3)
      logo3.setAlpha(.3)

      logo3.setAlpha(1)
      
      $("body").css("background-color", "#030508");
      fondo.y = -0;
      fondo.setScale(1.2);
      fondo.setTexture("base3");
    },this);


    // $("body").css("background-color", "#5f0d50");
    // $("body").css("background-color", "#041e3f");
    $("body").css("background-color", "#030508");

    fondo.x = -100;
    fondo.setScale(1.2);

    let controlesBloqueados = false;




    let pausa = this.add.sprite(this.scale.width-200, 520,"pausa").setOrigin(0.5).setScale(.3).setAlpha(1).setInteractive({ useHandCursor: true });
    let reiniciar = this.add.sprite(this.scale.width-120, 520,"reiniciar").setOrigin(0.5).setScale(.3).setAlpha(1).setInteractive({ useHandCursor: true });
    // let play = this.add.sprite(this.scale.width-220, 520,"play").setOrigin(0.5).setScale(.3).setAlpha(1).setInteractive({ useHandCursor: true });

    contenedor_total.add(pausa);
    contenedor_total.add(reiniciar);



    pausa.on('pointerdown', function (pointer){

      if(controlesBloqueados) return;

      if(this.escenaActual.scene.isPaused()){

        Principal.AudioMusica.play();
        this.escenaActual.scene.resume();
        pausa.setTexture("pausa");
      }
      else{
        Principal.AudioMusica.pause();
        this.escenaActual.scene.pause();
        pausa.setTexture("play");
      }
      


      pausa.x = pausa.x + 1.5;
      pausa.setScale(.32);
      this.puntosTotales = 4;
      setTimeout(()=>pausa.setScale(.3), 100);
      setTimeout(()=>pausa.x = pausa.x - 1.5, 100);
      

    }, this);

    reiniciar.on('pointerdown', function (pointer) {

      if(controlesBloqueados) return;
      // this.escenaActual.scene.restart();
      pausa.setTexture("pausa");
      Principal.AudioMusica.pause();
      Principal.AudioMusica.currentTime = 0
      Principal.AudioMusica.play();
      reiniciar.x = reiniciar.x + 1.5;
      reiniciar.setScale(.32);
      this.puntosTotales = 4;
      setTimeout(()=>reiniciar.setScale(.3), 100);
      setTimeout(()=>reiniciar.x = reiniciar.x - 1.5, 100);

      ReiniciarGrafico();

    }, this);


    // play
    // pausa
    // reiniciar


    c1.on('pointerdown', function (pointer) {

      if(controlesBloqueados) return;

      c1.setAlpha(.5);
      c2.setAlpha(.5);
      c3.setAlpha(.5);

      c1.setAlpha(1);

      c1.x = c1.x - 2.5;
      c1.setScale(.98);
      this.puntosTotales = 4;
      setTimeout(()=>c1.setScale(1), 100);
      setTimeout(()=>c1.x = c1.x + 2.5, 100);
      ReiniciarGrafico(GeneradorRespiracionCirculoGlobal)
    }, this);
    c2.on('pointerdown', function (pointer) {

      if(controlesBloqueados) return;

      c1.setAlpha(.5);
      c2.setAlpha(.5);
      c3.setAlpha(.5);

      c2.setAlpha(1);


      c2.x = c2.x - 2.5;
      c2.setScale(.98);
      this.puntosTotales = 3;
      setTimeout(()=>c2.setScale(1), 100);
      setTimeout(()=>c2.x = c2.x + 2.5, 100);
      ReiniciarGrafico(GeneradorRespiracionTrianguloGlobal)
    }, this);
    c3.on('pointerdown', function (pointer) {

      if(controlesBloqueados) return;

      c1.setAlpha(.5);
      c2.setAlpha(.5);
      c3.setAlpha(.5);

      c3.setAlpha(1);

      c3.x = c3.x - 2.5;
      c3.setScale(.98);
      this.puntosTotales = 4;
      setTimeout(()=>c3.setScale(1), 100);
      setTimeout(()=>c3.x = c3.x + 2.5, 100);
      ReiniciarGrafico(GeneradorRespiracionOchoGlobal)
    }, this);

    let ReiniciarGrafico = async (nuevaEscena = this.escenaActual)=>{
      this.cambiarJiro(0);

      
      
      // this.escenaActual.scene.setVisible(false);
      // this.escenaActual.scene.setVisible(false);
      
      console.log("bloqueado "+controlesBloqueados)
      if(controlesBloqueados) return;

      pausa.setAlpha(.5);
      reiniciar.setAlpha(.5);

      controlesBloqueados = true;

      GeneradorRespiracionCirculoGlobal.scene.setVisible(false);
      GeneradorRespiracionTrianguloGlobal.scene.setVisible(false);
      GeneradorRespiracionOchoGlobal.scene.setVisible(false);

      GeneradorRespiracionCirculoGlobal.scene.pause();
      GeneradorRespiracionTrianguloGlobal.scene.pause();
      GeneradorRespiracionOchoGlobal.scene.pause();
        
        this.escenaActual = nuevaEscena;
        
        

        await cuentaAtras();
        // setTimeout(()=>{

          this.scene.launch(nuevaEscena)
          this.escenaActual.scene.setVisible(false);

          console.log("reinicia")
          this.escenaActual.scene.restart();
          this.escenaActual.scene.setVisible(true);  
          controlesBloqueados = false;
          pausa.setAlpha(1);
          reiniciar.setAlpha(1);
        // },3800);

    }

    let cuentaAtras = async()=>{
      console.log("CUENTA ATRAS")
      // const circle = this.add.circle(this.scale.width/2, 400, 300, 0x2cbc54).setAlpha(0).setOrigin(.5); // Fondo verde

      
      this.sound.add("cuenta").play();
      // await new Promise(r=>setTimeout(()=>r(true),3500));
      
      // await new Promise(r=>setTimeout(()=>r(true),3000));


      const texto1 = this.add.text(this.scale.width/2, 400, 'A la cuenta de 3,\ndeberás inspirar hondamente...', {
        font: "bold 50px C_Arlon_Regular",
        shadow: {offsetX: 5,offsetY: 5,color: 'black',blur: 1,stroke: true,fill: true},
        color: '#FFFFFF',
        align: 'center'
      }).setOrigin(0.5).setAlpha(1);

      await new Promise(r=>setTimeout(()=>r(true),3200));
      texto1.setAlpha(0);
      // return;
      const circle = this.add.graphics();
      

      // Agregar sombra al círculo
      circle.fillStyle(0x2cbc54, 1); // Restaurar el color antes de agregar la sombra
      circle.fillStyle(0x000000, 0.5); // Color y opacidad de la sombra
      circle.fillCircle(this.scale.width/2 + 5, 400 + 5, 300); // Ajustar la posición para simular la sombra

      circle.fillStyle(0x2cbc54);
      circle.fillCircle(this.scale.width/2, 400, 300);

      circle.setAlpha(0);


        const textoo = this.add.text(this.scale.width/2, 400, '', {
            font: "bold 300px Arial",
            color: '#FFFFFF',
            align: 'center'
        }).setOrigin(0.5).setAlpha(0);

        await new Promise(r=>setTimeout(()=>r(true),500));
        this.sound.add("3_2_1").play();
        this.tweens.add({
          targets: circle,
          alpha: 1,  // Establecer el nivel de opacidad deseado (1 es completamente visible)
          duration: 200,  // Duración del tween en milisegundos
          ease: 'Linear',
          yoyo: false,  // Si deseas que se repita en reversa
          repeat: 0  // Número de repeticiones (0 para ninguna, ya que solo queremos fadeIn)
        });

        for(let i=3; i > 0; i--){
          console.log(i)
          textoo.setText(i.toString());
          this.tweens.add({
            targets: textoo,
            alpha: 1,  // Establecer el nivel de opacidad deseado (1 es completamente visible)
            duration: 100,  // Duración del tween en milisegundos
            ease: 'Linear',
            yoyo: false,  // Si deseas que se repita en reversa
            repeat: 0  // Número de repeticiones (0 para ninguna, ya que solo queremos fadeIn)
          });
          this.tweens.add({
            targets: textoo,
            alpha: 0,  // Establecer el nivel de opacidad deseado (0 es completamente transparente)
            duration: 100,  // Duración del tween en milisegundos
            ease: 'Linear',
            delay: 800,
            yoyo: false,  // Si deseas que se repita en reversa
            repeat: 0  // Número de repeticiones (0 para ninguna, ya que solo queremos fadeOut)
          });
          await new Promise(r=>setTimeout(()=>r(true),1000));
        }
        
        // await new Promise(r=>setTimeout(()=>r(true),150));
        this.tweens.add({
          targets: circle,
          alpha: 0,  // Establecer el nivel de opacidad deseado (1 es completamente visible)
          duration: 250,  // Duración del tween en milisegundos
          ease: 'Linear',
          yoyo: false,  // Si deseas que se repita en reversa
          repeat: 0  // Número de repeticiones (0 para ninguna, ya que solo queremos fadeIn)
        });

        await new Promise(r=>setTimeout(()=>r(true),500));
        return;
      //   // Iniciar la línea de tiempo
    }

    let ayuda = this.add.sprite(this.scale.width-45,(marcoFondo.y+tamanioMarco)+15,"ayuda").setOrigin(0.5).setScale(.3).setAlpha(1).setInteractive({ useHandCursor: true });
    ayuda.on('pointerdown', function (pointer) {
         ayuda.x = ayuda.x - 5;
         ayuda.setScale(.38);
         setTimeout(()=>ayuda.setScale(.3), 100);
         setTimeout(()=>ayuda.x = ayuda.x + 5, 100);
         Principal.global.alerta.fire({
           // icon: 'warning',
           width: "500",
           title: 'Instrucciones:',
           html: `<div style="font-size: 20px;">
           Esta aplicación le permitirá realizar respiraciones guiadas con sus estudiantes para calmar la ansiedad, el estrés y encontrar la anhelada paz y relajación para tener un excelente día.<br>
           Puede elegir la opción que más le acomode y regular el tiempo para realizar la respiración.</div>`,
           text: ``
         });
       }, this);
    
      //  this.scene.launch(GeneradorRespiracionCirculoGlobal);
       this.escenaActual = GeneradorRespiracionTrianguloGlobal;

      contenedor_total.add(ayuda);

    ReiniciarGrafico();

    this.input.on('dragend', (pointer, gameObject, dragX) => {
      ReiniciarGrafico();
      console.log(this.sensibilidad / 1000)
    },this);

    this.input.on('dragstart', (pointer, gameObject, dragX) => {
      
    },this);

    this.input.on('drag', (pointer, gameObject, dragX) => {
      if (dragX < min) return;
      if (dragX > max) return;

      let minOriginal = min;
      let maxOriginal = max;
      let valorOriginal = dragX;
      let rangoOriginal = maxOriginal - minOriginal;
      let valorMinimo = 8000;
      let valorMaximo = 20000;
      let valorEscalado = valorMaximo - ((valorOriginal - minOriginal) / rangoOriginal) * (valorMaximo - valorMinimo);
      dragX = Phaser.Math.Clamp(dragX, min, max);
      gameObject.x = dragX;
      this.sensibilidad = valorEscalado;
    }, this);

    this.intervaloDePuntos = 10;
    this.contadorEstrellas = 0;
  
    this.jiro = jiro2;

    this.validaPersonaje();

    contenedor_total.x  = 0;
    let menu = this.add.image(this.scale.width-340, 450, "menu_abierto").setInteractive({ useHandCursor: true }).setScale(.5)
    let retractado = false;
    contenedor_total.add(menu);
    menu.on('pointerdown', function (pointer) {
      if(retractado){
        retractado = false;
        menu.setTexture("menu_abierto")
      }else{
        retractado = true;
        menu.setTexture("menu_cerrado")
      }


      this.tweens.add({
        targets: contenedor_total,
        x: retractado ? 315 : 0,
        duration: 1000,  // Duración del tween en milisegundos
        ease: 'Bounce',
        yoyo: false,  // Si deseas que se repita en reversa
        repeat: 0  // Número de repeticiones (0 para ninguna, ya que solo queremos fadeIn)
      });

    },this);
  }
  setTexto(text){
      let delay = (this.sensibilidad / this.puntosTotales) - 1050;

      this.tweens.add({
        targets: text,
        alpha: 1,  // Establecer el nivel de opacidad deseado (1 es completamente visible)
        duration: 500,  // Duración del tween en milisegundos
        ease: 'Linear',
        yoyo: false,  // Si deseas que se repita en reversa
        repeat: 0  // Número de repeticiones (0 para ninguna, ya que solo queremos fadeIn)
      });
      this.tweens.add({
        targets: text,
        alpha: 0,  // Establecer el nivel de opacidad deseado (0 es completamente transparente)
        duration: 500,  // Duración del tween en milisegundos
        ease: 'Linear',
        delay: delay,
        yoyo: false,  // Si deseas que se repita en reversa
        repeat: 0  // Número de repeticiones (0 para ninguna, ya que solo queremos fadeOut)
      });
   
  }
  puntosTotales = 4;
  validaPersonaje(){
    if(this.skinSeleccionada==="fiu"){

      this.jiro.setScale(.5);
      this.jiro.y = 450;
      this.jiro.x = 100;
      
    }
    else{
      this.jiro.setScale(.8);
      this.jiro.y = 450;
      this.jiro.x =100;
    }
  }

  m1:any = null;
  m2:any = null;
  m3:any = null;

  t1:any = null;
  t2:any = null;
  t3:any = null;


  graphics;
  follower;
  path;


  Tiempo:any = null;
  Radar:any = null;
  segundosTotales:any = 0;
  estadoMicAnterior:any = false;
  intervaloMic:any = null;
  apagarMic:any = null;
  prenderMic:any = null;
  jiro:any = null;
  jiroDurmiendo:any = false;
  cantEstrellas:any = null;
  contadorEstrellas:any = 0;
  contadorEstrellasAnt:any = -1;
  intervaloDePuntos:any = 0;
  contEstrellas:any = null;
  segundosAnteriores:any = -1;
  checkSinSonido:any = true;
  sonidoActivo:any = 2;
  parpadeoText:any = false;
  numeroAnterior:any = -1;
  cambiarJiro(numero){
    return;
    console.error("meneando")
    this.validaPersonaje();
    let nueva = this.skinSeleccionada+numero;
    if(this.jiro.texture.key != nueva){
      this.jiro.setTexture(nueva);
    }
  }
  minAnterior:any = -1;
  segAnterior:any = -1;
  formato_tiempo(totalSeconds, isZero) {
    var minutes = Math.floor(totalSeconds / 60);
    var seconds = Math.floor(totalSeconds % 60);
    var milliseconds = Math.floor((totalSeconds % 1) * 1000);


    this.tweens.addCounter({
      from: this.minAnterior,
      to: minutes,
      duration: 1000,
      ease: 'linear',
      onUpdate: tween =>
      {
          const value = Math.round(tween.getValue());
          this.Tiempo[1].setText(value<10?("0"+value):value);
      },
      onComplete: t=>{
        // this.Tiempo[3].setText("000");
        this.minAnterior = minutes;
      }
    });

    this.tweens.addCounter({
      from: this.segAnterior,
      to: seconds,
      duration: 1000,
      ease: 'linear',
      onUpdate: tween =>
      {
          const value = Math.round(tween.getValue());
          this.Tiempo[2].setText(value<10?("0"+value):value);
          this.segAnterior = seconds;
      },
      onComplete: t=>{
        // this.Tiempo[3].setText("000");
      }
    });


    // return minutes;
  }
  corriendoDescuento:any = false;
  update(){
    
  }
}

class GeneradorRespiracionCirculo extends Phaser.Scene {

  constructor() {
    super({ key: 'GeneradorRespiracionCirculo' });
    GeneradorRespiracionCirculoGlobal = this;
  }
  create() {
    this.sound.pauseOnBlur = false;
    console.error("Creando GeneradorRespiracionCirculo");

      this.add.sprite(this.scale.width/2,450, "_circulo_").setOrigin(.5).setAlpha(.6).setScale(1.2)
      let _circulo2_ = this.add.sprite(this.scale.width/2,450, "_circulo2_").setOrigin(.5).setAlpha(.6).setScale(1)
      let _circulo3_ = this.add.sprite(this.scale.width/2,450, "_circulo3_").setOrigin(.5).setAlpha(1).setScale(1.2)

      let confi_actual = 0;
      let texto = this.add.text(this.scale.width/2,450, "", {
        font: "bold 64px Arial",
        align: "center",
        color: "#ffffff",
        // backgroundColor: "red",
        fixedWidth: this.scale.width
      }).setOrigin(.5).setAlpha(0);
      ContenedorGeneralGlobal.setTexto(texto);

      _circulo3_.setAngle(0);

      this.tweens.add({
        targets: _circulo2_,
        scale: 1.8,
        ease: 'Bounce.EaseIn',
        duration: 2000,
        yoyo : true,
        repeat: 2,
        onComplete: i=>{
        }
      });


      // this.sound.add("inhala").play();
      // this.sound.add("reten").play();
      // this.sound.add("exhala").play();
    
      

      // let tween1 = this.tweens.add({
      //   targets: _circulo3_,
      //   angle: 360,
      //   ease: 'Sine.Power',
      //   duration: 8000,
      //   onComplete: t=>{
      //     init_movimiento(ContenedorGeneralGlobal.sensibilidad);
      //   },
      //   // yoyo: true,
      //   repeat: 0
      // });


      let init_movimiento = tiempo=>{
          _circulo3_.setAngle(0);
          _circulo2_.setScale(1);
          _circulo3_.setTexture("_circulo3_");

          texto.setText("INHALA");
          this.sound.add("inhala").play();
          ContenedorGeneralGlobal.setTexto(texto);
          let tween1 = this.tweens.add({
            targets: _circulo3_,
            angle: 360,
            ease: 'Sine.Power',
            duration: tiempo,
            onRepeat: t=>{
              ContenedorGeneralGlobal.cambiarJiro(0);
              _circulo3_.setTexture("_circulo3_");
              ContenedorGeneralGlobal.setTexto(texto);
              this.sound.add("inhala").play();
              respiro();
            },
            // yoyo: true,
            repeat: -1
          });
          confi_actual++;

          let respiro = async ()=>{
            try{
              console.log(this.scene.isVisible())
              ContenedorGeneralGlobal.setTexto(texto);
              texto.setText("INHALA");
              let cortito = tiempo / 4;
              this.tweens.add({
                targets: _circulo2_,
                scale: 1.8,
                ease: 'Bounce.EaseIn',
                duration: cortito,
                onComplete: i=>{
                  ContenedorGeneralGlobal.setTexto(texto);
                  this.sound.add("reten").play();
                  texto.setText("RETÉN");
                }
              });
              await new Promise(r=>setTimeout(()=>r(true),cortito*2));
              texto.setText("EXHALA");
              this.sound.add("exhala").play();
              ContenedorGeneralGlobal.setTexto(texto);
              _circulo3_.setTexture("_circulo4_");
              ContenedorGeneralGlobal.cambiarJiro(1);
              this.tweens.add({
                targets: _circulo2_,
                scale: 1,
                ease: 'Bounce.EaseIn',
                duration: cortito,
                onComplete: i=>{
                  ContenedorGeneralGlobal.cambiarJiro(2);
                  _circulo3_.setTexture("_circulo5_");
                  console.log("cambio al 5")
                  ContenedorGeneralGlobal.setTexto(texto);
                  this.sound.add("reten").play();
                  texto.setText("RETÉN");
                }
              });
              await new Promise(r=>setTimeout(()=>r(true),cortito*2));
            }
            catch(E){}
            
        }
        respiro();
      }

      init_movimiento(ContenedorGeneralGlobal.sensibilidad);



    }
}
class GeneradorRespiracionTriangulo extends Phaser.Scene {

  constructor() {
    super({ key: 'GeneradorRespiracionTriangulo' });
    GeneradorRespiracionTrianguloGlobal = this;
  }
  drawEquilateralTriangle(graphics, triangleX, triangleY, triangleSize) {
      var x1 = triangleX - triangleSize / 2;
      var y1 = triangleY + triangleSize * Math.sqrt(3) / 6;
      var x2 = triangleX + triangleSize / 2;
      var y2 = y1;
      var x3 = triangleX;
      var y3 = triangleY - triangleSize * Math.sqrt(3) / 3;
      graphics.lineStyle(5, 0xffffff, 1);
      graphics.fillStyle(0x00ff00, .3);
      graphics.beginPath();
      graphics.moveTo(x1, y1);
      graphics.lineTo(x2, y2);
      graphics.lineTo(x3, y3);
      graphics.closePath();
      graphics.fillPath();
      graphics.strokePath();
      return [
        { x: x3, y: y3 },
        { x: x2, y: y2 },
        { x: x1, y: y1 }
        
    ];
  }

  create() {
    this.sound.pauseOnBlur = false;
      let graphics = this.add.graphics();
      let triangleX = this.scale.width/2;
      let triangleY = 550;
      let triangleSize = 750;
      let circleRadius = 40;
      let vertices = this.drawEquilateralTriangle(graphics, triangleX, triangleY, triangleSize);
      let circle = this.add.circle(triangleX - triangleSize / 2, triangleY + triangleSize * Math.sqrt(3) / 6, circleRadius, 0x6E3F91);
      circle.setStrokeStyle(5, 0xFFFFFF); 


      //  this.sound.add("inhala").play();
      // this.sound.add("reten").play();
      // this.sound.add("exhala").play();



      // this.sound.add("inhala").play();
      let texto = this.add.text(this.scale.width/2, 550, "INHALA", {
        font: "bold 64px Arial",
        align: "center",
        color: "#ffffff",
        // backgroundColor: "red",
        fixedWidth: this.scale.width
      }).setOrigin(.5).setAlpha(0);

      let mensajes = ["INHALA", "RETÉN", "EXHALA"];
      let audios = ["inhala", "reten", "exhala"];
      let posJiro = [0,2,1];
      let colores = [0x6E3F91, 0x3F8E8E, 0xDEDC00];

      let configureTween = (targetVertexIndex)=>{
        texto.setText(mensajes[targetVertexIndex]);
        ContenedorGeneralGlobal.setTexto(texto);
        // console.log(this.scene.isVisible())
        ContenedorGeneralGlobal.cambiarJiro(posJiro[targetVertexIndex]);
        circle.setFillStyle(colores[targetVertexIndex]);
        
        this.sound.add(audios[targetVertexIndex]).play();

        var targetVertex = vertices[targetVertexIndex];
        this.tweens.add({
            targets: circle,
            x: targetVertex.x,
            y: targetVertex.y,
            duration: ContenedorGeneralGlobal.sensibilidad/3,
            onComplete: function () {
                var nextVertexIndex = (targetVertexIndex + 1) % vertices.length;
                configureTween(nextVertexIndex);
            }
        });
      }
      configureTween(0);
    }
}

class GeneradorRespiracionOcho extends Phaser.Scene {

  constructor() {
    super({ key: 'GeneradorRespiracionOcho' });
    GeneradorRespiracionOchoGlobal = this;
  }


  create() {

    this.sound.pauseOnBlur = false;
    const graphics = this.add.graphics();

    // Configuración de la lemniscata
    const lemniscateConfig = {
        a: 400,
        b: 500,
        scale: 1,
        position: { x: 450, y: this.scale.width / 2 }
    };

    const circleRadius = 25;
    const totalTime = ContenedorGeneralGlobal.sensibilidad; // Duración total en milisegundos

    let calculateLemniscatePoints = (config)=>{
      const { a, b, scale, position } = config;
      const points = [];

      for (let t = 0; t <= 2 * Math.PI; t += 0.01) {
          const x = position.x + scale * a * Math.cos(t) / (Math.pow(Math.sin(t), 2) + 1);
          const y = position.y + scale * b * Math.cos(t) * Math.sin(t) / (Math.pow(Math.sin(t), 2) + 1);
          points.push({ x, y });
      }

      return points;
  }
    const lemniscatePoints = calculateLemniscatePoints(lemniscateConfig);

    // Dibuja la lemniscata
    graphics.lineStyle(5, 0xffffff, 1);
    graphics.fillStyle(0x00ff00, 0.2);
    graphics.beginPath();

    for (let i = 0; i < lemniscatePoints.length; i++) {
        const point = lemniscatePoints[i];

        if (i === 0 || i === lemniscatePoints.length - 1) {
            // Dibuja círculos en los extremos
            graphics.arc(point.y, point.x, circleRadius, 0, 2 * Math.PI);
        } else {
            // Dibuja círculos en los puntos intermedios
            graphics.arc(point.y, point.x, circleRadius, 0, 2 * Math.PI);
            graphics.lineTo(point.y, point.x);
        }
    }

    graphics.closePath();
    graphics.fillPath();
    graphics.strokePath();

    // Dibuja un círculo que sigue el contorno de la lemniscata
    const circleGraphics = this.add.graphics();
    let index = 0;
    const iterations = lemniscatePoints.length;
    const delay = totalTime / iterations;

    let colores = [0x6E3F91, 0xDEDC00, 0x3F8E8E, 0xDEDC00];
    this.time.addEvent({
        delay: delay,
        repeat: iterations - 1,
        loop: true,
        callback: function () {
          const point = lemniscatePoints[index];
          circleGraphics.clear();
          circleGraphics.fillStyle(colores[contador_de_ciclos],1);
          // circleGraphics.fillStyle(0x6E3F91, 1);
          circleGraphics.fillCircle(point.y, point.x, circleRadius);
          index = (index + 1) % lemniscatePoints.length;
        }
    });

    let numEvents = 4;
    let i = 1;
    const progress = i / numEvents;
    const timeToTrigger = totalTime * progress;
    let contador_de_ciclos = 0;
    let texto1 = this.add.text(this.scale.width/2, 235, "", {
      font: "bold 44px Arial",
      align: "center",
      color: "#ffffff",
      // backgroundColor: "red",
      fixedWidth: this.scale.width
    }).setOrigin(.5).setAlpha(0);
    let texto2 = this.add.text(this.scale.width/2, 670, "", {
      font: "bold 44px Arial",
      align: "center",
      color: "#ffffff",
      // backgroundColor: "red",
      fixedWidth: this.scale.width
    }).setOrigin(.5).setAlpha(0);

    let mensajes1 = ["INHALA", "RETÉN", "",""];
    let mensajes2 = ["", "", "EXHALA","RETÉN"];


    let audios = ["inhala", "reten", "exhala","reten"];

    //  this.sound.add("inhala").play();
      // this.sound.add("reten").play();
      // this.sound.add("exhala").play();
    
    let self = this;
    this.time.addEvent({
        delay: timeToTrigger,
        loop: true,
        callback: function () {
          // console.log(self.scene.isVisible())
          // ContenedorGeneralGlobal.cambiarJiro(posJiro[contador_de_ciclos]);
          
          circleGraphics.fillStyle(colores[contador_de_ciclos],1);
          
          ContenedorGeneralGlobal.setTexto(texto1);
          ContenedorGeneralGlobal.setTexto(texto2);

          self.sound.add(audios[contador_de_ciclos]).play();

          texto1.setText(mensajes1[contador_de_ciclos]);
          texto2.setText(mensajes2[contador_de_ciclos]);
          contador_de_ciclos++;
          if(contador_de_ciclos===4) contador_de_ciclos = 0;
        }
    });
      
    

  }

}