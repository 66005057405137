import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../inicio/services/global.service';
// import { NavigationEnd, NavigationStart } from '@angular/router';
import Phaser from 'phaser';
import { Ruidometro } from './widgets/ruidometro';
import { ActivatedRoute } from '@angular/router';

// import { interval } from 'rxjs';
declare var $: any;
declare var interact:any;
@Component({
  selector: 'app-board',
  templateUrl: './board.component.html',
  styleUrls: ['./board.component.css']
})
export class BoardComponent implements OnInit {

  phaserGame: any;
  config: Phaser.Types.Core.GameConfig;
  modoPizarra:any = true;
  botones:any = ["puntero", "lapiz", "goma"];
  recursos:any = ["ruidometro", "respirometro"];
  fondos:any = ["blanco", "fondo"];
  recursosActivados:any = {};
  clasesActivadas:any = {};

  constructor( public global:GlobalService, private route: ActivatedRoute){ 
    this.iniciar();
  }

  async iniciar(){
    setTimeout(()=>{
      $(".main-app").removeClass("hidden");
      this.route.params.subscribe(params => {
        if(params['id'] != "all") this.modoPizarra = false;
        this.inicializarTodo();
        this.activarRecurso(params['id']);
      });
    },1200);
   
    // var slider:any = document.getElementById("myRange");
    // var output = document.getElementById("demo");
    // output.innerHTML = slider.value;

    // slider.oninput = function() {
    //   output.innerHTML = this.value;
    // }

    await this.global.offLoading(2500);
    $("body").css("background-color", "#ffffff");
  }

  inicializarTodo(){
    let self = this;
    $(".resizable, .resizable button").mouseover(event=>{
      if(self.modoPizarra) $(event.currentTarget).find("button").removeClass("hidden");
      $(event.currentTarget).addClass("resizable-hover");
   });
   $(".resizable, .resizable button").mouseout(event=>{
    if(self.modoPizarra) $(event.currentTarget).find("button").addClass("hidden");
      $(event.currentTarget).removeClass("resizable-hover");
   });
   
    if(self.modoPizarra)
    interact('.resizable')
      .resizable({
        edges: { top: false, left: false, bottom: true, right: true },
        margin: 50,
        modifiers: [
          interact.modifiers.restrictEdges({
            outer: 'parent',
            endOnly: true,
          }),
          interact.modifiers.aspectRatio({
            ratio: 'preserve',
          }),
        ],
        listeners: {
          move: function (event) {
            let { x, y } = { x: event.rect.left, y : event.rect.top}
            x = (parseFloat(x) || 0) + event.deltaRect.left
            y = (parseFloat(y) || 0) + event.deltaRect.top
            Object.assign(event.target.style, {
              width: `${event.rect.width}px`,
              height: `${event.rect.height}px`,
              transform: `translate(${x}px, ${y}px)`
            });
            Object.assign(event.target.dataset, { x, y })
          }
        }
      }).on('resizemove', function (event) {
        if(self.modoPizarra) $(event.currentTarget).find("button").removeClass("hidden");
        $(event.currentTarget).addClass("resizable-hover");
        if(event.preEnd){
          if(self.modoPizarra) $(event.currentTarget).find("button").addClass("hidden");
          $(event.currentTarget).removeClass("resizable-hover");
        }
      });

    let position;
    interact('.draggable').draggable({
      ignoreFrom: '#myRange',
      listeners: {
        start (event) {
          position = { x: event.rect.left, y: event.rect.top }
        },
        move (event) {
          position.x += event.dx
          position.y += event.dy
          event.target.style.transform =
            `translate(${position.x}px, ${position.y}px)`
        },
      }
    })
  }

  ngOnInit(): void {
    // window.addEventListener('resize', event =>BarraPrincipalGlobal.scale.resize(window.innerWidth, window.innerHeight));
   
    // this.inicializarTodo();
     
  }

  
  activarFondo(fondo){
    // if(fondo === "ruidometro"){
      $(".pizarra").css("background-image", "url(assets/images/aplicativos/ruidometro/"+fondo+".png)")
    // } 
  }
  activarRecurso(recurso){
    // console.log(recurso
      
      if(recurso === "ruidometro"){
        if(!this.recursosActivados[recurso]){
          this.clasesActivadas[recurso] = new Ruidometro(this);
        }
        else{
          if(this.clasesActivadas[recurso].recursoActivo) this.clasesActivadas[recurso].ocultarRecurso();
            else this.clasesActivadas[recurso].mostrarRecurso();
        }
      }
  }
  

  
}


// class BarraPrincipal extends Phaser.Scene {

//   constructor() {
//     super({ key: 'BarraPrincipal' });
//     BarraPrincipalGlobal = this;
//   }
//   botones:any = ["puntero", "lapiz", "goma", "SEPARADOR"];
//   recursos:any = ["ruidometro", "respirometro"];
//   botonActivo:any = "puntero";
//   arrBotones:any = [];
//   preload() {
//     for(let i=0; i < this.botones.length; i++){
//       if(this.botones[i] === "SEPARADOR") continue;
//       this.load.image(this.botones[i], 'assets/images/aplicativos/board/'+this.botones[i]+'.png');
//       this.load.image((this.botones[i]+"_s"), 'assets/images/aplicativos/board/'+(this.botones[i]+"_s")+'.png');
//     }
//   }
//   resize (gameSize, baseSize, displaySize, resolution)
//   {
//     $("canvas").css({marginLeft:0,width:"100%",height:"auto", marginBottom: "-5px"});
//   }
//   create(){

//     this.sound.pauseOnBlur = false;
    
//     this.scene.moveBelow(this,LapizGlobal);
//     this.scene.moveBelow(this, BarraLateralGlobal);


//     let sizeRect = { w: this.scale.width, h: 100};
    
//     let barra = this.add.rectangle(0, this.scale.height-sizeRect.h, sizeRect.w, sizeRect.h, 0xf5f5f8, 1).setOrigin(0);
//     this.add.rectangle(0, this.scale.height-sizeRect.h-1, sizeRect.w, 1, 0xaeb7c2, 1).setOrigin(0);

//     let arrB = [];
//     let lastX = -80;
//     for(let i=0; i < this.botones.length; i++){
//       let boton = this.botones[i];
//       let hei = barra.y+2;

//       if(this.botones[i] === "SEPARADOR"){
//         this.add.rectangle(lastX+100, hei, 1, barra.height, 0xaeb7c2, 1).setOrigin(0);
//         lastX+= -10;
//         continue;
//       };      

//       let btn:any = this.add.image(0, hei, boton).setScale(1).setOrigin(0);
//       btn.x = lastX+5 + (btn.width +5)
//       btn.image = boton;
//       lastX = btn.x;
//       btn.setInteractive({ useHandCursor: true });
//       btn.on('pointerdown', function(pointer){
//         arrB.map((b:any)=>b.setTexture(b.image)); /*** resetea textura de colores */
//         btn.x = btn.x - 1;
//         btn.setScale(.99);
//         setTimeout(()=>btn.setScale(1), 100);
//         setTimeout(()=>btn.x = btn.x + 1, 100);
//         setTimeout(()=>btn.setTexture(boton+"_s"), 50);
//         this.botonActivo = btn.image;
//         setTimeout(()=>this.activarFunciones(btn.image), 60);
//       },this);
//       arrB.push(btn);
//     }
//     this.renderBotones();
//     this.arrBotones = arrB;
//     this.scene.launch(BarraLateralGlobal);
//     this.scene.launch(LapizGlobal);

//   }

//   renderBotones(){
//     this.arrBotones.map((boton:any)=>{
//       boton.setTexture(boton.image);
//       if(this.botonActivo === boton.image){
//         console.log(this.botonActivo)
//         boton.setTexture(boton.image+"_s");
//       }
//     });
//   }
//   recursoActivo:any = "";
//   sceneActiva:any;
//   activarFunciones(f){
//     LapizGlobal.LapizActivado = false;
//     switch(f){
//       case "lapiz":
//         LapizGlobal.LapizActivado = true;
//         break;
//       case "goma":
//         LapizGlobal.scene.restart();
//         LapizGlobal.LapizActivado = true;
//         this.botonActivo = "lapiz";
//         this.renderBotones();
//         break;
//       case "ruidometro":
//           if(this.recursoActivo === f){
//             this.sceneActiva.scene.remove();
//             this.botonActivo = "";
//             this.recursoActivo = null;
//             this.renderBotones();
//             break;
//           }
//           this.recursoActivo  = f;
//           let c = new Ruidometro();
//           this.sceneActiva = this.scene.add(f, c, true);
//           this.scene.moveBelow(LapizGlobal, c);
//           break;
//       default: break;
//     }
//   }
// }

// class BarraLateral extends Phaser.Scene {

//   constructor() {
//     super({ key: 'BarraLateral' });
//     BarraLateralGlobal = this;
//   }
//   preload() {

//   }
//   create(){
//     let sizeRect = { w: 100, h: this.scale.height} ;
//     this.add.rectangle(0, 0, sizeRect.w, sizeRect.h, 0xd3d3d3, 1).setOrigin(0);
//   }
// }

// class Lapiz extends Phaser.Scene {

//   constructor() {
//     super({ key: 'Lapiz' });
//     LapizGlobal = this;
//   }
//   LapizActivado:any = false;
//   preload() {
//     this.load.image('pen', 'assets/images/aplicativos/board/pen.png');
//   }
//   create(){
//     this.input.on('pointermove', function (pointer)
//     {
//       if (pointer.isDown && this.LapizActivado) this.add.image(pointer.x, pointer.y, 'pen').setScale(.3);    
//     }, this);
//   }
// }