<div class="hidden main-app">
	<!-- <img class="btn-hover-lsch pulso-css" *ngIf="api.ID_INSTITUCION != '' && global.isIframe" src="assets/images/abecedario/flecha.png" (click)="enviar_atras()" style="margin-top: 20px;margin-left: 10px;width: 40px;position: absolute;"> -->
	<img class="btn-hover efecto-destacado" *ngIf="api.ID_INSTITUCION != '' && global.isIframe" src="assets/images/abecedario/comentarios.svg" (click)="api.enviar_sugerencia('Generador de historias','extras/generador-historias')" style="left: 8px;top: 20px;">
	<img class="btn-hover efecto-destacado" *ngIf="!global.isIframe" src="assets/images/abecedario/comentarios.svg" (click)="api.valoraciones('Generador de historias','extras/generador-historias')" style="top: 20px;">
  <!-- <img class="btn-hover-lsch pulso-css" *ngIf="!global.isIframe" src="assets/images/abecedario/flecha.png" (click)="atras_landing()" style="margin-top: 20px;margin-left: 10px;width: 40px;position: absolute;"> -->
    <div id="contenedor-principal" style="opacity: 0">
		<div id="gameContainer"></div>
	</div>
	<div class="creandoFrases hidden" style="width: 100%;height: 100%;position: absolute;top: 0;left: 0;display: flex;align-items: center;justify-content: center;font-size: 59px;background: #673AB7;opacity: 0.6"></div>
	<div class="creandoFrases hidden" style="width: 100%;height: 100%;position: absolute;top: 0;left: 0;display: flex;align-items: center;justify-content: center;font-size: 59px;opacity: 1;color: white;    text-align: center;"><b>Creando frases...<br>{{CREANDO_FRASE}}</b></div>
</div>
<div class="hidden" id="fondo-imagen" style="position: relative;width: 800px;height: 629px;margin-top: 350px;">
    <img src="assets/images/aplicativos/generador/imagen.png" style="position: absolute; top: 0; left: 0; width: 800px; z-index: -1; object-fit: cover;">
    <div id="frases" [style.fontFamily]="fonts[FuenteActual]" style="position: absolute; top: 47%; left: 50%; transform: translate(-50%, -50%); text-align: center; width: 100%;padding: 0px 50px;word-wrap: break-word;line-height: inherit;font-size: 35px;color:#513d86">
        {{FRASE_MODAL}}
    </div>
</div>
<div id="modal-tipo-impresion" class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-md" style="margin-top: 100px" role="document">
        <div class="modal-content">
        <div class="">
            <div class="modal-header" style="text-align: center;">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <h5 class="modal-title" style="width:100%;font-size: 20px">¿Qué formato desea imprimir?</h5>
            </div>
            <div class="modal-body text-center" style="margin-top: 20px;margin-bottom: 20px;">
				<button class="btn btn-success" style="margin-right: 10px;font-size: 20px;" (click)="generatePDF('legal')"><i class="icon-file-pdf" style="margin-right: 5px;"></i> Oficio</button>
				<button class="btn btn-primary" style="margin-right: 10px;font-size: 20px;" (click)="generatePDF('letter')"><i class="icon-file-pdf" style="margin-right: 5px;"></i> Carta</button>
                <button class="btn btn-danger" style="margin-right: 10px;font-size: 20px;" (click)="cerrarModal()"><i class="icon-cross" style="margin-right: 5px;"></i> Cancelar</button>
            </div>
        </div>
        </div>
    </div>
</div> 
<app-valoraciones></app-valoraciones>