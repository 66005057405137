<div class="hidden main-app">
	<img class="btn-hover-lsch" *ngIf="api.ID_INSTITUCION != '' && global.isIframe" src="assets/images/abecedario/flecha.png" (click)="enviar_atras()" style="margin-top: 20px;margin-left: 10px;width: 40px;position: absolute;">
	<img class="btn-hover efecto-destacado" *ngIf="api.ID_INSTITUCION != '' && global.isIframe" src="assets/images/abecedario/comentarios.svg" (click)="api.enviar_sugerencia('Sopa de Sílabas y Letras','extras/sopa-de-silabas')" style="left: 8px;top: 65px;">
	<img class="btn-hover efecto-destacado" *ngIf="!global.isIframe" src="assets/images/abecedario/comentarios.svg" (click)="api.valoraciones('Sopa de Sílabas y Letras','extras/sopa-de-silabas')" style="top: 14px;">
</div>
<div id="portada" *ngIf="Portada" @salidaHaciaArriba>
    <div class="row" style="display: flex;flex-wrap: wrap;height: 100%;align-items: center;    justify-content: center;">
        <div class="col-sm-12 col-md-6 text-center">
            <br>
            <img src="assets/images/aplicativos/sopa-silabas/FONDO.png" style="width: 66vh;">
            <div style="font-family: 'Lato_Black';font-size: 20px;color: #4492cc">Este recurso te permitirá crear Sopas de Sílabas o Letras a partir de diversas categorías semánticas, lecturas y con múltiples configuraciones.</div>
        </div>
        <div class="col-sm-12 col-md-6 text-center">   
            <div class="title">¿Qué deseas generar?</div>
            <br>
            <br>
            <div class="col-sm-6 col-md-6" (click)="iniciar(true)">
                <div class="click" style="border: 1px solid #e0e0e0;background: white;padding: 10px;border-radius: 10px;">
                    <div style="font-family: 'Lato_Black';font-size: 20px;color: #4492cc">Sopa de Sílabas</div>            
                    <img src="assets/images/aplicativos/sopa-silabas/silabas.png" style="width: 86%;">
                </div>                
            </div>
            <div class="col-sm-6 col-md-6" (click)="iniciar(false)">
                <div class="click" style="border: 1px solid #e0e0e0;background: white;padding: 10px;border-radius: 10px;">
                    <div style="font-family: 'Lato_Black';font-size: 20px;color: #d94f4f">Sopa de Letras</div>         
                    <img src="assets/images/aplicativos/sopa-silabas/letras.png" style="width: 86%;">              
                </div>
                
            </div>
        </div>
        <div class="col-sm-12 col-md-12 text-center">
            <div class="text-center bienvenida" style="margin-top: 20px;margin-bottom: 10px;">
                <img src="assets/images/aplicativos/ruidometro/logos.png" style="width: 30%;">
                <br>
                Un producto con ❤ de integritic.cl
            </div>
        </div>
    </div>
</div>
<div class="container" style="margin-top: 50px;">
    <div class="title">Generador de Sopa de {{this.tipoSopa ? 'Sílabas' : 'Letras'}}</div>
</div>
<div class="row contenedor hidden">
    <div class="col-sm-12 col-md-3">
        <div class="flex" style="justify-content: center;">
            <div style="margin-left: 0;"> Sílabas</div>
            <label class="" style="margin-left: 5px;">
                <input type="radio" name="tipoSopa" (change)="cambiarTipoSopa(true)" id="tipo_silabas" value="silabas" checked>
            </label>
            <div style="margin-left: 20px;"> Letras</div>
            <label class="" style="margin-left: 5px;">
                <input type="radio" name="tipoSopa" (change)="cambiarTipoSopa(false)" id="tipo_letras" value="letras">
            </label>
        </div>
        <div class="col-sm-12">
            <div class="col-sm-7 no-padding" style="margin-top: 7px;">
                Anchura:
            </div>
            <div class="col-sm-5 no-padding">
                <input class="form-control" style="text-align: center;" [(ngModel)]="ANCHO" (change)="validarNumero('ANCHO',$event,5,12)" (keyup)="validarNumero('ANCHO',$event,5,12)" type="number" value="10" name="ANCHO" id="ANCHO">
            </div>
        </div>
        <div class="col-sm-12" style="margin-top: 3px;">
            <div class="col-sm-7 no-padding" style="margin-top: 7px;">
                Altura:
            </div>
            <div class="col-sm-5 no-padding">
                <input class="form-control" style="text-align: center;" [(ngModel)]="ALTO" (change)="validarNumero('ALTO',$event,5,12)" (keyup)="validarNumero('ALTO',$event,5,12)" type="number" value="10" name="ALTO" id="ALTO">
            </div>
        </div>
        <div class="col-sm-12" style="margin-top: 3px;">
            <div class="col-sm-7 no-padding">
                Cantidad de Palabras:
            </div>
            <div class="col-sm-5 no-padding">
                <input class="form-control" style="text-align: center;" [(ngModel)]="CANTIDAD" (change)="validarNumero('CANTIDAD',$event,1,15)" (keyup)="validarNumero('CANTIDAD',$event,1,15)" type="number" value="8" name="CANTIDAD" id="CANTIDAD">
            </div>
        </div>
        <div class="col-sm-12 mt-15">
            <div>Tipo:</div>
            <select class="select form-control" id="tipo_palabras" [(ngModel)]="TipoPalabra" (change)="changeVariableTipoEntrada()" required>
                <option value="Seleccione" selected disabled>Seleccione tipo</option>
                <option value="PALABRAS">Palabras</option>
                <option value="LECTURAS">Lecturas</option>                
                <option value="PERSONALIZADO-PALABRAS">Ingresar Palabras Personalizadas</option>
                <option value="PERSONALIZADO-TEXTO">Ingresar Lectura Personalizada</option>
            </select>
            <div class="text-center" style="margin-top: 10px;">
                <button *ngIf="this.ListadoPalabras.length > 0 && TipoPalabra == 'PERSONALIZADO-TEXTO'" style="margin-right: 2px;" class="btn btn-verde" title="Editar Lectura" (click)="ingresarLectura()"><i class="icon-pencil"></i></button>
                <button *ngIf="this.ListadoPalabras.length > 0 && TipoPalabra == 'PERSONALIZADO-TEXTO'" class="btn btn-info" title="Ver Lectura" (click)="AbrirLectura()"><i class="icon-book"></i></button>
            </div>
        </div>
        <div class="col-sm-12 mt-15" *ngIf="TipoPalabra == 'LECTURAS'">
            <div>Procedencia:</div>
            <select class="select form-control" [(ngModel)]="inputProcedencia" id="procedencia" (change)="changeProcedencia()" required>
                <option value="Seleccione" selected disabled>Seleccione procedencia</option>
                <option *ngFor="let procedencia of this.Procedencias; let i = index" value="{{procedencia.value}}">{{procedencia.text}}</option>
            </select>
        </div>
        <div class="col-sm-12 mt-15" *ngIf="TipoPalabra == 'LECTURAS'">
            <div>Curso:</div>
            <select class="select form-control" [(ngModel)]="inputCurso" id="curso" (change)="changeCurso()" required [disabled]="inputProcedencia == 'Seleccione' ? true : false">
                <option value="Seleccione" selected disabled>Seleccione curso</option>
                <option *ngFor="let curso of this.Cursos; let i = index" value="{{curso}}">{{curso}}</option>
            </select>
        </div>
        <div class="col-sm-12 mt-15" *ngIf="TipoPalabra == 'LECTURAS'">
            <div>Nombre de la lectura:</div>
            <div style="display: flex;">
                <select class="select form-control" [(ngModel)]="inputLectura" id="lectura" (change)="changeLectura()" required [disabled]="inputCurso == 'Seleccione' ? true : false">
                    <option value="Seleccione" selected disabled>Seleccione lectura</option>
                    <option *ngFor="let lectura of this.FiltroLecturas; let i = index" value="{{lectura._id}}">{{lectura.TITULO}} • {{lectura.CLASIFICACION}}</option>
                </select>
                <button *ngIf="inputLectura != ''" class="btn btn-primary" title="Ver Lectura" (click)="AbrirLectura()"><i class="icon-book"></i></button>
            </div>
        </div>
        <div class="col-sm-12 mt-15" *ngIf="TipoPalabra == 'PALABRAS'">
            <div>Categorías Semánticas:</div>
            <select class="select form-control" id="categorias" (change)="changeCategoria($event)" required>
                <option value="Seleccione" selected disabled>Seleccione categoría</option>
                <option *ngFor="let categoria of this.CategoriasPalabras; let i = index" value="{{categoria.CATEGORIA}}">{{categoria.CATEGORIA}}</option>
            </select>
        </div>
        <div class="col-sm-12 mt-15">
            <div>Palabras:</div>
            <div class="form-control contenedor-palabras">
                <div class="palabra" *ngFor="let palabra of this.ListadoPalabras; let i = index" data-id="{{palabra.PALABRA}}"><span class="icon-cross" (click)="eliminarListado(i)"></span>{{i+1}}.- {{palabra.PALABRA}}</div>
            </div>
            <br>
            <div class="text-center">
                <button class="btn btn-verde" style="margin-right: 2px" title="Agregar palabra personalizada" (click)="ingresarPalabras()"><i class="icon-plus3"></i></button>
                <button *ngIf="this.TipoPalabra != 'PERSONALIZADO'" class="btn btn-info" style="margin-right: 2px" title="Palabras aleatorias" (click)="SeleccionarPalabras()"><i class="icon-spinner11"></i></button>
                <button class="btn btn-danger" title="Limpiar selección" (click)="LimpiarListado()"><i class="icon-trash"></i></button>
            </div>
            
        </div>
        
    </div>
    <div class="col-sm-12 col-md-6 text-center central">
        <div id="sopaDeLetras" class="puzzle">
            <div *ngFor="let fila of this.puzzle; let x = index" style="display: flex;justify-content: center;">
                <span *ngFor="let columna of fila; let y = index" class="silaba {{FuenteActual}} {{isMayuscula}}" id="span-{{y+'-'+x}}">{{columna}}</span>
            </div>
        </div>
    </div>
    <div class="col-sm-12 col-md-3">
        <div class="subtitulo">Configuración</div>
        <br>
        <div class="flex">
            <label class="switch">
                <input type="checkbox" [(ngModel)]="isHorizontal" (click)="cambioSopa()">
                <span class="slider"></span>
            </label>
            <div style="margin-left: 10px;"> Horizontal</div>
        </div>
        <div class="flex" style="margin-top: 5px;">
            <label class="switch">
                <input type="checkbox" [(ngModel)]="isVertical" (click)="cambioSopa()">
                <span class="slider"></span>
            </label>
            <div style="margin-left: 10px;"> Vertical</div>
        </div>
        <div class="flex" style="margin-top: 5px;">
            <label class="switch">
                <input type="checkbox" [(ngModel)]="isDiagonal" (click)="cambioSopa()">
                <span class="slider"></span>
            </label>
            <div style="margin-left: 10px;"> Permitir diagonales</div>
        </div>
        <div class="flex" style="margin-top: 5px;">
            <label class="switch">
                <input type="checkbox" [(ngModel)]="isInvertidas" (click)="cambioSopa()">
                <span class="slider"></span>
            </label>
            <div style="margin-left: 10px;"> Permitir invertidas</div>
            <br>
        </div>
        <div class="flex" style="margin-top: 5px;">
            <label class="switch">
                <input type="checkbox" [(ngModel)]="mostrarSolucion" (click)="cambioSopa()" (change)="Findsolucion()">
                <span class="slider"></span>
            </label>
            <div style="margin-left: 10px;">Mostrar solución</div>
        </div>
        <div class="text-center" style="display: flex;align-items: center;margin-top: 20px;">
            <select id="FuenteActual" [(ngModel)]="FuenteActual" (change)="cambiaFuente()" class="form-control">
                <option value="Arial">Arial</option>
                <option value="Tahoma">Tahoma</option>
                <option *ngIf="isMayuscula != 'mayuscula'" value="Ligada">Escolar Ligada</option>
                <option value="Century">Century</option>
                <option value="Dislexia">Dislexia</option>
                <option value="Lsch">LSCH</option>
            </select>
            <button [class]="isMayuscula == 'mayuscula' ? 'btn btn-info' : 'btn btn-grey'" (click)="cambiarMayuscula('mayuscula')" title="Mayúscula" style="margin: 2px;padding: 1px 5px;"><img src="assets/images/aplicativos/sopa-silabas/font-1.png"></button>
            <button [class]="isMayuscula == 'mayuscula' ? 'btn btn-grey' : 'btn btn-info'" (click)="cambiarMayuscula('minuscula')" title="Minúscula" style="margin: 2px;padding: 1px 5px;"><img src="assets/images/aplicativos/sopa-silabas/font-2.png"></button><i class="fa fa-question-circle" (click)="mensaje_info('Configuración de la fuente')" style="font-size: 20px;cursor: pointer;color: #4392cc;margin-left: 3px;"></i>
        </div>
        <div class="text-center">
            <button class="btn {{claseBtn}}" id="boton-generar" (click)="GenerarSopa()" title="Generar Sopa" style="margin-top: 20px;font-size: 25px;font-family: 'Lato_Bold';border-radius: 80px;" [disabled]="ListadoPalabras.length == 0 ? true : false"><i class="icon-spinner11" style="margin-right: 5px;font-size: 20px;"></i> Generar Sopa</button>
        </div>
        <div style="margin-top: 60px;">
            <div class="subtitulo">Opciones Impresión</div>
            <br>
            <div class="flex">
                <label class="switch">
                    <input type="checkbox" [(ngModel)]="isListado">
                    <span class="slider"></span>
                </label>
                <div style="margin-left: 10px;">Listado de Palabras <i class="fa fa-question-circle" (click)="mensaje_info('Se muestran las palabras a buscar en la impresión')" style="font-size: 20px;cursor: pointer;color: #4392cc;margin-left: 3px;"></i></div>
            </div>
            <!-- <div class="flex" style="margin-top: 5px;">
                <label class="switch">
                    <input type="checkbox" [(ngModel)]="isSolucion">
                    <span class="slider"></span>
                </label>
                <div style="margin-left: 10px;">Solución</div>
            </div> -->
            <br>
            <div class="col-sm-12">
                <div class="col-sm-12 no-padding" style="margin-top: 7px;">
                    Tamaño:
                </div>
                <div class="col-sm-12 no-padding">
                    <select class="select form-control" id="TAMANIO" [(ngModel)]="TAMANIO" required>
                        <option value="letter" selected>Carta</option>
                        <option value="legal">Oficio</option>
                    </select>
                </div>
            </div>
            <div class="text-center">
                <button [class]="ListadoPalabras.length == 0 ? 'btn btn-grey' : 'btn btn-success'" style="margin-top: 20px;" *ngIf="puzzle" (click)="modalPDF()"><i class="icon-file-pdf" style="margin-right: 5px;"></i> Imprimir</button>
            </div>
        </div>
    </div>
</div>
<!-- <div>
    <div id="sopaDeLetras_word" class="puzzle">
        <div *ngFor="let fila of this.puzzle; let x = index" [class]="isMayuscula ? 'uppercase':'lowecase'" style="display: flex;justify-content: center;">
            <span *ngFor="let columna of fila; let y = index" class="silaba {{FuenteActual}}">{{columna}}</span>
        </div>
    </div>
</div> -->
<div id="modal-lectura" class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-md" style="margin-top: 100px" role="document">
        <div class="modal-content">
            <div class="modal-header" style="text-align: center;">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <h5 class="modal-title" style="font-size: 30px"><b>{{inputTitulo}}</b></h5>
            </div>
            <div class="modal-body text-center" style="padding: 40px;font-size: 20px;text-align: justify;">
                {{inputTexto}}
            </div>
        </div>
    </div>
</div>
<div id="modal-ingreso-palabras" class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-sm" style="margin-top: 100px" role="document">
        <div class="modal-content">
        <div class="">
            <div class="modal-header" style="text-align: center;">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <h5 class="modal-title" style="width:100%;font-size: 20px">Ingrese las palabras</h5>
            </div>
            <div class="modal-body text-center">
                <div>
                    Introduce las palabras que deseas añadir una por línea en el cuadro de texto que aparece a continuación.
                </div>
                <br>
                <textarea class="form-control" name="EXTRAS" id="EXTRAS" style="resize: none;" cols="30" rows="15"></textarea>
                <br>
                <button class="btn btn-info" *ngIf="puzzle" (click)="agregarPalabras()"><i class="icon-plus3" style="margin-right: 5px;"></i> Agregar palabras</button>
            </div>
        </div>
        </div>
    </div>
</div>
<div id="modal-ingreso-lectura" class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg" style="margin-top: 100px" role="document">
        <div class="modal-content">
        <div class="">
            <div class="modal-header" style="text-align: center;">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body text-center">
                <div id="PasoLectura_1" style="padding: 0px 20px;">
                    <div style="font-size: 30px;margin-bottom: 20px;"><img src="assets/images/aplicativos/sopa-silabas/btn-1.svg" style="width: 40px;margin-right: 5px;margin-top: -7px;"><b>Ingresar Texto:</b></div>
                    <br>
                    Título:
                    <input class="form-control" style="text-align: center;" name="LECTURA-TITULO" id="LECTURA-TITULO" maxlength="50" (keyup)="validar_cantidad()">
                    <br>
                    Texto:
                    <textarea class="form-control" name="LECTURA-TEXTO" id="LECTURA-TEXTO" style="resize: none;" cols="30" rows="15" (keyup)="validar_cantidad()"></textarea>
                    <label style="background: #34cd8f;padding: 3px 10px;color: white;font-family: 'Lato_Black';">{{contador_palabras}}</label>
                    <br>
                    <button class="btn btn-danger" *ngIf="puzzle" (click)="limpiarLectura()" style="margin-right: 2px;"><i class="icon-trash" style="margin-right: 5px;"></i> Limpiar</button>
                    <button class="btn btn-info" *ngIf="puzzle" (click)="Siguiente()"><i class="fa fa-arrow-right" style="margin-right: 5px;"></i> Siguiente</button>
                </div>
                <div class="hidden" id="PasoLectura_2" style="padding: 0px 20px;">
                    <div style="font-size: 30px;margin-bottom: 20px;"><img src="assets/images/aplicativos/sopa-silabas/btn-2.svg" style="width: 40px;margin-right: 5px;margin-top: -7px;"><b>Selección de palabras:</b></div>
                    <br>
                    <div class="row">
                        <div class="col-sm-12 text-left">Seleccione la palabra que desea utilizar para crear la sopa de letras:</div>
                        <br>
                        <div class="col-sm-8 text-center">
                            <span class="form-control" style="width: 100%;overflow-y: scroll; overflow-x: hidden;height: 50px;line-height: 25px;" id="SPAN-TITULO"></span>
                            <br>
                            <span class="form-control" style="width: 100%;overflow-y: scroll; overflow-x: hidden;height: 380px;line-height: 25px;word-wrap: break-word;" id="SPAN-TEXTO"></span>
                            <label style="background: #34cd8f;padding: 3px 10px;color: white;font-family: 'Lato_Black';">{{contador_palabras}}</label>
                        </div>
                        <div class="col-sm-4">
                            <div class="text-left">Palabras seleccionadas:</div>
                            <div class="form-control contenedor-palabras-texto">
                                <div class="palabra text-left" *ngFor="let palabra of this.ListadoTexto; let i = index"><span class="icon-cross" (click)="eliminarPalabraTexto(i,palabra.INDEX)"></span>{{i+1}}.- {{palabra.PALABRA}}</div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <button class="btn btn-info" *ngIf="puzzle" (click)="Atras()" style="margin-right: 2px;"><i class="fa fa-arrow-left" style="margin-right: 5px;"></i> Atrás</button>
                    <button class="btn btn-verde" *ngIf="puzzle" (click)="LecturaProcesada()"><i class="icon-check" style="margin-right: 5px;"></i> Finalizar</button>
                </div>
            </div>
        </div>
        </div>
    </div>
</div>
<div id="modal-ingreso-lectura-bd" class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg" style="margin-top: 100px" role="document">
        <div class="modal-content">
        <div class="">
            <div class="modal-header" style="text-align: center;">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body text-center">
                <div id="PasoLectura_3" style="padding: 0px 20px;">
                    <!-- <img src="assets/images/aplicativos/sopa-silabas/btn-1.svg" style="width: 40px;margin-right: 5px;margin-top: -7px;"> -->
                    <div style="font-size: 30px;margin-bottom: 20px;"><b>Selección de palabras:</b></div>
                    <div class="row">
                        <div class="col-sm-12 text-left">Seleccione la palabra que desea utilizar para crear la sopa de letras:</div>
                        <br>
                        <div class="col-sm-8 text-center">
                            <span class="form-control" style="width: 100%;overflow-y: scroll; overflow-x: hidden;height: 40px;line-height: 25px;" id="SPAN-TITULO-BD"></span>
                            <br>
                            <span class="form-control" style="width: 100%;overflow-y: scroll; overflow-x: hidden;height: 420px;line-height: 25px;word-wrap: break-word;" id="SPAN-TEXTO-BD"></span>
                        </div>
                        <div class="col-sm-4">
                            <div class="text-left">Palabras seleccionadas:</div>
                            <div class="form-control contenedor-palabras-texto">
                                <div class="palabra text-left" *ngFor="let palabra of this.ListadoTexto; let i = index"><span class="icon-cross" (click)="eliminarPalabraTexto(i,palabra.INDEX)"></span>{{i+1}}.- {{palabra.PALABRA}}</div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <button class="btn btn-verde" *ngIf="puzzle" (click)="LecturaProcesadaBD()"><i class="icon-check" style="margin-right: 5px;"></i> Finalizar</button>
                </div>
            </div>
        </div>
        </div>
    </div>
</div>
<div id="modal-impresion" class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-md" style="margin-top: 100px" role="document">
        <div class="modal-content">
        <div class="">
            <div class="modal-header" style="text-align: center;">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <h5 class="modal-title" style="width:100%;font-size: 20px">Opciones adicionales</h5>
            </div>
            <div class="modal-body text-center">
                <div>
                    Configura los datos que deseas integrar a tu documento
                </div>
                <br>
                <div class="flex" style="align-items: center;margin-bottom: 3px;">
                    <input style="width: 10%;" type="checkbox" [(ngModel)]="checkTitulo">
                    <div style="width: 20%;margin: 0px 10px;text-align: left;">Título:</div>
                    <input style="width: 70%;" class="form-control" type="text" name="Título" id="Título" value="Sopa de {{this.tipoSopa ? 'Sílabas' : 'Letras' }}">
                </div>
                <div class="flex" style="align-items: center;margin-bottom: 3px;">
                    <input style="width: 10%;" type="checkbox" [(ngModel)]="checkInstruccion">
                    <div style="width: 20%;margin: 0px 10px;text-align: left;">Instrucciones:</div>
                    <input style="width: 70%;" class="form-control" type="text" maxlength="90" name="Instrucciones" id="Instrucciones">
                </div>
                <div class="flex" style="align-items: center;margin-bottom: 3px;">
                    <input style="width: 10%;" type="checkbox" [(ngModel)]="checkNombre">
                    <div style="width: 20%;margin: 0px 10px;text-align: left;">Nombre:</div>
                    <input style="width: 70%;" class="form-control" type="text" name="Nombre" id="Nombre">
                </div>
                <div class="flex" style="align-items: center;margin-bottom: 3px;">
                    <input style="width: 10%;" type="checkbox" [(ngModel)]="checkCurso">
                    <div style="width: 20%;margin: 0px 10px;text-align: left;">Curso:</div>
                    <input style="width: 70%;" class="form-control" type="text" name="Curso" id="Curso">
                </div>
                <div class="flex" style="align-items: center;margin-bottom: 3px;">
                    <input style="width: 10%;" type="checkbox" [(ngModel)]="checkColegio">
                    <div style="width: 20%;margin: 0px 10px;text-align: left;">Colegio:</div>
                    <input style="width: 70%;" class="form-control" type="text" name="Colegio" id="Colegio" [(ngModel)]="this.DATOS_USUARIO.institucion">
                </div>
                <div class="flex" style="align-items: center;margin-bottom: 3px;">
                    <input style="width: 10%;" type="checkbox" [(ngModel)]="checkFecha">
                    <div style="width: 20%;margin: 0px 10px;text-align: left;">Fecha:</div>
                    <input style="width: 56%;" class="form-control" type="date" name="Fecha" id="Fecha">
                    <button class="btn btn-verde" *ngIf="puzzle" (click)="setFechahoy()"><i class="icon-calendar" style="margin-right: 5px;"></i> Hoy</button>
                </div>
                <div class="flex" style="align-items: center">
                    <input style="width: 10%;" type="checkbox" [(ngModel)]="checkDocente">
                    <div style="width: 20%;margin: 0px 10px;text-align: left;">Docente:</div>
                    <input style="width: 70%;" class="form-control" type="text" name="Docente" id="Docente" [(ngModel)]="this.DATOS_USUARIO.usuario">
                </div>
                <br>
                <button class="btn btn-verde" *ngIf="puzzle" (click)="generatePDF()"><i class="icon-file-pdf" style="margin-right: 5px;"></i> Imprimir</button>
            </div>
        </div>
        </div>
    </div>
</div> 
<img id="img-logo-pdf" class="hidden" src="assets/images/logo-largo.png">
<img id="img-logo-pdf-lt" class="hidden" src="assets/images/logo_rojo_definitivo.png">
<img id="img-logo-pdf-tt" class="hidden" src="assets/images/logo_tt.png">