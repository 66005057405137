import { Component,ElementRef, OnInit, Renderer2 ,HostListener} from '@angular/core';
import { ApiService } from 'src/app/inicio/services/api.service';
import { GlobalService } from 'src/app/inicio/services/global.service';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { trigger, transition, animate, style } from '@angular/animations';

declare var $: any;
declare var wordfind2: any;
declare var wordfindsopa: any;
declare var jsPDF: any;
declare var axios: any;
import ObjectID from "bson-objectid"
import html2canvas from 'html2canvas';
import { ActivatedRoute } from '@angular/router';
var GLOBAL;
@Component({
  selector: 'app-sopa-silabas',
  templateUrl: './sopa-silabas.component.html',
  styleUrls: ['./sopa-silabas.component.css'],
  animations: [
    trigger('salidaHaciaArriba', [
      transition(':leave', [
        animate('0.5s', style({ transform: 'translateY(-100%)', opacity: 0 }))
      ])
    ])
  ]
})
export class SopaSilabasComponent implements OnInit {
  fechaSeleccionada: string;
  mostrarSolucion:any = true;
  Portada:any = true;
  isHorizontal: boolean = true;
  isVertical: boolean = true;
  isDiagonal: boolean = false;
  isInvertidas: boolean = false;
  isListado: boolean = true;
  isSolucion: boolean = true;
  checkTitulo: boolean = true;
  checkInstruccion: boolean = true;
  checkNombre: boolean = true;
  checkCurso: boolean = true;
  checkColegio: boolean = true;
  checkFecha: boolean = true;
  checkDocente: boolean = true;
  CategoriasPalabras:any;
  TipoPalabra:any = "Seleccione";
  CategoriaActual:any = "Seleccione";
  inputProcedencia:any = "Seleccione";
  inputCurso:any = "Seleccione";
  inputLectura:any = "Seleccione";
  inputTitulo:any = "Seleccione";
  inputTexto:any = "Seleccione";
  inputTitulo_original:any;
  inputTexto_original:any;
  ListadoPalabras:any = [];
  ListadoTexto:any = [];
  Lecturas:any;
  ANCHO:any = 10;
  ALTO:any = 10;
  CANTIDAD:any = 8;
  isMayuscula:any = 'mayuscula';
  FuenteActual:any = "Arial";
  TAMANIO:any = "letter";
  PLATAFORMA:any = "TT";
  tipoSopa:any = true;
  puzzle:any = [
    ["","","","","","","","","",""],
    ["","","","","","","","","",""],
    ["","","","","","","","","",""],
    ["","","","","","","","","",""],
    ["","","","","","","","","",""],
    ["","","","","","","","","",""],
    ["","","","","","","","","",""],
    ["","","","","","","","","",""],
    ["","","","","","","","","",""],
    ["","","","","","","","","",""]
  ];
  solucion:any;
  Procedencias:any;
  alerta;
  FiltroLecturas:any;
  Cursos:any;
  COLOR_BORDES:any = "#4492cc";
  COLOR_FONDO:any = "#ebf3fa";
  OPCIONES_ADICIONALES_PDF:any = {
    Título: "",
    Nombre: "",
    Curso: "",
    Fecha: "",
    Colegio: "",
    Docente: "",
    Instrucciones: ""
  }
  SopaCreada:any = false;
  url_api = "https://api.gateway.integritic.cl/sv5/";
  PasoLectura = 1;
  claseBtn:any = 'btn-grey';
  audioClick:any = new Audio('assets/recursos/click.mp3');
  DATOS_USUARIO = {
    'usuario': '',
    'institucion': ''
  }
  ORDEN_CURSO = [
    'NT 1','NT 2','1° Básico','2° Básico','3° Básico','4° Básico','5° Básico','6° Básico','7° Básico','8° Básico','1° Medio','2° Medio','3° Medio','4° Medio',
  ];
  constructor(public global:GlobalService, public api:ApiService,private elementRef: ElementRef,private http: HttpClient,private renderer: Renderer2, private route: ActivatedRoute) {
    this.alerta = this.global.alerta;
    this.actualizarColor();
    this.url_api = window.location.origin.includes('localhost') ? "http://127.0.0.1:3445/" : "https://api.gateway.integritic.cl/sv5/";
    this.url_api = "https://api.gateway.integritic.cl/sv5/";
    const hoy = new Date();
    this.fechaSeleccionada = hoy.toISOString().slice(0, 10);
    if(global.isIframe){
      this.route.params.subscribe((params2:any) => {
        this.getDatos(params2);
      });
    }
  }
  async getDatos(params2){
    let datos = await this.api.getDatosUsuario(params2);
    console.log(datos)
    this.DATOS_USUARIO = {
      'usuario': datos["usuario"].toUpperCase(),
      'institucion': datos["institucion"].toUpperCase()
    }
  }
  ngOnInit(): void {
    this.getCategorias();
    this.getLecturas();
    GLOBAL = this;
    this.api.set_kpi_plataformas("Sopa de Sílabas y Letras","Ingresó al módulo");
  }
  iniciar(silaba){
    this.audioClick.play();
    const button = this.elementRef.nativeElement.querySelector('#tipo_silabas'); // Reemplaza '#miBoton' con el selector de tu botón
    const button2 = this.elementRef.nativeElement.querySelector('#tipo_letras'); // Reemplaza '#miBoton' con el selector de tu botón
    this.renderer.selectRootElement(silaba ? button : button2).click();
    this.Portada = false;
    setTimeout(() => {
      $('.contenedor').removeClass('hidden');
      this.ajustarCeldas();
    }, 100);
  }
  quitarCaracteres(texto){
    let caracteres = [".", ",", "-", "_", "¿", "?", "!", "¡", "“","”", "—", "  ", ":", "—", "…", ":", ";", "(", ")", "[", "]", "{", "}", "'", "\"", "¨", "/", "\\", "*", "#", "&", "%","..."];
    for (let x = 0; x < caracteres.length; x++) {
      texto = texto.replaceAll(caracteres[x],"");
    }
    return texto;
  }
  getClasificacionPalabras(){
    return new Promise(resolve=>{
      let parrafo = this.inputTitulo+" "+this.inputTexto;
      parrafo = this.quitarCaracteres(parrafo);
      this.http.post<any>(this.url_api+'juegoeduca/PalabrasClaves/',JSON.stringify({palabras: parrafo})).subscribe(response => {
        this.getPalabrasClaves(response);
        resolve(response);
      }, response_err=>{
        resolve(response_err.error)
      });
    });
  }
  PALABRAS_LECTURA:any = [];
  eliminarDuplicados(palabras){
    let obj_palabras = [];
    let array_palabras = [];
    for (let index = 0; index < palabras.length; index++) {
      if(!obj_palabras.includes(palabras[index].PALABRA.toLowerCase())){
        array_palabras.push(palabras[index]);
        obj_palabras.push(palabras[index].PALABRA.toLowerCase());
      }
    }
    array_palabras = array_palabras.sort(function () {
      return Math.random() - 0.5;
    });
    this.PALABRAS_LECTURA = array_palabras;
    return this.PALABRAS_LECTURA;
  }
  limpiarLectura(){
    $('#LECTURA-TITULO').val("");
    $('#LECTURA-TEXTO').val("");
    this.validar_cantidad();
  }
  async getPalabrasClaves(palabras){
    let tipos = ["NOUN","VERB"];
    let listado = palabras.filter(A => tipos.indexOf(A.PartOfSpeech.Tag) !== -1 && A.Text.length > 3);
    listado.forEach(listado => {
      listado["PALABRA"] = listado.Text;
    });
    listado = await this.eliminarDuplicados(listado);
    if(this.TipoPalabra == "LECTURAS"){
      this.procesarPalabrasTextosBD(listado);
      // this.ListadoPalabras = listado.slice(0,this.CANTIDAD);
      // $('#modalaso').addClass('hidden');
    }else{
      this.procesarPalabrasTextosPERSONALIZADO(listado);
    }
  }
  getPalabrasLectura(){
    this.PALABRAS_LECTURA = this.PALABRAS_LECTURA.sort(function () {
      return Math.random() - 0.5;
    });
    this.ListadoPalabras = this.PALABRAS_LECTURA.slice(0,this.CANTIDAD);
    this.destacarBoton();
  }
  eliminarListado(index){
    this.ListadoPalabras.splice(index, 1); 
    this.destacarBoton();   
  }
  eliminarPalabraTexto(index,palabra_index){
    let element = document.getElementById('palabra-'+palabra_index);
    this.ListadoTexto.splice(index, 1);    
    element.style.background = '';
    element.style.padding = '';
    element.style.borderRadius = '';
    element.style.color = '';
  }
  async getCategorias(){
    this.CategoriasPalabras = await this.api.getCategoriasPalabras();
    $('#modalaso').addClass('hidden');
    // return new Promise(resolve=>{
    //   this.http.get<any>(this.url_api+'juegoeduca/get/categorias_semanticas').subscribe(response => {
    //     this.CategoriasPalabras = response.filter(A=> A.INGLES_CATEGORIA_PALABRAS != "true");
    //     $('#modalaso').addClass('hidden');
    //     resolve(response);
    //   }, response_err=>{
    //     resolve(response_err.error)
    //   });
    // });
  }
  enviar_atras(){
    window.parent.postMessage({ type: 'navigate', url: "RecursosApoyo", recurso: "Sopa de Sílabas y Letras"}, '*');
  }
  avisoSopa(){
    if(this.SopaCreada){
      this.alerta.fire({
        title: "¿Está seguro que desea hacer cambios?",
        text: "Se eliminará la sopa actual",
        icon: 'warning',
        showDenyButton: false,
        showCancelButton: false,
        confirmButtonText: "Continuar",
        cancelButtonText: "Cancelar"
      }).then((result) => {
        if (result.isConfirmed) {
          this.ListadoPalabras = [];
          this.LimpiarPuzzle();
          this.SopaCreada = false;
          this.inputLectura = "Seleccione";
          this.limpiarSopa();
        }
      });
    }
  }
  changeVariableTipoEntrada(){
    if(this.SopaCreada){
      this.alerta.fire({
        title: "¿Está seguro que desea hacer cambios?",
        text: "Se eliminará la sopa actual",
        icon: 'warning',
        showDenyButton: false,
        showCancelButton: false,
        confirmButtonText: "Continuar",
        cancelButtonText: "Cancelar"
      }).then((result) => {
        if (result.isConfirmed) {
          if(this.TipoPalabra == "PERSONALIZADO-PALABRAS"){
            this.ingresarPalabras();
          }else if(this.TipoPalabra == "PERSONALIZADO-TEXTO"){
            this.ingresarLectura();
          }
          this.limpiarSopa();
        }
      });
    }else{
      if(this.TipoPalabra == "PERSONALIZADO-PALABRAS"){
        this.ingresarPalabras();
      }else if(this.TipoPalabra == "PERSONALIZADO-TEXTO"){
        this.ingresarLectura();
      }
    }
  }
  AbrirLectura(){
    $('#modal-lectura').modal({
          backdrop: 'static',
          keyboard: false
        });
  }
  changeProcedencia(){
    if(this.SopaCreada){
      this.alerta.fire({
        title: "¿Está seguro que desea hacer cambios?",
        text: "Se eliminará la sopa actual",
        icon: 'warning',
        showDenyButton: false,
        showCancelButton: false,
        confirmButtonText: "Continuar",
        cancelButtonText: "Cancelar"
      }).then((result) => {
        if (result.isConfirmed) {
          this.getLecturasByProcedencia();
          this.inputCurso = "Seleccione";
          this.inputLectura = "Seleccione";
          this.limpiarSopa();
        }
      });
    }else{
      this.getLecturasByProcedencia();
          this.inputCurso = "Seleccione";
          this.inputLectura = "Seleccione";
    }
  }
  changeCurso(){
    if(this.SopaCreada){
      this.alerta.fire({
        title: "¿Está seguro que desea hacer cambios?",
        text: "Se eliminará la sopa actual",
        icon: 'warning',
        showDenyButton: false,
        showCancelButton: false,
        confirmButtonText: "Continuar",
        cancelButtonText: "Cancelar"
      }).then((result) => {
        if (result.isConfirmed) {
          this.inputLectura = "Seleccione";
          this.getFiltroLecturas();
          this.limpiarSopa();
        }
      });
    }else{
      this.inputLectura = "Seleccione";
      this.getFiltroLecturas();
    }
  }
  changeLectura(){
    if(this.inputProcedencia && this.inputLectura && this.inputCurso){
      if(this.SopaCreada){
        this.alerta.fire({
          title: "¿Está seguro que desea continuar?",
          text: "Se eliminará la sopa actual",
          icon: 'warning',
          showDenyButton: false,
          showCancelButton: false,
          confirmButtonText: "Continuar",
          cancelButtonText: "No"
        }).then((result) => {
          if (result.isConfirmed) {
            this.extraerPalabras();
          }
        });
      }else{
        this.extraerPalabras();
      }
    }
  }
  async extraerPalabras(){
    let datos = this.FiltroLecturas.filter(A=> A._id == this.inputLectura)[0];
    this.inputTitulo = datos.TITULO;
    this.inputTexto = datos.TEXTO;
    $('#modalaso').removeClass('hidden');
    this.getClasificacionPalabras();
  }
  getFiltroLecturas(){
    if(this.inputCurso != "" && this.inputProcedencia != ""){
      this.FiltroLecturas = this.Lecturas.filter(A=> A.CURSO.includes(this.inputCurso));
      this.FiltroLecturas.sort(function(a, b) {
        return a.PRIORIDAD - b.PRIORIDAD;
      });
    }
  }
  async getLecturasByProcedencia(){
    $('#modalaso').removeClass('hidden');
    return new Promise(resolve=>{
      this.http.post<any>(this.url_api+'juegoeduca/get/textos_procedencia/',JSON.stringify({PROCEDENCIA: this.inputProcedencia})).subscribe(response => {
        this.Lecturas = response.textos;
        this.Cursos = this.ordenarCursos(response.cursos);
        $('#modalaso').addClass('hidden');
        resolve(response);
      }, response_err=>{
        resolve(response_err.error)
      });
    });
  }
  ordenarCursos(cursos) {
    cursos.sort((a, b) => {
      return this.ORDEN_CURSO.indexOf(a) - this.ORDEN_CURSO.indexOf(b);
    });
    return cursos;
  }
  async getLecturas(){
    return new Promise(resolve=>{
      this.http.get<any>(this.url_api+'juegoeduca/get/procedencias_textos').subscribe(response => {
        this.Procedencias = response;
        resolve(response);
      }, response_err=>{
        resolve(response_err.error)
      });
    });
  }
  SeleccionarPalabras(){
    if(this.TipoPalabra == "LECTURAS" || this.TipoPalabra == "PERSONALIZADO-TEXTO"){
        this.getPalabrasLectura();
    }else{
      this.getPalabrasCategoria();
    }
  }
  cambiarMayuscula(val){
    if(this.FuenteActual == "Ligada" && val == "mayuscula"){
      this.alerta.fire({
        title: 'La fuente Escolar Ligada solo puede usarse en minúscula',
        icon: 'warning',
        showDenyButton: false,
        showCancelButton: false,
        confirmButtonText: "Ok"
      });
    }else{
      this.isMayuscula = val;
    }  
  }
  getPalabrasCategoria(){
    if(this.CategoriaActual != "Seleccione"){
      let palabras = this.CategoriasPalabras.filter(A=> A.CATEGORIA == this.CategoriaActual)[0].PALABRAS;
      palabras.sort(function () {
        return Math.random() - 0.5;
      });
      palabras = palabras.filter(A=> A.length > 3).slice(0,this.CANTIDAD);
      let listado = [];
      for (let index = 0; index < palabras.length; index++) {
        listado.push({
          PALABRA: palabras[index],
          INDEX: index
        });
      }
      listado.sort(function () {
        return Math.random() - 0.5;
      });
      this.ListadoPalabras = listado;
      this.destacarBoton();
      $('#modalaso').addClass('hidden');      
    }
  }
  ingresarPalabras(){
    if(this.ListadoPalabras.length < 15){
      $('#modal-ingreso-palabras').modal({
          backdrop: 'static',
          keyboard: false
        });
    }else{
      this.alerta.fire({
        title: 'La cantidad máxima de palabras es de 15.',
        icon: 'warning',
        showDenyButton: false,
        showCancelButton: false,
        confirmButtonText: "Ok"
      });
    }
  }
  ingresarLectura(){
    $('#modal-ingreso-lectura').modal({
          backdrop: 'static',
          keyboard: false
        });
  }
  contador_palabras:any = "Cantidad de palabras: 0";
  validar_cantidad(){
    let cantidad = $('input[name="LECTURA-TITULO"]').val().trim().split(" ").filter(A => A).length + $('textarea[name="LECTURA-TEXTO"]').val().trim().split(" ").filter(A => A).length;
    this.contador_palabras = "Cantidad de palabras: "+cantidad;
  }
  Siguiente(){
    this.ListadoPalabras = [];
    this.agregarLectura();
  }
  Atras(){
    $('#PasoLectura_1').removeClass('hidden');
    $('#PasoLectura_2').addClass('hidden');
  }
  procesarPalabrasTextosBD(listado){
    this.ListadoTexto = [];
    $('#modal-ingreso-lectura-bd').modal({
          backdrop: 'static',
          keyboard: false
        });
    $('#SPAN-TITULO-BD').empty();
    $('#SPAN-TEXTO-BD').empty();
    let titulo = this.inputTitulo.replaceAll("\n"," ").split(" ").filter(A=> A);
    let texto = this.inputTexto.replaceAll("\n"," ").split(" ").filter(A=> A);
    let indexPalabra = 0;
    let html_titulo = "";
    for (let x = 0; x < titulo.length; x++) {
      const palabra = titulo[x];
      html_titulo += '<span class="palabra-texto" id="palabra-'+indexPalabra+'" style="cursor:pointer">'+palabra+"</span>"+"&nbsp;";
      indexPalabra++;
    }
    let html_texto = "";
    for (let y = 0; y < texto.length; y++) {
      const palabra = texto[y];
      html_texto += '<span class="palabra-texto" id="palabra-'+indexPalabra+'" style="cursor:pointer">'+palabra+"</span>"+"&nbsp;";
      indexPalabra++;
    }
    $('#SPAN-TITULO-BD').html(html_titulo);
    $('#SPAN-TEXTO-BD').html(html_texto);
    this.findInTexto('SPAN-TITULO-BD','SPAN-TEXTO-BD',listado);
  }
  findInTexto(titulo,texto,listado){
    const spans = document.querySelectorAll('#'+titulo+' span');
    const spans2 = document.querySelectorAll('#'+texto+' span');
    let contador_ingresos = 0;
    for (let z = 0; z < listado.length; z++) {
      let letra = listado[z];
      let spanEncontrado = null;
      spans2.forEach(span => {
        if (span.textContent.trim() === letra.PALABRA) {
          spanEncontrado = span;
          return; // Salir del bucle forEach una vez encontrado el span
        }
      });
      //AQUI OBTENER SPAN QUE CONTENGA LETRA INGRESADO
      const element = spanEncontrado;
      if(element && contador_ingresos < this.CANTIDAD){
        let index = element.id.replaceAll('palabra-','');
        element.style.background = '#1a9476';
        element.style.padding = '1px 5px';
        element.style.borderRadius = '3px';
        element.style.color = 'white';
        let letra_aux = letra.Text.trim();
        letra_aux = this.quitarCaracteres(letra_aux);
        this.ListadoTexto.push({PALABRA: letra_aux, INDEX : index});
        contador_ingresos++;
      }
    }
    $('.palabra-texto').on('click',function(event){
      GLOBAL.agregarPalabraTexto(event);
    })
    $('#modalaso').addClass('hidden');
  }
  procesarPalabrasTextosPERSONALIZADO(listado){
    this.ListadoTexto = [];
    $('#SPAN-TITULO').empty();
    $('#SPAN-TEXTO').empty();
    let titulo = this.inputTitulo.replaceAll("\n"," ").split(" ").filter(A=> A);
    let texto = this.inputTexto.replaceAll("\n"," ").split(" ").filter(A=> A);
    let indexPalabra = 1;
    let html_titulo = "";
    for (let x = 0; x < titulo.length; x++) {
      const palabra = titulo[x];
      html_titulo += '<span class="palabra-texto" id="palabra-'+indexPalabra+'" style="cursor:pointer">'+palabra+"</span>"+"&nbsp;";
      indexPalabra++;
    }
    let html_texto = "";
    for (let y = 0; y < texto.length; y++) {
      const palabra = texto[y];
      html_texto += '<span class="palabra-texto" id="palabra-'+indexPalabra+'" style="cursor:pointer">'+palabra+"</span>"+"&nbsp;";
      indexPalabra++;
    }
    $('#SPAN-TITULO').html(html_titulo);
    $('#SPAN-TEXTO').html(html_texto);
    this.findInTexto('SPAN-TITULO','SPAN-TEXTO',listado);
  }
  agregarPalabraTexto(objeto){
    let palabra = objeto.currentTarget.innerHTML;
    let index_palabra = objeto.currentTarget.id.replaceAll("palabra-","");
    palabra = this.quitarCaracteres(palabra);
    let index = -1;
    let mismas_palabras_index = [];
    for (let i = 0; i < this.ListadoTexto.length; i++) {
      if (this.ListadoTexto[i].PALABRA === palabra) {
        if(this.ListadoTexto[i].INDEX == index_palabra){
          index = i;
          break;
        }else{
          mismas_palabras_index.push(this.ListadoTexto[i].INDEX);
        }
      }
    }
    const element = document.getElementById(objeto.currentTarget.id);
    if (index !== -1) {
      // EXISTE LA PALABRA CON EL LMISMO INDEX LA SACA
      this.ListadoTexto.splice(index, 1);
      element.style.background = '';
      element.style.padding = '';
      element.style.borderRadius = '';
      element.style.color = '';
    } else {
      // NO EXISTE LA PALABRA CONSULTA SI YA HAY OTRA PALABRA IGUAL
      if(mismas_palabras_index.length == 0){
        if(this.ListadoTexto.length == 15){
          this.alerta.fire({
            title: 'La cantidad máxima de palabras es de 15.',
            icon: 'warning',
            showDenyButton: false,
            showCancelButton: false,
            confirmButtonText: "Ok"
          });
        }else{
          //NO HAY PALABRAS
          this.ListadoTexto.push({PALABRA: palabra, INDEX: objeto.currentTarget.id.replaceAll("palabra-","")});
          element.style.background = '#1a9476';
          element.style.padding = '1px 5px';
          element.style.borderRadius = '3px';
          element.style.color = 'white';
        }
      }else{
        this.alerta.fire({
          title: 'Ya está ingresada la palabra "'+palabra+'"',
          icon: 'warning',
          showDenyButton: false,
          showCancelButton: false,
          confirmButtonText: "Ok"
        });
      }
    }
  }
  agregarLectura(){
    let texto_total = $('input[name="LECTURA-TITULO"]').val().trim().split(" ").filter(A => A).length + $('textarea[name="LECTURA-TEXTO"]').val().trim().split(" ").filter(A => A).length;
    if($('input[name="LECTURA-TITULO"]').val().trim().length > 0 && $('textarea[name="LECTURA-TEXTO"]').val().trim().length > 0){
      if(texto_total > 30){
        $('#modalaso').removeClass('hidden');
        this.inputTitulo_original = $('input[name="LECTURA-TITULO"]').val();
        this.inputTexto_original = $('textarea[name="LECTURA-TEXTO"]').val();
        this.inputTitulo = $('input[name="LECTURA-TITULO"]').val().trim().replaceAll("\n"," ").replaceAll("  "," ");
        this.inputTexto = $('textarea[name="LECTURA-TEXTO"]').val().trim().replaceAll("\n"," ").replaceAll("  "," ");
        this.getClasificacionPalabras();
        $('#PasoLectura_1').addClass('hidden');
        $('#PasoLectura_2').removeClass('hidden');
      }else{
        this.alerta.fire({
          title: "El texto debe contener al menos 30 palabras",
          icon: 'warning',
          showDenyButton: false,
          showCancelButton: false,
          confirmButtonText: "Ok"
        });
      }
    }else{
      this.alerta.fire({
        title: "Complete los campos para continuar",
        icon: 'warning',
        showDenyButton: false,
        showCancelButton: false,
        confirmButtonText: "Ok"
      });
    }
  }
  cambioSopa(){
    if(this.SopaCreada){
      this.destacarBoton();
    }
  }
  LecturaProcesada(){
    this.ListadoPalabras = this.ListadoTexto;
    this.destacarBoton();
    $('#modal-ingreso-lectura').modal('hide');    
    $('#CANTIDAD').val(this.ListadoTexto.length);
  }
  LecturaProcesadaBD(){
    this.ListadoPalabras = this.ListadoTexto;
    this.destacarBoton();
    $('#modal-ingreso-lectura-bd').modal('hide');    
    $('#CANTIDAD').val(this.ListadoTexto.length);
  }
  agregarPalabras(){
    let palabras_seteado = [];
    let palabras = $('#EXTRAS').val().split('\n');
    for (let index = 0; index < palabras.length; index++) {
      const linea = palabras[index].split(" ");
      for (let x = 0; x < linea.length; x++) {
        let palabra = linea[x];
        if(palabra){
          palabra = this.quitarCaracteres(palabra);
          if(!palabras_seteado.includes(palabra.toLowerCase())){
            palabras_seteado.push(palabra.toLowerCase());
          }
        }
      }
    }
    if((this.ListadoPalabras.length + palabras_seteado.length) <= 15){
      for (let x = 0; x < palabras_seteado.length; x++) {
        if(palabras_seteado[x].trim() != ""){
          let existe = this.ListadoPalabras.filter(A=> A.PALABRA.trim() === palabras_seteado[x].trim())[0];
          if(!existe){
            this.ListadoPalabras.push({PALABRA: palabras_seteado[x].trim()});
          }          
        }
      }      
      $('#EXTRAS').val("");
      $('#modal-ingreso-palabras').modal('hide');
    }else{
      let faltan = 15 - (this.ListadoPalabras.length);
      this.alerta.fire({
        title: "La cantidad máxima de palabras es de 15",
        text: "¿desea continuar con las primeras "+faltan+" palabras ingresadas?",
        icon: 'warning',
        showDenyButton: false,
        showCancelButton: false,
        confirmButtonText: "Continuar",
        cancelButtonText: "No"
      }).then((result) => {
        if (result.isConfirmed) {
          palabras_seteado = palabras_seteado.slice(0,faltan);
          for (let x = 0; x < palabras_seteado.length; x++) {
            if(palabras_seteado[x].trim() != ""){
              let existe = this.ListadoPalabras.filter(A=> A.PALABRA.trim() === palabras_seteado[x].trim())[0];
              if(!existe){
                this.ListadoPalabras.push({PALABRA: palabras_seteado[x].trim()});
              }              
            }
          }          
          $('#EXTRAS').val("");
          $('#modal-ingreso-palabras').modal('hide');
        }
      });
    }
  }
  changeCategoria(event){
    if(this.SopaCreada){
      this.alerta.fire({
        title: "¿Está seguro que desea hacer cambios?",
        text: "Se eliminará la sopa actual",
        icon: 'warning',
        showDenyButton: false,
        showCancelButton: false,
        confirmButtonText: "Continuar",
        cancelButtonText: "Cancelar"
      }).then((result) => {
        if (result.isConfirmed) {
          this.CategoriaActual = event.target.value;
          this.limpiarSopa();
          this.getPalabrasCategoria();
        }
      });
    }else{
      this.CategoriaActual = event.target.value;
      this.getPalabrasCategoria();
    }
  }
  limpiarSopa(){
    this.ListadoPalabras = [];
    this.LimpiarPuzzle();
    this.SopaCreada = false;
  }
  mensaje_info(texto){
    this.alerta.fire({
      title: texto,
      icon: 'info',
      showDenyButton: false,
      showCancelButton: false,
      confirmButtonText: "Ok"
    });
  }
  LimpiarPuzzle(){
    this.puzzle = [
      ["","","","","","","","","",""],
      ["","","","","","","","","",""],
      ["","","","","","","","","",""],
      ["","","","","","","","","",""],
      ["","","","","","","","","",""],
      ["","","","","","","","","",""],
      ["","","","","","","","","",""],
      ["","","","","","","","","",""],
      ["","","","","","","","","",""],
      ["","","","","","","","","",""]
    ];
  }
  LimpiarListado(){
    this.alerta.fire({
      title: "¿Está seguro que desea hacer cambios?",
      text: "Se eliminará la sopa actual",
      icon: 'warning',
      showDenyButton: false,
      showCancelButton: false,
      confirmButtonText: "Continuar",
      cancelButtonText: "Cancelar"
    }).then((result) => {
      if (result.isConfirmed) {
        this.ListadoPalabras = [];
        this.LimpiarPuzzle();
        this.SopaCreada = false;
        this.TipoPalabra = "Seleccione";
        this.inputProcedencia = "Seleccione";
        this.inputCurso = "Seleccione";
        this.inputLectura = "Seleccione";
        this.limpiarSopa();
      }
    });
  }
  @HostListener('window:resize', ['$event'])
  ajustarCeldas(){
    const elementosSilaba = document.querySelectorAll('.silaba');
    elementosSilaba.forEach((elementoSilaba: HTMLElement) => {
      const anchoEnPantalla = elementoSilaba.offsetWidth;
      elementoSilaba.style.height = anchoEnPantalla + "px";
    });
  }
  PalabrasSopa:any = [];
  async GenerarSopa(){
    this.audioClick.play();
    if(!this.isHorizontal && !this.isVertical && !this.isDiagonal && !this.isInvertidas){
      this.alerta.fire({
        title: "Debe activar al menos una orientación para la sopa",
        icon: 'warning',
        showDenyButton: false,
        showCancelButton: false,
        confirmButtonText: "Ok"
      });
      return;
    }
    if(this.ListadoPalabras.length == 0 && !this.SopaCreada){
      this.alerta.fire({
        title: "Debe ingresar al menos una palabra y crear sopa",
        icon: 'warning',
        showDenyButton: false,
        showCancelButton: false,
        confirmButtonText: "Ok"
      });
      return;
    }
    $('#modalaso').removeClass('hidden');
    setTimeout(() => {
      this.quitarDestacado();
      let orientations = [];
      if(this.isHorizontal){
        orientations.push("horizontal");
        if(this.isInvertidas){
          orientations.push("horizontalBack");
        }
      }
      if(this.isVertical){
        orientations.push("vertical");
      }
      if(this.isDiagonal){
        orientations.push("diagonal");
        orientations.push("diagonalUp");
        if(this.isInvertidas){
          orientations.push("diagonalBack");
          orientations.push("diagonalUpBack");
          orientations.push("verticalUp");
        }
      }
      let options = {
        allowExtraBlanks: false,
        allowedMissingWords: 0,
        width: Number(this.ANCHO),
        height: Number(this.ALTO),
        maxAttempts: 1,
        maxGridGrowth: 20,
        orientations: orientations
      };
      let array_palabras =  this.ListadoPalabras;
      this.PalabrasSopa = [];
      for (let x = 0; x < array_palabras.length; x++) {
        const palabra = array_palabras[x].PALABRA;
        this.PalabrasSopa.push(palabra);
      }
      if(this.tipoSopa){
        let puzzle = wordfind2.newPuzzle(this.PalabrasSopa, options);
        this.puzzle = puzzle[0];
        this.solucion = puzzle[1];
        this.cambiarDimensiones();
        if(puzzle[2].length > 0){
          this.avisoErrorSopa(puzzle[2]);
        }
      }else{
        let puzzle = wordfindsopa.newPuzzle(this.PalabrasSopa, options);
        this.puzzle = puzzle[0];
        this.solucion = puzzle[1];
        this.cambiarDimensiones();
        if(puzzle[2].length > 0){
          this.avisoErrorSopa(puzzle[2]);
        }
      }
      this.SopaCreada = true;
      this.api.set_kpi_plataformas('Sopa de Sílabas y Letras','Crea sopa de '+(this.tipoSopa ? 'sílabas' : 'letras'+', Tipo de Texto: '+$('#tipo_palabras option:selected').text()));
      setTimeout(() => {
        this.Findsolucion();
        this.ajustarCeldas();
      }, 10);
      setTimeout(() => {
        $('#modalaso').addClass('hidden');
      }, 200);
    }, 800);
  }
  COLORES:any =[
    '#FF4D0059',
  '#0059FF59',
  '#cfff3359',
  '#FF7F0059',
  '#7F00FF59',
  '#C7003959',
  '#FFC30059',
  '#00A70059',
  '#FFC3A059',
  '#00ff7f59',
  '#0057FF59',
  '#FF000059',
  '#FF7F0059',
  '#FFFF0059',
  '#00FF0059',
  '#00FF7F59',
  '#00FFFF59',
  '#007FFF59',
  '#0000FF59',
  '#7F00FF59',
  '#FF00FF59',
  '#FF007F59',
  '#FF450059',
  '#FF634759',
  '#FF8C0059',
  '#FFA50059',
  '#FFD70059',
  '#ADFF2F59',
  '#32CD3259',
  '#9400D359',
  '#80008059'];
  Findsolucion(){
    if(this.mostrarSolucion){
      let cont = 0;
      for (let palabra in this.solucion) {
        let silabas = this.solucion[palabra];
        for (let x = 0; x < silabas.length; x++) {
          const silaba = silabas[x];
          $('#span-'+silaba.x+"-"+silaba.y).css("background",this.COLORES[cont]);
        }
        cont++;
      }
    }else{
      $('.silaba').css("background","white");
    }
  }
  cambiarTipoSopa(val){
    this.tipoSopa = val;
    this.cambiarColor();
    if(this.ListadoPalabras.length > 0){
      this.GenerarSopa();
    }
  }
  cambiarDimensiones(){
    this.ANCHO = this.puzzle[0].length;
    this.ALTO = this.puzzle.length;
  }
  avisoErrorSopa(mensaje){
    this.alerta.fire({
      title: "¡Atención!",
      text: "Se adaptó el tamaño de la sopa a "+this.ANCHO+"x"+this.ALTO+" para las palabras ingresadas.",
      icon: 'warning',
      showDenyButton: false,
      showCancelButton: false,
      confirmButtonText: "Ok"
    });
  }
  modalPDF(){
    if(!this.SopaCreada){
      this.alerta.fire({
        title: "Debe generar la sopa de "+(this.tipoSopa ? 'sílabas' : 'letras'),
        icon: 'warning',
        showDenyButton: false,
        showCancelButton: false,
        confirmButtonText: "Ok"
      });
      return;
    }
    $('#modal-impresion').modal({
          backdrop: 'static',
          keyboard: false
        });
  }
  quitarDestacado(){
    this.claseBtn = 'btn-primary';
    $('#boton-generar').html('<i class="icon-spinner11" style="margin-right: 5px;font-size: 20px;"></i> Generar Sopa');
    $('#boton-generar').removeClass('pulso-css');
  }
  destacarBoton(){
    this.claseBtn = 'btn-verde';
    if(this.SopaCreada){
      $('#boton-generar').html('<i class="icon-spinner11" style="margin-right: 5px;font-size: 20px;"></i> Re-generar Sopa');
    }else{
      $('#boton-generar').html('<i class="icon-spinner11" style="margin-right: 5px;font-size: 20px;"></i> Generar Sopa');
    }
    $('#boton-generar').addClass('pulso-css');
  }
  setFechahoy(){
    console.log(this.fechaSeleccionada)
    $('#Fecha').val(this.fechaSeleccionada);
  }
  generatePDF() {
    $('#modalaso').removeClass('hidden');
    var fechaOriginal = $('#Fecha').val();
    if(fechaOriginal != ''){
      var partesFecha = fechaOriginal.split('-');
      var año = partesFecha[0];
      var mes = partesFecha[1];
      var día = partesFecha[2];
      fechaOriginal = día + '-' + mes + '-' + año;
    }else{
      fechaOriginal = '_______/_______/_______'
    }
    this.OPCIONES_ADICIONALES_PDF["Título"] = $('#Título').val();
    this.OPCIONES_ADICIONALES_PDF["Nombre"] = $('#Nombre').val();
    this.OPCIONES_ADICIONALES_PDF["Curso"] = $('#Curso').val();
    this.OPCIONES_ADICIONALES_PDF["Fecha"] = fechaOriginal;
    this.OPCIONES_ADICIONALES_PDF["Colegio"] = $('#Colegio').val();
    this.OPCIONES_ADICIONALES_PDF["Docente"] = $('#Docente').val();
    this.OPCIONES_ADICIONALES_PDF["Instrucciones"] = $('#Instrucciones').val();
    if(this.ListadoPalabras.length == 0 && !this.SopaCreada){
      this.alerta.fire({
        title: "Debe ingresar al menos una palabra y crear sopa",
        icon: 'warning',
        showDenyButton: false,
        showCancelButton: false,
        confirmButtonText: "Ok"
      });
      return;
    }
    const pdf = new jsPDF('p', 'px', this.TAMANIO);
    $('.silaba').css("background","white");
    const element = document.getElementById('sopaDeLetras');
    html2canvas(element).then((canvas) => {
      var pageHeight = pdf.internal.pageSize.height || pdf.internal.pageSize.getHeight();
      var pageWidth = pdf.internal.pageSize.width || pdf.internal.pageSize.getWidth();
      const imageData = canvas.toDataURL('image/png');
      const imgWidth = this.TAMANIO == 'letter' ? (this.isListado ? (this.ALTO > 10 ? 270 : 320) : 400) : 400;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const xOffsetImg = (pdf.internal.pageSize.getWidth() - imgWidth) / 2;
      pdf.addImage(imageData, 'PNG', xOffsetImg, 150, imgWidth, imgHeight);
      var ubicacion_y = 0;
      //OPCIONES ADICIONALES
      for(let x in this.OPCIONES_ADICIONALES_PDF){
        pdf.setFont("Helvetica");
        pdf.setFontSize(12);
        if(x == "Nombre" && this.checkNombre){
          pdf.text(x+":",(25),(50+ubicacion_y));
          if(this.OPCIONES_ADICIONALES_PDF[x] != ""){
            pdf.text(this.OPCIONES_ADICIONALES_PDF[x],(65),(50+ubicacion_y));
          }else{
            pdf.text("_______________________________________________________________________",(65),(50+ubicacion_y));
          }
          ubicacion_y = ubicacion_y + 15;
        }else if(x == "Curso" && this.checkCurso){
          pdf.text(x+":",(25),(50+ubicacion_y));
          if(this.OPCIONES_ADICIONALES_PDF[x] != ""){
            pdf.text(this.OPCIONES_ADICIONALES_PDF[x],(65),(50+ubicacion_y));
          }else{
            pdf.text("______________________________________",(65),(50+ubicacion_y));
          }
        }else if(x == "Fecha" && this.checkFecha){
          pdf.text(x+":",(25+240),(50+ubicacion_y));
          if(this.OPCIONES_ADICIONALES_PDF[x] != ""){
            pdf.text(this.OPCIONES_ADICIONALES_PDF[x],(65+240),(50+ubicacion_y));
          }else{
            pdf.text("_______________________",(65+240),(50+ubicacion_y));
          }
          ubicacion_y = ubicacion_y + 15;
        }else if(x == "Colegio" && this.checkColegio){
          pdf.text(x+":",(25),(50+ubicacion_y));
          if(this.OPCIONES_ADICIONALES_PDF[x] != ""){
            pdf.text(this.OPCIONES_ADICIONALES_PDF[x],(65),(50+ubicacion_y));
          }else{
            pdf.text("_______________________________________________________________________",(65),(50+ubicacion_y));
          }
          ubicacion_y = ubicacion_y + 15;
        }else if(x == "Docente" && this.checkDocente){
          pdf.text(x+":",(25),(50+ubicacion_y));
          if(this.OPCIONES_ADICIONALES_PDF[x] != ""){
            pdf.text(this.OPCIONES_ADICIONALES_PDF[x],(65),(50+ubicacion_y));
          }else{
            pdf.text("_______________________________________________________________________",(65),(50+ubicacion_y));
          }
          ubicacion_y = ubicacion_y + 15;
        }else if(x == "Instrucciones" && this.checkInstruccion){
          pdf.text(x+":",(25),(50+ubicacion_y));
          if(this.OPCIONES_ADICIONALES_PDF[x] != ""){
            if(this.OPCIONES_ADICIONALES_PDF[x].length > 80){
              let texto = this.splitText(this.OPCIONES_ADICIONALES_PDF[x]);
              pdf.text(texto[0],(85),(50+ubicacion_y));
              pdf.text(texto[1],(25),(50+ubicacion_y+15));
            }else{
              pdf.text(this.OPCIONES_ADICIONALES_PDF[x],(85),(50+ubicacion_y));
            }
            
          }else{
            pdf.text("___________________________________________________________________",(85),(50+ubicacion_y));
            pdf.text("_______________________________________________________________________________",(25),(50+ubicacion_y+15));
          }
          ubicacion_y = ubicacion_y + 15;
        }
      }
      if(this.isListado){
        if(this.tipoSopa){
          pdf.setFillColor(235, 243, 250);
        }else{
          pdf.setFillColor(255, 238, 238);
        }
        pdf.rect(0, this.TAMANIO == 'letter' ? 480 : 570, pdf.internal.pageSize.getWidth(), (this.TAMANIO == 'letter' ? 150 : 200), 'F');
        pdf.setFontSize(15);
        let texto = 'Listado de Palabras:';
        var fontSize = 15; // Tamaño de la fuente
        var textoWidth = pdf.getStringUnitWidth(texto) * fontSize / pdf.internal.scaleFactor;
        // Calcular la posición x para centrar el texto
        var x = (pdf.internal.pageSize.getWidth() - textoWidth) / 2;

        pdf.text('Listado de Palabras:', x, (this.TAMANIO == 'letter' ? 500 : 600));
        pdf.setFontSize(13);
        let y_inicial = this.TAMANIO == 'letter' ? 520 : 630;
        let x_inicial = this.TAMANIO == 'letter' ? 20 : 20;
        let limite = this.TAMANIO == 'letter' ? 3 : 4;
        let contador = 0;
        for (let x = 0; x < this.ListadoPalabras.length; x++) {
          pdf.text((x+1)+".- "+this.ListadoPalabras[x].PALABRA, x_inicial, y_inicial);
          y_inicial += 15;
          contador++;
          if(contador == limite){
            y_inicial = this.TAMANIO == 'letter' ? 520 : 630;
            x_inicial += 85;
            contador = 0;
          }
        }
      }
      for(let i = 0; i < pdf.internal.getNumberOfPages(); i++) {
        pdf.setPage(i); 
        var pag_actual = pdf.internal.getCurrentPageInfo().pageNumber;
        pdf.setTextColor(0, 0, 0);
        pdf.setFont('Helvetica', 'bold');
        pdf.setFontSize(15);
        if(this.checkTitulo){
          pdf.text(this.OPCIONES_ADICIONALES_PDF["Título"], pageWidth / 2, 30, {align: 'center'});
        }
        pdf.setFont('Helvetica');
        pdf.setFontSize(8);
        pdf.setTextColor(117, 115, 109);
        pdf.setDrawColor(0, 0, 0).setLineWidth(0.1).line(25, (pageHeight-30), 430, (pageHeight-30));
        pdf.text("Sopa de "+(this.tipoSopa ? 'Sílabas' : 'Letras'), 25, pageHeight-20);
        pdf.text("www.integritic.cl", 150, pageHeight-20);
        pdf.text("soporte@integritic.cl", 210, pageHeight-20);
        pdf.text("+56932390405", 280, pageHeight-20);
        pdf.text("Página "+(pag_actual)+"/"+pdf.internal.getNumberOfPages(), 400, pageHeight-20);
      }
      pdf.save(this.OPCIONES_ADICIONALES_PDF["Título"].replaceAll(' ','_')+'.pdf');
      // const pdfData = pdf.output('blob');
      // const fileURL = URL.createObjectURL(pdfData);

      // window.open(fileURL, '_blank');
      if(this.mostrarSolucion){
        this.Findsolucion();
      }
      $('#modal-impresion').modal('hide');
      $('#modalaso').addClass('hidden');
      this.api.set_kpi_plataformas('Sopa de Sílabas y Letras','Genera PDF de sopa de '+(this.tipoSopa ? 'sílabas' : 'letras'+', Tamaño: '+$('#TAMANIO option:selected').text()));
    });
  }  
  cambiaFuente(){
    this.api.set_kpi_plataformas('Sopa de Sílabas y Letras','Cambia Fuente de '+(this.tipoSopa ? 'sílabas' : 'letras'+', '+$('#FuenteActual option:selected').text()));
  }
  splitText(text: string, maxChars: number = 80): string[] {
    const words = text.split(' ');
    const lines: string[] = [];
    let currentLine = '';

    for (const word of words) {
      if ((currentLine + ' ' + word).trim().length <= maxChars) {
        currentLine = currentLine ? currentLine + ' ' + word : word;
      } else {
        lines.push(currentLine);
        currentLine = word;
      }
    }

    if (currentLine) {
      lines.push(currentLine);
    }

    return lines;
  }
  validarNumero(value,event,min,max){
    if(event.target.value > max){
      this.alerta.fire({
        title: "El máximo permitido es de "+max,
        icon: 'warning',
        showDenyButton: false,
        showCancelButton: false,
        confirmButtonText: "Ok"
      });
      event.target.value = max;
      this[value] = max;
    }
    if(event.target.value < min){
      this.alerta.fire({
        title: "El mínimo permitido es de "+min,
        icon: 'warning',
        showDenyButton: false,
        showCancelButton: false,
        confirmButtonText: "Ok"
      });
      event.target.value = min;
      this[value] = min;
    }
  }
  cambiarColor(): void {
    if(this.tipoSopa){
      this.COLOR_BORDES = "#4492cc";
      this.COLOR_FONDO = "#ebf3fa";
    }else{
      this.COLOR_BORDES = "#d94f4f";
      this.COLOR_FONDO = "#ffeeee";
    }
    this.actualizarColor();
  }
  private actualizarColor(): void {
    // Accedemos al estilo del componente
    const componenteStyle = this.elementRef.nativeElement.style;
    // Actualizamos la variable CSS
    componenteStyle.setProperty('--color-borde', this.COLOR_BORDES);
    componenteStyle.setProperty('--color-fondo', this.COLOR_FONDO);
    $("body").css({"background": this.COLOR_FONDO});
    
  }
}