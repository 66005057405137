import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../inicio/services/global.service';
import Phaser from 'phaser';
import { ApiService } from 'src/app/inicio/services/api.service';
import { interval } from 'rxjs'; // Importa interval desde RxJS
import { Router } from '@angular/router';
declare var Howl: any;
declare var $: any;

var PantallaPresentacionGlobal;
var ContenedorGeneralGlobal;
var ContenedorCronometroGlobal;
var Principal;
@Component({
  selector: 'app-temporizador-emojis',
  templateUrl: './temporizador-emojis.component.html',
  styleUrls: ['./temporizador-emojis.component.css']
})
export class TemporizadorEmojisComponent implements OnInit {
  phaserGame: any;
  config: Phaser.Types.Core.GameConfig;
  constructor( public global:GlobalService, public api:ApiService,private router: Router){ 
    Principal = this;
    this.config = {
      type: Phaser.CANVAS,
      roundPixels: true,
      scale: {
        width: 1920,
        height: 900,
        parent: 'gameContainer',
        fullscreenTarget: 'gameContainer',
        mode: Phaser.Scale.FIT,
        autoCenter: Phaser.Scale.CENTER_BOTH,
      },
      dom: {
          createContainer: true
      },
      backgroundColor: '#ffffff',
      scene: [PantallaPresentacion,ContenedorGeneral]
    };
    this.iniciar();
  }
  
  enviar_atras(){
    window.parent.postMessage({ type: 'navigate', url: "RecursosApoyo", recurso: "Temporizador Emojis"}, '*');
  }
  atras_landing(){
    window.location.href = "/extras/temporizadores";
    // this.router.navigateByUrl("/extras/temporizadores", { skipLocationChange: false }).then(() => this.router.navigate(["/extras/temporizadores"]));
  }
  async iniciar(){
    setTimeout(()=>{
      $(".main-app").removeClass("hidden");
      $("#contenedor-principal").html(`<div id="gameContainer" style=" background: #eb8aca;"></div>`);
      this.phaserGame = new Phaser.Game(this.config);
    },1200)

    await this.global.offLoading(2500);
    $("body").css("background-color", "#eb8aca");
  }

  ngOnInit(): void {
    Principal.api.set_kpi_plataformas("Temporizador Emojis","Ingresó al módulo");
  }
}
class PantallaPresentacion extends Phaser.Scene {
  tiempo:any = {
    hora: 0,
    minutos: 3,
    segundos: 0
  };
  temporizador:any;
  Emitterconfetti:any;
  sonido_9:any;
  FondoCronometro:any;
  temporizadorGrafico:any;
  tiempoTranscurrido = 0;
  tiempoTotalSegundos:any = 20;
  ColorTiempo:any = 0x00d4ff;
  skinCambio:any;
  skinTiempo:any="skin_circulo";
  intervalos:any;
  audio_reloj = new Howl({
    src: "assets/audio/reloj_corto2.mp3",
    loop: true,
    volume: 1
  });
  pop:any;
  constructor() {
    super({ key: 'PantallaPresentacion' });
    PantallaPresentacionGlobal = this;
  }
  preload() {
    this.load.atlas('pedropedro','assets/assets/particles/pedropedro.png','assets/assets/particles/pedropedro.json')
    this.load.image('fondo_temporizador', 'assets/images/aplicativos/temporizador/fondo_temporizador.png');

    this.load.audio('a_jugar_sonido','assets/recursos/click.mp3');
    this.load.audio('skinCambio','assets/images/aplicativos/temporizador/skinCambio.mp3');
    this.load.image('base', 'assets/images/aplicativos/temporizador/fondo5.png');
    this.load.image('a_jugar', 'assets/images/aplicativos/temporizador/a_jugar_3.png');

    this.load.image('logos', 'assets/images/aplicativos/ruidometro/logos.png');
    this.load.image('ayuda', 'assets/images/aplicativos/ruidometro/ayuda.png');
    this.load.image('salir', 'assets/images/aplicativos/ruidometro/salir.png');

    this.load.atlas('confetti','assets/assets/particles/confetti.png','assets/assets/particles/confetti.json')
    this.load.audio('sonido_9', 'assets/images/aplicativos/temporizador/sonido_9.mp3');
    this.load.image('skin_circulo', 'assets/images/aplicativos/temporizador/fondo-circulo-2.png');
    this.load.image('skin_estrella', 'assets/images/aplicativos/temporizador/fondo-estrella-2.png');
    this.load.image('skin_rectangulo', 'assets/images/aplicativos/temporizador/fondo-rectangulo-2.png');
    this.load.image('skin_cuadrado', 'assets/images/aplicativos/temporizador/fondo-cuadrado-2.png');
    this.load.image('skin_corazon', 'assets/images/aplicativos/temporizador/fondo-corazon-2.png');
    this.load.image('skin_rombo', 'assets/images/aplicativos/temporizador/fondo-rombo.png');
    this.load.image('skin_emoji', 'assets/images/aplicativos/temporizador/fondo-emoji.png');
    this.load.image('skin_mano', 'assets/images/aplicativos/temporizador/fondo-mano.png');
    this.load.image('skin_bateria', 'assets/images/aplicativos/temporizador/bateria_verde.png');
    this.load.image('skin_bateria2', 'assets/images/aplicativos/temporizador/bateria_2_verde.png');
    this.load.image('menu_emoji', 'assets/images/aplicativos/temporizador/menu-emoji.png');
    this.load.image('menu_emoji_1', 'assets/images/aplicativos/temporizador/menu-emoji-1.png');
    this.load.image('menu_emoji_2', 'assets/images/aplicativos/temporizador/menu-emoji-2.png');
    this.load.image('menu_emoji_3', 'assets/images/aplicativos/temporizador/menu-emoji-3.png');
    this.load.image('menu_fiu', 'assets/images/aplicativos/temporizador/menu-fiu.png');
    this.load.image('menu_jiro', 'assets/images/aplicativos/temporizador/menu-jiro.png');
    this.load.image('menu_mano', 'assets/images/aplicativos/temporizador/menu-mano.png');
    this.load.image('menu_pedro', 'assets/images/aplicativos/temporizador/menu-pedro.png');
    this.load.image('menu_emoji2', 'assets/images/aplicativos/temporizador/menu-emoji2.png');
  this.load.audio('pop', 'assets/audio/pop_2.mp3');
  }
  resize (gameSize, baseSize, displaySize, resolution)
  {
    $("canvas").css({marginLeft:0,width:"100%",height:"auto", marginBottom: "-5px"});
  }
  create(){
    this.pop = this.sound.add('pop');
    this.skinCambio = this.sound.add('skinCambio');
    $("body, #gameContainer").css("background-color", "#eb8aca");
    this.sonido_9 = this.sound.add('sonido_9');
    this.scale.on('resize', this.resize, this);
    this.scale.on('orientationchange', (e: string) => {
      switch(e) {
          case 'portrait-primary':
              break;
          case 'landscape-primary':
              break;
          default:  
      }
      $("canvas").css({marginLeft:0,width:"100%",height:"auto", marginBottom: "-5px"});
    });

    this.scene.moveBelow(this, ContenedorGeneralGlobal);
    

    this.add.image(-2,0,"base").setOrigin(0).setScale(1).setAlpha(.7);
    let aumento_lateral = -260;
    let inicio = this.add.sprite(this.scale.width/2+aumento_lateral-140,650,"a_jugar").setOrigin(0.5).setScale(1).setAlpha(1).setInteractive({ useHandCursor: true });
    this.add.text(0+aumento_lateral,140, "Temporizador Visual\nEmojis" , {
      font: "bold 85px Arial",
      align: "center",
      color: "white",
      shadow: {offsetX: 5,offsetY: 5,color: 'black',blur: 1,stroke: true,fill: true},
      // backgroundColor: "red",
      fixedWidth: this.scale.width+aumento_lateral
    }).setVisible(true);

    this.add.text(0+aumento_lateral,350, "Timer que proporciona una representación\nvisual del tiempo utilizando Emojis.\nAtención: Si cambia de página cuando use un\ntemporizador, se detendrá." , {
      font: "bold 30px Arial",
      align: "center",
      color: "white",
      shadow: {offsetX: 2.5,offsetY: 2.5,color: 'black',blur: 1,stroke: true,fill: true},
      // backgroundColor: "red",
      fixedWidth: this.scale.width+aumento_lateral
    }).setVisible(true);

    let logos = this.add.sprite(this.scale.width/2+aumento_lateral-130,800,"logos").setOrigin(0.5).setScale(.5).setAlpha(1).setInteractive({ useHandCursor: true });
    let intLink =this.add.text(0+aumento_lateral,850, "Un producto con ❤ de integritic.cl" , {
      font: "20px Arial",
      align: "center",
      color: "white",
      shadow: {offsetX: 1.5,offsetY: 1.5,color: 'black',blur: 1,stroke: true,fill: true},
      // backgroundColor: "red",
      fixedWidth: this.scale.width+aumento_lateral
    }).setVisible(true).setInteractive({useHandCursor:true});

    intLink.on('pointerdown', function (pointer) {
      window.open("https://www.integritic.cl", '_blank');
    }, this);

    let ayuda = this.add.sprite(this.scale.width/2+aumento_lateral-130,530,"ayuda").setOrigin(0.5).setScale(.5).setAlpha(1).setInteractive({ useHandCursor: true });
    inicio.on('pointerdown', function (pointer) {
      this.sound.add('a_jugar_sonido').play();
      inicio.x = inicio.x - 5;
      inicio.setScale(.95);
      setTimeout(()=>inicio.setScale(1), 100);
      setTimeout(()=>inicio.x = inicio.x + 5, 100);
      this.scene.launch(ContenedorGeneralGlobal);
      setTimeout(()=>{
        this.scene.remove(PantallaPresentacionGlobal);
        $("body, #gameContainer").css("background-color", "#ffffff");
      }, 500);
      this.intervalos.unsubscribe();
    }, this);

    ayuda.on('pointerdown', function (pointer) {
      ayuda.x = ayuda.x - 5;
      ayuda.setScale(.48);
      setTimeout(()=>ayuda.setScale(.5), 100);
      setTimeout(()=>ayuda.x = ayuda.x + 5, 100);
      Principal.global.alerta.fire({
        width: "500",
        title: 'Instrucciones:',
        html: `<div style="font-size: 20px;">Podrás seleccionar entre varios tipos de emojis.</div>`,
        text: ``
      });
    }, this);
    this.crearImagenes();
    this.intervalos = interval(2000).subscribe(() => {
    });
    this.anims.create({
      key: 'pedropedro_anim', // Nombre de la animación
      frames: this.anims.generateFrameNames('pedropedro'), // Generar los nombres de los frames desde el atlas 'pedropedro'
      frameRate: 15, // Velocidad de la animación en fotogramas por segundo
      repeat: 0 // -1 para que la animación se repita infinitamente
    });
    $('#modal-temp').addClass('hidden');
  }
  indiceActual = 0;
  valores = ["skin_rombo","skin_circulo","skin_estrella","skin_cuadrado"];
  getNumeros(numImagenes){
    return [3,.8,1000,140];
  }
  imagenesContenedor:any = [];
  crearImagenesEnRectangulo(scene) {
    let numImagenes = this.tiempoTotalSegundos - Math.floor(this.tiempoTranscurrido);
    let datos_numeros = this.getNumeros(numImagenes);
    let x = datos_numeros[2];
    let y = datos_numeros[3];
    let width = this.scale.width - 10;
    let height = this.scale.height - 370;
    let filas = Math.ceil(Math.sqrt(numImagenes));
    let columnas = Math.ceil(numImagenes / filas);
    let areaWidth = width / columnas;
    let areaHeight = height / filas;
    let margen = 5;
    let posX = x;
    let posY = y;
    let contador = 0;
    let cont_img = 0;
    let imgs = ["menu_emoji","menu_emoji_1","menu_emoji_2","menu_emoji_3","menu_fiu","menu_jiro","menu_mano","menu_pedro","menu_emoji2"];
    for (let i = 0; i < numImagenes; i++) {
      if (i < numImagenes) {
        let aux_img = this.skinTiempo;
        let image = scene.add.image(0, 0, imgs[cont_img]);                
        image.setScale(datos_numeros[1]);           
        let imagenAncho = image.displayWidth;
        let imagenAlto = image.displayHeight;  
        let imgPosX = posX + (areaWidth - imagenAncho) / 2;
        let imgPosY = posY + (areaHeight - imagenAlto) / 2;
        image.setPosition(imgPosX, imgPosY);
        if (contador == datos_numeros[0]) {
          contador = 0;
          posY += imagenAlto + margen;
          posX = x;
        } else {
          contador++;
          posX += imagenAncho + margen;
        }
        if(cont_img == 8){
          cont_img = 0;
        }else{
          cont_img++;
        }
        this.imagenesContenedor.push(image);
      }
    }
  }
  crearImagenes(){
    this.eliminarImagenes();
    this.imagenesContenedor = [];
    this.crearImagenesEnRectangulo(this);
  }
  eliminarImagen() {    
    for (let i = this.imagenesContenedor.length - 1; i >= 0; i--) {
      if (this.imagenesContenedor[i]) {            
        this.imagenesContenedor[i].destroy();            
        this.imagenesContenedor[i] = null;
        return; 
      }
    }
  }
  eliminarImagenes() {    
    for (let i = this.imagenesContenedor.length - 1; i >= 0; i--) {
      if (this.imagenesContenedor[i]) {            
        this.imagenesContenedor[i].destroy();            
        this.imagenesContenedor[i] = null;
      }
    }    
    this.imagenesContenedor = []; 
  }
  tiempoAcumulado:any = 0;
  update(tiempo, delta){    
    this.tiempoTranscurrido += delta / 1000;    
    let restante = this.tiempoTotalSegundos - Math.floor(this.tiempoTranscurrido);
    if(restante == 0){
      this.tiempoTotalSegundos = 20;
      this.tiempoTranscurrido = 0;
      this.crearImagenes();
    }
    this.tiempoAcumulado += delta / 1000;
    if (this.tiempoAcumulado >= 1) {
      this.pop.play();
      this.eliminarImagen();
      this.tiempoAcumulado = 0;
    }
  }
  crearMascaraCircular(graphics, x, y, radius) {
    graphics.beginPath();
    graphics.fillStyle(0xffffff,0); // Color de la máscara
    graphics.arc(x, y, radius, 0, Math.PI * 2, false); // Dibuja un arco completo para crear un círculo
    graphics.fillPath();
  }
  // Crear una máscara con forma de estrella
  crearMascaraEstrella(graphics, x, y, innerRadius, outerRadius, points) {
    graphics.beginPath();
    var angle = Math.PI / points;

    for (var i = 0; i < points * 2; i++) {
        var radius = i % 2 === 0 ? outerRadius : innerRadius;
        var currX = x + Math.cos(i * angle - Math.PI / 2) * radius; // Resta Math.PI / 2 para enderezar la estrella
        var currY = y + Math.sin(i * angle - Math.PI / 2) * radius; // Resta Math.PI / 2 para enderezar la estrella

        if (i === 0) {
            graphics.moveTo(currX, currY);
        } else {
            graphics.lineTo(currX, currY);
        }
    }

    graphics.closePath();
    graphics.fillPath();
  }
  efectoConfetti(){
    this.Emitterconfetti = this.add.particles('confetti').createEmitter({
      frame: ['efect_1.png','efect_2.png','efect_3.png','efect_4.png','efect_5.png','efect_6.png'],
      x: this.scale.width/2-400,
      y: -50,
      rotate: { start: 0, end: 360, ease: 'Back.easeOut' },
      alpha: {min:75, max: 100},
      lifespan: 3000,
      angle: {min: 0, max: 360},
      speed: { min : 100, max: 200},
      scale: {start: 0.8, end: 0},
      gravityY: 200
    });
    setTimeout(() => {
      this.Emitterconfetti.stop();
    }, 2000);
  }
  enviar_atras(){
    window.parent.postMessage({ type: 'navigate', url: "RecursosApoyo", recurso: "Ruidómetro"}, '*');
  }
}
class ContenedorGeneral extends Phaser.Scene {

  constructor() {
    super({ key: 'ContenedorGeneral' });
    ContenedorGeneralGlobal = this;   
  }
  audio_conteo = new Howl({
    src: "assets/audio/conteo3.mp3",
    volume: 1
  });
  audio_reloj = new Howl({
    src: "assets/audio/reloj_corto2.mp3",
    loop: true,
    volume: 0
  });
  reproduciendoEfecto = false;
  skinTiempo:any="menu_pedro";
  selectEl:any;
  timer:any;
  tiempo:any = {
    hora: 0,
    minutos: 0,
    segundos: 0
  };
  isIniciado:any = false;
  isPaused:any = true;
  isStarted:any = false;
  timer_1:any;
  timer_2:any;
  timer_3:any;
  timer_4:any;
  timer_5:any;
  timer_6:any;
  timer_7:any;
  timer_8:any;
  timer_personalizado:any;
  ColorTiempo:any = 0x00d4ff;
  ColorFondo:any = 0xffffff;//0xffffff;
  EstadoColorPicker: any = false;
  circuloTiempoColor:any;
  BotonEditarTiempo:any;
  circuloFondoColor:any;
  BotonEditarFondo:any;
  text_background:any;
  text_colores:any;
  ContenedorFondo:any;
  fondoMarco:any;
  borde_medio_temporizador:any;
  borde_fuera_temporizador:any;
  borde_medio2_temporizador:any;
  borde_interior_temporizador:any;
  pauseButton:any;
  playButton:any;
  restartButton:any;
  tortaProgreso: Phaser.GameObjects.Graphics; // Variable para el gráfico de la "torta"
  tiempoTotalSegundos: number = 0; // Tiempo total inicial en segundos
  tiempoRestanteSegundos: number = 0; // Tiempo restante en segundos
  BotonMasHora:any;
  BotonMenosHora:any;
  BotonMasMinutos:any;
  BotonMenosMinutos:any;
  BotonMasSegundos:any;
  BotonMenosSegundos:any;
  BotonPlayPrev:any;
  sonidoClick:any;
  sonidoEncendido:any;
  sonidoMenu:any;
  sonido_1:any;
  sonido_2:any;
  sonido_3:any;
  sonido_4:any;
  sonido_5:any;
  sonido_6:any;
  sonido_7:any;
  sonido_8:any;
  sonido_9:any;
  sonido_10:any;
  sonido_11:any;
  sonido_12:any;
  sonido_13:any;
  sonido_14:any;
  sonido_15:any;
  sonido_16:any;
  sonido_17:any;
  pop:any;
  sonidoBomba:any;
  BotonSonido:any;
  BotonPantalla:any;
  estadoPantalla:any = false;
  estadoSonido:any = true;
  estadoSonidoPrev:any = true;
  sonidoActual:any = 'sonido_14';
  efectoActual:any = 'pedropedro';
  Emitterconfetti:any;
  EmitterEmoji:any;
  BotonEfecto_1:any;
  BotonEfecto_2:any;
  BotonEfecto_3:any;
  BotonEfecto_4:any;
  BotonEfecto_5:any;
  BotonMenu:any;
  EstadoMenu:any = true;
  elementosMoviles:any;
  marcoFondo:any;
  marcoConfiguracion:any;
  marcoBomba:any;
  texto_tiempo:any;
  efecto:any;
  sonido:any;
  colorSelector:any;
  bordeSelector:any;
  btnCerrar:any;
  btncolorSelector:any;
  containerCambioColor:any;
  containerMenu:any;
  containerEmojis:any;
  containerCronometro:any;
  containerModal:any;
  bordeCurva:any;
  rellenoCurva:any;
  chispa:any;
  dibujoCurva:any;
  t = 0;
  bomba:any;
  text_temporizador:any;
  tipo_temporizador_1:any;
  tipo_temporizador_2:any;
  tipo_temporizador_3:any;
  tipo_temporizador_4:any;  
  tipo_temporizador_5:any;
  tipo_temporizador_6:any;
  tipo_temporizador_7:any;
  tipo_temporizador_8:any;
  tipo_temporizador_9:any;
  tipo_temporizador_10:any;
  temporizadorTexto;
  BordeCronometro;
  FondoCronometro;
  BordeCronometroTexto;
  temporizadorGrafico;
  manecillaReloj;
  tiempoTranscurrido = 0;
  temporizadorCorriendo = false;
  tiempoPausado = 0;
  modalTiempo:any;
  estadoTiempo:any;
  backDrop:any;
  switch_fondo:any;
  switch_activado:any;
  switch_desactivado:any;
  switch_icono:any;
  EstadoCronometro:any = false;
  EstadoAudioReloj:any = false;
  EstadoAudioConteo:any = false;
  AudioActual:any;
  BordeEmojis:any;
  startTimeGlobal: number;
  pausedTime: number;
  elapsedTime:number;
  preload() {
    this.load.image('sprite_1', 'assets/assets/particles/sprite_1.png');
    this.load.image('sprite_2', 'assets/assets/particles/sprite_2.png');
    this.load.image('sprite_3', 'assets/assets/particles/sprite_3.png');
    this.load.image('sprite_4', 'assets/assets/particles/sprite_4.png');
    this.load.image('sprite_5', 'assets/assets/particles/sprite_5.png');
    this.load.image('sprite_6', 'assets/assets/particles/sprite_6.png');
    this.load.image('sprite_7', 'assets/assets/particles/sprite_7.png');

    // this.load.image('switch_fondo', 'assets/images/aplicativos/temporizador/switch-fondo-3.png');
    this.load.image('switch_activado', 'assets/images/aplicativos/temporizador/switch-activado-3.png');
    this.load.image('switch_desactivado', 'assets/images/aplicativos/temporizador/switch-desactivado-3.png');
    this.load.image('switch_icono', 'assets/images/aplicativos/temporizador/switch-icono-3.png');

    this.load.image('explosion_1', 'assets/assets/particles/explosion_1.png');
    this.load.image('explosion_2', 'assets/assets/particles/explosion_2.png');
    this.load.image('explosion_3', 'assets/assets/particles/explosion_3.png');
    this.load.image('explosion_4', 'assets/assets/particles/explosion_4.png');
    this.load.image('explosion_5', 'assets/assets/particles/explosion_5.png');
    this.load.image('explosion_6', 'assets/assets/particles/explosion_6.png');
    this.load.image('explosion_7', 'assets/assets/particles/explosion_7.png');
    this.load.image('explosion_8', 'assets/assets/particles/explosion_8.png');
    this.load.image('explosion_9', 'assets/assets/particles/explosion_9.png');
    this.load.atlas('pedropedro','assets/assets/particles/pedropedro.png','assets/assets/particles/pedropedro.json')
    this.load.image('sprite', 'assets/images/aplicativos/temporizador/btn-1-3.png');
    this.load.image('btn_1', 'assets/images/aplicativos/temporizador/btn-1-3.png');
    this.load.image('btn_2', 'assets/images/aplicativos/temporizador/btn-2-3.png');
    this.load.image('btn_3', 'assets/images/aplicativos/temporizador/btn-3-3.png');
    this.load.image('btn_4', 'assets/images/aplicativos/temporizador/btn-4-3.png');
    this.load.image('btn_5', 'assets/images/aplicativos/temporizador/btn-5-3.png');
    this.load.image('btn_6', 'assets/images/aplicativos/temporizador/btn-6-3.png');
    this.load.image('btn_7', 'assets/images/aplicativos/temporizador/btn-7-3.png');
    this.load.image('btn_8', 'assets/images/aplicativos/temporizador/btn-8-3.png');
    this.load.image('timer_personalizado', 'assets/images/aplicativos/temporizador/btn-editar-3.png');
    this.load.audio('sonido_1', 'assets/images/aplicativos/temporizador/sonido_1.mp3');
    this.load.audio('sonido_2', 'assets/images/aplicativos/temporizador/sonido_2.mp3');
    this.load.audio('sonido_3', 'assets/images/aplicativos/temporizador/sonido_3.mp3');
    this.load.audio('sonido_4', 'assets/images/aplicativos/temporizador/sonido_4.mp3');
    this.load.audio('sonido_5', 'assets/images/aplicativos/temporizador/sonido_5.mp3');
    this.load.audio('sonido_6', 'assets/images/aplicativos/temporizador/sonido_6.mp3');
    this.load.audio('sonido_7', 'assets/images/aplicativos/temporizador/sonido_7.mp3');
    this.load.audio('sonido_8', 'assets/images/aplicativos/temporizador/sonido_8.mp3');
    this.load.audio('sonido_9', 'assets/images/aplicativos/temporizador/sonido_9.mp3');
    this.load.audio('sonido_10', 'assets/images/aplicativos/temporizador/sonido_10.mp3');
    this.load.audio('sonido_11', 'assets/images/aplicativos/temporizador/sonido_11.mp3');
    this.load.audio('sonido_12', 'assets/images/aplicativos/temporizador/sonido_12.mp3');
    this.load.audio('sonido_13', 'assets/images/aplicativos/temporizador/sonido_13.mp3');
    this.load.audio('sonido_14', 'assets/images/aplicativos/temporizador/musica_pedro.mp3');
    this.load.audio('sonido_15', 'assets/images/aplicativos/temporizador/sonido_15.mp3');
    this.load.audio('sonido_16', 'assets/images/aplicativos/temporizador/sonido_16.mp3');
    this.load.audio('sonido_17', 'assets/images/aplicativos/temporizador/sonido_17.mp3');
    this.load.audio('sonidoBomba', 'assets/images/aplicativos/temporizador/efecto_bomba.mp3');
    this.load.audio('pop', 'assets/audio/pop_2.mp3');
    this.load.audio('sonidoClick', 'assets/recursos/click.mp3');
    this.load.audio('sonidoEncendido', 'assets/images/aplicativos/temporizador/sonido_encendido.mp3');
    this.load.audio('sonidoMenu', 'assets/recursos/clic_ver_mas.mp3');
    this.load.image('mask', 'assets/images/aplicativos/temporizador/mascara.png');
    this.load.image('img_sonido_1', 'assets/images/aplicativos/temporizador/sonido_1.png');
    this.load.image('img_sonido_2', 'assets/images/aplicativos/temporizador/sonido_2.png');
    this.load.image('img_sonido_3', 'assets/images/aplicativos/temporizador/sonido_3.png');
    this.load.image('img_sonido_4', 'assets/images/aplicativos/temporizador/sonido_4.png');
    this.load.image('img_sonido_5', 'assets/images/aplicativos/temporizador/sonido_5.png');
    this.load.image('img_sonido_6', 'assets/images/aplicativos/temporizador/sonido_6.png');
    this.load.image('img_sonido_7', 'assets/images/aplicativos/temporizador/sonido_7.png');

    this.load.image('emoji_1', 'assets/images/aplicativos/temporizador/em_1.png');
    this.load.image('emoji_2', 'assets/images/aplicativos/temporizador/em_2.png');
    this.load.image('emoji_3', 'assets/images/aplicativos/temporizador/em_3.png');
    this.load.image('emoji_4', 'assets/images/aplicativos/temporizador/em_4.png');
    this.load.image('emoji_5', 'assets/images/aplicativos/temporizador/em_5.png');
    this.load.image('emoji_6', 'assets/images/aplicativos/temporizador/em_6.png');
    this.load.image('emoji_7', 'assets/images/aplicativos/temporizador/em_7.png');
    this.load.image('emoji_8', 'assets/images/aplicativos/temporizador/em_8.png');
    this.load.image('emoji_9', 'assets/images/aplicativos/temporizador/em_9.png');
    this.load.image('emoji_10', 'assets/images/aplicativos/temporizador/em_10.png');
    this.load.image('emoji_11', 'assets/images/aplicativos/temporizador/em_11.png');
    this.load.image('emoji_0', 'assets/images/aplicativos/temporizador/em_12.png');
    this.load.image('mano', 'assets/images/aplicativos/temporizador/mano.png');


    this.load.image('bomb', 'assets/images/aplicativos/temporizador/bomb.png');

    this.load.image('BordeCronometro', 'assets/images/aplicativos/temporizador/cronometro-3.png');
    this.load.image('FondoCronometro', 'assets/images/aplicativos/temporizador/fondo-circulo-2.png');
    
    this.load.image('skin_circulo', 'assets/images/aplicativos/temporizador/fondo-circulo.png');
    this.load.image('skin_estrella', 'assets/images/aplicativos/temporizador/fondo-estrella.png');
    this.load.image('skin_rectangulo', 'assets/images/aplicativos/temporizador/fondo-rectangulo.png');
    this.load.image('skin_cuadrado', 'assets/images/aplicativos/temporizador/fondo-cuadrado.png');
    this.load.image('bateria', 'assets/images/aplicativos/temporizador/bateria.png');
    this.load.image('bateria_verde', 'assets/images/aplicativos/temporizador/bateria_verde.png');
    this.load.image('bateria_2', 'assets/images/aplicativos/temporizador/bateria_2.png');
    this.load.image('bateria_2_verde', 'assets/images/aplicativos/temporizador/bateria_2_verde.png');
    this.load.image('iniciar', 'assets/images/aplicativos/temporizador/iniciar-3.png');

    this.load.image('menu_emoji', 'assets/images/aplicativos/temporizador/menu-emoji.png');
    this.load.image('menu_emoji_1', 'assets/images/aplicativos/temporizador/menu-emoji-1.png');
    this.load.image('menu_emoji_2', 'assets/images/aplicativos/temporizador/menu-emoji-2.png');
    this.load.image('menu_emoji_3', 'assets/images/aplicativos/temporizador/menu-emoji-3.png');
    this.load.image('menu_fiu', 'assets/images/aplicativos/temporizador/menu-fiu.png');
    this.load.image('menu_jiro', 'assets/images/aplicativos/temporizador/menu-jiro.png');
    this.load.image('menu_mano', 'assets/images/aplicativos/temporizador/menu-mano.png');
    this.load.image('menu_pedro', 'assets/images/aplicativos/temporizador/menu-pedro.png');
    this.load.image('menu_emoji2', 'assets/images/aplicativos/temporizador/menu-emoji2.png');

    this.load.image('efecto_1', 'assets/images/aplicativos/temporizador/efecto_1_3.png');
    this.load.image('efecto_2', 'assets/images/aplicativos/temporizador/efecto_2_3.png');
    this.load.image('efecto_3', 'assets/images/aplicativos/temporizador/efecto_3_3.png');
    this.load.image('efecto_4', 'assets/images/aplicativos/temporizador/efecto_4_3.png');
    this.load.image('efecto_5', 'assets/images/aplicativos/temporizador/efecto_5_2.png');

    this.load.image('borde', 'assets/images/aplicativos/temporizador/borde-estilo-3.png');
    this.load.image('borde_configuracion', 'assets/images/aplicativos/temporizador/borde-configuracion.png');
    this.load.image('configuracion', 'assets/images/aplicativos/temporizador/configuracion.png');
    this.load.image('modalTiempo', 'assets/images/aplicativos/temporizador/modaltiempo-3.png');
    this.load.image('editar', 'assets/images/aplicativos/temporizador/editar-3.png');
    this.load.image('fondo_temporizador', 'assets/images/aplicativos/temporizador/fondo_temporizador.png');
    this.load.image('menu_abierto', 'assets/images/aplicativos/temporizador/menu_abierto_3.png');
    this.load.image('menu_cerrado', 'assets/images/aplicativos/temporizador/menu_cerrado_3.png');

    this.load.image('bordeTiempo', 'assets/images/aplicativos/temporizador/borde-color-tiempo-3.png');
    this.load.image('bordeFondo', 'assets/images/aplicativos/temporizador/borde-color-fondo-3.png');

    this.load.image('btn_pausa', 'assets/images/aplicativos/temporizador/btn-pausa-3.png');
    this.load.image('btn_play', 'assets/images/aplicativos/temporizador/btn-play-3.png');
    this.load.image('btn_replay', 'assets/images/aplicativos/temporizador/btn-replay-3.png');
    this.load.image('btn_mas', 'assets/images/aplicativos/temporizador/btn-mas-3.png');
    this.load.image('btn_menos', 'assets/images/aplicativos/temporizador/btn-menos-3.png');
    this.load.image('btn_suave', 'assets/images/aplicativos/temporizador/suave.png');
    this.load.image('btn_fuerte', 'assets/images/aplicativos/temporizador/fuerte.png');
    this.load.image('colorSelector', 'assets/images/aplicativos/temporizador/colorSelector2.png');
    this.load.image('btnCerrar', 'assets/images/aplicativos/temporizador/salir-3.png');
    this.load.image('sonido_activo', 'assets/images/aplicativos/temporizador/sonido_activo_3.png');
    this.load.image('sonido_mute', 'assets/images/aplicativos/temporizador/sonido_mute_3.png');

    this.load.image('ampliar', 'assets/images/aplicativos/temporizador/ampliar-3.png');
    this.load.image('no_ampliar', 'assets/images/aplicativos/temporizador/no_ampliar_3.png');
    this.load.atlas('confetti','assets/assets/particles/confetti.png','assets/assets/particles/confetti.json')
    this.load.atlas('emojis','assets/assets/particles/emojis.png','assets/assets/particles/emojis.json')
    this.load.atlas('efecto_chispa','assets/assets/particles/chispa.png','assets/assets/particles/chispa.json')
    this.load.atlas('efecto_bomba','assets/assets/particles/bomba.png','assets/assets/particles/bomba.json')
  }
  CargaAudios(){    
    this.sonido_1 = this.sound.add('sonido_1');
    this.sonido_2 = this.sound.add('sonido_2');
    this.sonido_3 = this.sound.add('sonido_3');
    this.sonido_4 = this.sound.add('sonido_4');
    this.sonido_5 = this.sound.add('sonido_5');
    this.sonido_6 = this.sound.add('sonido_6');
    this.sonido_7 = this.sound.add('sonido_7');
    this.sonido_8 = this.sound.add('sonido_8');
    this.sonido_9 = this.sound.add('sonido_9');
    this.sonido_10 = this.sound.add('sonido_10');
    this.sonido_11 = this.sound.add('sonido_11');
    this.sonido_12 = this.sound.add('sonido_12');
    this.sonido_13 = this.sound.add('sonido_13');
    this.sonido_14 = this.sound.add('sonido_14');
    this.sonido_15 = this.sound.add('sonido_15');
    this.sonido_16 = this.sound.add('sonido_16');
    this.sonido_17 = this.sound.add('sonido_17');
    this.pop = this.sound.add('pop');
    this.sonidoBomba = this.sound.add('sonidoBomba');
    this.sonidoClick = this.sound.add('sonidoClick');
    this.sonidoEncendido = this.sound.add('sonidoEncendido');
    this.sonidoMenu = this.sound.add('sonidoMenu');
  }
  formato_tiempo(number, length) {
    var str = '' + number;
    while (str.length < length) {
        str = '0' + str;
    }
    return str;
  }
  MasHora() {
    this.tiempo.hora++;
    if (this.tiempo.hora >= 24) {
        this.tiempo.hora = 0;
    }
    if(this.tiempo.hora == 1){
      this.tiempo.minutos = 0;
      this.tiempo.segundos = 0;
    }
    this.CambiarTiempoTotal();
  } 
  MenosHora() {
      this.tiempo.hora--;
      if (this.tiempo.hora < 0) {
          this.tiempo.hora = 23;
      }
      this.CambiarTiempoTotal();
  }
  MasMinuto() {
      this.tiempo.minutos++;
      if (this.tiempo.minutos >= 60) {
          this.tiempo.minutos = 0;
      }
      this.CambiarTiempoTotal();
  }
  MenosMinuto() {
    this.tiempo.minutos--;
    if (this.tiempo.minutos < 0) {
      this.tiempo.minutos = 59;
    }
    this.CambiarTiempoTotal();
  }
  MasSegundos() {
    this.tiempo.segundos++;
    if (this.tiempo.segundos >= 60) {
      this.tiempo.segundos = 0;
    }
    this.CambiarTiempoTotal();
  }
  MenosSegundos() {
    this.tiempo.segundos--;
    if (this.tiempo.segundos < 0) {
      this.tiempo.segundos = 59;
    }
    this.CambiarTiempoTotal();
  }
  updateTimeText() {
    var formattedTime = this.formatoTiempo(this.tiempoTotalSegundos - Math.floor(this.tiempoTranscurrido));
    this.temporizadorTexto.setText(formattedTime);
    this.BordeCronometroTexto.setText(formattedTime);
  }
  reproducirEfecto(sonido){
    if(this.estadoSonido){
      sonido.play(); 
      this.AudioActual = sonido;
    }
  }
  reproducirAudio(){
    if(ContenedorGeneralGlobal[this.sonidoActual]){
      if(this.estadoSonido && this.sonidoActual != 'efecto_1'){
        ContenedorGeneralGlobal[this.sonidoActual].play(); 
      }
    }   
  }
  resetTime() {
    this.tiempo.hora = 0;
    this.tiempo.minutos = 0;
    this.tiempo.segundos = 0;
    this.quitarAutomatico();
    this.tiempoTranscurrido = 0;
    this.tiempoTotalSegundos = 0;
    this.quitarEfectos();
    this.crearImagenes();
  }
  CambiarFondo(color){
    this.ColorFondo = color;
    this.ContenedorFondo.clear();
    this.circuloFondoColor.setFillStyle(this.ColorFondo);
    this.ContenedorFondo.fillStyle(this.ColorFondo, 1);
    this.ContenedorFondo.fillRoundedRect(0, 0, this.scale.width, this.scale.height, 0);
  }
  CambiarTiempo(color){
    this.ColorTiempo = color;
    this.circuloTiempoColor.setFillStyle(this.ColorTiempo);
  }
  CambiarTiempoTotal(){
    this.tiempoTotalSegundos = this.tiempo.segundos + this.tiempo.minutos*60 + this.tiempo.hora * 3600;
    this.tiempoTranscurrido = 0;
    this.crearImagenes();
  }
  CambiarAutomatico(button){
    this.timer_1.setAlpha(1); 
    this.timer_2.setAlpha(1); 
    this.timer_3.setAlpha(1); 
    this.timer_4.setAlpha(1); 
    this.timer_5.setAlpha(1); 
    this.timer_6.setAlpha(1); 
    this.timer_7.setAlpha(1); 
    this.timer_8.setAlpha(1); 
    button.setScale(.5).setAlpha(.5);
    setTimeout(() => {
      button.setScale(.6)
    }, 200);
    this.CambiarTiempoTotal();
  }
  CambiarSonido(button,sonido){
    button.setScale(.25).setAlpha(.5);
    setTimeout(() => {
      button.setScale(.3)
    }, 200);
    this.sonidoActual = sonido;
  }
  CambiarEfecto(button,efecto){
    this.BotonEfecto_1.setAlpha(1); 
    this.BotonEfecto_2.setAlpha(1); 
    this.BotonEfecto_3.setAlpha(1); 
    this.BotonEfecto_4.setAlpha(1); 
    this.BotonEfecto_5.setAlpha(1); 
    button.setScale(.2).setAlpha(.5);
    setTimeout(() => {
      button.setScale(.25)
    }, 200);
    this.efectoActual = efecto;
  }
  CambiarSkin(button,efecto){
    this.tipo_temporizador_1.setAlpha(1); 
    this.tipo_temporizador_2.setAlpha(1); 
    this.tipo_temporizador_3.setAlpha(1); 
    this.tipo_temporizador_4.setAlpha(1); 
    this.tipo_temporizador_6.setAlpha(1); 
    this.tipo_temporizador_7.setAlpha(1); 
    this.tipo_temporizador_8.setAlpha(1); 
    this.tipo_temporizador_9.setAlpha(1); 
    this.tipo_temporizador_10.setAlpha(1); 
    this.skinTiempo = efecto;
    button.setAlpha(.5);
    this.crearImagenes();
    if(this.skinTiempo == "menu_pedro"){
      this.BotonEfecto_5.emit('pointerdown');
      $("#tipo_sonido").val('sonido_14').trigger("change");
      this.sonidoActual = "sonido_14";
    }else{
      this.BotonEfecto_2.emit('pointerdown');
      $("#tipo_sonido").val('sonido_2').trigger("change");
      this.sonidoActual = "sonido_2";
    }
    Principal.api.set_kpi_plataformas("Temporizador Emojis","Cambia emoji: "+this.skinTiempo);
  }
  CambiarPrevisualizarSonido(){
    if(this.estadoSonidoPrev){
      this.BotonPlayPrev.setTexture("sonido_activo");
      this.estadoSonidoPrev = false;
    }else{
      this.BotonPlayPrev.setTexture("sonido_activo");
      this.estadoSonidoPrev = true;
      ContenedorGeneralGlobal[this.sonidoActual].stop(); 
    }
  }
  colorActualCambio:any;
  abrirColor(tipo,value){
    if(tipo == "ColorTiempo"){
      this.bordeSelector.setTexture("bordeTiempo");
    }else{
      this.bordeSelector.setTexture("bordeFondo");
    }
    if(this.colorActualCambio == tipo){
      if(this.EstadoColorPicker){
        // this.containerCambioColor.setVisible(false);
        // this.EstadoColorPicker = false;
      }else{
        this.containerCambioColor.setVisible(true);
        this.EstadoColorPicker = true;
      }
    }else{
      this.containerCambioColor.setVisible(true);
      this.EstadoColorPicker = true;
    }
    this.colorActualCambio = tipo;
  }
  cerrarColor(){
    this.containerCambioColor.setVisible(false);
    this.EstadoColorPicker = false;
  }
  abrir_modal(button){
    if(this.estadoTiempo){
      this.estadoTiempo = false;
      this.containerModal.setVisible(false);
    }else{
      this.estadoTiempo = true;
      this.containerModal.setVisible(true);
    }
    button.setScale(.55)
    setTimeout(() => {
      button.setScale(.65)
    }, 200);
  }
  cerrarModal(){
    var formattedTime = this.formatoTiempo(this.tiempoTotalSegundos - Math.floor(this.tiempoTranscurrido)).split(":");
    this.tiempo.segundos = Number(formattedTime[2]);
    this.tiempo.minutos = Number(formattedTime[1]);
    this.tiempo.hora = Number(formattedTime[0]);
    this.tiempoActual = this.tiempo.segundos + this.tiempo.minutos*60 + this.tiempo.hora * 3600;
    this.estadoTiempo = false;
    this.containerModal.setVisible(false);
    this.crearImagenes();
  }
  CambiarEstado(){
    if(this.estadoSonido){
      this.BotonSonido.setTexture("sonido_mute");
      this.estadoSonido = false;
    }else{
      this.BotonSonido.setTexture("sonido_activo");
      this.estadoSonido = true;
    }
    ContenedorGeneralGlobal[this.sonidoActual].stop(); 
  }
  getNombreSonido(sonido){
    switch(sonido) {
      case 'sonido_14': return "Pedro Music" ;break;
      case 'sonido_1': return "Mario Bross" ;break;
      case 'sonido_2': return "Fornite Victory" ;break;
      case 'sonido_3': return "Campana" ;break;
      case 'sonido_4': return "Triste" ;break;
      case 'sonido_5': return "Arpa" ;break;
      case 'sonido_6': return "Aplausos" ;break;
      case 'sonido_7': return "Risa Bebé" ;break;
      case 'sonido_8': return "Grillos" ;break;
      case 'sonido_9': return "Niños" ;break;
      case 'sonido_10': return "Horror" ;break;
      case 'sonido_11': return "Bocina Reggaeton" ;break;
      case 'sonido_12': return "Explosión Fuerte" ;break;
      case 'sonido_13': return "Explosión Suave" ;break;
      case 'sonido_15': return "Paz Mental" ;break;
      case 'sonido_16': return "Ecos del alma" ;break;
      case 'sonido_17': return "Armonía Interior" ;break;
      case 'efecto_1': return "Ninguno" ;break;
      default: return sonido; break;
    }
  }
  CambiarPantalla(){
    if(this.estadoPantalla){
      this.BotonPantalla.setTexture("ampliar");
      this.estadoPantalla = false;
    }else{
      this.BotonPantalla.setTexture("no_ampliar");
      this.estadoPantalla = true;
    }
    const element = document.documentElement; // Elemento HTML raíz
    if(!document.fullscreenElement){
      if(element.requestFullscreen){
        element.requestFullscreen();
      }
    }else{
      if(document.exitFullscreen){
        document.exitFullscreen();
      }
    }
  }
  quitarAutomatico(){
    this.timer_1.setAlpha(1); 
    this.timer_2.setAlpha(1); 
    this.timer_3.setAlpha(1); 
    this.timer_4.setAlpha(1); 
    this.timer_5.setAlpha(1); 
    this.timer_6.setAlpha(1); 
    this.timer_7.setAlpha(1); 
    // this.timer_8.setAlpha(1); 
  }
  resize (gameSize, baseSize, displaySize, resolution){
    $("canvas").css({marginLeft:0,width:"100%",height:"auto", marginBottom: "-5px"});
  }
  updateElapsedTime(): void {
    const currentTime = Date.now();
    this.elapsedTime = Math.floor((currentTime - this.startTimeGlobal) / 1000);
    // Actualizar el cronómetro en tu juego con this.elapsedTime
  }
  async create(){
    this.startTimeGlobal = Date.now();
    setInterval(() => {
      this.updateElapsedTime();
    }, 1000);
    this.scale.on('resize', this.resize, this);
    this.input.setDefaultCursor('pointer');
    this.CargaAudios();
    let min = 1630;
    let max = this.scale.width - 30;
    let posC1 = 180;
    let tamanioMarco = 720;
    this.ContenedorFondo = this.add.graphics();
    this.ContenedorFondo.fillStyle(0xffffff,1);//(0xffffff, 1);
    this.ContenedorFondo.fillRoundedRect(0, 0, this.scale.width, this.scale.height, 0);
    
    this.BordeEmojis = this.add.graphics();
    this.BordeEmojis.lineStyle(5, 0x39c460);
    this.BordeEmojis.strokeRect(20, 135, this.scale.width-40, this.scale.height - 370);
    this.marcoFondo = this.add.image(this.scale.width-210, this.scale.height - 120,"borde_configuracion").setInteractive();    
    this.containerMenu = this.add.container(0, 0);
    this.containerEmojis = this.add.container(0, 0);
    this.containerMenu.add(this.marcoFondo);
    const style = {
      fontSize: '20px',
      fontFamily: 'Arial',
      color: '#ffffff',
      backgroundColor: '#ff00ff'
    };
    let diferencia =  70;
    this.timer_1 = this.add.image(570 + diferencia,830,"btn_1").setScale(.62).setInteractive();
    this.timer_2 = this.add.image(660 + diferencia,830,"btn_2").setScale(.62).setInteractive();
    this.timer_3 = this.add.image(750 + diferencia,830,"btn_3").setScale(.62).setInteractive();
    this.timer_4 = this.add.image(840 + diferencia,830,"btn_4").setScale(.62).setInteractive();
    this.timer_5 = this.add.image(930 + diferencia,830,"btn_5").setScale(.62).setInteractive();
    this.timer_6 = this.add.image(1020 + diferencia,830,"btn_6").setScale(.62).setInteractive();
    this.timer_7 = this.add.image(1110 + diferencia,830,"btn_7").setScale(.62).setInteractive();
    this.timer_8 = this.add.image(1200 + diferencia,830,"timer_personalizado").setScale(.62).setInteractive();
    // this.timer_personalizado = this.add.image(1200 + diferencia,830,"timer_personalizado").setScale(.62).setInteractive();
    
    this.BotonSonido = this.add.sprite(this.scale.width - 135,57,"sonido_activo").setScale(.5).setInteractive();
    this.BotonSonido.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.CambiarEstado();
    });
    this.BotonPantalla = this.add.sprite(this.scale.width - 55,57,"ampliar").setScale(.5).setInteractive();
    this.BotonPantalla.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.CambiarPantalla();
    });
    this.timer_1.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.tiempoActual = 15;
      this.tiempo.hora = 0;
      this.tiempo.minutos = 0;
      this.tiempo.segundos = 15;
      this.CambiarAutomatico(this.timer_1);
    });

    this.timer_2.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.tiempoActual = 30;
      this.tiempo.hora = 0;
      this.tiempo.minutos = 0;
      this.tiempo.segundos = 30;
      this.CambiarAutomatico(this.timer_2);
    });

    this.timer_3.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.tiempoActual = 60;
      this.tiempo.hora = 0;
      this.tiempo.minutos = 1;
      this.tiempo.segundos = 0;
      this.CambiarAutomatico(this.timer_3);
    });

    this.timer_4.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.tiempoActual = 180;
      this.tiempo.hora = 0;
      this.tiempo.minutos = 3;
      this.tiempo.segundos = 0;
      this.CambiarAutomatico(this.timer_4);
    });

    this.timer_5.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.tiempoActual = 300;
      this.tiempo.hora = 0;
      this.tiempo.minutos = 5;
      this.tiempo.segundos = 0;
      this.CambiarAutomatico(this.timer_5);
    });

    this.timer_6.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.tiempoActual = 600;
      this.tiempo.hora = 0;
      this.tiempo.minutos = 10;
      this.tiempo.segundos = 0;
      this.CambiarAutomatico(this.timer_6);
    });

    this.timer_7.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.tiempoActual = 1800;
      this.tiempo.hora = 0;
      this.tiempo.minutos = 30;
      this.tiempo.segundos = 0;
      this.CambiarAutomatico(this.timer_7);
    });

    // this.timer_8.on('pointerdown', () => {
    //   this.reproducirEfecto(this.sonidoClick);
    //   this.tiempoActual = 3600;
    //   this.tiempo.hora = 1;
    //   this.tiempo.minutos = 0;
    //   this.tiempo.segundos = 0;
    //   this.CambiarAutomatico(this.timer_8);
    // });
    this.timer_8.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.abrir_modal(this.timer_8);
    });
    this.circuloFondoColor = this.add.circle(this.scale.width - 330,736, 25, this.ColorFondo, 1).setInteractive().setScale(1.1);
    this.circuloFondoColor.setStrokeStyle(5, 0x33875a);     
    this.circuloFondoColor.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.circuloFondoColor.setScale(.8);
      setTimeout(() => {
        this.circuloFondoColor.setScale(1.1)
      }, 200);
      this.abrirColor("ColorFondo",this.circuloFondoColor);
    });    
    this.containerMenu.add(this.circuloFondoColor);
    this.containerCambioColor = this.add.container(0, 0).setVisible(false);
    let diff = 0;
    var select:any = document.createElement('select');

    var options = [{
      value: "sonido_14",
      text: "Pedro Music"
    },{
      value: "sonido_1",
      text: "Mario Bross"
    },{
      value: "sonido_2",
      text: "Fornite Victory"
    },{
      value: "sonido_3",
      text: "Campana"
    },{
      value: "sonido_4",
      text: "Triste"
    },{
      value: "sonido_5",
      text: "Arpa"
    },{
      value: "sonido_6",
      text: "Aplausos"
    },{
      value: "sonido_7",
      text: "Risa Bebé"
    },{
      value: "sonido_8",
      text: "Grillos"
    },{
      value: "sonido_9",
      text: "Niños"
    },{
      value: "sonido_10",
      text: "Horror"
    },{
      value: "sonido_11",
      text: "Bocina Reggaeton"
    },{
      value: "sonido_13",
      text: "Explosión Suave"
    },{
      value: "sonido_15",
      text: "Paz Mental"
    },{
      value: "sonido_16",
      text: "Ecos del alma"
    },{
      value: "sonido_17",
      text: "Armonía Interior"
    },{
      value: "efecto_1",
      text: "Ninguno"
    }];

    options.forEach(function(option) {
        var opt = document.createElement('option');
        opt.value = option.value;
        opt.innerHTML = option.text;
        select.appendChild(opt);
    });
    select.id = "tipo_sonido";
    select.style.border = "none";
    select.style.borderRadius = "60px";
    select.style.height = '40px';
    select.style.width = '150';
    select.style.fontSize = '16px';
    select.style.textAlign = 'center';
    select.style.background = 'white';
    select.style.border = '4px solid #33875a';
    select.style.color = '#33875a';
    select.style.fontWeight = 'bold';
    select.addEventListener("change", function() {
      if(!ContenedorGeneralGlobal.estadoSonidoPrev){
        ContenedorGeneralGlobal.CambiarPrevisualizarSonido();
      }     
      ContenedorGeneralGlobal.sonidoActual = this.value;
    });
    document.body.appendChild(select);
    this.selectEl = this.add.dom(this.scale.width-270, 630+diff+85, select).setOrigin(0);    
    this.containerMenu.add(this.selectEl);
    this.BotonPlayPrev = this.add.sprite(this.scale.width-75,650+diff+85,"sonido_activo").setScale(.3).setInteractive();
    this.containerMenu.add(this.BotonPlayPrev);
    this.BotonPlayPrev.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.BotonPlayPrev.setScale(.2);
      setTimeout(() => {
        this.BotonPlayPrev.setScale(.3)
      }, 200);
      this.reproducirAudio();
      this.CambiarPrevisualizarSonido();
    });    
    // this.efecto = this.add.text(this.scale.width - 205,590+150, "Efecto", {
    //   font: "bold 20px Arial",
    //   align: "center",
    //   color: "#33875a"
    // });
    
    // this.containerMenu.add(this.efecto);
    this.BotonEfecto_1 = this.add.image(this.scale.width - 325,640+150,"efecto_1").setScale(.25).setInteractive();
    this.BotonEfecto_2 = this.add.image(this.scale.width - 210,640+150,"efecto_2").setScale(.25).setInteractive();
    this.BotonEfecto_3 = this.add.image(this.scale.width - 96,640+150,"efecto_3").setScale(.25).setInteractive();
    this.BotonEfecto_4 = this.add.image(this.scale.width - 270,680+150,"efecto_4").setScale(.25).setInteractive();
    this.BotonEfecto_5 = this.add.image(this.scale.width - 150,680+150,"efecto_5").setScale(.25).setInteractive().setAlpha(.5);
    this.containerMenu.add(this.BotonEfecto_1);
    this.containerMenu.add(this.BotonEfecto_2);
    this.containerMenu.add(this.BotonEfecto_3);
    this.containerMenu.add(this.BotonEfecto_4);
    this.containerMenu.add(this.BotonEfecto_5);
    this.BotonEfecto_1.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.CambiarEfecto(this.BotonEfecto_1,'ninguno');
    });
    this.BotonEfecto_2.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.CambiarEfecto(this.BotonEfecto_2,'confetti');
    });
    this.BotonEfecto_3.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.CambiarEfecto(this.BotonEfecto_3,'emojis');
    });
    this.BotonEfecto_4.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.CambiarEfecto(this.BotonEfecto_4,'explosion');
    });
    this.BotonEfecto_5.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.CambiarEfecto(this.BotonEfecto_5,'pedropedro');
    });
    this.marcoConfiguracion = this.add.image(this.scale.width/2, 75,"configuracion").setScale(.41).setInteractive();
    this.tipo_temporizador_1 = this.add.image(570 + diferencia - 40 ,65,"menu_emoji").setScale(.4).setInteractive();
    this.tipo_temporizador_2 = this.add.image(660 + diferencia - 40 ,65,"menu_emoji_1").setScale(.4).setInteractive();
    this.tipo_temporizador_3 = this.add.image(750 + diferencia - 40 ,65,"menu_emoji_2").setScale(.4).setInteractive();
    this.tipo_temporizador_4 = this.add.image(840 + diferencia - 40 ,65,"menu_emoji_3").setScale(.4).setInteractive();
    this.tipo_temporizador_6 = this.add.image(930 + diferencia - 40 ,65,"menu_fiu").setScale(.4).setInteractive();
    this.tipo_temporizador_7 = this.add.image(1020 + diferencia - 40 ,65,"menu_jiro").setScale(.4).setInteractive();
    this.tipo_temporizador_8 = this.add.image(1110 + diferencia - 40 ,65,"menu_mano").setScale(.4).setInteractive();
    this.tipo_temporizador_9 = this.add.image(1200 + diferencia - 40 ,65,"menu_emoji2").setScale(.4).setInteractive();
    this.tipo_temporizador_10 = this.add.image(1290 + diferencia - 40 ,65,"menu_pedro").setScale(.4).setInteractive().setAlpha(.5);
    this.containerEmojis.add(this.marcoConfiguracion);
    this.containerEmojis.add(this.tipo_temporizador_1);
    this.containerEmojis.add(this.tipo_temporizador_2);
    this.containerEmojis.add(this.tipo_temporizador_3);
    this.containerEmojis.add(this.tipo_temporizador_4);
    this.containerEmojis.add(this.tipo_temporizador_6);
    this.containerEmojis.add(this.tipo_temporizador_7);
    this.containerEmojis.add(this.tipo_temporizador_8);
    this.containerEmojis.add(this.tipo_temporizador_9);
    this.containerEmojis.add(this.tipo_temporizador_10);
    this.tipo_temporizador_1.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.CambiarSkin(this.tipo_temporizador_1,'menu_emoji');
      this.tipo_temporizador_1.setScale(.4);
      setTimeout(() => {
        this.tipo_temporizador_1.setScale(.4);
      }, 200);
    });
    this.tipo_temporizador_2.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.CambiarSkin(this.tipo_temporizador_2,'menu_emoji_1');
      this.tipo_temporizador_2.setScale(.3);
      setTimeout(() => {
        this.tipo_temporizador_2.setScale(.4);
      }, 200);
    });
    this.tipo_temporizador_3.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.CambiarSkin(this.tipo_temporizador_3,'menu_emoji_2');
      this.tipo_temporizador_3.setScale(.3);
      setTimeout(() => {
        this.tipo_temporizador_3.setScale(.4);
      }, 200);
    });
    this.tipo_temporizador_4.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.CambiarSkin(this.tipo_temporizador_4,'menu_emoji_3');
      this.tipo_temporizador_4.setScale(.3);
      setTimeout(() => {
        this.tipo_temporizador_4.setScale(.4);
      }, 200);
    });
    this.tipo_temporizador_6.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.CambiarSkin(this.tipo_temporizador_6,'menu_fiu');
      this.tipo_temporizador_6.setScale(.3);
      setTimeout(() => {
        this.tipo_temporizador_6.setScale(.4);
      }, 200);
    });
    this.tipo_temporizador_7.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.CambiarSkin(this.tipo_temporizador_7,'menu_jiro');
      this.tipo_temporizador_7.setScale(.3);
      setTimeout(() => {
        this.tipo_temporizador_7.setScale(.4);
      }, 200);
    });
    this.tipo_temporizador_8.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.CambiarSkin(this.tipo_temporizador_8,'menu_mano');
      this.tipo_temporizador_8.setScale(.3);
      setTimeout(() => {
        this.tipo_temporizador_8.setScale(.4);
      }, 200);
    });
    this.tipo_temporizador_9.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.CambiarSkin(this.tipo_temporizador_9,'menu_emoji2');
      this.tipo_temporizador_9.setScale(.3);
      setTimeout(() => {
        this.tipo_temporizador_9.setScale(.4);
      }, 200);
    });
    this.tipo_temporizador_10.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.CambiarSkin(this.tipo_temporizador_10,'menu_pedro');
      this.tipo_temporizador_10.setScale(.3);
      setTimeout(() => {
        this.tipo_temporizador_10.setScale(.4);
      }, 200);
    });
    this.manecillaReloj = new Phaser.Geom.Line(400, 300, 400, 100);

    this.Bateria = this.add.graphics();
    this.Bateria.fillStyle(0x00ff00, 1);
    this.Bateria.fillRect(594, 120, 700, 400,0).setVisible(false);

    this.anchoInicialBateria = 700;
    this.anchoBateriaActual = this.anchoInicialBateria;

    this.Bateria2 = this.add.graphics();
    this.Bateria2.fillStyle(0x00ff00, 1);
    this.Bateria2.fillRect(825, 90, 273, 484,0).setVisible(false);

    this.alturaInicialBateria = 484;
    this.alturaBateriaActual = this.alturaInicialBateria;

    this.FondoBateria = this.add.image(this.scale.width/2, 320,"bateria").setScale(.65).setVisible(false);
    this.FondoBateria2 = this.add.image(this.scale.width/2, 320,"bateria_2").setScale(.45).setVisible(false);
    this.crearCronometro();
    this.bordeSelector = this.add.image(this.scale.width-510, 775, 'bordeTiempo').setScale(.21);
    this.colorSelector = this.add.image(this.scale.width-510, 780, 'colorSelector').setScale(.16);
    
    this.colorSelector.setInteractive();
    this.colorSelector.on('pointerdown', function(pointer, localX, localY, event) {
        var color = this.textures.getPixel(localX, localY, 'colorSelector');
        color = Phaser.Display.Color.RGBToString(color.r, color.g, color.b, color.a);
        if(this.colorActualCambio == "ColorTiempo"){  
          this.CambiarTiempo(color.replace("#", "0x"));
        }else{
          $("body, #gameContainer").css("background-color", color);
          this.CambiarFondo(color.replace("#", "0x"));
        }
    }, this);
    this.btnCerrar = this.add.image(this.scale.width-510, 870, 'btnCerrar').setScale(.3).setInteractive();
    this.btnCerrar.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.cerrarColor();
    });
    this.containerCambioColor.add(this.bordeSelector);
    this.containerCambioColor.add(this.colorSelector);
    this.containerCambioColor.add(this.btnCerrar);
    this.containerCambioColor.setDepth(10);
    this.tiempo.hora = 0;
    this.tiempo.minutos = 1;
    this.tiempo.segundos = 0;
    this.CambiarAutomatico(this.timer_3);
    this.anims.create({
      key: 'pedropedro_anim', // Nombre de la animación
      frames: this.anims.generateFrameNames('pedropedro'), // Generar los nombres de los frames desde el atlas 'pedropedro'
      frameRate: 10, // Velocidad de la animación en fotogramas por segundo
      repeat: 1 // -1 para que la animación se repita infinitamente
    });
    // this.pedropedro();
    
  }
  FondoBateria:any;
  FondoBateria2:any;
  anchoBateriaActual:any;
  Bateria:any;
  Bateria2:any;
  alturaInicialBateria:any;
  anchoInicialBateria:any;
  alturaBateriaActual
  crearImagenes(){
    this.eliminarImagenes();
    this.imagenesContenedor = [];
    this.crearImagenesEnRectangulo(this);
  }
  crearCronometro(){
    this.containerModal = this.add.container(this.scale.width/2-480,this.scale.height/2-400).setScale(.5).setVisible(false);
    this.modalTiempo = this.add.image(this.scale.width/2,this.scale.height/2,"modalTiempo");   
    this.temporizadorTexto = this.add.text(this.scale.width/2, 400, '00:00:00', { fontFamily: 'Arial', fontSize: '240px', color: '#000000' });
    this.temporizadorTexto.setOrigin(0.5); 
    this.BordeCronometro = this.add.image(290, this.scale.height - 70,"BordeCronometro").setScale(.2).setVisible(false);
    this.BordeCronometroTexto = this.add.text(195, this.scale.height - 95, '00:00:00', { fontFamily: 'Arial', fontSize: '50px', color: '#000000' }).setVisible(false);
    this.BotonMasHora = this.add.image(625,250,"btn_mas").setScale(.65).setInteractive();
    this.BotonMenosHora = this.add.image(625,540,"btn_menos").setScale(.65).setInteractive();

    this.BotonMasHora.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.quitarAutomatico();
      this.MasHora();
    });

    this.BotonMenosHora.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.quitarAutomatico();
      this.MenosHora();
    });
    
    this.BotonMasMinutos = this.add.image(960,250,"btn_mas").setScale(.65).setInteractive();
    this.BotonMenosMinutos = this.add.image(960,540,"btn_menos").setScale(.65).setInteractive();

    this.BotonMasMinutos.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.quitarAutomatico();
      this.MasMinuto();
    });

    this.BotonMenosMinutos.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.quitarAutomatico();
      this.MenosMinuto();
    });
    
    this.BotonMasSegundos = this.add.image(1290,250,"btn_mas").setScale(.65).setInteractive();
    this.BotonMenosSegundos = this.add.image(1290,540,"btn_menos").setScale(.65).setInteractive();

    this.BotonMasSegundos.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.quitarAutomatico();
      this.MasSegundos();
    });

    this.BotonMenosSegundos.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.quitarAutomatico();
      this.MenosSegundos();
    });
    let iniciar = this.add.image(900,750,"iniciar").setInteractive();
    iniciar.on('pointerdown', () => {
      iniciar.setScale(.9);
      setTimeout(() => {
        iniciar.setScale(1);
      },200);
      this.cerrarModal();
    });
    let salir = this.add.image(1200,750,"btnCerrar").setInteractive();
    salir.on('pointerdown', () => {
      salir.setScale(.9);
      setTimeout(() => {
        salir.setScale(1);
      },200);
      this.cerrarModal();
    });
    this.pauseButton = this.add.image(this.scale.width/2 - 100,this.scale.height - 180,"btn_pausa").setAlpha(.5).setScale(.55).setInteractive();
    this.playButton = this.add.image((this.scale.width/2)-5,this.scale.height - 180,"btn_play").setAlpha(.5).setScale(.75).setInteractive();
    this.restartButton = this.add.image(this.scale.width/2 + 90,this.scale.height - 180,"btn_replay").setAlpha(.5).setScale(.55).setInteractive();

    this.playButton.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      if(!this.isStarted){
        this.CambiarTiempoTotal();
      }
      this.isPaused = false;        
      this.playButton.setScale(.65);
      setTimeout(() => {
        this.playButton.setScale(.75);
      },200);
      this.isStarted = true;
    });

    this.pauseButton.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.isPaused = true;
      this.pauseButton.setScale(.45);
      setTimeout(() => {
        this.pauseButton.setScale(.55);
      },200);
    });

    this.restartButton.on('pointerdown', () => {
      this.reproducirEfecto(this.sonidoClick);
      this.isPaused = true;
      this.isStarted = false;
      this.resetTime();
      this.restartButton.setScale(.45);
      setTimeout(() => {
        this.restartButton.setScale(.55);
      },200);
    });
    this.switch_activado = this.add.image(530,this.scale.height - 70,"switch_desactivado").setScale(.15);
    this.switch_icono = this.add.image(500,this.scale.height - 70,"switch_icono").setScale(.2).setInteractive();
    this.switch_icono.on('pointerdown', () => {
      this.mostrarCronometro();
    });
    this.crearImagenes();
    this.containerModal.add(this.modalTiempo);
    this.containerModal.add(this.temporizadorTexto);
    this.containerModal.add(this.BotonMasHora);
    this.containerModal.add(this.BotonMenosHora);
    this.containerModal.add(this.BotonMasMinutos);
    this.containerModal.add(this.BotonMenosMinutos);
    this.containerModal.add(this.BotonMasSegundos);
    this.containerModal.add(this.BotonMenosSegundos);
    this.containerModal.add(iniciar);
    this.containerModal.add(salir);
    this.containerModal.setDepth(10);
  }
  mostrarCronometro(){
    this.reproducirEfecto(this.sonidoMenu);
    if(this.EstadoCronometro){
      this.tweens.add({
        targets: this.switch_icono,
        x: 500,
        duration: 100,
        ease: 'Linear'
      });
      setTimeout(() => {
        this.switch_activado.setTexture("switch_desactivado");
      }, 100);
      this.EstadoCronometro = false;
      this.BordeCronometro.setVisible(false);
      this.BordeCronometroTexto.setVisible(false);
    }else{
      this.tweens.add({
        targets: this.switch_icono,
        x: 550,
        duration: 100,
        ease: 'Linear'
      });
      setTimeout(() => {
        this.switch_activado.setTexture("switch_activado");
      }, 100);
      this.EstadoCronometro = true;
      this.BordeCronometro.setVisible(true);
      this.BordeCronometroTexto.setVisible(true);
    }
  }
  getNumeros(numImagenes){
    if(numImagenes <= 15){ //15 seg      
      return [15,0.55,-30,240];
    }else if(numImagenes > 15 && numImagenes <= 30){ //30 seg      
      return [14,0.55,20,240];
    }else if(numImagenes > 30 && numImagenes <= 60){ //1 minuto      
      return [15,0.55,30,240];
    }else if(numImagenes > 60 && numImagenes <= 180){ //3 minutos      
      return [27,0.3,40,220];
    }else if(numImagenes > 180 && numImagenes <= 300){ //5 minutos      
      return [35,0.23,40,210];
    }else if(numImagenes > 300 && numImagenes <= 600){ //10 minutos      
      return [51,0.15,40,210];
    }else if(numImagenes > 600 && numImagenes <= 1800){ //30  minutos      
      return [81,0.086,35,165];
    }else{      
      return [115,0.055,30,154];
    }
  }
  imagenesContenedor:any = [];
  crearImagenesEnRectangulo(scene) {
    let numImagenes = this.tiempoTotalSegundos-this.tiempoTranscurrido;
    let datos_numeros = this.getNumeros(numImagenes);
    let x = datos_numeros[2];
    let y = datos_numeros[3];
    let width = this.scale.width - 10;
    let height = this.scale.height - 370;
    let filas = Math.ceil(Math.sqrt(numImagenes));
    let columnas = Math.ceil(numImagenes / filas);
    let areaWidth = width / columnas;
    let areaHeight = height / filas;
    let margen = 5;
    let anchoImagen = areaWidth - margen;
    let altoImagen = areaHeight - margen;
    let posX = x;
    let posY = y;
    let contador = 0;
    for (let i = 0; i < numImagenes; i++) {
      if (i < numImagenes) {
        let aux_img = this.skinTiempo;
        let image;
        image = scene.add.image(0, 0, aux_img);
        image.setScale(datos_numeros[1]);
        // if (aux_img === 'menu_pedro') {
        //   image = scene.add.sprite(0, 0, 'pedropedro');
        //   image.setScale(datos_numeros[1]);
        //   image.play('pedropedro_anim'); 
        //   image.anims.stopOnRepeat = false; 
        // } else {
        //   image = scene.add.image(0, 0, aux_img);
        //   image.setScale(datos_numeros[1]);
        // }
        let imagenAncho = image.displayWidth;
        let imagenAlto = image.displayHeight;
        let imgPosX = posX + (areaWidth - imagenAncho) / 2;
        let imgPosY = posY + (areaHeight - imagenAlto) / 2;

        image.setPosition(imgPosX, imgPosY);
        
        if (contador == datos_numeros[0]) {
            contador = 0;
            posY += imagenAlto + margen;
            posX = x;
        } else {
            contador++;
            posX += imagenAncho + margen;
        }
        
        this.imagenesContenedor.push(image);
      }
    }
}

  eliminarImagen() {
    for (let i = this.imagenesContenedor.length - 1; i >= 0; i--) {
        if (this.imagenesContenedor[i]) {
            this.imagenesContenedor[i].destroy();
            this.imagenesContenedor[i] = null;
            return;
        }
    }
}

eliminarImagenes() {
    for (let i = this.imagenesContenedor.length - 1; i >= 0; i--) {
      if (this.imagenesContenedor[i]) {
          this.imagenesContenedor[i].destroy();
          this.imagenesContenedor[i] = null;
      }
    }
    this.imagenesContenedor = []; 
  }

  tiempoActual:any = 60;
  update(tiempo, delta) {
    this.updateTimeText();
    if (!this.isPaused) {
      this.tiempoTranscurrido += delta / 1000;
      this.BordeCronometroTexto.setText(this.formatoTiempo(this.tiempoTotalSegundos - Math.floor(this.tiempoTranscurrido)));
      let restante = this.tiempoTotalSegundos - Math.floor(this.tiempoTranscurrido);
      if(this.tiempoActual != restante){
        this.eliminarImagen();
        this.reproducirEfecto(this.pop);
        this.tiempoActual = restante;
      }
      if(this.isStarted && restante == 0){
        this.ReproduceEfectos();
        this.isStarted = false;
        this.isPaused = true;
      }
    }
    if(this.tiempo.segundos > 0 || this.tiempo.minutos > 0 || this.tiempo.hora > 0){
      if(this.isPaused){
        if(!this.reproduciendoEfecto){
          this.playButton.setInteractive().setAlpha(1);
        }
        this.pauseButton.disableInteractive().setAlpha(.5);
      }else{
        this.playButton.disableInteractive().setAlpha(.5);
        this.pauseButton.setInteractive().setAlpha(1);
        this.restartButton.setInteractive().setAlpha(1);
      }
    }else{
      this.playButton.disableInteractive().setAlpha(.5);
      this.pauseButton.disableInteractive().setAlpha(.5);
      this.restartButton.disableInteractive().setAlpha(.5);
    }
    if(this.sonidoActual== "efecto_1"){
      this.BotonPlayPrev.disableInteractive().setAlpha(.5);
    }else{
      if(!this.isPaused){
        this.BotonPlayPrev.disableInteractive().setAlpha(.5);
      }else{
        this.BotonPlayPrev.setInteractive().setAlpha(1);
      }
    }
    if(this.isStarted){
      this.timer_1.setVisible(false);
      this.timer_2.setVisible(false);
      this.timer_3.setVisible(false);
      this.timer_4.setVisible(false);
      this.timer_5.setVisible(false);
      this.timer_6.setVisible(false);
      this.timer_7.setVisible(false);
      this.timer_8.setVisible(false);
      // this.timer_personalizado.setVisible(false);
      // this.switch_activado.setVisible(false);
      // this.switch_icono.setVisible(false);
      this.tweens.add({
        targets: this.containerMenu,
        x: this.containerMenu.x + 320,
        duration: 100,
        ease: 'Linear'
      });
      this.tweens.add({
        targets: this.containerEmojis,
        y: this.containerEmojis.y - 320,
        duration: 100,
        ease: 'Linear'
      });
    }else{
      this.timer_1.setVisible(true);
      this.timer_2.setVisible(true);
      this.timer_3.setVisible(true);
      this.timer_4.setVisible(true);
      this.timer_5.setVisible(true);
      this.timer_6.setVisible(true);
      this.timer_7.setVisible(true);
      this.timer_8.setVisible(true);
      // this.timer_personalizado.setVisible(true);
      this.switch_activado.setVisible(true);
      this.switch_icono.setVisible(true);
      this.tweens.add({
        targets: this.containerMenu,
        x: 0,
        duration: 100,
        ease: 'Linear'
      });
      this.tweens.add({
        targets: this.containerEmojis,
        y: 0,
        duration: 100,
        ease: 'Linear'
      });
    }
    if(this.tiempo.hora == 0){
      this.BotonMasHora.setVisible(true);
      this.BotonMenosHora.setVisible(false);
      this.BotonMasMinutos.setVisible(true);
      this.BotonMenosMinutos.setVisible(true);

      this.BotonMasSegundos.setVisible(true);
      this.BotonMenosSegundos.setVisible(true);
    }else if(this.tiempo.hora == 1){
      this.BotonMasHora.setVisible(false);
      this.BotonMenosHora.setVisible(true);

      this.BotonMasMinutos.setVisible(false);
      this.BotonMenosMinutos.setVisible(false);

      this.BotonMasSegundos.setVisible(false);
      this.BotonMenosSegundos.setVisible(false);
    }
  }
  formatoTiempo(segundos) {
      var horas = Math.floor(segundos / 3600);
      var minutos = Math.floor((segundos % 3600) / 60);
      var segundosRestantes = segundos % 60;
      return this.pad(horas) + ":" + this.pad(minutos) + ":" + this.pad(segundosRestantes);
  }
  pad(numero) {
      return numero < 10 ? '0' + numero : numero;
  }
  getColorForHeight(height, maxHeight) {
    const greenThreshold = 0.6;
    const yellowThreshold = 0.2;
    let percentage = height / maxHeight;
    if (percentage > greenThreshold) {
      return 0x00ff00;
    } else if (percentage > yellowThreshold) {
      return 0xffff00;
    } else {
      return 0xff0000;
    }
  }
  crearMascaraCircular(graphics, x, y, radius) {
    graphics.beginPath();
    graphics.fillStyle(0xffffff,0); 
    graphics.arc(x, y, radius, 0, Math.PI * 2, false); 
    graphics.fillPath();
  }
  crearMascaraEstrella(graphics, x, y, innerRadius, outerRadius, points) {
    graphics.beginPath();
    var angle = Math.PI / points;

    for (var i = 0; i < points * 2; i++) {
        var radius = i % 2 === 0 ? outerRadius : innerRadius;
        var currX = x + Math.cos(i * angle - Math.PI / 2) * radius; 
        var currY = y + Math.sin(i * angle - Math.PI / 2) * radius; 

        if (i === 0) {
            graphics.moveTo(currX, currY);
        } else {
            graphics.lineTo(currX, currY);
        }
    }

    graphics.closePath();
    graphics.fillPath();
  }
  DURACION:any = 2000;
  ReproduceEfectos(){
    this.DURACION = this.getTimeDuracion();
    this.reproduciendoEfecto = true;
    this.reproducirAudio();
    if(this.efectoActual == 'confetti'){
      this.efectoConfetti();
    }else if(this.efectoActual == 'emojis'){
      this.efectoEmoji();
    }else if(this.efectoActual == 'explosion'){
      this.explotar();
    }else if(this.efectoActual == 'pedropedro'){
      this.pedropedro();
    }else{
      //sin efecto
    }
    Principal.api.set_kpi_plataformas("Temporizador Emojis","Reproduce efecto: "+this.efectoActual+" "+this.getNombreSonido(this.sonidoActual));
  }
  getTimeDuracion(){
    if(ContenedorGeneralGlobal[this.sonidoActual]){
      return Math.round(ContenedorGeneralGlobal[this.sonidoActual].duration)*1000;
    }else{
      return 2000;
    }
  }
  quitarEfectos(){
    ContenedorGeneralGlobal[this.sonidoActual].pause();
    if(this.pedro_img){
      this.pedro_img.remove(); // Eliminar del DOM
      this.pedro_img = null;
    }
    // this.pedro_circulo.destroy();
    if(this.Emitterconfetti){
      this.Emitterconfetti.stop();
    }
    if(this.EmitterEmoji){
      this.EmitterEmoji.stop();
    }
    this.reproduciendoEfecto = false;
  }
  pedro_img;
  pedro_circulo;
  pedropedro(){
    // Crear y configurar la imagen
  this.pedro_img = document.createElement('img');
  this.pedro_img.src = 'https://soporte-integritic.s3.amazonaws.com/pedro+368.gif';
  this.pedro_img.style.width = '450px';
  this.pedro_img.style.height = '450px';
  this.pedro_img.style.borderRadius = '220px';
  this.pedro_img.style.position = 'absolute'; // Asegúrate de que el elemento esté en posición absoluta
  this.pedro_img.style.top = '-200px';
  this.pedro_img.style.left = `${this.scale.width / 4}px`;
  this.pedro_img.style.border = '15px solid black';

  // Añadir el elemento DOM a la escena
  this.add.dom(this.scale.width / 4, -200, this.pedro_img);

  // Definir la animación
  let startY = -200;
  let endY = 500;
  let duration = 100; // Duración en milisegundos
  let startTime = null;

  function animate(time) {
    if (!startTime) startTime = time;
    let elapsed = time - startTime;
    let progress = Math.min(elapsed / duration, 1); // Progreso entre 0 y 1
    let newY = startY + (endY - startY) * progress;
    this.pedro_img.style.top = `${newY}px`;

    if (progress < 1) {
      requestAnimationFrame(animate.bind(this)); // Continuar animando
    }
  }

  requestAnimationFrame(animate.bind(this));
    setTimeout(() => {
      if(this.pedro_img){
        this.pedro_img.remove(); // Eliminar del DOM
        this.pedro_img = null;
        ContenedorGeneralGlobal[this.sonidoActual].pause();
      }
      this.reproduciendoEfecto = false;
      this.resetTime();
    }, this.DURACION);
    this.Emitterconfetti = this.add.particles('confetti').createEmitter({
      frame: ['efect_1.png','efect_2.png','efect_3.png','efect_4.png','efect_5.png','efect_6.png'],
      x: this.scale.width/2,
      y: -100,
      rotate: { start: 0, end: 360, ease: 'Back.easeOut' },
      alpha: {min:75, max: 100},
      lifespan: 3000,
      angle: {min: 0, max: 360},
      speed: { min : 100, max: 200},
      scale: {start: 0.8, end: 0},
      gravityY: 200
    });
    setTimeout(() => {
      this.Emitterconfetti.stop();
    }, 2000);
  }
  explotar(){
    const textureNames = ['explosion_1', 'explosion_2', 'explosion_3', 'explosion_4', 'explosion_5', 'explosion_6', 'explosion_7', 'explosion_8', 'explosion_9'];
    let currentTextureIndex = 0;
    let sprite_explosion = this.add.sprite(this.scale.width/2 ,this.scale.height/2-100, 'efecto_bomba').setScale(1.7);
    function changeTexture2() {
      const nextTextureName = textureNames[currentTextureIndex];
      sprite_explosion.setTexture(nextTextureName);
      currentTextureIndex++;
    }
    const delayBetweenTextureChanges = 200;
    const changeTextureEvent = this.time.addEvent({
      delay: delayBetweenTextureChanges,
      callback: changeTexture2,
      callbackScope: this,
      loop: true 
    });
    setTimeout(() => {
      this.reproduciendoEfecto = false;
      this.resetTime();
    }, this.DURACION);
  }
  efectoConfetti(){
    this.Emitterconfetti = this.add.particles('confetti').createEmitter({
      frame: ['efect_1.png','efect_2.png','efect_3.png','efect_4.png','efect_5.png','efect_6.png'],
      x: this.scale.width/2,
      y: -100,
      rotate: { start: 0, end: 360, ease: 'Back.easeOut' },
      alpha: {min:75, max: 100},
      lifespan: 3000,
      angle: {min: 0, max: 360},
      speed: { min : 100, max: 200},
      scale: {start: 0.8, end: 0},
      gravityY: 200
    });
    setTimeout(() => {
      this.Emitterconfetti.stop();
      this.reproduciendoEfecto = false;
      this.resetTime();
    }, this.DURACION);
  }
  efectoEmoji(){
    this.EmitterEmoji = this.add.particles('emojis').createEmitter({
      frame: ['em_10','em_3','em_11','em_12','em_2','em_4','em_6','em_8','em_9','em_7','em_5','em_1'],
      x: this.scale.width/2,
      y: -100,
      rotate: { start: 0, end: 360, ease: 'Back.easeOut' },
      alpha: {min:75, max: 100},
      lifespan: 3000,
      angle: {min: 0, max: 360},
      speed: { min : 100, max: 200},
      scale: {start: 0.4, end: 0},
      gravityY: 200
    });
    setTimeout(() => {
      this.EmitterEmoji.stop();
      this.reproduciendoEfecto = false;
      this.resetTime();
    }, this.DURACION);
  }
}