<div style="position: absolute;z-index: 2;">
    <div style="font-size: 50px;text-align: center;color: #e98932;font-family: Lato_Bold;margin: 30px;background: white;border: 4px solid #e98932;border-radius: 20px;width: 86%;margin: 60px auto;">Temporizadores</div>
    <div class="row" style="margin: 70px 100px;">
        <div class="col-sm-3">
            <div class="cuadro pulso-css">
                <img src="assets/recursos/cronometro.png" style="width: 100%;" (click)="ir('extras/temporizador')">
                <img (click)="mensaje('Timer numérico para gestionar el tiempo.')" src="assets/images/abecedario/ayuda.svg" style="width: 20px;width: 2vw;position: absolute;right: 30px;top: 15px;">
            </div>
        </div>
        <div class="col-sm-3">
            <div class="cuadro pulso-css">
                <img src="assets/recursos/visuales.png" style="width: 100%;" (click)="ir('extras/temporizadorvisual')">
                <img (click)="mensaje('Timer que proporciona una representación visual del tiempo.')" src="assets/images/abecedario/ayuda.svg" style="width: 20px;width: 2vw;position: absolute;right: 30px;top: 15px;">
            </div>
        </div>
        <div class="col-sm-3">
            <div class="cuadro pulso-css">
                <img src="assets/recursos/temporizador3.png" style="width: 100%;" (click)="ir('extras/temporizadoremojis')">
                <img (click)="mensaje('Timer que proporciona una representación visual del tiempo utilizando Emojis.')" src="assets/images/abecedario/ayuda.svg" style="width: 20px;width: 2vw;position: absolute;right: 30px;top: 15px;">
            </div>
        </div>
        <div class="col-sm-3">
            <div class="cuadro pulso-css">
                <img src="assets/recursos/sensorial.png" style="width: 100%;" (click)="ir('extras/temporizadorsensorial')">
                <img (click)="mensaje('Timer que proporciona una forma de representar el tiempo de forma relajante.')" src="assets/images/abecedario/ayuda.svg" style="width: 20px;width: 2vw;position: absolute;right: 30px;top: 15px;">
            </div>
        </div>
        <div class="col-sm-12 col-md-12 text-center" style="margin-top: 20px;">
            <div class="text-center bienvenida" style="margin-top: 20px;margin-bottom: 10px;">
                <img src="assets/images/aplicativos/ruidometro/logos.png" style="width: 30%;">
                <br>
                Un producto con ❤ de integritic.cl
            </div>
        </div>
    </div>
</div>
<div class="bg"></div>