<div id="modal-valoracion" class="modal fade" tabindex="-1" role="dialog">
    <div class="modal-dialog">
      <div class="estilo-modal modal-content">
        <div class="estilo-contenido-modal">
          <div class="modal-header" style="text-align: center;    border: none;">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="api.cerrar_modal();">
              <span aria-hidden="true">&times;</span>
            </button>
            <h5 class="modal-title" style="font-size: 20px;color:black;margin-top: 30px">Por favor evalúa nuestro recurso<br>"<b class="modulo_valoracion"></b>"</h5>
          </div>
          <div class="modal-body">
            <div class="text-center" style="display: flex;justify-content: center;margin-top: 30px">
              <img class="emoticones efectohover-css" id="bajo" src="assets/images/valoracion/bajo.svg" (click)="api.valoracion('bajo')" style="width: 80px; margin-right: 10px;cursor:pointer">
              <img class="emoticones efectohover-css" id="medio" src="assets/images/valoracion/medio.svg" (click)="api.valoracion('medio')" style="width: 80px; margin-right: 10px;cursor:pointer">
              <img class="emoticones efectohover-css" id="alto" src="assets/images/valoracion/alto.svg" (click)="api.valoracion('alto')" style="width: 80px;cursor:pointer ">
            </div>
            <br>
            <div class="text-center" style="margin-top: 30px">
              <textarea class="form-control" name="" id="valor_texto" cols="30" rows="1" style="resize: none;" maxlength="100" placeholder="Coméntanos qué te pareció"></textarea>
              <br>
              <button type="button" class="btn btn-success" (click)="api.setDatosValoracion()">Enviar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>