declare var $: any;
export class Ruidometro implements Class {

  Board:any;
  intervaloMic;
  micEncendido = true;
  sensibilidad = 2.6;
  porcentaje = 0;
  recursoActivo:any = true;
  skinSeleccionada:any =  "jiro";
  constructor(board) {
    //       super({});

    $("#myRange").on("change", e=>{
      this.sensibilidad = Number($("#myRange").val())
      // console.log($("#myRange").val())
     })


    this.Board = board;
    this.Board.recursosActivados["ruidometro"] = true;

    if($(".ruidometro").hasClass("hidden")){
      this.mostrarRecurso();

    }
    else{
      this.ocultarRecurso();
    }
    
  }
  mostrarRecurso(){
    this.recursoActivo = true;
    $(".ruidometro").removeClass("hidden");
    this.Board.activarFondo("fondo");

      if(!this.micIniciado){
        // $(".pizarra").css("background-image", "url(assets/images/aplicativos/ruidometro/fondo.png)")

        // var movableElement:any = document.getElementsByClassName('opciones')[0];
        // var movableElementWidth:any = $(movableElement)[0].offsetWidth - 10;
      
        // movableElement.style.transform = 'translateX(' + (window.innerWidth - movableElementWidth) + 'px)';
        this.iniciar_mic();
      }
  }
  ocultarRecurso(){
    this.recursoActivo = false;
    this.Board.activarFondo("blanco");
    $(".ruidometro").addClass("hidden");
  }
  cambiarSkin(valor){
    $("#radar").prop("src", "assets/images/aplicativos/ruidometro/radar_"+valor+".png");
    $("#skin").prop("src", "assets/images/aplicativos/ruidometro/"+this.skinSeleccionada+"_"+valor+".png");
  }
  compruebaRadar(){
    if(this.porcentaje<2){
      this.cambiarSkin(0);
    }
    else if(this.porcentaje<23){
      this.cambiarSkin(1);
    }
    else if(this.porcentaje<62){
      this.cambiarSkin(2);
    }
    else if(this.porcentaje<88){
      this.cambiarSkin(3);
    }
    else if(this.porcentaje<120){
      this.cambiarSkin(4);
    }
    else if(this.porcentaje<160){
      this.cambiarSkin(5);
    }
    else{
      this.cambiarSkin(6);
      // if(this.contadorEstrellas-1 >= 0){
        // if(!this.corriendoDescuento){
        //   if(this.contadorEstrellas-1 >= 0) this.contadorEstrellas -= 1;
        //   this.corriendoDescuento=true;
        //   if(this.checkSinSonido) this.sound.add(this.sonidoActivo === 2?('corneta_'+this.skinSeleccionada):'shhhh').play();
        //   setTimeout(()=>{
        //     this.corriendoDescuento=false;
        //   },5000)
        // // }
        // // this.contadorEstrellasAnt = this.contadorEstrellas;
      }

  }
  
  micIniciado=false;
  iniciar_mic(){
    this.micIniciado = true;
    const audioContext = new (window.AudioContext)();
    const analyser = audioContext.createAnalyser();
    navigator.mediaDevices.getUserMedia({ audio: { echoCancellation: false } }).then(stream => {
      this.micEncendido = true;
      // prenderMic();
        const source:any = audioContext.createMediaStreamSource(stream);
        source.connect(analyser);
        analyser.fftSize = 512;
        // analyser.minDecibels = -127;
        // analyser.maxDecibels = 0;
        // analyser.minDecibels = -130;
        // analyser.maxDecibels = 0;
        analyser.smoothingTimeConstant = .98;
        source.connect(analyser);
        const volumes = new Uint8Array(analyser.frequencyBinCount);
        let calcularNivelRuido = async () => {
            // console.log(this.add["scene"])
            // if(this.add["scene"]===null) return clearInterval(this.intervaloMic);


          if(!this.micEncendido) return;

          let query:any = { "name": 'microphone' } ;
          const permisos = await navigator.permissions.query(query);
          if (permisos.state === 'granted'){}//prenderMic();
          else {
            alert("debes habilitar el permiso del microfono, haciendo click en el icono")
            // apagarMic();
            return this.micEncendido = false;;
          }

          if(source.context.state==="suspended"){
            return source.context.resume();
          }
          analyser.getByteFrequencyData(volumes);
          // let volumeSum = 0;
          // for(const volume of volumes)
          //   volumeSum += volume;
          
          //   const averageVolume = volumeSum / volumes.length;
          // let numero = (averageVolume * 100 / 127) * this.sensibilidad
          // numero = numero>122 ? 122: numero;
          // numero = numero<0 ? 0: numero;
          // this.porcentaje = numero;

          var length = volumes.length;
          let values = 0;
          for (var i = 0; i < length; i++) {
            values += (volumes[i]);
          }

            var average = values / length;
          let averageNoiseLevel = (average + 0) * this.sensibilidad;
          this.porcentaje = averageNoiseLevel>190 ? 190:averageNoiseLevel

          // Ejemplo de uso para mapear el rango de 0 a 180 a 0 a 120


          this.compruebaRadar();
          $("#flecha").css("transform", "rotate(" + this.porcentaje + "deg)");


          // console.log(this.porcentaje)


        };
        this.intervaloMic = setInterval(calcularNivelRuido, 1);
      })
      .catch(error => {
        if (error.name === 'NotAllowedError' || error.name === 'PermissionDeniedError') {
          alert("El usuario ha denegado el acceso al micrófono después de preguntar.")
        } 
        // else return apagarMic();  
      });
  }


}


// declare var $: any;
// export class Ruidometro extends Phaser.Scene {

//     constructor() {
//       super({});
//       console.log("ruidometoror");
//     //   ContenedorGeneralGlobal = this;
//       // PantallaInicialGlobal = this;
//     }
//     preload() {
  
      
//       for(let i=0; i <= 5; i++) this.load.image('jiro'+i, 'assets/images/aplicativos/ruidometro/jiro'+i+'.png');
//       for(let i=0; i <= 5; i++) this.load.image('fiu'+i, 'assets/images/aplicativos/ruidometro/fiu_'+i+'.png');
//       for(let i=0; i <= 6; i++) this.load.image('radar_'+i, 'assets/images/aplicativos/ruidometro/radar_'+i+'.png');
  
//       this.load.image('estrella', 'assets/images/aplicativos/ruidometro/estrella.png');
  
//       this.load.image('base', 'assets/images/aplicativos/ruidometro/fondo.png');
//       this.load.image('radar', 'assets/images/aplicativos/ruidometro/radar.png');
  
//       this.load.audio('shhhh','assets/images/aplicativos/ruidometro/shhhhh.mp3');
//       this.load.audio('corneta','assets/images/aplicativos/ruidometro/sonido_wrong_silencio.mp3');
  
//       // src/assets/images/aplicativos/ruidometro/fiu_enojao.mp3 src/assets/images/aplicativos/ruidometro/jiro_enojao.mp3
  
//       this.load.audio('corneta_jiro','assets/images/aplicativos/ruidometro/jiro_enojao.mp3');
//       this.load.audio('corneta_fiu','assets/images/aplicativos/ruidometro/fiu_enojao.mp3');
  
//       this.load.image('radar_color', 'assets/images/aplicativos/ruidometro/radar_color.png');
//       this.load.image('radar_falso', 'assets/images/aplicativos/ruidometro/radar_falso.png');
//       this.load.image('sombra', 'assets/images/aplicativos/ruidometro/sombra.png');
  
//       this.load.image('flecha', 'assets/images/aplicativos/ruidometro/flecha.png');
    
//       /*+ **/
//       this.load.image('sensibilidad', 'assets/images/aplicativos/ruidometro/sensibilidad.png');
//       this.load.image('mic', 'assets/images/aplicativos/ruidometro/mic.png');
//       this.load.image('alerta', 'assets/images/aplicativos/ruidometro/alerta.png');
  
//       this.load.image('silencio_', 'assets/images/aplicativos/ruidometro/silencio_.png');
//       this.load.image('enojado', 'assets/images/aplicativos/ruidometro/enojado.png');
  
//       this.load.image('check', 'assets/images/aplicativos/ruidometro/check.png');
  
//       this.load.image('mic_icon', 'assets/images/aplicativos/ruidometro/mic_icon.png');
//       this.load.image('mic_off', 'assets/images/aplicativos/ruidometro/mic_off.png');
  
//       this.load.image('circulo', 'assets/images/aplicativos/ruidometro/circulo.png');
  
//       this.load.image('sh', 'assets/images/aplicativos/ruidometro/sh.png');
//       this.load.image('zz', 'assets/images/aplicativos/ruidometro/zz.png');
//       this.load.image('silencio', 'assets/images/aplicativos/ruidometro/silencio.png');
  
//       this.load.image('jiro', 'assets/images/aplicativos/ruidometro/jiro.png');
//       this.load.image('fiu', 'assets/images/aplicativos/ruidometro/fiu.png');
  
//       this.load.image('reinicio', 'assets/images/aplicativos/ruidometro/reinicio.png');
  
      
//       for(let i=0; i <= 10; i++) this.load.image('e'+i, 'assets/images/aplicativos/ruidometro/estrellas_'+i+'.png');
//       this.load.image('ayuda', 'assets/images/aplicativos/ruidometro/ayuda.png');
//       this.load.atlas('flares', 'assets/assets/particles/flares.png', 'assets/assets/particles/flares.json');
  
//     }
    
    
//     flecha:any;
//     porcentaje:any;
//     sensibilidad:any = 1.6; // de .1 hasta 2
//     micEncendido:any = false;
  
//     skinSeleccionada:any = "fiu";
  
//     async create(){
//         console.log("creando");
  

//       this.add.image(-2,-200,"base").setOrigin(0).setScale(1).setAlpha(1);
//     //   this.add.rectangle(0, this.scale.height-100, this.scale.width, 100, 0xb7229b, 1).setOrigin(0);
//       const contenedor_radar:any = this.add.container(this.scale.width / 2 - 300, 400);

//       const contenedor_estrellas:any = this.add.container(110, 5);
//       contenedor_estrellas.setScale(.7)


//       let radar_color = this.add.sprite(0,0,"radar_0").setOrigin(0).setScale(1).setAlpha(1).setDepth(1);
//     //   radar_color.x = (this.scale.width / 2 - radar_color.width / 2) - 100
//     //   radar_color.y = (this.scale.height - radar_color.height) - 20
  
//       this.Radar = radar_color;
//       console.log(contenedor_radar)
//         contenedor_radar.setVisible(true)
      
  
//       // let radar_falso = this.add.image(radar_color.x,radar_color.y,"radar_falso").setOrigin(0).setScale(1).setAlpha(1).setDepth(2);
//       // // radar_falso.
  
//       // this.add.image(radar_color.x,radar_color.y,"radar_color").setOrigin(0).setScale(1).setAlpha(.2).setDepth(3);
      
//       // let radar = this.add.image(radar_color.x,radar_color.y,"radar").setOrigin(0).setScale(1).setDepth(4);
      
//       let sombra = this.add.sprite(radar_color.x,radar_color.y,"sombra").setOrigin(0).setScale(1).setDepth(5)
//       let flecha = this.add.sprite(0,0,"flecha").setScale(1.3).setOrigin(0.5, 0.5).setDepth(7);
      
//       // radar_color.x += 2;
//       // radar_color.y += 2;
  
//       // radar_falso.x += 100
  
//       flecha.x = radar_color.x+(radar_color.width/2);
//       flecha.y = radar_color.y+(radar_color.height-80);
//       this.flecha = flecha;
  
    
//       let jiro2 = this.add.sprite(0,0,this.skinSeleccionada+"2").setOrigin(.5).setScale(.5).setDepth(6);
  
//       jiro2.x = radar_color.x+(radar_color.width/2) ;
//       jiro2.y = radar_color.y - 115;
  
//       let min = 1630;
//       let max = this.scale.width - 30;
  
//       let posC1 = 180;

//       contenedor_radar.add(radar_color);
//       contenedor_radar.add(sombra);
//       contenedor_radar.add(jiro2);
//       contenedor_radar.add(flecha);
//     //   this.add.existing(contenedor_radar);

//       contenedor_radar.setScale(.6);
//       /** marco */
//       let tamanioMarco = 720;
//       var marcoFondo = this.add.graphics();
//       marcoFondo.fillStyle(0xFF1B88, .3);
//       marcoFondo.fillRoundedRect(min-25, 45, 310, tamanioMarco+10, 20);
  
//       var fondoMarco = this.add.graphics();
//       fondoMarco.fillStyle(0x1f002c, .5);
//       fondoMarco.fillRoundedRect(min-20, 50, 300, tamanioMarco, 15);
  
  
//       var input:any = document.createElement('input');
//       input.type = 'text',
//       input.min = '0';
//       input.id = "text-cant",
//       input.value = "10";
//       input.style.border = "none";
//       input.style.borderRadius = "10px";
//       input.pattern = "[0-9]+";
//       input.style.height = '50px';
//       input.maxLength = 4;
//       input.style.width = '200px';
//       input.style.fontSize = '30px';
//       input.style.textAlign = 'center';
//       // input.style.transform = 'translate(-50%, -50%)';
  
//       input.addEventListener("input", function() {
//         this.value = this.value.replace(/[^\d]/g, '');
//       });
  
//       let diff = 0 ;
  
//       document.body.appendChild(input);
//       var inputEl:any = this.add.dom(this.scale.width-250, 420+diff, input).setOrigin(0);
//       inputEl.on('pointerdown', function (pointer) {}, this);
//       /** fin marco */
  
     
//       let jiro = this.add.sprite(this.scale.width-125,530+diff,"jiro").setOrigin(0).setScale(.7).setAlpha(.5).setInteractive({ useHandCursor: true });
//       let fiu = this.add.sprite(this.scale.width-265,530+diff,"fiu").setOrigin(0).setScale(.7).setInteractive({ useHandCursor: true });
  
//       let reinicio = this.add.sprite(this.scale.width-250,695+diff,"reinicio").setOrigin(0).setScale(.9).setAlpha(1).setInteractive({ useHandCursor: true });
  
//       reinicio.on('pointerdown', function (pointer) {
//         // Principal.global.alerta.fire({
//         //   // icon: 'warning',
//         //   title: 'Juego reiniciado',
//         //   html: `comenzamos de nuevo`,
//         //   // text: ``
//         // });
  
//         reinicio.x = reinicio.x - 5;
//         reinicio.setScale(.95);
//         setTimeout(()=>reinicio.setScale(.9), 100);
//         setTimeout(()=>reinicio.x = reinicio.x + 5, 100);
  
//         this.contadorEstrellas = 0;
//         this.segundosTotales = 0;
        
  
//       }, this);
  
      
//       jiro.on('pointerdown', function (pointer) {
//         fiu.setAlpha(.5);
//         jiro.setAlpha(1);
//         this.jiro.setVisible(false);
//         this.skinSeleccionada = "jiro";
//         this.validaPersonaje();
//         setTimeout(()=>this.jiro.setVisible(true),100)
//       }, this);
      
//       fiu.on('pointerdown', function (pointer) {
//         fiu.setAlpha(1);
//         jiro.setAlpha(.5);
//         this.jiro.setVisible(false);
//         this.skinSeleccionada = "fiu";
//         this.validaPersonaje();
//         setTimeout(()=>this.jiro.setVisible(true),100)
//       }, this);
  
      
  
  
  
//       let linea = this.add.graphics();
//       linea.fillStyle(0xffffff);
//       linea.fillRect(min, posC1-2,  max-min, 5);
  
  
//       let linea2 = this.add.graphics();linea2.fillStyle(0xffffff);linea2.fillRect(min-19, 350+diff,  299, 1);
//       let linea4 = this.add.graphics();linea4.fillStyle(0xffffff);linea4.fillRect(min-19, 520+diff,  299, 1);
//       let linea3 = this.add.graphics();linea3.fillStyle(0xffffff);linea3.fillRect(min-19, 620+diff,  299, 1);
//       let linea5 = this.add.graphics();linea5.fillStyle(0xffffff);linea5.fillRect(min-19, 110,  299, 1);
//       let linea6 = this.add.graphics();linea6.fillStyle(0xffffff);linea6.fillRect(min-19, 210,  299, 1);
  
  
//       const circulo = this.add.image(min + (max - min) / 2, posC1, 'circulo');
//       circulo.setInteractive({ draggable: true, useHandCursor: true });
  
//       let pos_mic = 55;
      
//       this.add.image(min+40,posC1-60,"sensibilidad").setOrigin(0).setScale(.7).setAlpha(1);
//       this.add.image(min+15,pos_mic,"mic").setOrigin(0).setScale(.7).setAlpha(1);
//       let mic:any = this.add.sprite(min+190,pos_mic,"mic_icon").setOrigin(0).setScale(.7);
//       mic.setInteractive({ useHandCursor: true });
  
  
//       this.add.image(min+5,130+90,"alerta").setOrigin(0).setScale(.7).setAlpha(1);
//       let check = this.add.image(min+210,130+90,"check").setOrigin(0).setScale(.7).setAlpha(1);
  
  
//       let silencio = this.add.image(min+110-50,180+90,"silencio_").setOrigin(0).setScale(.3).setAlpha(.3);
//       let enojado = this.add.image(min+190-50,180+90,"enojado").setOrigin(0).setScale(.33).setAlpha(1);
  
//       check.setInteractive({ useHandCursor: true });
//       silencio.setInteractive({ useHandCursor: true });
//       enojado.setInteractive({ useHandCursor: true });
  
//       mic.on('pointerdown', (pointer, gameObject, dragX) => {
//         if(this.micEncendido){
//           apagarMic();
//         }
//         else iniciar_mic();
//       });
//       check.on('pointerdown', (pointer, gameObject, dragX) => {
//         if(this.checkSinSonido){
//           this.checkSinSonido = false;
//           check.setAlpha(.3);
//           // apagarMic();
//         }
//         else{
//           this.checkSinSonido = true;
//           check.setAlpha(1);
//         }
//       });
  
//       silencio.on('pointerdown', (pointer, gameObject, dragX) => {
//         if(this.sonidoActivo === 1){
//           this.sonidoActivo = 2;
//           silencio.setAlpha(.3);
//           enojado.setAlpha(1);
//           // apagarMic();
//         }
//         else{
//           this.sonidoActivo = 1;
//           silencio.setAlpha(1);
//           enojado.setAlpha(.3);
//         }
//       });
  
//       enojado.on('pointerdown', (pointer, gameObject, dragX) => {
//         if(this.sonidoActivo === 2){
//           this.sonidoActivo = 1;
//           enojado.setAlpha(.3);
//           silencio.setAlpha(1);
//           // apagarMic();
//         }
//         else{
//           this.sonidoActivo = 2;
//           enojado.setAlpha(1);
//           silencio.setAlpha(.3);
//         }
//       });
  
//       // this.checkSinSonido = checkEncendido;
  
//       let ayuda = this.add.sprite(this.scale.width-40,750,"ayuda").setOrigin(0.5).setScale(.3).setAlpha(1).setInteractive({ useHandCursor: true });
//       ayuda.on('pointerdown', function (pointer) {
//            ayuda.x = ayuda.x - 5;
//            ayuda.setScale(.38);
//            setTimeout(()=>ayuda.setScale(.3), 100);
//            setTimeout(()=>ayuda.x = ayuda.x + 5, 100);
//         //    Principal.global.alerta.fire({
//         //      // icon: 'warning',
//         //      width: "500",
//         //      title: 'Instrucciones:',
//         //      html: `<div style="font-size: 20px;">Controla el ruido de tu sala de manera entretenida.<br>
//         //      Por cada 10 segundos de silencio (configurable), el curso gana una estrella por no despertar a la mascota ( Fiu o Jiro)<br>
//         //      Si el curso llega a ruido nivel máximo, se pierde 1 estrella.<br>
//         //      ¡A jugar controlando el ruido!</div>`,
//         //      text: ``
//         //    });
//            // setTimeout(()=>this.scene.launch(ContenedorGeneralGlobal), 100);
//            // setTimeout(()=>this.scene.remove(PantallaPresentacionGlobal), 150);
//          }, this);
  
  
//       this.input.on('drag', (pointer, gameObject, dragX) => {
//         if(!this.micEncendido) return;
//         if(dragX<min) return;
//         if(dragX>max) return;
//         let minOriginal = min;
//         let maxOriginal = max;
//         let valorOriginal = dragX;
//         let rangoOriginal = maxOriginal - minOriginal;
//         let factorDeEscala = (3) / rangoOriginal;
//         let valorEscalado = .1 + (valorOriginal - minOriginal) * factorDeEscala;
//         this.sensibilidad = valorEscalado < .1 ? .1 : valorEscalado;
//         dragX = Phaser.Math.Clamp(dragX,min,max);
//         gameObject.x = dragX;
//       },this);
  
  
//       let verificarPermisoMic = async()=> {
//         try {
//           let query:any = { "name": 'microphone' } ;
//           const permisos = await navigator.permissions.query(query);
//           if (permisos.state === 'granted') {
//             iniciar_mic();
//             prenderMic();
//           } else {
//             // alert("debes habilitar el permiso del microfono, haciendo click en el icono")
//             iniciar_mic()
//           }
//         } catch (error) {
//           apagarMic();
//         }
//       }
//       let prenderMic = ()=>{
//         if(this.add["scene"]===null) return clearInterval(this.intervaloMic);
//         mic.setTexture("mic_icon");
//         mic.setAlpha(1);
//         linea.setAlpha(1);
//         circulo.setAlpha(1);
//       }
//       let apagarMic = ()=>{
//         if(this.add["scene"]===null) return clearInterval(this.intervaloMic);
//         this.porcentaje = 0.1;
//         this.segundosTotales = 0;
//         this.micEncendido = false;
//         clearInterval(this.intervaloMic);
//         mic.setTexture("mic_off");
//         mic.setAlpha(.5);
//         linea.setAlpha(.5);
//         circulo.setAlpha(.5);
//       }
//       let iniciar_mic = ()=>{
//         const audioContext = new (window.AudioContext)();
//         const analyser = audioContext.createAnalyser();
//         navigator.mediaDevices.getUserMedia({ audio: { echoCancellation: false } }).then(stream => {
//           this.micEncendido = true;
//           prenderMic();
//             const source:any = audioContext.createMediaStreamSource(stream);
//             source.connect(analyser);
//             analyser.fftSize = 512;
//             // analyser.minDecibels = -127;
//             // analyser.maxDecibels = 0;
//             // analyser.minDecibels = -130;
//             // analyser.maxDecibels = 0;
//             analyser.smoothingTimeConstant = .98;
//             source.connect(analyser);
//             const volumes = new Uint8Array(analyser.frequencyBinCount);
//             let calcularNivelRuido = async () => {
//                 // console.log(this.add["scene"])
//                 if(this.add["scene"]===null) return clearInterval(this.intervaloMic);


//               if(!this.micEncendido) return;
  
//               let query:any = { "name": 'microphone' } ;
//               const permisos = await navigator.permissions.query(query);
//               if (permisos.state === 'granted')prenderMic();
//               else {
//                 alert("debes habilitar el permiso del microfono, haciendo click en el icono")
//                 apagarMic();
//                 return this.micEncendido = false;;
//               }
  
//               if(source.context.state==="suspended"){
//                 return source.context.resume();
//               }
//               analyser.getByteFrequencyData(volumes);
//               // let volumeSum = 0;
//               // for(const volume of volumes)
//               //   volumeSum += volume;
              
//               //   const averageVolume = volumeSum / volumes.length;
//               // let numero = (averageVolume * 100 / 127) * this.sensibilidad
//               // numero = numero>122 ? 122: numero;
//               // numero = numero<0 ? 0: numero;
//               // this.porcentaje = numero;
  
//               var length = volumes.length;
//               let values = 0;
//               for (var i = 0; i < length; i++) {
//                 values += (volumes[i]);
//               }
    
//                 var average = values / length;
//               let averageNoiseLevel = (average + 0) * this.sensibilidad;
//               this.porcentaje = averageNoiseLevel>120 ? 120:averageNoiseLevel
  
  
//               // console.log(this.porcentaje)
  
  
//             };
//             this.intervaloMic = setInterval(calcularNivelRuido, 1);
//           })
//           .catch(error => {
//             if (error.name === 'NotAllowedError' || error.name === 'PermissionDeniedError') {
//               alert("El usuario ha denegado el acceso al micrófono después de preguntar.")
//             } else return apagarMic();  
//           });
//       }
  
//       let inicio_x = 40;
//       let padding = 74;    // const estrella = this.add.image(inicio_x, 30, 'estrella').setOrigin(0);
  
//       const cont_estrellas = this.add.sprite(10, 10, 'e0').setOrigin(0);
//       contenedor_estrellas.add(cont_estrellas);
  
//       this.intervaloDePuntos = 10;
//       this.contadorEstrellas = 0;
//       let tiempo_limpiado = false;
//       this.time.addEvent({
//         delay: 1000,
//         callback: function(){
  
//             if(tiempo_limpiado){
//               tiempo_limpiado = false;
//             }
//             if(this.jiroDurmiendo && this.micEncendido){
//               this.segundosTotales++;
//               let intervalo = Number($("#text-cant").val());
//               // if(this.segundosTotales%Math.round(intervalo/2) === 0 && this.segundosTotales != 0){
//               //   cont_estrellas.setTexture("e1");
//               // }
//               if(this.segundosTotales%intervalo === 0 && this.segundosTotales != 0){
//                 this.contadorEstrellas+=1;
//                 tiempo_limpiado=true;
//               }
//             }
//             else{
//               cont_estrellas.setTexture("e"+(this.contadorEstrellas>10?10:this.contadorEstrellas));
//             }
//           },
//           callbackScope: this,
//           loop: true
//       });
  
//       this.prenderMic = prenderMic;
//       this.apagarMic = apagarMic;
//       this.contEstrellas = cont_estrellas;
//       this.jiro = jiro2;
  
      
      
//       verificarPermisoMic();
  
//       this.add.text(inputEl.x-20,(inputEl.y-60), "Puntos por silencio\ncada", {
//         font: "bold 24px Arial",
//         align: "center",
//         color: "#ffffff"
//       });
//       this.add.text(inputEl.x+40,inputEl.y+60, "Segundos", {
//         font: "bold 24px Arial",
//         align: "center",
//         color: "#ffffff"
//       });
  
  
//       this.cantEstrellas = this.add.text(630,36, "0", {
//         font: "bold 44px Arial",
//         align: "center",
//         color: "#ffffff",
//         // backgroundColor: "red",
//         fixedWidth: 140
//       });

//       contenedor_estrellas.add(this.cantEstrellas);
      
      
//       let restaG = 10;
//       let difY = 50;
//       this.Tiempo = [
//         this.add.text(((this.scale.width)-255),(680 - difY) + diff, "Tiempo Dormido:", {font: "bold 24px Arial",align: "center",color: "#ffffff"}),
//         this.add.text(((this.scale.width)-235)-restaG,(710 - difY) + diff, "00", {font: "bold 35px Arial",align: "right",color: "#ffffff", backgroundColor: "transparent", fixedWidth: 40}),
//         this.add.text(((this.scale.width)-180)-restaG,(710 - difY) + diff, "00", {font: "bold 35px Arial",align: "center",color: "#ffffff", backgroundColor: "transparent", fixedWidth: 40}),
//         this.add.text(((this.scale.width)-120)-restaG,(710 - difY) + diff, "000", {font: "bold 35px Arial",align: "left",color: "#ffffff", backgroundColor: "transparent", fixedWidth: 60}),
  
//         this.add.text(((this.scale.width)-193)-restaG,(710 - difY) + diff, ":", {font: "bold 35px Arial",align: "center",color: "#ffffff", backgroundColor: "transparent", fixedWidth: 10}),
//         this.add.text(((this.scale.width)-135)-restaG,(710 - difY) + diff, ":", {font: "bold 35px Arial",align: "center",color: "#ffffff", backgroundColor: "transparent", fixedWidth: 10}),
  
//       ];
  
//       this.m1 = this.add.image(1050, 200, 'zz').setVisible(true);
//       this.m2 = this.add.image(700, 160, 'sh').setVisible(true);
//       this.m3 = this.add.image(1050, 160, 'silencio').setVisible(true);
  
//       this.m3.flipX = true;
      
  
//       this.t1 = this.add.text(0,this.m1.y-60, "zZzZ" , {
//         font: "bold 64px Arial",
//         align: "center",
//         color: "#ff1b88"
//       }).setVisible(true);
  
//       this.t2 = this.add.text(0,this.m2.y-10, "shhh!" , {
//         font: "bold 44px Arial",
//         align: "center",
//         color: "#ff1b88"
//       }).setVisible(true);
  
//       this.t3 = this.add.text(0,this.m3.y-10, "¡Silencio!" , {
//         font: "bold 45px Arial",
//         align: "center",
//         color: "#ff1b88"
//       }).setVisible(true);

//       contenedor_radar.add(this.m1);
//       contenedor_radar.add(this.m2);
//       contenedor_radar.add(this.m3);
//       contenedor_radar.add(this.t1);
//       contenedor_radar.add(this.t2);
//       contenedor_radar.add(this.t3);
  

//       let generarDrag = (contenedor, referencia)=>{
//         const borderGraphics = this.add.graphics();
        
//         // contenedor.add(borderGraphics);
//         const borderWidth = 5;
//         const borderColor = 0x30ff00;

//         contenedor.setSize(referencia.width, referencia.height+(referencia.height/2));

//         console.log(contenedor)

//         borderGraphics.lineStyle(borderWidth, borderColor);
//         borderGraphics.strokeRect(contenedor.x, contenedor.y, contenedor.width, contenedor.height);
//         borderGraphics.setVisible(false);

//         // contenedor.isContenedor = true;
//         contenedor.setInteractive();
//         this.input.setDraggable(contenedor);

//         contenedor.on('pointerover', () =>
//         {
//             // console.log("por aca")
//         });

//         contenedor.on('pointerout', () =>
//         {
//             // borderGraphics.setVisible(false);
//         });
//         let dragging = false;
//         contenedor.on('dragend', () =>
//         {
//             dragging = false;
//             setTimeout(()=>{
//                 if(dragging) return;
//                 if(this.add["scene"]===null) return clearInterval(this.intervaloMic);
//                 borderGraphics.setVisible(false);
//             }, 2000)
//         });

//         this.input.on('drag', (pointer, gameObject, dragX, dragY) =>
//         {
//             // console.log(gameObject)
//             if(contenedor.nameContenedor === gameObject.nameContenedor){
//                 borderGraphics.setVisible(true);
//                 gameObject.x = dragX;
//                 gameObject.y = dragY;
//                 dragging = true;
//             }
//             // gameObject.x = dragX;
//             // gameObject.y = dragY;
//         });
//       }
        
//       contenedor_estrellas.nameContenedor = "estrellas";
//       contenedor_radar.nameContenedor = "radar";
      
//       generarDrag(contenedor_radar, { height: this.jiro.height, width: this.jiro.width });
//       generarDrag(contenedor_estrellas, { height: this.contEstrellas.height, width: this.contEstrellas.height });


//       this.validaPersonaje();
//     }
  
//     validaPersonaje(){

//         this.m1.x = this.jiro.x+(this.jiro.width/2);
//         this.m2.x = this.jiro.x-(this.jiro.width/2);
//         this.m3.x = this.jiro.x+(this.jiro.width/2);
  
//         this.t1.x = this.m1.x-90;
//         this.t2.x = this.m2.x-60;
//         this.t3.x = this.m3.x-90;
  
//         this.m1.y = (this.jiro.y-200);
//         this.m2.y = (this.jiro.y-200);
//         this.m3.y = (this.jiro.y-200);
  
//         this.t1.y = this.m1.y-60;
//         this.t2.y = this.m2.y-10;
//         this.t3.y = this.m3.y-55;


//       if(this.skinSeleccionada==="fiu"){
  
//         this.jiro.setScale(.5);
//       }
//       else{
//         this.jiro.setScale(.8);    
//       }
//     }
  
//     m1:any = null;
//     m2:any = null;
//     m3:any = null;
  
//     t1:any = null;
//     t2:any = null;
//     t3:any = null;
  
//     Tiempo:any = null;
//     Radar:any = null;
//     segundosTotales:any = 0;
//     estadoMicAnterior:any = false;
//     intervaloMic:any = null;
//     apagarMic:any = null;
//     prenderMic:any = null;
//     jiro:any = null;
//     jiroDurmiendo:any = false;
//     cantEstrellas:any = null;
//     contadorEstrellas:any = 0;
//     contadorEstrellasAnt:any = -1;
//     intervaloDePuntos:any = 0;
//     contEstrellas:any = null;
//     segundosAnteriores:any = -1;
//     checkSinSonido:any = true;
//     sonidoActivo:any = 2;
//     parpadeoText:any = false;
//     numeroAnterior:any = -1;
//     cambiarJiro(numero){
//       this.validaPersonaje();
//       let nueva = this.skinSeleccionada+numero;
//       if(this.jiro.texture.key != nueva){
//         let despertando_al_jiro= ()=>{
//           if(!this.parpadeoText && this.numeroAnterior === 0){
//             console.log("despertando_al_jiro");
//             this.parpadeoText = true;
//             setTimeout(()=>{ if(this.add["scene"]===null) return clearInterval(this.intervaloMic); for(let i=0; i < this.Tiempo.length; i++) this.Tiempo[i].setColor("#FF0000");},0)
//             setTimeout(()=>{ if(this.add["scene"]===null) return clearInterval(this.intervaloMic); for(let i=0; i < this.Tiempo.length; i++) this.Tiempo[i].setColor("#FFFFFF");},100)
//             setTimeout(()=>{ if(this.add["scene"]===null) return clearInterval(this.intervaloMic); for(let i=0; i < this.Tiempo.length; i++) this.Tiempo[i].setColor("#FF0000");},200)
//             setTimeout(()=>{ if(this.add["scene"]===null) return clearInterval(this.intervaloMic); for(let i=0; i < this.Tiempo.length; i++) this.Tiempo[i].setColor("#FFFFFF");},300)
//             setTimeout(()=>{ if(this.add["scene"]===null) return clearInterval(this.intervaloMic); for(let i=0; i < this.Tiempo.length; i++) this.Tiempo[i].setColor("#FF0000");},400)
//             setTimeout(()=>{ if(this.add["scene"]===null) return clearInterval(this.intervaloMic); for(let i=0; i < this.Tiempo.length; i++) this.Tiempo[i].setColor("#FFFFFF");},500)
//             setTimeout(()=>{ if(this.add["scene"]===null) return clearInterval(this.intervaloMic); for(let i=0; i < this.Tiempo.length; i++) this.Tiempo[i].setColor("#FF0000");},600)
//             setTimeout(()=>{ if(this.add["scene"]===null) return clearInterval(this.intervaloMic); for(let i=0; i < this.Tiempo.length; i++) this.Tiempo[i].setColor("#FFFFFF");},700) 
//             setTimeout(()=>{ if(this.add["scene"]===null) return clearInterval(this.intervaloMic); for(let i=0; i < this.Tiempo.length; i++) this.Tiempo[i].setColor("#FF0000");},800)
//             setTimeout(()=>{ if(this.add["scene"]===null) return clearInterval(this.intervaloMic); for(let i=0; i < this.Tiempo.length; i++) this.Tiempo[i].setColor("#FFFFFF");},900)
//             setTimeout(()=>{ if(this.add["scene"]===null) return clearInterval(this.intervaloMic); this.parpadeoText = false},1000)
//           }
//           // this.tweens.add({
//           //   targets: [this.Tiempo[0]],
//           //   duration: 2000, // Duración de cada parpadeo en milisegundos
//           //   repeat: -1, // -1 para que se repita infinitamente
//           //   yoyo: true, // Para que la animación se revierta (rojo a blanco y viceversa)
//           //   ease: 'Power2', // Puedes cambiar esto para ajustar la interpolación de la animación
//           //   color: "0xFF0000" // Color rojo
//           // });
//         }
//         if(numero === 0){
//           this.jiroDurmiendo = true;
          
  
//           this.m1.setVisible(true);
//           this.m2.setVisible(false);
//           this.m3.setVisible(false);
          
//           this.t1.setVisible(true);
//           this.t2.setVisible(false);
//           this.t3.setVisible(false);
//         }
//         else if(numero === 5){
          
  
//           // despertando_al_jiro();
//           this.segundosTotales = 0;
//           this.jiroDurmiendo = false;
  
//           this.m2.setVisible(true);
//           this.m3.setVisible(true);
//           this.m1.setVisible(false);
  
//           this.t2.setVisible(true);
//           this.t3.setVisible(true);
//           this.t1.setVisible(false);
  
//         }
//         else {
//           despertando_al_jiro();
//           this.m1.setVisible(false);
//           this.m2.setVisible(false);
//           this.m3.setVisible(false);
  
//           this.t1.setVisible(false);
//           this.t2.setVisible(false);
//           this.t3.setVisible(false);
  
//           this.segundosTotales = 0;
//           this.jiroDurmiendo = false;
//         }
//         this.numeroAnterior = numero;
//         this.jiro.setTexture(nueva);
//       }
//     }
//     minAnterior:any = 0;
//     segAnterior:any = 0;
//     formato_tiempo(totalSeconds, isZero) {
//       var minutes = Math.floor(totalSeconds / 60);
//       var seconds = Math.floor(totalSeconds % 60);
//       var milliseconds = Math.floor((totalSeconds % 1) * 1000);
  
  
//       this.tweens.addCounter({
//         from: this.minAnterior,
//         to: minutes,
//         duration: 1000,
//         ease: 'linear',
//         onUpdate: tween =>
//         {
//             const value = Math.round(tween.getValue());
//             this.Tiempo[1].setText(value<10?("0"+value):value);
//         },
//         onComplete: t=>{
//           // this.Tiempo[3].setText("000");
//           this.minAnterior = minutes;
//         }
//       });
  
//       this.tweens.addCounter({
//         from: this.segAnterior,
//         to: seconds,
//         duration: 1000,
//         ease: 'linear',
//         onUpdate: tween =>
//         {
//             const value = Math.round(tween.getValue());
//             this.Tiempo[2].setText(value<10?("0"+value):value);
//             this.segAnterior = seconds;
//         },
//         onComplete: t=>{
//           // this.Tiempo[3].setText("000");
//         }
//       });
  
  
//       // return minutes;
//     }
//     corriendoDescuento:any = false;
//     update(){
//       if(this.flecha){
  
  
  
//         if(this.contadorEstrellasAnt != this.contadorEstrellas){
//           let currentScore = this.contadorEstrellasAnt;
//           let newScore = this.contadorEstrellas;
//           this.tweens.addCounter({
//             from: currentScore,
//             to: newScore,
//             duration: 500,
//             ease: 'linear',
//             onUpdate: tween =>
//             {
//                 const value = Math.round(tween.getValue());
//                 this.cantEstrellas.setText(value);
  
//             },
//             onComplete: t=>{
//               const emitter: any = this.add.particles('flares').createEmitter({
//                 x: this.cantEstrellas.x + (this.cantEstrellas.width / 2),
//                 y: this.cantEstrellas.y  + (this.cantEstrellas.height / 2),
//                 frame: ['green'],
//                 lifespan: 2000,
//                 speed: { min: 150, max: 250 },
//                 scale: { start: 0.8, end: 0 },
//                 gravityY: 150,
//                 blendMode: 'ADD',
//                 // emitting: false
//             });
            
//             emitter.explode(16);
//             this.contEstrellas.setTexture("e"+(this.contadorEstrellas>10?10:this.contadorEstrellas));
//               this.tweens.add({
//                 targets: this.contEstrellas,
//                 y: this.contEstrellas.y+1,
//                 // y: this.contEstrellas.y+5,
//                 duration: 500,
//                 ease: 'Quad.easeInOut',
//                 // delay: 30,
//                 yoyo: true,
//                 repeat: 0,
//                 onComplete: t=>{
//                   this.contEstrellas.setTexture("e"+(this.contadorEstrellas>10?10:this.contadorEstrellas));
//                 }
//               });
  
//             }
//           });
//           this.contadorEstrellasAnt = this.contadorEstrellas;
//         }
//         if(this.porcentaje){
//           this.flecha.rotation = Phaser.Math.DegToRad((this.porcentaje) * 1.6);
//           if(this.micEncendido != this.estadoMicAnterior){
//             this.estadoMicAnterior = this.micEncendido;
//           }
  
//           /** mascara de colores */
//           if(this.porcentaje<2){
//             this.Radar.setTexture("radar_0");
//           }
//           else if(this.porcentaje<14){
//             this.Radar.setTexture("radar_1");
//           }
//           else if(this.porcentaje<29){
//             this.Radar.setTexture("radar_2");
//           }
//           else if(this.porcentaje<56){
//             this.Radar.setTexture("radar_3");
//           }
//           else if(this.porcentaje<86){
//             this.Radar.setTexture("radar_4");
//           }
//           else if(this.porcentaje<110){
//             this.Radar.setTexture("radar_5");
//           }
//           else{
//             this.Radar.setTexture("radar_6");
//           }
  
  
//           if(this.porcentaje<1){
//             this.cambiarJiro(0);
//           }
//           else if(this.porcentaje<6){
//             this.cambiarJiro(0);
//           }
//           else if(this.porcentaje<13){
//             this.cambiarJiro(0);
//           }
//           else if(this.porcentaje<28){
//             this.cambiarJiro(1);
//           }
//           else if(this.porcentaje<34){
//             this.cambiarJiro(1);
//           }
//           else if(this.porcentaje<56){
//             this.cambiarJiro(2);
//           }
//           else if(this.porcentaje<86){
//             this.cambiarJiro(3);
//           }
//           else if(this.porcentaje<110){
//             this.cambiarJiro(4);
//           }
//           else{
  
//             // if(this.contadorEstrellas-1 >= 0){
//               if(!this.corriendoDescuento){
//                 if(this.contadorEstrellas-1 >= 0) this.contadorEstrellas -= 1;
//                 this.corriendoDescuento=true;
//                 if(this.checkSinSonido) this.sound.add(this.sonidoActivo === 2?('corneta_'+this.skinSeleccionada):'shhhh').play();
//                 setTimeout(()=>{
//                   this.corriendoDescuento=false;
//                 },5000)
//               // }
//               // this.contadorEstrellasAnt = this.contadorEstrellas;
//             }
//             this.cambiarJiro(5);
//           }
//           if(this.segundosTotales === 0 && this.segundosAnteriores > this.segundosTotales){
//             this.formato_tiempo(this.segundosTotales, true);
//             this.segundosAnteriores = this.segundosTotales;
//           }
//           else if(this.segundosAnteriores != this.segundosTotales){
//             this.formato_tiempo(this.segundosTotales, false);
//               this.tweens.addCounter({
//                 from: 0,
//                 to: 999,
//                 duration: 1000,
//                 ease: 'linear',
//                 onUpdate: tween =>
//                 {
//                     const value = Math.round(tween.getValue());
//                     this.Tiempo[3].setText(value);
//                 },
//                 onComplete: t=>{
//                   this.Tiempo[3].setText("000");
//                 }
//               });
//             this.segundosAnteriores = this.segundosTotales;
//           }
          
  
//         }
//       }
//     }
//   }
  