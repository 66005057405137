import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/inicio/services/api.service';
import { GlobalService } from 'src/app/inicio/services/global.service';
declare var $: any;

@Component({
  selector: 'app-landing-temporizadores',
  templateUrl: './landing-temporizadores.component.html',
  styleUrls: ['./landing-temporizadores.component.css']
})
export class LandingTemporizadoresComponent implements OnInit {
  alerta;
  constructor(public global: GlobalService,public api: ApiService, private router: Router) {
    this.alerta = this.global.alerta;
  }

  ngOnInit(): void {
    $('#modalaso').addClass('hidden');
    this.api.set_kpi_plataformas('Landing Temporizadores','Ingresó a módulo');
  }
  ir(ruta){
    this.navigate(ruta);
  }
  private navigate(url: string) {
    window.location.href = url;
  }
  mensaje(texto){
    this.alerta.fire({
      title: texto,
      icon: 'warning',
      showDenyButton: false,
      showCancelButton: false,
      confirmButtonText: "Ok"
    });
  }
}
