import { Component,ElementRef, OnInit, Renderer2 ,HostListener, ViewChild} from '@angular/core';
import { ApiService } from 'src/app/inicio/services/api.service';
import { GlobalService } from 'src/app/inicio/services/global.service';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { trigger, transition, animate, style } from '@angular/animations';
import { asBlob } from 'html-docx-js-typescript'
import { saveAs } from 'file-saver'

declare var $: any;
declare var jsPDF: any;
import ObjectID from "bson-objectid"
import html2canvas from 'html2canvas';
import { ActivatedRoute } from '@angular/router';
var GLOBAL;

@Component({
  selector: 'app-nube-palabras',
  templateUrl: './nube-palabras.component.html',
  styleUrls: ['./nube-palabras.component.css'],
  animations: [
    trigger('salidaHaciaArriba', [
      transition(':leave', [
        animate('0.5s', style({ transform: 'translateY(-100%)', opacity: 0 }))
      ])
    ])
  ]
})
export class NubePalabrasComponent implements OnInit {
  InstruccionInicial:any = true;
  IMG_PORTADA:any = 1;
  URL_IMG:any = "";
  FormaActual:any = "circulo";
  OrientacionActual:any = "0.5";
  tipoColor:any = "color_aleatorio";
  ColorActual:any = "#000000";
  fechaSeleccionada: string;
  Portada:any = true;
  CategoriasPalabras:any;
  TipoPalabra:any = "Seleccione";
  CategoriaActual:any = "Seleccione";
  inputProcedencia:any = "Seleccione";
  inputCurso:any = "Seleccione";
  inputLectura:any = "Seleccione";
  inputTitulo:any = "Seleccione";
  inputTexto:any = "Seleccione";
  inputCursoAlumno:any = "Seleccione";
  inputDetalleAlumno:any = "Seleccione";
  inputOpcionAlumno:any = "Seleccione";
  inputCategoria:any = "Seleccione";
  inputTitulo_original:any;
  inputTexto_original:any;
  ListadoPalabras:any = [];
  ListadoTexto:any = [];
  Lecturas:any;
  ANCHO:any = 10;
  ALTO:any = 10;
  CANTIDAD:any = 20;
  isMayuscula:any = 'minuscula';
  FuenteActual:any = "Arial";
  Procedencias:any;
  alerta;
  FiltroLecturas:any;
  Cursos:any;
  GraficoCreado:any = false;
  url_api = "https://api.gateway.integritic.cl/sv5/";
  PasoLectura = 1;
  claseBtn:any = 'btn-grey';
  audioClick:any = new Audio('assets/recursos/click.mp3');
  CategoriaSombraActual:any = "formas_geometricas";
  SombraActual:any = "circulo";
  DATOS_USUARIO = {
    'usuario': '',
    'institucion': ''
  }
  ORDEN_CURSO = [
    'NT 1','NT 2','1° Básico','2° Básico','3° Básico','4° Básico','5° Básico','6° Básico','7° Básico','8° Básico','1° Medio','2° Medio','3° Medio','4° Medio',
  ];
  cursoAlumnoDetalle:any = [];
  cursosAlumnos:any;
  params:any;
  intervaloImg;
  listadoAlumnosCurso:any;
  LECTURA_TITULO:any;
  LECTURA_TEXTO:any;
  isB2C:any = false;
  constructor(public global:GlobalService, public api:ApiService,private elementRef: ElementRef,private http: HttpClient,private renderer: Renderer2, private route: ActivatedRoute) {
    this.alerta = this.global.alerta;
    this.url_api = window.location.origin.includes('localhost') ? "http://127.0.0.1:3445/" : "https://api.gateway.integritic.cl/sv5/";
    this.url_api = "https://api.gateway.integritic.cl/sv5/";
    const hoy = new Date();
    this.fechaSeleccionada = hoy.toISOString().slice(0, 10);
    if(this.api.isIframe){
      this.route.params.subscribe((params:any) => {
        this.getDatos(params);
        this.params = params;
        if(this.params.plataforma.includes("_B2C")){
          this.isB2C = true;
        }
        this.api.getCursosSam(params).then((reponse:any)=>{
          this.cursosAlumnos  = reponse.sort(function(a, b) {
            return a.ORDEN - b.ORDEN;
          });
          console.log(this.cursosAlumnos);
          if(this.isB2C){
            let curso = [{_id: this.cursosAlumnos[0]._id, NOMBRE : "Matrícula", DETALLE: ["Todos los Estudiantes"]}];
            console.log(curso)
            this.cursosAlumnos = curso;
          }
        })
      });
    }
    $("body").css({"background": '#e2fdf3'});
  }
  changeIMG(){
    if(this.IMG_PORTADA < 5){
      this.IMG_PORTADA = this.IMG_PORTADA+1;
    }else{
      this.IMG_PORTADA = 1;
    }
  }
  async getDatos(params){
    let datos = await this.api.getDatosUsuario(params);
    this.DATOS_USUARIO = {
      'usuario': datos["usuario"].toUpperCase(),
      'institucion': datos["institucion"].toUpperCase()
    }
  }
  ngOnInit(): void {
    this.getCategorias();
    this.getLecturas();
    GLOBAL = this;
    this.api.set_kpi_plataformas("Nube de Palabras","Ingresó al módulo");
    this.intervaloImg = setInterval(this.changeIMG.bind(this), 1000);
    // this.generatePDF();
  }
  cambiaForma(){

  }
  cambiaOrientacion(){

  }
  setSombra(id){
    this.FormaActual = id;
    this.destacarBoton();
  }
  sombras:any = {
    animales : [
      {id: "pajaro", text: "Ave"},
      {id: "avestruz", text: "Avestruz"},
      {id: "caballo", text: "Caballo"},
      {id: "canguro", text: "Canguro"},
      {id: "cerdo", text: "Cerdo"},
      {id: "conejo", text: "Conejo"},
      {id: "elefante", text: "Elefante"},
      {id: "gallina", text: "Gallina"},
      {id: "gallo", text: "Gallo"},
      {id: "gato", text: "Gato"},
      {id: "lagartija", text: "Lagartija"},
      {id: "leon", text: "León"},
      {id: "mariposa", text: "Mariposa"},
      {id: "mono", text: "Mono"},
      {id: "perro", text: "Perro"},
      {id: "pez", text: "Pez"},
      {id: "ternero", text: "Ternero"},
      {id: "tiburon", text: "Tiburón"},
      {id: "tortuga", text: "Tortuga"},
      {id: "toro", text: "Toro"},
      {id: "vaca", text: "Vaca"},
    ],
    numeros: [
      {id: "0", text: "0"},
      {id: "1", text: "1"},
      {id: "2", text: "2"},
      {id: "3", text: "3"},
      {id: "4", text: "4"},
      {id: "5", text: "5"},
      {id: "6", text: "6"},
      {id: "7", text: "7"},
      {id: "8", text: "8"},
      {id: "9", text: "9"},
      {id: "10", text: "10"}
    ],
    formas_geometricas : [
      {id: "circulo", text: "Círculo"},
      {id: "corazon", text: "Corazón"},
      {id: "estrella", text: "Estrella"},
      {id: "mitad_circulo", text: "Semi Círculo"},
      {id: "pentagono", text: "Pentágono"},
      {id: "triangulo", text: "Triángulo"},
    ],
    otras_formas : [
      {id: "auto", text: "Auto"},
      {id: "fiu", text: "Fiu"},
      {id: "flor", text: "Flor"}
    ],
    navidad : [
      {id: "arbol", text: "Árbol"},
      {id: "mono_nieve", text: "Muñeco de nieve"},
      {id: "angel", text: "Ángel"},
      {id: "pascuero", text: "Viejo Pascuero"},
      {id: "bota", text: "Bota"},
      {id: "campana", text: "Campana"},
      {id: "cinta", text: "Cinta"},
      {id: "copo", text: "Copo de nieve"},
      {id: "esfera", text: "Esfera"},
      {id: "guante", text: "Guante"},
      {id: "jengi", text: "Galleta"},
      {id: "regalo", text: "Regalo"},
      {id: "reno", text: "Reno"},
      {id: "reno2", text: "Reno 2"},
      {id: "reyes_magos", text: "Reyes magos"},
      {id: "sombrero2", text: "Sombrero"},
      {id: "vela", text: "Vela"},
      {id: "jesus", text: "Jesús"},
      // {id: "maria", text: "Virgen María"},
    ],
    halloween : [
      {id: "calabaza", text: "Calabaza"},
      {id: "sombrero", text: "Sombrero"},
      {id: "arania", text: "Araña"},
      {id: "calavera", text: "Calavera"},
      {id: "fantasma", text: "Fantasma"},
      {id: "luna", text: "Luna"},
      {id: "mansion", text: "Mansión"},
      {id: "murcielago", text: "Murciélago"},
      // {id: "telaarania", text: "Tela de araña"},
      // {id: "murcielago", text: "Murciélago"},
    ],
    pokemon : [
      {id: "pikachu", text: "Pikachu"},
      {id: "bulbasaur", text: "Bulbasaur"},
      {id: "charmander", text: "Charmander"},
      {id: "psyduck", text: "Psyduck"},
      {id: "squirtle", text: "Squirtle"},
      {id: "togepi", text: "Togepi"},
      {id: "jigglypuff", text: "Jigglypuff"},
    ],
    todas : [
      {id: "pajaro", text: "Ave"},
      {id: "avestruz", text: "Avestruz"},
      {id: "caballo", text: "Caballo"},
      {id: "canguro", text: "Canguro"},
      {id: "cerdo", text: "Cerdo"},
      {id: "conejo", text: "Conejo"},
      {id: "elefante", text: "Elefante"},
      {id: "gallina", text: "Gallina"},
      {id: "gallo", text: "Gallo"},
      {id: "gato", text: "Gato"},
      {id: "lagartija", text: "Lagartija"},
      {id: "leon", text: "León"},
      {id: "mariposa", text: "Mariposa"},
      {id: "mono", text: "Mono"},
      {id: "perro", text: "Perro"},
      {id: "pez", text: "Pez"},
      {id: "ternero", text: "Ternero"},
      {id: "tiburon", text: "Tiburón"},
      {id: "tortuga", text: "Tortuga"},
      {id: "toro", text: "Toro"},
      {id: "vaca", text: "Vaca"},
      {id: "0", text: "0"},
      {id: "1", text: "1"},
      {id: "2", text: "2"},
      {id: "3", text: "3"},
      {id: "4", text: "4"},
      {id: "5", text: "5"},
      {id: "6", text: "6"},
      {id: "7", text: "7"},
      {id: "8", text: "8"},
      {id: "9", text: "9"},
      {id: "10", text: "10"},
      {id: "circulo", text: "Círculo"},
      {id: "corazon", text: "Corazón"},
      {id: "estrella", text: "Estrella"},
      {id: "mitad_circulo", text: "Semi Círculo"},
      {id: "pentagono", text: "Pentágono"},
      {id: "triangulo", text: "Triángulo"},
      {id: "auto", text: "Auto"},
      {id: "fiu", text: "Fiu"},
      {id: "flor", text: "Flor"},
      {id: "arbol", text: "Árbol"},
      {id: "mono_nieve", text: "Muñeco de nieve"},
      {id: "angel", text: "Ángel"},
      {id: "pascuero", text: "Viejo Pascuero"},
      {id: "bota", text: "Bota"},
      {id: "campana", text: "Campana"},
      {id: "cinta", text: "Cinta"},
      {id: "copo", text: "Copo de nieve"},
      {id: "esfera", text: "Esfera"},
      {id: "guante", text: "Guante"},
      {id: "jengi", text: "Galleta"},
      {id: "regalo", text: "Regalo"},
      {id: "reno", text: "Reno"},
      {id: "reno2", text: "Reno 2"},
      {id: "reyes_magos", text: "Reyes magos"},
      {id: "sombrero2", text: "Sombrero"},
      {id: "vela", text: "Vela"},
      {id: "jesus", text: "Jesús"},
      {id: "calabaza", text: "Calabaza"},
      {id: "sombrero", text: "Sombrero"},
      {id: "arania", text: "Araña"},
      {id: "calavera", text: "Calavera"},
      {id: "fantasma", text: "Fantasma"},
      {id: "luna", text: "Luna"},
      {id: "mansion", text: "Mansión"},
      {id: "murcielago", text: "Murciélago"},
      {id: "pikachu", text: "Pikachu"},
      {id: "bulbasaur", text: "Bulbasaur"},
      {id: "charmander", text: "Charmander"},
      {id: "psyduck", text: "Psyduck"},
      {id: "squirtle", text: "Squirtle"},
      {id: "togepi", text: "Togepi"},
      {id: "jigglypuff", text: "Jigglypuff"},
    ]
  }
  changeSombras(){

  }
  capitalizarPalabras(texto) {
    let palabras = texto.toLowerCase().split(' ');
    for (let i = 0; i < palabras.length; i++) {
      palabras[i] = palabras[i].charAt(0).toUpperCase() + palabras[i].slice(1);
    }
    let resultado = palabras.join(' ');
    return resultado;
  }
  contadorAlumnos:any = 0;
  totalAlumnos:any = 0;
  changeOpcionAlumno(){
    console.log(this.listadoAlumnosCurso)
    let listado = [];
    this.listadoAlumnosCurso = this.listadoAlumnosCurso.sort((a,b)=>a.ALUMNOCURSO.NUMERO_LISTA-b.ALUMNOCURSO.NUMERO_LISTA);
    if(this.inputOpcionAlumno != "Seleccione"){
      for (let x = 0; x < this.listadoAlumnosCurso.length; x++) {
        const alumno = this.listadoAlumnosCurso[x];
        let text = "";
        if(this.inputOpcionAlumno == "1"){
          text = alumno["NOMBRES"].trim().split(" ")[0];
        }else if(this.inputOpcionAlumno == "2"){
          text = alumno["APELLIDOPATERNO"]+" "+alumno["APELLIDOMATERNO"];
        }else if(this.inputOpcionAlumno == "3"){
          text = alumno["APELLIDOPATERNO"];
        }else if(this.inputOpcionAlumno == "4"){
          text = alumno["APELLIDOMATERNO"];
        }else if(this.inputOpcionAlumno == "5"){
          text = alumno["NOMBRES"].trim().split(" ")[0]+" "+alumno["APELLIDOPATERNO"]+" "+alumno["APELLIDOMATERNO"];
        }else{
          text = (alumno["NOMBRES"]+" "+alumno["APELLIDOPATERNO"]+" "+alumno["APELLIDOMATERNO"]).trim();
        }
        listado.push({PALABRA: this.capitalizarPalabras(text), N_LISTA : alumno["ALUMNOCURSO"]["NUMERO_LISTA"], ID : alumno._id,  checked : true});
      }
    }
    this.ListadoPalabras = listado;
    this.contadorAlumnos = this.ListadoPalabras.length;
    this.totalAlumnos = this.ListadoPalabras.length;
    this.destacarBoton();
  }
  uncheckAlumno(ID){
    this.ListadoPalabras.map(alumno=>{
      if(alumno.ID == ID){
        let check =  document.getElementById('check-'+ID) as HTMLInputElement
        alumno.checked = check.checked;
      }
    });
    this.contadorAlumnos = this.ListadoPalabras.filter(A => A.checked).length;
  }
  quitarAlumnos(){
    this.ListadoPalabras.map(alumno=>{
      alumno.checked = false;
    });
    this.contadorAlumnos = this.ListadoPalabras.filter(A => A.checked).length;
  }
  seleccionarAlumnos(){
    this.ListadoPalabras.map(alumno=>{
      alumno.checked = true;
    });
    this.contadorAlumnos = this.ListadoPalabras.filter(A => A.checked).length;
  }
  alumnosPrueba = [
    {
      'ALUMNOCURSO' : {'NUMERO_LISTA': '3', 'FECHA_INGRESO': '2023-05-12 04:00:00', 'FECHA_RETIRO': '1900-01-01 04:00:00', 'ESTADO': '1'},
      'APELLIDOMATERNO': "LAGOS",
      'APELLIDOPATERNO': "BARRIGA",
      'NOMBRES' : "AGUSTÍN IGNACIO",
      'RUN' : "55.619.993-0",
      '_id' : "5f85b979da13c5e92a04e709"
    },
    {
      'ALUMNOCURSO' : {'NUMERO_LISTA': '4', 'FECHA_INGRESO': '2023-05-12 04:00:00', 'FECHA_RETIRO': '1900-01-01 04:00:00', 'ESTADO': '1'},
      'APELLIDOMATERNO': "LAGOS",
      'APELLIDOPATERNO': "BARRIGA",
      'NOMBRES' : "AGUSTÍN IGNACIO",
      'RUN' : "55.619.993-0",
      '_id' : "5f85b979da13c5e61a04e709"
    },
    {
      'ALUMNOCURSO' : {'NUMERO_LISTA': '1', 'FECHA_INGRESO': '2023-05-12 04:00:00', 'FECHA_RETIRO': '1900-01-01 04:00:00', 'ESTADO': '1'},
      'APELLIDOMATERNO': "PROHENS",
      'APELLIDOPATERNO': "ÁVILA",
      'NOMBRES' : "EXEQUIEL ISAIAS",
      'RUN' : "55.619.993-0",
      '_id' : "5f85b979da13c5891a04e709"
    },
    {
      'ALUMNOCURSO' : {'NUMERO_LISTA': '5', 'FECHA_INGRESO': '2023-05-12 04:00:00', 'FECHA_RETIRO': '1900-01-01 04:00:00', 'ESTADO': '1'},
      'APELLIDOMATERNO': "LAGOS",
      'APELLIDOPATERNO': "BARRIGA",
      'NOMBRES' : "AGUSTÍN IGNACIO",
      'RUN' : "55.619.993-0",
      '_id' : "5f85b979da13c5991a04e709"
    },
    {
      'ALUMNOCURSO' : {'NUMERO_LISTA': '30', 'FECHA_INGRESO': '2023-05-12 04:00:00', 'FECHA_RETIRO': '1900-01-01 04:00:00', 'ESTADO': '1'},
      'APELLIDOMATERNO': "PEREIRA",
      'APELLIDOPATERNO': "MANCILLA",
      'NOMBRES' : "FELIPE ALEJANDRO",
      'RUN' : "55.619.993-0",
      '_id' : "5f85b979da13c5e21a04e709"
    }
  ]
  changeDetalleAlumno(){
    if(this.inputDetalleAlumno != "Seleccione" && this.inputCursoAlumno != "Seleccione"){
      $('#modalaso').removeClass('hidden');
      this.api.getAlumnosCurso({curso: this.inputCursoAlumno, letra: (this.inputDetalleAlumno == "Todos los Estudiantes" ? "B2C" : this.inputDetalleAlumno), promocion: this.params.promocion, institucion: this.params.institucion, plataforma: this.params.plataforma}).then(response=>{
        this.listadoAlumnosCurso = response;//this.alumnosPrueba;
        console.log(this.listadoAlumnosCurso)
        $('#modalaso').addClass('hidden');
        this.changeOpcionAlumno();
      });
    }
  }
  changeCursoAlumno(){
    let filtro = this.cursosAlumnos.filter(A=> A._id == this.inputCursoAlumno)[0];
    if (filtro) {
      this.cursoAlumnoDetalle = filtro.DETALLE;
      this.inputDetalleAlumno = "Seleccione";
      this.inputOpcionAlumno = "Seleccione";
      this.ListadoPalabras = [];
      if(this.isB2C){
        this.inputDetalleAlumno = "Todos los Estudiantes";
        this.changeDetalleAlumno();
      }
    }
  }
  iniciar(){
    this.audioClick.play();
    this.Portada = false;
    setTimeout(() => {
      $('.contenedor').removeClass('hidden');
    }, 100);
  }
  quitarCaracteres(texto){
    let caracteres = [".", ",", "-", "_", "¿", "?", "!", "¡", "“","”", "—", "  ", ":", "—", "…", ":", ";", "(", ")", "[", "]", "{", "}", "'", "\"", "¨", "/", "\\", "*", "#", "&", "%","..."];
    for (let x = 0; x < caracteres.length; x++) {
      texto = texto.replaceAll(caracteres[x],"");
    }
    return texto;
  }
  getClasificacionPalabras(){
    return new Promise(resolve=>{
      let parrafo = this.inputTitulo+" "+this.inputTexto;
      parrafo = this.quitarCaracteres(parrafo);
      this.http.post<any>(this.url_api+'juegoeduca/PalabrasClaves/',JSON.stringify({palabras: parrafo})).subscribe(response => {
        this.getPalabrasClaves(response);
        resolve(response);
      }, response_err=>{
        resolve(response_err.error)
      });
    });
  }
  PALABRAS_LECTURA:any = [];
  eliminarDuplicados(palabras){
    let obj_palabras = [];
    let array_palabras = [];
    for (let index = 0; index < palabras.length; index++) {
      if(!obj_palabras.includes(palabras[index].PALABRA.toLowerCase())){
        array_palabras.push(palabras[index]);
        obj_palabras.push(palabras[index].PALABRA.toLowerCase());
      }
    }
    array_palabras = array_palabras.sort(function () {
      return Math.random() - 0.5;
    });
    this.PALABRAS_LECTURA = array_palabras;
    return this.PALABRAS_LECTURA;
  }
  limpiarLectura(){
    $('#LECTURA-TITULO').val("");
    $('#LECTURA-TEXTO').val("");
    this.validar_cantidad();
  }
  async getPalabrasClaves(palabras){
    let tipos = ["NOUN","VERB"];
    let listado = palabras.filter(A => tipos.indexOf(A.PartOfSpeech.Tag) !== -1 && A.Text.length > 3);
    listado.forEach(listado => {
      listado["PALABRA"] = listado.Text;
    });
    listado = await this.eliminarDuplicados(listado);
    if(this.TipoPalabra == "LECTURAS"){
      this.procesarPalabrasTextosBD(listado);
      // this.ListadoPalabras = listado.slice(0,this.CANTIDAD);
      // $('#modalaso').addClass('hidden');
    }else{
      this.procesarPalabrasTextosPERSONALIZADO(listado);
    }
  }
  getPalabrasLectura(){
    this.PALABRAS_LECTURA = this.PALABRAS_LECTURA.sort(function () {
      return Math.random() - 0.5;
    });
    this.ListadoPalabras = this.PALABRAS_LECTURA.slice(0,this.CANTIDAD);
    this.destacarBoton();
  }
  eliminarListado(index){
    this.ListadoPalabras.splice(index, 1); 
    this.destacarBoton();   
  }
  eliminarPalabraTexto(index,palabra_index){
    let element = document.getElementById('palabra-'+palabra_index);
    this.ListadoTexto.splice(index, 1);    
    element.style.background = '';
    element.style.padding = '';
    element.style.borderRadius = '';
    element.style.color = '';
  }
  async getCategorias(){
    this.CategoriasPalabras = await this.api.getCategoriasPalabras();
    $('#modalaso').addClass('hidden');
    // return new Promise(resolve=>{
    //   this.http.get<any>(this.url_api+'juegoeduca/get/categorias_semanticas').subscribe(response => {
    //     this.CategoriasPalabras = response.filter(A=> A.INGLES_CATEGORIA_PALABRAS != "true");
    //     $('#modalaso').addClass('hidden');
    //     resolve(response);
    //   }, response_err=>{
    //     resolve(response_err.error)
    //   });
    // });
  }
  enviar_atras(){
    window.parent.postMessage({ type: 'navigate', url: "RecursosApoyo", recurso: "Nube de palabras"}, '*');
  }
  avisoSopa(){
    if(this.GraficoCreado){
      this.alerta.fire({
        allowOutsideClick: false,  // Evita cerrar al hacer clic fuera
  allowEscapeKey: false ,
        title: "¿Está seguro que desea hacer cambios?",
        text: "Se eliminará el gráfico actual",
        icon: 'warning',
        showDenyButton: false,
        showCancelButton: false,
        confirmButtonText: "Continuar",
        cancelButtonText: "Cancelar"
      }).then((result) => {
        if (result.isConfirmed) {
          this.ListadoPalabras = [];
          this.GraficoCreado = false;
          this.inputLectura = "Seleccione";          
        }
      });
    }
  }
  changeVariableTipoEntrada(){
    if(this.GraficoCreado){
      this.alerta.fire({
        allowOutsideClick: false,  // Evita cerrar al hacer clic fuera
  allowEscapeKey: false ,
        title: "¿Está seguro que desea hacer cambios?",
        text: "Se eliminará el gráfico actual",
        icon: 'warning',
        showDenyButton: false,
        showCancelButton: false,
        confirmButtonText: "Continuar",
        cancelButtonText: "Cancelar"
      }).then((result) => {
        if (result.isConfirmed) {
          this.GraficoCreado = false;
          if(this.TipoPalabra == "PERSONALIZADO-PALABRAS"){
            this.ingresarPalabras();
            this.ListadoPalabras = [];
          }else if(this.TipoPalabra == "PERSONALIZADO-TEXTO"){
            this.ListadoPalabras = [];
            this.ingresarLectura();
          }else{
            this.ListadoPalabras = [];
            this.inputCursoAlumno = "Seleccione";
            this.inputDetalleAlumno = "Seleccione"
            this.inputProcedencia = "Seleccione"
            this.inputCurso = "Seleccione"
            this.inputLectura = "Seleccione"
            this.inputCategoria = "Seleccione"
          }        
        }
      });
    }else{
      if(this.TipoPalabra == "PERSONALIZADO-PALABRAS"){
        this.ingresarPalabras();
      }else if(this.TipoPalabra == "PERSONALIZADO-TEXTO"){
        this.ingresarLectura();
      }else{
        this.ListadoPalabras = [];
        this.inputCursoAlumno = "Seleccione";
        this.inputDetalleAlumno = "Seleccione"
        this.inputProcedencia = "Seleccione"
        this.inputCurso = "Seleccione"
        this.inputLectura = "Seleccione"
        this.inputCategoria = "Seleccione"
      }
    }
  }
  getCursoB2C(){
    console.log('getcurdon2c')
  }
  borrarSeleccion(){
    this.TipoPalabra = "Seleccione"
  }
  AbrirLectura(){
    $('#modal-lectura').modal({
          backdrop: 'static',
          keyboard: false
        });
  }
  changeProcedencia(){
    if(this.GraficoCreado){
      this.alerta.fire({
        allowOutsideClick: false,  // Evita cerrar al hacer clic fuera
  allowEscapeKey: false ,
        title: "¿Está seguro que desea hacer cambios?",
        text: "Se eliminará el gráfico actual",
        icon: 'warning',
        showDenyButton: false,
        showCancelButton: false,
        confirmButtonText: "Continuar",
        cancelButtonText: "Cancelar"
      }).then((result) => {
        if (result.isConfirmed) {
          this.getLecturasByProcedencia();
          this.inputCurso = "Seleccione";
          this.inputLectura = "Seleccione";
          this.GraficoCreado = false;          
        }
      });
    }else{
      this.getLecturasByProcedencia();
          this.inputCurso = "Seleccione";
          this.inputLectura = "Seleccione";
    }
  }
  changeCurso(){
    if(this.GraficoCreado){
      this.alerta.fire({
        allowOutsideClick: false,  // Evita cerrar al hacer clic fuera
  allowEscapeKey: false ,
        title: "¿Está seguro que desea hacer cambios?",
        text: "Se eliminará el gráfico actual",
        icon: 'warning',
        showDenyButton: false,
        showCancelButton: false,
        confirmButtonText: "Continuar",
        cancelButtonText: "Cancelar"
      }).then((result) => {
        if (result.isConfirmed) {
          this.GraficoCreado = false;
          this.inputLectura = "Seleccione";
          this.getFiltroLecturas();          
        }
      });
    }else{
      this.inputLectura = "Seleccione";
      this.getFiltroLecturas();
    }
  }
  changeLectura(){
    if(this.inputProcedencia && this.inputLectura && this.inputCurso){
      if(this.GraficoCreado){
        this.alerta.fire({
          allowOutsideClick: false,  // Evita cerrar al hacer clic fuera
  allowEscapeKey: false ,
          title: "¿Está seguro que desea continuar?",
          text: "Se eliminará el gráfico actual",
          icon: 'warning',
          showDenyButton: false,
          showCancelButton: false,
          confirmButtonText: "Continuar",
          cancelButtonText: "No"
        }).then((result) => {
          if (result.isConfirmed) {
            this.GraficoCreado = false;
            this.extraerPalabras();
          }
        });
      }else{
        this.extraerPalabras();
      }
    }
  }
  async extraerPalabras(){
    let datos = this.FiltroLecturas.filter(A=> A._id == this.inputLectura)[0];
    this.inputTitulo = datos.TITULO;
    this.inputTexto = datos.TEXTO;
    $('#modalaso').removeClass('hidden');
    this.getClasificacionPalabras();
  }
  getFiltroLecturas(){
    if(this.inputCurso != "" && this.inputProcedencia != ""){
      this.FiltroLecturas = this.Lecturas.filter(A=> A.CURSO.includes(this.inputCurso));
      this.FiltroLecturas.sort(function(a, b) {
        return a.PRIORIDAD - b.PRIORIDAD;
      });
    }
  }
  async getLecturasByProcedencia(){
    $('#modalaso').removeClass('hidden');
    return new Promise(resolve=>{
      this.http.post<any>(this.url_api+'juegoeduca/get/textos_procedencia/',JSON.stringify({PROCEDENCIA: this.inputProcedencia})).subscribe(response => {
        this.Lecturas = response.textos;
        this.Cursos = this.ordenarCursos(response.cursos);
        $('#modalaso').addClass('hidden');
        resolve(response);
      }, response_err=>{
        resolve(response_err.error)
      });
    });
  }
  ordenarCursos(cursos) {
    cursos.sort((a, b) => {
      return this.ORDEN_CURSO.indexOf(a) - this.ORDEN_CURSO.indexOf(b);
    });
    return cursos;
  }
  async getLecturas(){
    return new Promise(resolve=>{
      this.http.get<any>(this.url_api+'juegoeduca/get/procedencias_textos').subscribe(response => {
        response.map(procedencia=>{
          if(procedencia.value == "Crececontigo"){
            procedencia["ORDEN"] = 3;
          }else if(procedencia.value == "Lecturas Universales"){
            procedencia["ORDEN"] = 6;
          }else if(procedencia.value == "Método Matte"){
            procedencia["ORDEN"] = 2;
          }else if(procedencia.value == "Paso a pasito"){
            procedencia["ORDEN"] = 4;
          }else if(procedencia.value == "Lecturas libro Letrapps"){
            procedencia["ORDEN"] = 5;
          }else if(procedencia.value == "LEO PRIMERO"){
            procedencia["ORDEN"] = 1;
          }
        })
        this.Procedencias = response.sort(function(a, b) {
          return a.ORDEN - b.ORDEN;
        });
        resolve(response);
      }, response_err=>{
        resolve(response_err.error)
      });
    });
  }
  SeleccionarPalabras(){
    if(this.TipoPalabra == "LECTURAS" || this.TipoPalabra == "PERSONALIZADO-TEXTO"){
        this.getPalabrasLectura();
    }else{
      this.getPalabrasCategoria();
    }
  }
  cambiarMayuscula(val){
    if(this.FuenteActual == "Ligada" && val == "mayuscula"){
      this.alerta.fire({
        allowOutsideClick: false,  // Evita cerrar al hacer clic fuera
  allowEscapeKey: false ,
        title: 'La fuente Escolar Ligada solo puede usarse en minúscula',
        icon: 'warning',
        showDenyButton: false,
        showCancelButton: false,
        confirmButtonText: "Ok"
      });
    }else{
      this.isMayuscula = val;
    }  
  }
  getPalabrasCategoria(){
    if(this.CategoriaActual != "Seleccione"){
      let palabras = this.CategoriasPalabras.filter(A=> A.CATEGORIA == this.CategoriaActual)[0].PALABRAS;
      palabras.sort(function () {
        return Math.random() - 0.5;
      });
      palabras = palabras.filter(A=> A.length > 3).slice(0,this.CANTIDAD);
      let listado = [];
      for (let index = 0; index < palabras.length; index++) {
        listado.push({
          PALABRA: palabras[index],
          INDEX: index
        });
      }
      listado.sort(function () {
        return Math.random() - 0.5;
      });
      this.ListadoPalabras = listado;
      this.destacarBoton();
      $('#modalaso').addClass('hidden');      
    }
  }
  ingresarPalabras(){
    $('#modal-ingreso-palabras').modal({
      backdrop: 'static',
      keyboard: false
    });
  //   if(this.ListadoPalabras.length < 15){
  //     $('#modal-ingreso-palabras').modal({
  //         backdrop: 'static',
  //         keyboard: false
  //       });
  //   }else{
  //     this.alerta.fire({
  //       allowOutsideClick: false,  // Evita cerrar al hacer clic fuera
  // allowEscapeKey: false ,
  //       title: 'La cantidad máxima de palabras es de 15.',
  //       icon: 'warning',
  //       showDenyButton: false,
  //       showCancelButton: false,
  //       confirmButtonText: "Ok"
  //     });
  //   }
  }
  ingresarLectura(){
    $('#modal-ingreso-lectura').modal({
          backdrop: 'static',
          keyboard: false
        });
  }
  contador_palabras:any = "Cantidad de palabras: 0";
  validar_cantidad(){
    let cantidad = $('input[name="LECTURA-TITULO"]').val().trim().split(" ").filter(A => A).length + $('textarea[name="LECTURA-TEXTO"]').val().trim().split(" ").filter(A => A).length;
    this.contador_palabras = "Cantidad de palabras: "+cantidad;
  }
  Siguiente(){
    this.ListadoPalabras = [];
    this.agregarLectura();
  }
  Atras(){
    $('#PasoLectura_1').removeClass('hidden');
    $('#PasoLectura_2').addClass('hidden');
  }
  procesarPalabrasTextosBD(listado){
    this.ListadoTexto = [];
    $('#modal-ingreso-lectura-bd').modal({
          backdrop: 'static',
          keyboard: false
        });
    $('#SPAN-TITULO-BD').empty();
    $('#SPAN-TEXTO-BD').empty();
    let titulo = this.inputTitulo.replaceAll("\n"," ").split(" ").filter(A=> A);
    let texto = this.inputTexto.replaceAll("\n"," ").split(" ").filter(A=> A);
    let indexPalabra = 0;
    let html_titulo = "";
    for (let x = 0; x < titulo.length; x++) {
      const palabra = titulo[x];
      html_titulo += '<span class="palabra-texto" id="palabra-'+indexPalabra+'" style="cursor:pointer">'+palabra+"</span>"+"&nbsp;";
      indexPalabra++;
    }
    let html_texto = "";
    for (let y = 0; y < texto.length; y++) {
      const palabra = texto[y];
      html_texto += '<span class="palabra-texto" id="palabra-'+indexPalabra+'" style="cursor:pointer">'+palabra+"</span>"+"&nbsp;";
      indexPalabra++;
    }
    $('#SPAN-TITULO-BD').html(html_titulo);
    $('#SPAN-TEXTO-BD').html(html_texto);
    this.findInTexto('SPAN-TITULO-BD','SPAN-TEXTO-BD',listado);
  }
  findInTexto(titulo,texto,listado){
    const spans = document.querySelectorAll('#'+titulo+' span');
    const spans2 = document.querySelectorAll('#'+texto+' span');
    let contador_ingresos = 0;
    for (let z = 0; z < listado.length; z++) {
      let letra = listado[z];
      let spanEncontrado = null;
      spans2.forEach(span => {
        if (span.textContent.trim() === letra.PALABRA) {
          spanEncontrado = span;
          return; // Salir del bucle forEach una vez encontrado el span
        }
      });
      //AQUI OBTENER SPAN QUE CONTENGA LETRA INGRESADO
      const element = spanEncontrado;
      if(element && contador_ingresos < this.CANTIDAD){
        let index = element.id.replaceAll('palabra-','');
        element.style.background = '#1a9476';
        element.style.padding = '1px 5px';
        element.style.borderRadius = '3px';
        element.style.color = 'white';
        let letra_aux = letra.Text.trim();
        letra_aux = this.quitarCaracteres(letra_aux);
        this.ListadoTexto.push({PALABRA: letra_aux, INDEX : index});
        contador_ingresos++;
      }
    }
    $('.palabra-texto').on('click',function(event){
      GLOBAL.agregarPalabraTexto(event);
    })
    $('#modalaso').addClass('hidden');
  }
  procesarPalabrasTextosPERSONALIZADO(listado){
    this.ListadoTexto = [];
    $('#SPAN-TITULO').empty();
    $('#SPAN-TEXTO').empty();
    let titulo = this.inputTitulo.replaceAll("\n"," ").split(" ").filter(A=> A);
    let texto = this.inputTexto.replaceAll("\n"," ").split(" ").filter(A=> A);
    let indexPalabra = 1;
    let html_titulo = "";
    for (let x = 0; x < titulo.length; x++) {
      const palabra = titulo[x];
      html_titulo += '<span class="palabra-texto" id="palabra-'+indexPalabra+'" style="cursor:pointer">'+palabra+"</span>"+"&nbsp;";
      indexPalabra++;
    }
    let html_texto = "";
    for (let y = 0; y < texto.length; y++) {
      const palabra = texto[y];
      html_texto += '<span class="palabra-texto" id="palabra-'+indexPalabra+'" style="cursor:pointer">'+palabra+"</span>"+"&nbsp;";
      indexPalabra++;
    }
    $('#SPAN-TITULO').html(html_titulo);
    $('#SPAN-TEXTO').html(html_texto);
    this.findInTexto('SPAN-TITULO','SPAN-TEXTO',listado);
  }
  agregarPalabraTexto(objeto){
    let palabra = objeto.currentTarget.innerHTML;
    let index_palabra = objeto.currentTarget.id.replaceAll("palabra-","");
    palabra = this.quitarCaracteres(palabra);
    let index = -1;
    let mismas_palabras_index = [];
    for (let i = 0; i < this.ListadoTexto.length; i++) {
      if (this.ListadoTexto[i].PALABRA === palabra) {
        if(this.ListadoTexto[i].INDEX == index_palabra){
          index = i;
          break;
        }else{
          mismas_palabras_index.push(this.ListadoTexto[i].INDEX);
        }
      }
    }
    const element = document.getElementById(objeto.currentTarget.id);
    if (index !== -1) {
      // EXISTE LA PALABRA CON EL LMISMO INDEX LA SACA
      this.ListadoTexto.splice(index, 1);
      element.style.background = '';
      element.style.padding = '';
      element.style.borderRadius = '';
      element.style.color = '';
    } else {
      // NO EXISTE LA PALABRA CONSULTA SI YA HAY OTRA PALABRA IGUAL
      if(mismas_palabras_index.length == 0){
        if(this.ListadoTexto.length == 15){
          this.alerta.fire({
            allowOutsideClick: false,  // Evita cerrar al hacer clic fuera
  allowEscapeKey: false ,
            title: 'La cantidad máxima de palabras es de 15.',
            icon: 'warning',
            showDenyButton: false,
            showCancelButton: false,
            confirmButtonText: "Ok"
          });
        }else{
          //NO HAY PALABRAS
          this.ListadoTexto.push({PALABRA: palabra, INDEX: objeto.currentTarget.id.replaceAll("palabra-","")});
          element.style.background = '#1a9476';
          element.style.padding = '1px 5px';
          element.style.borderRadius = '3px';
          element.style.color = 'white';
        }
      }else{
        this.alerta.fire({
          allowOutsideClick: false,  // Evita cerrar al hacer clic fuera
  allowEscapeKey: false ,
          title: 'Ya está ingresada la palabra "'+palabra+'"',
          icon: 'warning',
          showDenyButton: false,
          showCancelButton: false,
          confirmButtonText: "Ok"
        });
      }
    }
  }
  agregarLectura(){
    let texto_total = $('input[name="LECTURA-TITULO"]').val().trim().split(" ").filter(A => A).length + $('textarea[name="LECTURA-TEXTO"]').val().trim().split(" ").filter(A => A).length;
    if($('input[name="LECTURA-TITULO"]').val().trim().length > 0 && $('textarea[name="LECTURA-TEXTO"]').val().trim().length > 0){
      if(texto_total > 30){
        $('#modalaso').removeClass('hidden');
        this.inputTitulo_original = $('input[name="LECTURA-TITULO"]').val();
        this.inputTexto_original = $('textarea[name="LECTURA-TEXTO"]').val();
        this.inputTitulo = $('input[name="LECTURA-TITULO"]').val().trim().replaceAll("\n"," ").replaceAll("  "," ");
        this.inputTexto = $('textarea[name="LECTURA-TEXTO"]').val().trim().replaceAll("\n"," ").replaceAll("  "," ");
        this.getClasificacionPalabras();
        $('#PasoLectura_1').addClass('hidden');
        $('#PasoLectura_2').removeClass('hidden');
      }else{
        this.alerta.fire({
          allowOutsideClick: false,  // Evita cerrar al hacer clic fuera
  allowEscapeKey: false ,
          title: "El texto debe contener al menos 30 palabras",
          icon: 'warning',
          showDenyButton: false,
          showCancelButton: false,
          confirmButtonText: "Ok"
        });
      }
    }else{
      this.alerta.fire({
        allowOutsideClick: false,  // Evita cerrar al hacer clic fuera
  allowEscapeKey: false ,
        title: "Complete los campos para continuar",
        icon: 'warning',
        showDenyButton: false,
        showCancelButton: false,
        confirmButtonText: "Ok"
      });
    }
  }
  cambioSopa(){
    if(this.GraficoCreado){
      this.destacarBoton();
    }
  }
  LecturaProcesada(){
    this.ListadoPalabras = this.ListadoTexto;
    this.destacarBoton();
    $('#modal-ingreso-lectura').modal('hide');    
    $('#CANTIDAD').val(this.ListadoTexto.length);
  }
  LecturaProcesadaBD(){
    this.ListadoPalabras = this.ListadoTexto;
    this.destacarBoton();
    $('#modal-ingreso-lectura-bd').modal('hide');    
    $('#CANTIDAD').val(this.ListadoTexto.length);
  }
  agregarPalabras(){
    let palabras_seteado = [];
    let palabras = $('#EXTRAS').val().split('\n');
    for (let index = 0; index < palabras.length; index++) {
      const linea = palabras[index].split(" ");
      for (let x = 0; x < linea.length; x++) {
        let palabra = linea[x];
        if(palabra){
          palabra = this.quitarCaracteres(palabra);
          if(!palabras_seteado.includes(palabra.toLowerCase())){
            palabras_seteado.push(palabra.toLowerCase());
          }
        }
      }
    }
    if((this.ListadoPalabras.length + palabras_seteado.length) <= 15){
      for (let x = 0; x < palabras_seteado.length; x++) {
        if(palabras_seteado[x].trim() != ""){
          let existe = this.ListadoPalabras.filter(A=> A.PALABRA.trim() === palabras_seteado[x].trim())[0];
          if(!existe){
            this.ListadoPalabras.push({PALABRA: palabras_seteado[x].trim()});
          }          
        }
      }      
      $('#EXTRAS').val("");
      $('#modal-ingreso-palabras').modal('hide');
      this.destacarBoton();
    }else{
      let faltan = 15 - (this.ListadoPalabras.length);
      this.alerta.fire({
        allowOutsideClick: false,  // Evita cerrar al hacer clic fuera
  allowEscapeKey: false ,
        title: "La cantidad máxima de palabras es de 15",
        text: "¿desea continuar con las primeras "+faltan+" palabras ingresadas?",
        icon: 'warning',
        showDenyButton: false,
        showCancelButton: false,
        confirmButtonText: "Continuar",
        cancelButtonText: "No"
      }).then((result) => {
        if (result.isConfirmed) {
          palabras_seteado = palabras_seteado.slice(0,faltan);
          for (let x = 0; x < palabras_seteado.length; x++) {
            if(palabras_seteado[x].trim() != ""){
              let existe = this.ListadoPalabras.filter(A=> A.PALABRA.trim() === palabras_seteado[x].trim())[0];
              if(!existe){
                this.ListadoPalabras.push({PALABRA: palabras_seteado[x].trim()});
              }              
            }
          }          
          $('#EXTRAS').val("");
          $('#modal-ingreso-palabras').modal('hide');
          this.destacarBoton();
        }
      });
    }
  }
  changeCategoria(event){
    if(this.GraficoCreado){
      this.alerta.fire({
        allowOutsideClick: false,  // Evita cerrar al hacer clic fuera
  allowEscapeKey: false ,
        title: "¿Está seguro que desea hacer cambios?",
        text: "Se eliminará el gráfico actual",
        icon: 'warning',
        showDenyButton: false,
        showCancelButton: false,
        confirmButtonText: "Continuar",
        cancelButtonText: "Cancelar"
      }).then((result) => {
        if (result.isConfirmed) {
          this.GraficoCreado = false;
          this.CategoriaActual = event.target.value;          
          this.getPalabrasCategoria();
        }
      });
    }else{
      this.CategoriaActual = event.target.value;
      this.getPalabrasCategoria();
    }
  }
  changeConfiguracion(){
    if(this.GraficoCreado){
      this.alerta.fire({
        allowOutsideClick: false,  // Evita cerrar al hacer clic fuera
        allowEscapeKey: false ,
        title: "¿Está seguro que desea hacer cambios?",
        text: "Se eliminará el gráfico actual",
        icon: 'warning',
        showDenyButton: false,
        showCancelButton: false,
        confirmButtonText: "Continuar",
        cancelButtonText: "Cancelar"
      }).then((result) => {
        if (result.isConfirmed) {
          this.GraficoCreado = false;
          this.destacarBoton();
        }        
      });
    }
  }
  mensaje_info(texto){
    this.alerta.fire({
      allowOutsideClick: false,  // Evita cerrar al hacer clic fuera
  allowEscapeKey: false ,
      title: texto,
      icon: 'info',
      showDenyButton: false,
      showCancelButton: false,
      confirmButtonText: "Ok"
    });
  }
  LimpiarListado(){
    this.alerta.fire({
      allowOutsideClick: false,  // Evita cerrar al hacer clic fuera
  allowEscapeKey: false ,
      title: "¿Está seguro que desea hacer cambios?",
      text: "Se eliminará el gráfico actual",
      icon: 'warning',
      showDenyButton: false,
      showCancelButton: false,
      confirmButtonText: "Continuar",
      cancelButtonText: "Cancelar"
    }).then((result) => {
      if (result.isConfirmed) {
        this.ListadoPalabras = [];
        this.GraficoCreado = false;
        this.TipoPalabra = "Seleccione";
        this.inputProcedencia = "Seleccione";
        this.inputCurso = "Seleccione";
        this.inputLectura = "Seleccione";        
      }
    });
  }
  getDataPalabras(){
    let parrafo = "";
    let datos = this.ListadoPalabras;
    if(this.TipoPalabra == "CURSO"){
      datos = this.ListadoPalabras.filter(A => A.checked);
    }
    for (let x = 0; x < datos.length; x++) {
      const palabra = datos[x].PALABRA;
      parrafo += palabra+" ";
    }
    return new Promise(resolve=>{
      let array_palabras = [];
      this.http.post<any>(this.url_api+'juegoeduca/PalabrasClaves/',JSON.stringify({palabras: parrafo})).subscribe(response => {
        for (let x = 0; x < response.length; x++) {
          const data_palabras = response[x];
          array_palabras.push({
            cantidad : 1,
            palabra : this.isMayuscula == 'mayuscula' ? (data_palabras.Text.toUpperCase()) : (data_palabras.Text.toLowerCase()),
            precision : data_palabras.PartOfSpeech.Score,
            tipo : data_palabras.PartOfSpeech.Tag
          });
        }
        resolve(array_palabras);
      }, response_err=>{
        resolve(response_err.error)
      });
    });
  }
  GenerarGrafico(){
    if(this.TipoPalabra == 'CURSO'){
      if(this.contadorAlumnos == 0){
        this.alerta.fire({
          allowOutsideClick: false,  // Evita cerrar al hacer clic fuera
          allowEscapeKey: false ,
          title: "Debe seleccionar al menos un estudiante",
          icon: 'warning',
          showDenyButton: false,
          showCancelButton: false,
          confirmButtonText: "Ok"
        });
      }else{
        this.crearImagenGrafico();
      }
    }else{
      this.crearImagenGrafico();
    }
  }
  async crearImagenGrafico(){
    this.audioClick.play();
    $('#modalaso').removeClass('hidden');
    let data = await this.getDataPalabras();
    return new Promise(resolve=>{
      this.http.post<any>('https://cloudword.ecs.integritic.cl/generate/chart',{forma: this.FormaActual, data: data, orientacion: this.OrientacionActual, tipo_color: this.tipoColor, color: this.ColorActual},{
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        }
      }).subscribe(response => {
        this.URL_IMG = response.image;
        this.GraficoCreado = true;
        this.InstruccionInicial = false;
        clearInterval(this.intervaloImg);
        this.quitarDestacado();
        $('#modalaso').addClass('hidden');
        let kpi = "Generó Gráfico: ";
        if(this.TipoPalabra == "CURSO"){
          kpi += "Curso / ";
        }else if(this.TipoPalabra == "PALABRAS"){
          kpi += "Categoría de Palabras / ";
        }else if(this.TipoPalabra == "LECTURAS"){
          kpi += "Lecturas / ";
        }else if(this.TipoPalabra == "PERSONALIZADO-PALABRAS"){
          kpi += "Palabras personalizadas / ";
        }else if(this.TipoPalabra == "PERSONALIZADO-TEXTO"){
          kpi += "Lectura Personalizada / ";
        }
        kpi += this.FormaActual+" / "+this.OrientacionActual+" / "+this.tipoColor+" / "+this.ColorActual;
        console.log(kpi)
        this.api.set_kpi_plataformas('Nube de Palabras',kpi);
        resolve(response);
      }, response_err=>{
        resolve(response_err.error)
      });
    });
  }
  COLORES:any =[
    '#FF4D0059',
    '#0059FF59',
    '#cfff3359',
    '#FF7F0059',
    '#7F00FF59',
    '#C7003959',
    '#FFC30059',
    '#00A70059',
    '#FFC3A059',
    '#00ff7f59',
    '#0057FF59',
    '#FF000059',
    '#FF7F0059',
    '#FFFF0059',
    '#00FF0059',
    '#00FF7F59',
    '#00FFFF59',
    '#007FFF59',
    '#0000FF59',
    '#7F00FF59',
    '#FF00FF59',
    '#FF007F59',
    '#FF450059',
    '#FF634759',
    '#FF8C0059',
    '#FFA50059',
    '#FFD70059',
    '#ADFF2F59',
    '#32CD3259',
    '#9400D359',
    '#80008059'
  ];
  modalPDF(){
    if(!this.GraficoCreado){
      this.alerta.fire({
        allowOutsideClick: false,  // Evita cerrar al hacer clic fuera
  allowEscapeKey: false ,
        title: "Debe generar la nube de palabras",
        icon: 'warning',
        showDenyButton: false,
        showCancelButton: false,
        confirmButtonText: "Ok"
      });
      return;
    }
    $('#modal-impresion').modal({
          backdrop: 'static',
          keyboard: false
        });
  }
  quitarDestacado(){
    this.claseBtn = 'btn-primary';
    $('#boton-generar').html('<i class="icon-spinner11" style="margin-right: 5px;font-size: 20px;"></i> Generar Nube');
    $('#boton-generar').removeClass('pulso-css');
  }
  destacarBoton(){
    this.claseBtn = 'btn-verde';
    if(this.GraficoCreado){
      $('#boton-generar').html('<i class="icon-spinner11" style="margin-right: 5px;font-size: 20px;"></i> Re-generar Nube');
    }else{
      $('#boton-generar').html('<i class="icon-spinner11" style="margin-right: 5px;font-size: 20px;"></i> Generar Nube');
    }
    $('#boton-generar').addClass('pulso-css');
  }
  // generatePDF() {
  //   $('#modalaso').removeClass('hidden');
  //   var fechaOriginal = $('#Fecha').val();
  //   if(fechaOriginal != ''){
  //     var partesFecha = fechaOriginal.split('-');
  //     var año = partesFecha[0];
  //     var mes = partesFecha[1];
  //     var día = partesFecha[2];
  //     fechaOriginal = día + '-' + mes + '-' + año;
  //   }else{
  //     fechaOriginal = '_______/_______/_______'
  //   }
  //   this.OPCIONES_ADICIONALES_PDF["Título"] = $('#Título').val();
  //   this.OPCIONES_ADICIONALES_PDF["Nombre"] = $('#Nombre').val();
  //   this.OPCIONES_ADICIONALES_PDF["Curso"] = $('#Curso').val();
  //   this.OPCIONES_ADICIONALES_PDF["Fecha"] = fechaOriginal;
  //   this.OPCIONES_ADICIONALES_PDF["Colegio"] = $('#Colegio').val();
  //   this.OPCIONES_ADICIONALES_PDF["Docente"] = $('#Docente').val();
  //   this.OPCIONES_ADICIONALES_PDF["Instrucciones"] = $('#Instrucciones').val();
  //   if(this.ListadoPalabras.length == 0 && !this.GraficoCreado){
  //     this.alerta.fire({
  //   allowOutsideClick: false,  // Evita cerrar al hacer clic fuera
  // allowEscapeKey: false ,
  //       title: "Debe ingresar al menos una palabra y crear nube",
  //       icon: 'warning',
  //       showDenyButton: false,
  //       showCancelButton: false,
  //       confirmButtonText: "Ok"
  //     });
  //     return;
  //   }
  //   const pdf = new jsPDF('p', 'px', this.TAMANIO);
  //   $('.silaba').css("background","white");
  //   const element = document.getElementById('sopaDeLetras');
  //   html2canvas(element).then((canvas) => {
  //     var pageHeight = pdf.internal.pageSize.height || pdf.internal.pageSize.getHeight();
  //     var pageWidth = pdf.internal.pageSize.width || pdf.internal.pageSize.getWidth();
  //     const imageData = canvas.toDataURL('image/png');
  //     const imgWidth = this.TAMANIO == 'letter' ? (this.isListado ? (this.ALTO > 10 ? 270 : 320) : 400) : 400;
  //     const imgHeight = (canvas.height * imgWidth) / canvas.width;
  //     const xOffsetImg = (pdf.internal.pageSize.getWidth() - imgWidth) / 2;
  //     pdf.addImage(imageData, 'PNG', xOffsetImg, 150, imgWidth, imgHeight);
  //     var ubicacion_y = 0;
  //     //OPCIONES ADICIONALES
  //     for(let x in this.OPCIONES_ADICIONALES_PDF){
  //       pdf.setFont("Helvetica");
  //       pdf.setFontSize(12);
  //       if(x == "Nombre" && this.checkNombre){
  //         pdf.text(x+":",(25),(50+ubicacion_y));
  //         if(this.OPCIONES_ADICIONALES_PDF[x] != ""){
  //           pdf.text(this.OPCIONES_ADICIONALES_PDF[x],(65),(50+ubicacion_y));
  //         }else{
  //           pdf.text("_______________________________________________________________________",(65),(50+ubicacion_y));
  //         }
  //         ubicacion_y = ubicacion_y + 15;
  //       }else if(x == "Curso" && this.checkCurso){
  //         pdf.text(x+":",(25),(50+ubicacion_y));
  //         if(this.OPCIONES_ADICIONALES_PDF[x] != ""){
  //           pdf.text(this.OPCIONES_ADICIONALES_PDF[x],(65),(50+ubicacion_y));
  //         }else{
  //           pdf.text("______________________________________",(65),(50+ubicacion_y));
  //         }
  //       }else if(x == "Fecha" && this.checkFecha){
  //         pdf.text(x+":",(25+240),(50+ubicacion_y));
  //         if(this.OPCIONES_ADICIONALES_PDF[x] != ""){
  //           pdf.text(this.OPCIONES_ADICIONALES_PDF[x],(65+240),(50+ubicacion_y));
  //         }else{
  //           pdf.text("_______________________",(65+240),(50+ubicacion_y));
  //         }
  //         ubicacion_y = ubicacion_y + 15;
  //       }else if(x == "Colegio" && this.checkColegio){
  //         pdf.text(x+":",(25),(50+ubicacion_y));
  //         if(this.OPCIONES_ADICIONALES_PDF[x] != ""){
  //           pdf.text(this.OPCIONES_ADICIONALES_PDF[x],(65),(50+ubicacion_y));
  //         }else{
  //           pdf.text("_______________________________________________________________________",(65),(50+ubicacion_y));
  //         }
  //         ubicacion_y = ubicacion_y + 15;
  //       }else if(x == "Docente" && this.checkDocente){
  //         pdf.text(x+":",(25),(50+ubicacion_y));
  //         if(this.OPCIONES_ADICIONALES_PDF[x] != ""){
  //           pdf.text(this.OPCIONES_ADICIONALES_PDF[x],(65),(50+ubicacion_y));
  //         }else{
  //           pdf.text("_______________________________________________________________________",(65),(50+ubicacion_y));
  //         }
  //         ubicacion_y = ubicacion_y + 15;
  //       }else if(x == "Instrucciones" && this.checkInstruccion){
  //         pdf.text(x+":",(25),(50+ubicacion_y));
  //         if(this.OPCIONES_ADICIONALES_PDF[x] != ""){
  //           if(this.OPCIONES_ADICIONALES_PDF[x].length > 80){
  //             let texto = this.splitText(this.OPCIONES_ADICIONALES_PDF[x]);
  //             pdf.text(texto[0],(85),(50+ubicacion_y));
  //             pdf.text(texto[1],(25),(50+ubicacion_y+15));
  //           }else{
  //             pdf.text(this.OPCIONES_ADICIONALES_PDF[x],(85),(50+ubicacion_y));
  //           }
            
  //         }else{
  //           pdf.text("___________________________________________________________________",(85),(50+ubicacion_y));
  //           pdf.text("_______________________________________________________________________________",(25),(50+ubicacion_y+15));
  //         }
  //         ubicacion_y = ubicacion_y + 15;
  //       }
  //     }
  //     if(this.isListado){
  //       pdf.setFillColor(255, 238, 238);
  //       pdf.rect(0, this.TAMANIO == 'letter' ? 480 : 570, pdf.internal.pageSize.getWidth(), (this.TAMANIO == 'letter' ? 150 : 200), 'F');
  //       pdf.setFontSize(15);
  //       let texto = 'Listado de Palabras:';
  //       var fontSize = 15; // Tamaño de la fuente
  //       var textoWidth = pdf.getStringUnitWidth(texto) * fontSize / pdf.internal.scaleFactor;
  //       // Calcular la posición x para centrar el texto
  //       var x = (pdf.internal.pageSize.getWidth() - textoWidth) / 2;

  //       pdf.text('Listado de Palabras:', x, (this.TAMANIO == 'letter' ? 500 : 600));
  //       pdf.setFontSize(13);
  //       let y_inicial = this.TAMANIO == 'letter' ? 520 : 630;
  //       let x_inicial = this.TAMANIO == 'letter' ? 20 : 20;
  //       let limite = this.TAMANIO == 'letter' ? 3 : 4;
  //       let contador = 0;
  //       for (let x = 0; x < this.ListadoPalabras.length; x++) {
  //         pdf.text((x+1)+".- "+this.ListadoPalabras[x].PALABRA, x_inicial, y_inicial);
  //         y_inicial += 15;
  //         contador++;
  //         if(contador == limite){
  //           y_inicial = this.TAMANIO == 'letter' ? 520 : 630;
  //           x_inicial += 85;
  //           contador = 0;
  //         }
  //       }
  //     }
  //     for(let i = 0; i < pdf.internal.getNumberOfPages(); i++) {
  //       pdf.setPage(i); 
  //       var pag_actual = pdf.internal.getCurrentPageInfo().pageNumber;
  //       pdf.setTextColor(0, 0, 0);
  //       pdf.setFont('Helvetica', 'bold');
  //       pdf.setFontSize(15);
  //       if(this.checkTitulo){
  //         pdf.text(this.OPCIONES_ADICIONALES_PDF["Título"], pageWidth / 2, 30, {align: 'center'});
  //       }
  //       pdf.setFont('Helvetica');
  //       pdf.setFontSize(8);
  //       pdf.setTextColor(117, 115, 109);
  //       pdf.setDrawColor(0, 0, 0).setLineWidth(0.1).line(25, (pageHeight-30), 430, (pageHeight-30));
  //       pdf.text("Nube de palabras", 25, pageHeight-20);
  //       pdf.text("www.integritic.cl", 150, pageHeight-20);
  //       pdf.text("soporte@integritic.cl", 210, pageHeight-20);
  //       pdf.text("+56932390405", 280, pageHeight-20);
  //       pdf.text("Página "+(pag_actual)+"/"+pdf.internal.getNumberOfPages(), 400, pageHeight-20);
  //     }
  //     pdf.save(this.OPCIONES_ADICIONALES_PDF["Título"].replaceAll(' ','_')+'.pdf');
  //     // const pdfData = pdf.output('blob');
  //     // const fileURL = URL.createObjectURL(pdfData);

  //     // window.open(fileURL, '_blank');
  //     if(this.mostrarSolucion){
  //       this.Findsolucion();
  //     }
  //     $('#modal-impresion').modal('hide');
  //     $('#modalaso').addClass('hidden');
  //     this.api.set_kpi_plataformas('Nube de palabras','Genera PDF');
  //   });
  // }  

  calculateWidth(): void {
    const imgElement = document.getElementById('imgNube') as HTMLImageElement;
    const originalWidth = imgElement.naturalWidth;
    const originalHeight = imgElement.naturalHeight;
    const maxHeight = 450;
    // Calculate the new width to maintain the aspect ratio
    let newWidth:any = (originalWidth / originalHeight) * maxHeight;
    return newWidth;
  }
  async generateDocx() {
    let width = await this.calculateWidth();
    var html = '';
    let newBreakLine = () => {
        html += '<br>'
    }
    let plataforma = "";
    // html += '<div  align="left">'
    // if(this.api.OrigenIframe == 'juegoeduca'){
    //   plataforma = "Juegoeduca.com";
    //   let url_data = "https://soporte-integritic.s3.amazonaws.com/LSCH/logo1.png";
    //   let b64 = await this.api.getImgb64(url_data);
    //   // html += '<img src="'+(b64["data"])+'"  width="80" height="38">'
    // }else if(this.api.OrigenIframe == 'tabulatest'){
    //   plataforma = "Tabulatest.cl";
    //   let url_data = "https://soporte-integritic.s3.amazonaws.com/LOGOS/logo_tbt.png";
    //   let b64 = await this.api.getImgb64(url_data);
    //   // html += '<img src="'+(b64["data"])+'"  width="100" height="27">'
    // }else if(this.api.OrigenIframe == 'letrapps'){
    //   plataforma = "Letrapps.cl";
    //   let url_data = "https://soporte-integritic.s3.amazonaws.com/LOGOS/lt_rojo.png";
    //   let b64 = await this.api.getImgb64(url_data);
    //   // html += '<img src="'+(b64["data"])+'"  width="95" height="27">'
    // }else if(this.api.OrigenIframe == 'inclutics'){
    //   plataforma = "Inclutics.cl";
    //   let url_data = "https://soporte-integritic.s3.amazonaws.com/LOGOS/inclutics_logo_largo.png";
    //   let b64 = await this.api.getImgb64(url_data);
    //   // html += '<img src="'+(b64["data"])+'"  width="100" height="20">'
    // }else{
    //   plataforma = "Juegoeduca.com";
    //   let url_data = "https://soporte-integritic.s3.amazonaws.com/LSCH/logo1.png";
    //   let b64 = await this.api.getImgb64(url_data);
    //   // html += '<img src="'+(b64["data"])+'"  width="80" height="38">'
    // }      
    // html += '</div>'
    html += '<h4><center>Nube de Palabras</center></h4>';
    if(this.TipoPalabra == "CURSO"){
      html += "<h3><center>Curso: "+($('#cursoAlumno option:selected').text()+" "+$('#detalleAlumno option:selected').text())+"</center></h3>";
    }else if(this.TipoPalabra == "PALABRAS"){
      html += "<h3><center>Categoría de Palabras: "+($('#categorias option:selected').text())+"</center></h3>";
    }else if(this.TipoPalabra == "LECTURAS"){
      html += "<h3><center>Lectura: "+($('#lectura option:selected').text())+"</center></h3>";
    }else if(this.TipoPalabra == "PERSONALIZADO-PALABRAS"){
      html += "<h3><center>Palabras personalizadas"+"</center></h3>";
    }else if(this.TipoPalabra == "PERSONALIZADO-TEXTO"){
      console.log(this.LECTURA_TITULO)
      html += "<h3><center>Lectura Personalizada: "+(this.LECTURA_TITULO)+"</center></h3>";
    }
    html += '<center><img src="'+(this.URL_IMG)+'"  width="'+width+'" height="450"></center>'
    var tabla = '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; www.integritic.cl &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; soporte@integritic.cl &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; +56 9 3239 0405';
    var header = '<!DOCTYPE html><head><meta http-equiv="Content-Type" content="text/html; charset=UTF-8"><style type="text/css">@page Section1 {  margin:0.75in 0.75in 0.75in 0.75in;  size:841.7pt 595.45pt;  mso-page-orientation:landscape;  mso-header-margin:0.5in;  mso-header: h1;  mso-footer-margin:0.5in;  mso-footer: f1;    }    div.Section1 {page:Section1;}    p.headerFooter { margin:0in; text-align: center; }    </style></head>';
    var content = '<body style="font-family:Arial"><div class=Section1><table style="margin-left:50in;"><tr style="height:1pt;mso-height-rule:exactly"><td><div style="text-align: center;mso-element:footer" id=f1>'+tabla+'</div>&nbsp;</td></tr></table>'+html+'</div></body></html>'
    asBlob(header+content ,{ orientation: "landscape", margins: { top: 20, left: 20, right: 20 } }).then(data => {
      saveAs(data, 'Nube de Palabras.docx');
      this.api.set_kpi_plataformas('Nube de Palabras','Exportó word');
    })
    // $('.prueba').html(header+content);
    //HEADER Y FOOTER CON PAGINACION
    //var content = '<!DOCTYPE html><head><style type="text/css">@page Section1 {  margin:0.75in 0.75in 0.75in 0.75in;  size:841.7pt 595.45pt;  mso-page-orientation:landscape;  mso-header-margin:0.5in;  mso-header: h1;  mso-footer-margin:0.5in;  mso-footer: f1;    }    div.Section1 {page:Section1;}    p.headerFooter { margin:0in; text-align: center; }    </style></head><body style="font-family:Arial"><div class=Section1><!-- header/footer:  This element will appears in your main document (unless you save in a separate HTML),  therefore, we move it off the page (left 50 inches) and relegate its height  to 1pt by using a table with 1 exact-height row--><table style="margin-left:50in;"><tr style="height:1pt;mso-height-rule:exactly">    <td>      <div style="mso-element:header" id=h1><p class=headerFooter>    Header content goes here </p></div>&nbsp;</td><td><div style="mso-element:footer" id=f1><p class=headerFooter>Page<span style="mso-field-code:PAGE"></span>of<span style="mso-field-code:NUMPAGES"></span></p></div>&nbsp;</td></tr></table>Main content goes here.Heres a page break:<br clear=all style="mso-special-character:line-break; page-break-before:always">This is page 2</div></body></html>'
  }
  // generateImage(): void {
  //   const imageUrl = this.URL_IMG; 
  //   const link = document.createElement('a');
  //   link.href = imageUrl;
  //   link.download = 'Nube de palabras.png';
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  //   this.api.set_kpi_plataformas('Nube de Palabras','Exportó imagen');
  // }
  generateImage(): void {
    const elementId = 'imgNube'; // Cambia esto por el ID de tu elemento
    const element = document.getElementById(elementId);
    
    if (element) {
      html2canvas(element).then(canvas => {
        const imageUrl = canvas.toDataURL('image/png');
        
        const link = document.createElement('a');
        link.href = imageUrl;
        link.download = 'Nube_de_palabras.png';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        
        // Aquí puedes llamar a tu función para registrar la acción
        this.api.set_kpi_plataformas('Nube de Palabras', 'Exportó imagen');
      }).catch(error => {
        console.error('Error al generar la imagen:', error);
      });
    } else {
      console.error('Elemento no encontrado');
    }
  }
  generatePDF() {
    $('#modalaso').removeClass('hidden');
    if(this.ListadoPalabras.length == 0 && !this.GraficoCreado){
      this.alerta.fire({
        allowOutsideClick: false,
        allowEscapeKey: false ,
        title: "Debe ingresar al menos una palabra y crear nube",
        icon: 'warning',
        showDenyButton: false,
        showCancelButton: false,
        confirmButtonText: "Ok"
      });
      return;
    }
    const pdf = new jsPDF('l', 'px', 'l');
    const element = document.getElementById('imgNube');
    html2canvas(element).then((canvas) => {
      var pageHeight = pdf.internal.pageSize.height || pdf.internal.pageSize.getHeight();
      var pageWidth = pdf.internal.pageSize.width || pdf.internal.pageSize.getWidth();
      console.log(pageWidth)
      const imageData = canvas.toDataURL('image/png');
      const imgWidth = 350;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      const xOffsetImg = (pdf.internal.pageSize.getWidth() - imgWidth) / 2;
      pdf.addImage(imageData, 'PNG', xOffsetImg, 80, imgWidth, imgHeight);
      var ubicacion_y = 0;
      for(let i = 0; i < pdf.internal.getNumberOfPages(); i++) {
        pdf.setPage(i); 
        var pag_actual = pdf.internal.getCurrentPageInfo().pageNumber;
        pdf.setTextColor(0, 0, 0);
        pdf.setFont('Helvetica', 'bold');
        pdf.setFontSize(25);
        pdf.text("Nube de Palabras", pageWidth / 2, 30, {align: 'center'});
        pdf.setFont('Helvetica', 'bold');
        pdf.setFontSize(18);
        if(this.TipoPalabra == "CURSO"){
          pdf.text("Curso: "+($('#cursoAlumno option:selected').text()+" "+$('#detalleAlumno option:selected').text()), pageWidth / 2, 60, {align: 'center'});
        }else if(this.TipoPalabra == "PALABRAS"){
          pdf.text("Categoría de Palabras: "+($('#categorias option:selected').text()), pageWidth / 2, 60, {align: 'center'});
        }else if(this.TipoPalabra == "LECTURAS"){
          pdf.text("Lectura: "+($('#lectura option:selected').text()), pageWidth / 2, 60, {align: 'center'});
        }else if(this.TipoPalabra == "PERSONALIZADO-PALABRAS"){
          pdf.text("Palabras personalizadas", pageWidth / 2, 60, {align: 'center'});
        }else if(this.TipoPalabra == "PERSONALIZADO-TEXTO"){
          pdf.text("Lectura Personalizada: "+(this.LECTURA_TITULO), pageWidth / 2, 60, {align: 'center'});
        }
        
        pdf.setFont('Helvetica');
        pdf.setFontSize(8);
        pdf.setTextColor(117, 115, 109);
        pdf.setDrawColor(0, 0, 0).setLineWidth(0.1).line(25, (pageHeight-30), 600, (pageHeight-30));
        // pdf.text("Nube de palabras", 25, pageHeight-20);
        pdf.text("www.integritic.cl", 150+80, pageHeight-20);
        pdf.text("soporte@integritic.cl", 210+80, pageHeight-20);
        pdf.text("+56 9 3239 0405", 280+80, pageHeight-20);
        // pdf.text("Página "+(pag_actual)+"/"+pdf.internal.getNumberOfPages(), 570, pageHeight-20);
      }
      pdf.save('Nube de Palabras.pdf');
      $('#modal-impresion').modal('hide');
      $('#modalaso').addClass('hidden');
      this.api.set_kpi_plataformas('Nube de palabras','Genera PDF');
    });
  }  
  cambiaFuente(){
    this.changeConfiguracion();
    this.api.set_kpi_plataformas('Nube de palabras','Cambia Fuente '+$('#FuenteActual option:selected').text());
  }
  splitText(text: string, maxChars: number = 80): string[] {
    const words = text.split(' ');
    const lines: string[] = [];
    let currentLine = '';

    for (const word of words) {
      if ((currentLine + ' ' + word).trim().length <= maxChars) {
        currentLine = currentLine ? currentLine + ' ' + word : word;
      } else {
        lines.push(currentLine);
        currentLine = word;
      }
    }

    if (currentLine) {
      lines.push(currentLine);
    }

    return lines;
  }
  validarNumero(value,event,min,max){
    if(event.target.value > max){
      this.alerta.fire({
        allowOutsideClick: false,  // Evita cerrar al hacer clic fuera
  allowEscapeKey: false ,
        title: "El máximo permitido es de "+max,
        icon: 'warning',
        showDenyButton: false,
        showCancelButton: false,
        confirmButtonText: "Ok"
      });
      event.target.value = max;
      this[value] = max;
    }
    if(event.target.value < min){
      this.alerta.fire({
        allowOutsideClick: false,  // Evita cerrar al hacer clic fuera
  allowEscapeKey: false ,
        title: "El mínimo permitido es de "+min,
        icon: 'warning',
        showDenyButton: false,
        showCancelButton: false,
        confirmButtonText: "Ok"
      });
      event.target.value = min;
      this[value] = min;
    }
  }
}