import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../inicio/services/global.service';
// import { NavigationEnd, NavigationStart } from '@angular/router';
import Phaser from 'phaser';
import { ApiService } from 'src/app/inicio/services/api.service';
// import { interval } from 'rxjs';
declare var $: any;

var ContenedorGeneralGlobal;
var PantallaPresentacionGlobal;
var Principal;

@Component({
  selector: 'app-ruidometro',
  templateUrl: './ruidometro.component.html',
  styleUrls: ['./ruidometro.component.css']
})
export class RuidometroComponent implements OnInit {

  phaserGame: any;
  config: Phaser.Types.Core.GameConfig;
  
  constructor( public global:GlobalService, public api:ApiService){ 
    Principal = this;
    this.config = {
      type: Phaser.CANVAS,
      roundPixels: true,
      scale: {
        width: 1920,
        height: 900,
        parent: 'gameContainer',
        fullscreenTarget: 'gameContainer',
        mode: Phaser.Scale.FIT,
        autoCenter: Phaser.Scale.CENTER_BOTH,
      },
      dom: {
          createContainer: true
      },
      backgroundColor: '#fff8ed',
      scene: [PantallaPresentacion, ContenedorGeneral]
    };
    this.iniciar();
  }
  enviar_atras(){
    window.parent.postMessage({ type: 'navigate', url: "RecursosApoyo", recurso: "Ruidómetro"}, '*');
  }
  async iniciar(){
    setTimeout(()=>{
      $(".main-app").removeClass("hidden");
      $("#contenedor-principal").html(`<div id="gameContainer" style=" background: #5f0d50;"></div>`);
      this.phaserGame = new Phaser.Game(this.config);
    },1200)

    await this.global.offLoading(2500);
    $("body").css("background-color", "#5f0d50");
    console.log("listo");
  }

  ngOnInit(): void {
    this.api.set_kpi_plataformas("Ruidómetro","Ingresó al módulo");
  }

  iniciarJuegoPausado(){
    PantallaPresentacionGlobal.scene.resume();
    $("#modal-instrucciones").hide();
    setTimeout(() => {
      PantallaPresentacionGlobal.sound.add('estrella').play();
    }, 550);
  }

}


class PantallaPresentacion extends Phaser.Scene {
  estadoPantalla:any = false;
  constructor() {
    super({ key: 'PantallaPresentacion' });
    PantallaPresentacionGlobal = this;
  }
  preload() {
    this.load.audio('estrella','assets/images/aplicativos/ruidometro/estrellas.mp3');
    this.load.audio('a_jugar_sonido','assets/recursos/click.mp3');
    for(let i=0; i <= 10; i++) this.load.image('e'+i, 'assets/images/aplicativos/ruidometro/estrellas_'+i+'.png');


    this.load.image('base', 'assets/images/aplicativos/ruidometro/fondo.png');
    this.load.image('a_jugar', 'assets/images/aplicativos/ruidometro/a_jugar.png');
    let i  = 2;
    this.load.image('jiro_e', 'assets/images/aplicativos/ruidometro/jiro_'+i+'.png');
    this.load.image('fiu_e', 'assets/images/aplicativos/ruidometro/fiu_'+i+'.png');

    this.load.image('logos', 'assets/images/aplicativos/ruidometro/logo-blanco.png');
    this.load.image('ayuda', 'assets/images/aplicativos/ruidometro/ayuda.png');
    this.load.image('ampliar', 'assets/images/aplicativos/ruidometro/ampliar.png');
    this.load.image('no_ampliar', 'assets/images/aplicativos/ruidometro/no_ampliar.png');
    this.load.image('salir', 'assets/images/aplicativos/ruidometro/salir.png');

    this.load.atlas('flares', 'assets/assets/particles/flares.png', 'assets/assets/particles/flares.json');

  }
  resize (gameSize, baseSize, displaySize, resolution)
  {
    $("canvas").css({marginLeft:0,width:"100%",height:"auto", marginBottom: "-5px"});
  }
  create(){
    if(!Principal.global.isIframe){
      this.scene.pause();
    }
    this.scale.on('resize', this.resize, this);
    this.scale.on('orientationchange', (e: string) => {
      switch(e) {
          case 'portrait-primary':
              break;
          case 'landscape-primary':
              break;
          default:  
      }
      $("canvas").css({marginLeft:0,width:"100%",height:"auto", marginBottom: "-5px"});
    });

    this.scene.moveBelow(this, ContenedorGeneralGlobal);
    // this.scene.launch(ContenedorGeneralGlobal)
    // this.scene.pause(ContenedorGeneralGlobal)


    

    this.add.image(-2,0,"base").setOrigin(0).setScale(1).setAlpha(.7);

    this.add.image(500,600,"fiu_e").setOrigin(0.5).setScale(.45).setAlpha(1);
    this.add.image(1400,600,"jiro_e").setOrigin(0.5).setScale(.7).setAlpha(1);


    let inicio = this.add.sprite(this.scale.width/2,650,"a_jugar").setOrigin(0.5).setScale(1).setAlpha(1).setInteractive({ useHandCursor: true });
    let estrellas = this.add.sprite(this.scale.width/2,70,"e0").setOrigin(0.5).setScale(1).setAlpha(1);

    
    let anterior = -1;

    const emitter: any = this.add.particles('flares').createEmitter({
      x: estrellas.x+305,
      y: estrellas.y,
      frame: ['green'],
      lifespan: 2000,
      speed: { min: 150, max: 250 },
      scale: { start: 0.1, end: 0 },
      gravityY: 150,
      blendMode: 'ADD',
      // emitting: false
      });
    emitter.explode(16);


    let cantEstrellas = this.add.text(estrellas.x+240,45, "0", {
      font: "bold 44px Arial",
      align: "center",
      color: "#ffffff",
      // backgroundColor: "red",
      fixedWidth: 140
    });


    this.tweens.addCounter({
      from: 0,
      to: 10,
      duration: 2000,
      ease: 'linear',
      onUpdate: tween =>
      {
        let value = Math.round(tween.getValue());
        if(anterior < value){
          estrellas.setTexture("e"+value);
          cantEstrellas.setText(value.toString());
          emitter.explode(16);
          anterior = value;
        }
        
      },
      onComplete: t=>{
        // // this.Tiempo[3].setText("000");
        // this.minAnterior = minutes;
      }
    });
    this.add.text(0,150, "Ruidómetro Escolar" , {
      font: "bold 85px Arial",
      align: "center",
      color: "white",
      shadow: {offsetX: 5,offsetY: 5,color: 'black',blur: 1,stroke: true,fill: true},
      // backgroundColor: "red",
      fixedWidth: this.scale.width
    }).setVisible(true);

    this.add.text(0,300, "Controla el ruido de tu sala con gamificación\ny mantiene la atención de tus estudiantes" , {
      font: "bold 30px Arial",
      align: "center",
      color: "white",
      shadow: {offsetX: 2.5,offsetY: 2.5,color: 'black',blur: 1,stroke: true,fill: true},
      // backgroundColor: "red",
      fixedWidth: this.scale.width
    }).setVisible(true);

    let logos = this.add.sprite(this.scale.width/2,800,"logos").setOrigin(0.5).setScale(.5).setAlpha(1).setInteractive({ useHandCursor: true });
    let intLink =this.add.text(0,850, "Un producto con ❤ de integritic.cl" , {
      font: "20px Arial",
      align: "center",
      color: "white",
      shadow: {offsetX: 1.5,offsetY: 1.5,color: 'black',blur: 1,stroke: true,fill: true},
      // backgroundColor: "red",
      fixedWidth: this.scale.width
    }).setVisible(true).setInteractive({useHandCursor:true});

    intLink.on('pointerdown', function (pointer) {
      window.open("https://www.integritic.cl", '_blank');
    }, this);

    // ContenedorGeneralGlobal.micEncendido = false;

    let ayuda = this.add.sprite(this.scale.width/2,450,"ayuda").setOrigin(0.5).setScale(.5).setAlpha(1).setInteractive({ useHandCursor: true });
    // let salir = this.add.sprite(120,this.scale.height-40,"salir").setOrigin(0.5).setScale(.3).setAlpha(1).setInteractive({ useHandCursor: true });
    // salir.on('pointerdown', function (pointer) {
    //   salir.x = salir.x - 5;
    //   salir.setScale(.35);
    //   setTimeout(()=>salir.setScale(.3), 100);
    //   setTimeout(()=>salir.x = salir.x + 5, 100);
    //   this.enviar_atras();
    //   // setTimeout(()=>this.scene.launch(ContenedorGeneralGlobal), 100);
    //   // setTimeout(()=>this.scene.remove(PantallaPresentacionGlobal), 150);
    // }, this);
    inicio.on('pointerdown', function (pointer) {
      this.sound.add('a_jugar_sonido').play();
      inicio.x = inicio.x - 5;
      inicio.setScale(.95);
      setTimeout(()=>inicio.setScale(1), 100);
      setTimeout(()=>inicio.x = inicio.x + 5, 100);
      setTimeout(()=>this.scene.launch(ContenedorGeneralGlobal), 100);
      setTimeout(()=>this.scene.remove(PantallaPresentacionGlobal), 150);
      PantallaPresentacionGlobal.CambiarPantalla();
      PantallaPresentacionGlobal.estadoPantalla = true;
    }, this);

    ayuda.on('pointerdown', function (pointer) {
      ayuda.x = ayuda.x - 5;
      ayuda.setScale(.48);
      setTimeout(()=>ayuda.setScale(.5), 100);
      setTimeout(()=>ayuda.x = ayuda.x + 5, 100);
      Principal.global.alerta.fire({
        // icon: 'warning',
        width: "500",
        title: 'Instrucciones:',
        html: `<div style="font-size: 20px;">Controla el ruido de tu sala de manera entretenida.<br>
        Por cada 10 segundos de silencio (configurable), el curso gana una estrella por no despertar a la mascota ( Fiu o Jiro)<br>
        Si el curso llega a ruido nivel máximo, se pierde 1 estrella.<br>
        ¡A jugar controlando el ruido!</div>`,
        text: ``
      });
      // setTimeout(()=>this.scene.launch(ContenedorGeneralGlobal), 100);
      // setTimeout(()=>this.scene.remove(PantallaPresentacionGlobal), 150);
    }, this);
    
    if(Principal.global.isIframe){
      setTimeout(() => {
        this.sound.add('estrella').play();
      }, 1000);
    }
  }
  CambiarPantalla(){
    const element = document.documentElement; // Elemento HTML raíz
    if(!document.fullscreenElement){
      if(element.requestFullscreen){
        element.requestFullscreen();
      }
    }else{
      if(document.exitFullscreen){
        document.exitFullscreen();
      }
    }
  }
  enviar_atras(){
    window.parent.postMessage({ type: 'navigate', url: "RecursosApoyo", recurso: "Ruidómetro"}, '*');
  }
}
class ContenedorGeneral extends Phaser.Scene {

  constructor() {
    super({ key: 'ContenedorGeneral' });
    ContenedorGeneralGlobal = this;
    // PantallaInicialGlobal = this;
  }
  preload() {

    
    for(let i=0; i <= 5; i++) this.load.image('jiro'+i, 'assets/images/aplicativos/ruidometro/jiro_'+i+'.png');
    for(let i=0; i <= 5; i++) this.load.image('fiu'+i, 'assets/images/aplicativos/ruidometro/fiu_'+i+'.png');
    for(let i=0; i <= 6; i++) this.load.image('radar_'+i, 'assets/images/aplicativos/ruidometro/radar_'+i+'.png');

    this.load.image('estrella', 'assets/images/aplicativos/ruidometro/estrella.png');

    this.load.image('base', 'assets/images/aplicativos/ruidometro/fondo.png');
    this.load.image('radar', 'assets/images/aplicativos/ruidometro/radar.png');
    this.load.audio('shhhh','assets/images/aplicativos/ruidometro/shhhhh.mp3');
    this.load.audio('corneta','assets/images/aplicativos/ruidometro/sonido_wrong_silencio.mp3');

    // src/assets/images/aplicativos/ruidometro/fiu_enojao.mp3 src/assets/images/aplicativos/ruidometro/jiro_enojao.mp3

    this.load.audio('corneta_jiro','assets/images/aplicativos/ruidometro/jiro_enojao.mp3');
    this.load.audio('corneta_fiu','assets/images/aplicativos/ruidometro/fiu_enojao.mp3');

    this.load.image('radar_color', 'assets/images/aplicativos/ruidometro/radar_color.png');
    this.load.image('radar_falso', 'assets/images/aplicativos/ruidometro/radar_falso.png');
    this.load.image('sombra', 'assets/images/aplicativos/ruidometro/sombra.png');

    this.load.image('flecha', 'assets/images/aplicativos/ruidometro/flecha.png');
  
    /*+ **/
    this.load.image('sensibilidad', 'assets/images/aplicativos/ruidometro/sensibilidad.png');
    this.load.image('mic', 'assets/images/aplicativos/ruidometro/mic.png');
    this.load.image('alerta', 'assets/images/aplicativos/ruidometro/alerta.png');

    this.load.image('silencio_', 'assets/images/aplicativos/ruidometro/silencio_.png');
    this.load.image('enojado', 'assets/images/aplicativos/ruidometro/enojado.png');

    this.load.image('check', 'assets/images/aplicativos/ruidometro/check.png');

    this.load.image('mic_icon', 'assets/images/aplicativos/ruidometro/mic_icon.png');
    this.load.image('mic_off', 'assets/images/aplicativos/ruidometro/mic_off.png');

    this.load.image('circulo', 'assets/images/aplicativos/ruidometro/circulo.png');

    this.load.image('sh', 'assets/images/aplicativos/ruidometro/sh.png');
    this.load.image('zz', 'assets/images/aplicativos/ruidometro/zz.png');
    this.load.image('silencio', 'assets/images/aplicativos/ruidometro/silencio.png');
    this.load.image('cambiar', 'assets/images/aplicativos/ruidometro/cambiar.png');
    this.load.image('subir', 'assets/images/aplicativos/ruidometro/subir.png');
    this.load.image('bajar', 'assets/images/aplicativos/ruidometro/bajar.png');
    this.load.image('emoji', 'assets/images/aplicativos/ruidometro/emoji.png');
    this.load.image('globo', 'assets/images/aplicativos/ruidometro/globo.png');
    this.load.image('globo2', 'assets/images/aplicativos/ruidometro/globo2.png');
    this.load.image('flecha2', 'assets/images/aplicativos/ruidometro/flecha2.png');

    this.load.image('jiro', 'assets/images/aplicativos/ruidometro/jiro.png');
    this.load.image('fiu', 'assets/images/aplicativos/ruidometro/fiu.png');

    this.load.image('reinicio', 'assets/images/aplicativos/ruidometro/reinicio.png');

    


  }
  
  
  flecha:any;
  porcentaje:any;
  sensibilidad:any = 1.6; // de .1 hasta 2
  micEncendido:any = false;
  mensaje:any;
  mensaje_tiempo:any;
  mensaje_perder:any;
  skinSeleccionada:any = "fiu";
  flecha2:any;
  globo:any;
  globo2:any;
  emoji:any;
  BotonPantalla:any;
  async create(){
    // console.log("ñe")
    this.sound.pauseOnBlur = false;
    this.add.image(-2,-200,"base").setOrigin(0).setScale(1).setAlpha(1);
    this.add.rectangle(0, this.scale.height-100, this.scale.width, 100, 0xb7229b, 1).setOrigin(0);

    let radar_color = this.add.sprite(0,0,"radar_0").setOrigin(0).setScale(1).setAlpha(1).setDepth(1);
    radar_color.x = (this.scale.width / 2 - radar_color.width / 2) - 100
    radar_color.y = (this.scale.height - radar_color.height) - 20

    this.Radar = radar_color;
    

    // let radar_falso = this.add.image(radar_color.x,radar_color.y,"radar_falso").setOrigin(0).setScale(1).setAlpha(1).setDepth(2);
    // // radar_falso.

    // this.add.image(radar_color.x,radar_color.y,"radar_color").setOrigin(0).setScale(1).setAlpha(.2).setDepth(3);
    
    // let radar = this.add.image(radar_color.x,radar_color.y,"radar").setOrigin(0).setScale(1).setDepth(4);
    
    let sombra = this.add.image(radar_color.x,radar_color.y,"sombra").setOrigin(0).setScale(1).setDepth(5)
    let flecha = this.add.image(0,0,"flecha").setScale(1.3).setOrigin(0.5, 0.5).setDepth(7);
    
    // radar_color.x += 2;
    // radar_color.y += 2;

    // radar_falso.x += 100

    flecha.x = ((this.scale.width / 2 - flecha.width / 2) + 120) - 10;
    flecha.y = (this.scale.height) - 110;
    this.flecha = flecha;

   


    let jiro2 = this.add.sprite(0,0,this.skinSeleccionada+"2").setOrigin(0).setScale(.5).setDepth(6);

    jiro2.x = (this.scale.width / 2 - jiro2.width / 2)+ 20;
    jiro2.y = 230;

    let min = 1630;
    let max = this.scale.width - 30;

    let posC1 = 180;

    /** marco */
    let tamanioMarco = 720;
    var marcoFondo = this.add.graphics();
    marcoFondo.fillStyle(0xFF1B88, .3);
    marcoFondo.fillRoundedRect(min-25, 45, 310, tamanioMarco+10, 20);

    var fondoMarco = this.add.graphics();
    fondoMarco.fillStyle(0x1f002c, .5);
    fondoMarco.fillRoundedRect(min-20, 50, 300, tamanioMarco, 15);


    var input:any = document.createElement('input');
    input.type = 'text',
    input.min = '0';
    input.disabled = 'disabled';
    input.id = "text-cant",
    input.value = "10";
    input.style.border = "none";
    input.style.borderRadius = "10px";
    input.pattern = "[0-9]+";
    input.style.height = '50px';
    input.maxLength = 4;
    input.style.width = '180px';
    input.style.fontSize = '30px';
    input.style.textAlign = 'center';
    input.style.background = 'white';
    // input.style.transform = 'translate(-50%, -50%)';

    input.addEventListener("input", function() {
      this.value = this.value.replace(/[^\d]/g, '');
      // Tthis.cambiar_mensaje();
    });
    let diff = 0 ;

    document.body.appendChild(input);
    var inputEl:any = this.add.dom(this.scale.width-250, 420+diff, input).setOrigin(0);
    inputEl.on('pointerdown', function (pointer) {}, this);
    /** fin marco */

   
    let jiro = this.add.sprite(this.scale.width-125,530+diff,"jiro").setOrigin(0).setScale(.7).setAlpha(.5).setInteractive({ useHandCursor: true });
    let fiu = this.add.sprite(this.scale.width-265,530+diff,"fiu").setOrigin(0).setScale(.7).setInteractive({ useHandCursor: true });
    this.flecha2 = this.add.sprite(110,120,"flecha2").setOrigin(0).setScale(0.5).setInteractive({ useHandCursor: true });
    this.globo = this.add.sprite(170,130,"globo").setOrigin(0).setScale(1.2).setInteractive({ useHandCursor: true });
    this.globo2 = this.add.sprite(170,130,"globo2").setOrigin(0).setScale(1.2).setInteractive({ useHandCursor: true }).setVisible(false);
    let texto = "¡Hola! Soy "+this.capitalizeFirstLetter(this.skinSeleccionada)+"\ny necesito descansar.\nDeben mantener el máximo\nde silencio y les daré\nuna estrellita por cada\n\n\nsin ruido";
    let texto_2 = "10 segundos";
    this.mensaje = this.add.text(200,150, texto , {
      font: "20px Arial",
      align: "center",
      color: "#ff1b88"
    }).setVisible(true);
    this.mensaje_tiempo = this.add.text(233,265, texto_2 , {
      font: "bold 28px Arial",
      align: "center",
      color: "#ff1b88"
    }).setVisible(true);
    this.mensaje_perder = this.add.text(200,160, "Perdieron una\nestrella por Ruido" , {
      font: "30px Arial",
      align: "center",
      color: "#e20e0e"
    }).setVisible(false);
    this.emoji = this.add.sprite(280,240,"emoji").setOrigin(0).setScale(.6).setInteractive({ useHandCursor: true }).setVisible(false);
    let reinicio = this.add.sprite(this.scale.width-250,695+diff,"reinicio").setOrigin(0).setScale(.9).setAlpha(1).setInteractive({ useHandCursor: true });
    reinicio.on('pointerdown', function (pointer) {
      Principal.global.alerta.fire({
        // icon: 'warning',
        title: 'Juego reiniciado',
        html: `comenzamos de nuevo`,
        // text: ``
      });

      reinicio.x = reinicio.x - 5;
      reinicio.setScale(.95);
      setTimeout(()=>reinicio.setScale(.9), 100);
      setTimeout(()=>reinicio.x = reinicio.x + 5, 100);

      this.contadorEstrellas = 0;
      this.segundosTotales = 0;
      

    }, this);

    
    jiro.on('pointerdown', function (pointer) {

      

     
      fiu.setAlpha(.5);
      jiro.setAlpha(1);
      this.jiro.setVisible(false);
      this.skinSeleccionada = "jiro";
      this.cambiar_mensaje();
      this.validaPersonaje();
      setTimeout(()=>this.jiro.setVisible(true),100)

    }, this);
    
    fiu.on('pointerdown', function (pointer) {
      fiu.setAlpha(1);
      jiro.setAlpha(.5);
      this.jiro.setVisible(false);
      this.skinSeleccionada = "fiu";
      this.cambiar_mensaje();
      this.validaPersonaje();
      setTimeout(()=>this.jiro.setVisible(true),100)
    }, this);

    



    let linea = this.add.graphics();
    linea.fillStyle(0xffffff);
    linea.fillRect(min, posC1-2,  max-min, 5);


    let linea2 = this.add.graphics();linea2.fillStyle(0xffffff);linea2.fillRect(min-19, 350+diff,  299, 1);
    let linea4 = this.add.graphics();linea4.fillStyle(0xffffff);linea4.fillRect(min-19, 520+diff,  299, 1);
    let linea3 = this.add.graphics();linea3.fillStyle(0xffffff);linea3.fillRect(min-19, 620+diff,  299, 1);
    let linea5 = this.add.graphics();linea5.fillStyle(0xffffff);linea5.fillRect(min-19, 110,  299, 1);
    let linea6 = this.add.graphics();linea6.fillStyle(0xffffff);linea6.fillRect(min-19, 210,  299, 1);


    const circulo = this.add.image(min + (max - min) / 2, posC1, 'circulo');
    circulo.setInteractive({ draggable: true, useHandCursor: true });

    let pos_mic = 55;
    
    this.add.image(min+40,posC1-60,"sensibilidad").setOrigin(0).setScale(.7).setAlpha(1);
    this.add.image(min+15,pos_mic,"mic").setOrigin(0).setScale(.7).setAlpha(1);
    let mic:any = this.add.sprite(min+190,pos_mic,"mic_icon").setOrigin(0).setScale(.7);
    mic.setInteractive({ useHandCursor: true });


    this.add.image(min+5,130+90,"alerta").setOrigin(0).setScale(.7).setAlpha(1);
    let check = this.add.image(min+210,130+90,"check").setOrigin(0).setScale(.7).setAlpha(1);


    let silencio = this.add.image(min+110-50,180+90,"silencio_").setOrigin(0).setScale(.3).setAlpha(.3);
    let enojado = this.add.image(min+190-50,180+90,"enojado").setOrigin(0).setScale(.33).setAlpha(1);

    check.setInteractive({ useHandCursor: true });
    silencio.setInteractive({ useHandCursor: true });
    enojado.setInteractive({ useHandCursor: true });

    mic.on('pointerdown', (pointer, gameObject, dragX) => {
      if(this.micEncendido){
        apagarMic();
      }
      else iniciar_mic();
    });
    check.on('pointerdown', (pointer, gameObject, dragX) => {
      if(this.checkSinSonido){
        this.checkSinSonido = false;
        check.setAlpha(.3);
        // apagarMic();
      }
      else{
        this.checkSinSonido = true;
        check.setAlpha(1);
      }
    });

    silencio.on('pointerdown', (pointer, gameObject, dragX) => {
      if(this.sonidoActivo === 1){
        this.sonidoActivo = 2;
        silencio.setAlpha(.3);
        enojado.setAlpha(1);
        // apagarMic();
      }
      else{
        this.sonidoActivo = 1;
        silencio.setAlpha(1);
        enojado.setAlpha(.3);
      }
    });

    enojado.on('pointerdown', (pointer, gameObject, dragX) => {
      if(this.sonidoActivo === 2){
        this.sonidoActivo = 1;
        enojado.setAlpha(.3);
        silencio.setAlpha(1);
        // apagarMic();
      }
      else{
        this.sonidoActivo = 2;
        enojado.setAlpha(1);
        silencio.setAlpha(.3);
      }
    });

    // this.checkSinSonido = checkEncendido;
    this.BotonPantalla = this.add.sprite(this.scale.width-280,750,"no_ampliar").setOrigin(0.5).setScale(.3).setAlpha(1).setInteractive({ useHandCursor: true });
    this.BotonPantalla.on('pointerdown', () => {
      PantallaPresentacionGlobal.CambiarPantalla();
      if(PantallaPresentacionGlobal.estadoPantalla){
        this.BotonPantalla.setTexture("ampliar");
        PantallaPresentacionGlobal.estadoPantalla = false;
      }else{
        this.BotonPantalla.setTexture("no_ampliar");
        PantallaPresentacionGlobal.estadoPantalla = true;
      }
    });
    let ayuda = this.add.sprite(this.scale.width-40,750,"ayuda").setOrigin(0.5).setScale(.3).setAlpha(1).setInteractive({ useHandCursor: true });
    ayuda.on('pointerdown', function (pointer) {
         ayuda.x = ayuda.x - 5;
         ayuda.setScale(.38);
         setTimeout(()=>ayuda.setScale(.3), 100);
         setTimeout(()=>ayuda.x = ayuda.x + 5, 100);
         Principal.global.alerta.fire({
           // icon: 'warning',
           width: "500",
           title: 'Instrucciones:',
           html: `<div style="font-size: 20px;">Controla el ruido de tu sala de manera entretenida.<br>
           Por cada 10 segundos de silencio (configurable), el curso gana una estrella por no despertar a la mascota ( Fiu o Jiro)<br>
           Si el curso llega a ruido nivel máximo, se pierde 1 estrella.<br>
           ¡A jugar controlando el ruido!</div>`,
           text: ``
         });
         // setTimeout(()=>this.scene.launch(ContenedorGeneralGlobal), 100);
         // setTimeout(()=>this.scene.remove(PantallaPresentacionGlobal), 150);
       }, this);

    // let salir = this.add.sprite(120,this.scale.height-40,"salir").setOrigin(0.5).setScale(.3).setAlpha(1).setInteractive({ useHandCursor: true });
    // salir.on('pointerdown', function (pointer) {
    //   salir.x = salir.x - 5;
    //   salir.setScale(.35);
    //   setTimeout(()=>salir.setScale(.3), 100);
    //   setTimeout(()=>salir.x = salir.x + 5, 100);
    //   this.enviar_atras();
    //   // setTimeout(()=>this.scene.launch(ContenedorGeneralGlobal), 100);
    //   // setTimeout(()=>this.scene.remove(PantallaPresentacionGlobal), 150);
    // }, this);
    this.input.on('drag', (pointer, gameObject, dragX) => {
      if(!this.micEncendido) return;
      if(dragX<min) return;
      if(dragX>max) return;
      let minOriginal = min;
      let maxOriginal = max;
      let valorOriginal = dragX;
      let rangoOriginal = maxOriginal - minOriginal;
      let factorDeEscala = (3) / rangoOriginal;
      let valorEscalado = .1 + (valorOriginal - minOriginal) * factorDeEscala;
      this.sensibilidad = valorEscalado < .1 ? .1 : valorEscalado;
      dragX = Phaser.Math.Clamp(dragX,min,max);
      gameObject.x = dragX;
    },this);


    let verificarPermisoMic = async()=> {
      try {
        let query:any = { "name": 'microphone' } ;
        const permisos = await navigator.permissions.query(query);
        if (permisos.state === 'granted') {
          iniciar_mic();
          prenderMic();
        } else {
          // alert("debes habilitar el permiso del microfono, haciendo click en el icono")
          iniciar_mic()
        }
      } catch (error) {
        apagarMic();
      }
    }
    let prenderMic = ()=>{
      mic.setTexture("mic_icon");
      mic.setAlpha(1);
      linea.setAlpha(1);
      circulo.setAlpha(1);
    }
    let apagarMic = ()=>{
      this.porcentaje = 0.1;
      this.segundosTotales = 0;
      this.micEncendido = false;
      clearInterval(this.intervaloMic);
      mic.setTexture("mic_off");
      mic.setAlpha(.5);
      linea.setAlpha(.5);
      circulo.setAlpha(.5);
    }
    let iniciar_mic = ()=>{
      const audioContext = new (window.AudioContext)();
      const analyser = audioContext.createAnalyser();
      navigator.mediaDevices.getUserMedia({ audio: { echoCancellation: false } }).then(stream => {
        this.micEncendido = true;
        prenderMic();
          const source:any = audioContext.createMediaStreamSource(stream);
          source.connect(analyser);
          analyser.fftSize = 512;
          // analyser.minDecibels = -127;
          // analyser.maxDecibels = 0;
          // analyser.minDecibels = -130;
          // analyser.maxDecibels = 0;
          analyser.smoothingTimeConstant = .98;
          source.connect(analyser);
          const volumes = new Uint8Array(analyser.frequencyBinCount);
          let calcularNivelRuido = async () => {

            if(!this.micEncendido) return;

            let query:any = { "name": 'microphone' } ;
            const permisos = await navigator.permissions.query(query);
            if (permisos.state === 'granted')prenderMic();
            else {
              alert("debes habilitar el permiso del microfono, haciendo click en el icono")
              apagarMic();
              return this.micEncendido = false;;
            }

            if(source.context.state==="suspended"){
              return source.context.resume();
            }
            analyser.getByteFrequencyData(volumes);
            // let volumeSum = 0;
            // for(const volume of volumes)
            //   volumeSum += volume;
            
            //   const averageVolume = volumeSum / volumes.length;
            // let numero = (averageVolume * 100 / 127) * this.sensibilidad
            // numero = numero>122 ? 122: numero;
            // numero = numero<0 ? 0: numero;
            // this.porcentaje = numero;

            var length = volumes.length;
            let values = 0;
            for (var i = 0; i < length; i++) {
              values += (volumes[i]);
            }
  
              var average = values / length;
            let averageNoiseLevel = (average + 0) * this.sensibilidad;
            this.porcentaje = averageNoiseLevel>120 ? 120:averageNoiseLevel


            // console.log(this.porcentaje)


          };
          this.intervaloMic = setInterval(calcularNivelRuido, 1);
        })
        .catch(error => {
          if (error.name === 'NotAllowedError' || error.name === 'PermissionDeniedError') {
            alert("El usuario ha denegado el acceso al micrófono después de preguntar.")
          } else return apagarMic();  
        });
    }

    let inicio_x = 40;
    let padding = 74;    // const estrella = this.add.image(inicio_x, 30, 'estrella').setOrigin(0);

    const cont_estrellas = this.add.sprite(90, 10, 'e0').setOrigin(0);

    this.intervaloDePuntos = 10;
    this.contadorEstrellas = 0;
    let tiempo_limpiado = false;
    this.time.addEvent({
      delay: 1000,
      callback: function(){
          // this.cambiar_mensaje();
          if(tiempo_limpiado){
            tiempo_limpiado = false;
          }
          if(this.jiroDurmiendo && this.micEncendido){
            this.segundosTotales++;
            let intervalo = Number($("#text-cant").val());
            // if(this.segundosTotales%Math.round(intervalo/2) === 0 && this.segundosTotales != 0){
            //   cont_estrellas.setTexture("e1");
            // }
            if(this.segundosTotales%intervalo === 0 && this.segundosTotales != 0){
              this.contadorEstrellas+=1;
              tiempo_limpiado=true;
            }
          }
          else{
            cont_estrellas.setTexture("e"+(this.contadorEstrellas>10?10:this.contadorEstrellas));
          }
        },
        callbackScope: this,
        loop: true
    });

    this.prenderMic = prenderMic;
    this.apagarMic = apagarMic;
    this.contEstrellas = cont_estrellas;
    this.jiro = jiro2;

    
    
    verificarPermisoMic();

    this.add.text(inputEl.x-20,(inputEl.y-60), "Puntos por silencio\ncada", {
      font: "bold 24px Arial",
      align: "center",
      color: "#ffffff"
    });
    this.add.text(inputEl.x+30,inputEl.y+60, "Segundos", {
      font: "bold 24px Arial",
      align: "center",
      color: "#ffffff"
    });
    // let cambiar = this.add.image(inputEl.x+165, inputEl.y+75, 'cambiar').setVisible(true).setScale(.5).setInteractive({useHandCursor:true});
    // cambiar.on('pointerdown', function (pointer) {
    //   this.cambiar_mensaje();
    // }, this);

    let subir = this.add.image(inputEl.x+200, inputEl.y+25, 'subir').setVisible(true).setScale(.5).setInteractive({useHandCursor:true});
    subir.on('pointerdown', function (pointer) {
      this.subir_tiempo();
    }, this);

    let bajar = this.add.image(inputEl.x-20, inputEl.y+25, 'bajar').setVisible(true).setScale(.5).setInteractive({useHandCursor:true});
    bajar.on('pointerdown', function (pointer) {
      this.bajar_tiempo();
    }, this);

    this.cantEstrellas = this.add.text(710,36, "0", {
      font: "bold 44px Arial",
      align: "center",
      color: "#ffffff",
      // backgroundColor: "red",
      fixedWidth: 140
    });
    
    let restaG = 10;
    let difY = 50;
    this.Tiempo = [
      this.add.text(((this.scale.width)-255),(680 - difY) + diff, "Tiempo Dormido:", {font: "bold 24px Arial",align: "center",color: "#ffffff"}),
      this.add.text(((this.scale.width)-235)-restaG,(710 - difY) + diff, "00", {font: "bold 35px Arial",align: "right",color: "#ffffff", backgroundColor: "transparent", fixedWidth: 40}),
      this.add.text(((this.scale.width)-180)-restaG,(710 - difY) + diff, "00", {font: "bold 35px Arial",align: "center",color: "#ffffff", backgroundColor: "transparent", fixedWidth: 40}),
      this.add.text(((this.scale.width)-120)-restaG,(710 - difY) + diff, "000", {font: "bold 35px Arial",align: "left",color: "#ffffff", backgroundColor: "transparent", fixedWidth: 60}),

      this.add.text(((this.scale.width)-193)-restaG,(710 - difY) + diff, ":", {font: "bold 35px Arial",align: "center",color: "#ffffff", backgroundColor: "transparent", fixedWidth: 10}),
      this.add.text(((this.scale.width)-135)-restaG,(710 - difY) + diff, ":", {font: "bold 35px Arial",align: "center",color: "#ffffff", backgroundColor: "transparent", fixedWidth: 10}),

    ];

    this.m1 = this.add.image(1050, 200, 'zz').setVisible(false);
    this.m2 = this.add.image(700, 160, 'sh').setVisible(false);
    this.m3 = this.add.image(1050, 160, 'silencio').setVisible(false);

    this.m3.flipX = true;
    

    this.t1 = this.add.text(0,this.m1.y-60, "zZzZ" , {
      font: "bold 64px Arial",
      align: "center",
      color: "#ff1b88"
    }).setVisible(false);

    this.t2 = this.add.text(0,this.m2.y-10, "shhh!" , {
      font: "bold 44px Arial",
      align: "center",
      color: "#ff1b88"
    }).setVisible(false);

    this.t3 = this.add.text(0,this.m3.y-10, "¡Silencio!" , {
      font: "bold 45px Arial",
      align: "center",
      color: "#ff1b88"
    }).setVisible(false);

    this.validaPersonaje();
  }
  cambiar_mensaje(){
    let tiempo = $("#text-cant").val();
    if(tiempo.toString().length > 0){
      this.mensaje.setText("¡Hola! Soy "+this.capitalizeFirstLetter(this.skinSeleccionada)+"\ny necesito descansar.\nDeben mantener el máximo\nde silencio y les daré\nuna estrellita por cada\n\n\nsin ruido");
      this.mensaje_tiempo.setText((tiempo + (tiempo === "1" ? " segundo" : " segundos")));
      this.mensaje_tiempo.setX(tiempo.toString().length < 2 ? 233 : 220);
    }
    // else{
    //   $("#text-cant").val(10).trigger("change");
    // }
  }
  subir_tiempo(){
    let tiempo = Number($("#text-cant").val());
    if(tiempo + 1 < 1001){
      tiempo++;
      $("#text-cant").val(tiempo).trigger("change");
    }
    this.cambiar_mensaje();
  }
  bajar_tiempo(){
    let tiempo = Number($("#text-cant").val());
    if(tiempo - 1 > 0){
      tiempo--;
      $("#text-cant").val(tiempo).trigger("change");
    }
    this.cambiar_mensaje();
  }
  capitalizeFirstLetter(string) {
    string = string.toLowerCase();
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  enviar_atras(){
    window.parent.postMessage({ type: 'navigate', url: "RecursosApoyo", recurso: "Ruidómetro"}, '*');
  }
  validaPersonaje(){
    if(this.skinSeleccionada==="fiu"){

      this.jiro.setScale(.5);
      this.jiro.y = 100;
      this.jiro.x = (this.scale.width / 2 - this.jiro.width / 2)+ 80;

      this.m1.x = 1110;
      this.m2.x = 600;
      this.m3.x = 1110;

      this.t1.x = this.m1.x-90;
      this.t2.x = this.m2.x-60;
      this.t3.x = this.m3.x-90;

      this.m1.y = 110;
      this.m2.y = 160;
      this.m3.y = 110;

      this.t1.y = this.m1.y-60;
      this.t2.y = this.m2.y-10;
      this.t3.y = this.m3.y-55;

      
    }
    else{
      this.jiro.setScale(.8);
      let difX = 0;
      let difY = 0;

      this.jiro.y = 110;
      this.jiro.x = (this.scale.width / 2 - this.jiro.width / 2)-  30;
      
      this.m1.x = 1150;
      this.m2.x = 600;
      this.m3.x = 1150;

      this.t1.x = this.m1.x-90;
      this.t2.x = this.m2.x-60;
      this.t3.x = this.m3.x-90;

      this.m1.y = 200;
      this.m2.y = 160;
      this.m3.y = 200;

      this.t1.y = this.m1.y-60;
      this.t2.y = this.m2.y-10;
      this.t3.y = this.m3.y-55;

      

    }
  }

  m1:any = null;
  m2:any = null;
  m3:any = null;

  t1:any = null;
  t2:any = null;
  t3:any = null;

  Tiempo:any = null;
  Radar:any = null;
  segundosTotales:any = 0;
  estadoMicAnterior:any = false;
  intervaloMic:any = null;
  apagarMic:any = null;
  prenderMic:any = null;
  jiro:any = null;
  jiroDurmiendo:any = false;
  cantEstrellas:any = null;
  contadorEstrellas:any = 0;
  contadorEstrellasAnt:any = -1;
  intervaloDePuntos:any = 0;
  contEstrellas:any = null;
  segundosAnteriores:any = -1;
  checkSinSonido:any = true;
  sonidoActivo:any = 2;
  parpadeoText:any = false;
  numeroAnterior:any = -1;
  cambiarJiro(numero){
    this.validaPersonaje();
    let nueva = this.skinSeleccionada+numero;
    if(this.jiro.texture.key != nueva){
      let despertando_al_jiro= ()=>{
        console.log("despertando_al_jiro");
        if(!this.parpadeoText && this.numeroAnterior === 0){
          this.parpadeoText = true;
          setTimeout(()=>{ for(let i=0; i < this.Tiempo.length; i++) this.Tiempo[i].setColor("#FF0000");},0)
          setTimeout(()=>{ for(let i=0; i < this.Tiempo.length; i++) this.Tiempo[i].setColor("#FFFFFF");},100)
          setTimeout(()=>{ for(let i=0; i < this.Tiempo.length; i++) this.Tiempo[i].setColor("#FF0000");},200)
          setTimeout(()=>{ for(let i=0; i < this.Tiempo.length; i++) this.Tiempo[i].setColor("#FFFFFF");},300)
          setTimeout(()=>{ for(let i=0; i < this.Tiempo.length; i++) this.Tiempo[i].setColor("#FF0000");},400)
          setTimeout(()=>{ for(let i=0; i < this.Tiempo.length; i++) this.Tiempo[i].setColor("#FFFFFF");},500)
          setTimeout(()=>{ for(let i=0; i < this.Tiempo.length; i++) this.Tiempo[i].setColor("#FF0000");},600)
          setTimeout(()=>{ for(let i=0; i < this.Tiempo.length; i++) this.Tiempo[i].setColor("#FFFFFF");},700) 
          setTimeout(()=>{ for(let i=0; i < this.Tiempo.length; i++) this.Tiempo[i].setColor("#FF0000");},800)
          setTimeout(()=>{ for(let i=0; i < this.Tiempo.length; i++) this.Tiempo[i].setColor("#FFFFFF");},900)
          setTimeout(()=>{ this.parpadeoText = false},1000)

        }
        // this.tweens.add({
        //   targets: [this.Tiempo[0]],
        //   duration: 2000, // Duración de cada parpadeo en milisegundos
        //   repeat: -1, // -1 para que se repita infinitamente
        //   yoyo: true, // Para que la animación se revierta (rojo a blanco y viceversa)
        //   ease: 'Power2', // Puedes cambiar esto para ajustar la interpolación de la animación
        //   color: "0xFF0000" // Color rojo
        // });
      }
      if(numero === 0){
        this.jiroDurmiendo = true;
        this.m1.setVisible(true);
        this.m2.setVisible(false);
        this.m3.setVisible(false);
        
        this.t1.setVisible(true);
        this.t2.setVisible(false);
        this.t3.setVisible(false);
      }
      else if(numero === 5){    
        // despertando_al_jiro();
        this.segundosTotales = 0;
        this.jiroDurmiendo = false;

        this.m2.setVisible(true);
        this.m3.setVisible(true);
        this.m1.setVisible(false);

        this.t2.setVisible(true);
        this.t3.setVisible(true);
        this.t1.setVisible(false);
      }
      else {
        despertando_al_jiro();
        this.m1.setVisible(false);
        this.m2.setVisible(false);
        this.m3.setVisible(false);

        this.t1.setVisible(false);
        this.t2.setVisible(false);
        this.t3.setVisible(false);

        this.segundosTotales = 0;
        this.jiroDurmiendo = false;
      }
      this.numeroAnterior = numero;
      this.jiro.setTexture(nueva);
    }
  }
  minAnterior:any = -1;
  segAnterior:any = -1;
  formato_tiempo(totalSeconds, isZero) {
    var minutes = Math.floor(totalSeconds / 60);
    var seconds = Math.floor(totalSeconds % 60);
    var milliseconds = Math.floor((totalSeconds % 1) * 1000);


    this.tweens.addCounter({
      from: this.minAnterior,
      to: minutes,
      duration: 1000,
      ease: 'linear',
      onUpdate: tween =>
      {
          const value = Math.round(tween.getValue());
          this.Tiempo[1].setText(value<10?("0"+value):value);
      },
      onComplete: t=>{
        // this.Tiempo[3].setText("000");
        this.minAnterior = minutes;
      }
    });

    this.tweens.addCounter({
      from: this.segAnterior,
      to: seconds,
      duration: 1000,
      ease: 'linear',
      onUpdate: tween =>
      {
          const value = Math.round(tween.getValue());
          this.Tiempo[2].setText(value<10?("0"+value):value);
          this.segAnterior = seconds;
      },
      onComplete: t=>{
        // this.Tiempo[3].setText("000");
      }
    });


    // return minutes;
  }
  corriendoDescuento:any = false;
  update(){
    if(this.flecha){



      if(this.contadorEstrellasAnt != this.contadorEstrellas){
        let currentScore = this.contadorEstrellasAnt;
        let newScore = this.contadorEstrellas;
        this.tweens.addCounter({
          from: currentScore,
          to: newScore,
          duration: 500,
          ease: 'linear',
          onUpdate: tween =>
          {
              const value = Math.round(tween.getValue());
              this.cantEstrellas.setText(value);

          },
          onComplete: t=>{
            const emitter: any = this.add.particles('flares').createEmitter({
              x: this.cantEstrellas.x + (this.cantEstrellas.width / 2),
              y: this.cantEstrellas.y  + (this.cantEstrellas.height / 2),
              frame: ['green'],
              lifespan: 2000,
              speed: { min: 150, max: 250 },
              scale: { start: 0.8, end: 0 },
              gravityY: 150,
              blendMode: 'ADD',
              // emitting: false
          });
          
          emitter.explode(16);
          this.contEstrellas.setTexture("e"+(this.contadorEstrellas>10?10:this.contadorEstrellas));
            this.tweens.add({
              targets: this.contEstrellas,
              y: this.contEstrellas.y+1,
              // y: this.contEstrellas.y+5,
              duration: 500,
              ease: 'Quad.easeInOut',
              // delay: 30,
              yoyo: true,
              repeat: 0,
              onComplete: t=>{
                this.contEstrellas.setTexture("e"+(this.contadorEstrellas>10?10:this.contadorEstrellas));
              }
            });

          }
        });
        this.contadorEstrellasAnt = this.contadorEstrellas;
      }
      if(this.porcentaje){
        this.flecha.rotation = Phaser.Math.DegToRad((this.porcentaje) * 1.6);
        if(this.micEncendido != this.estadoMicAnterior){
          this.estadoMicAnterior = this.micEncendido;
        }

        /** mascara de colores */
        if(this.porcentaje<2){
          this.Radar.setTexture("radar_0");
        }
        else if(this.porcentaje<14){
          this.Radar.setTexture("radar_1");
        }
        else if(this.porcentaje<29){
          this.Radar.setTexture("radar_2");
        }
        else if(this.porcentaje<56){
          this.Radar.setTexture("radar_3");
        }
        else if(this.porcentaje<86){
          this.Radar.setTexture("radar_4");
        }
        else if(this.porcentaje<110){
          this.Radar.setTexture("radar_5");
        }
        else{
          this.Radar.setTexture("radar_6");
        }


        if(this.porcentaje<1){
          this.cambiarJiro(0);
        }
        else if(this.porcentaje<6){
          this.cambiarJiro(0);
        }
        else if(this.porcentaje<13){
          this.cambiarJiro(0);
        }
        else if(this.porcentaje<28){
          this.cambiarJiro(1);
        }
        else if(this.porcentaje<34){
          this.cambiarJiro(1);
        }
        else if(this.porcentaje<56){
          this.cambiarJiro(2);
        }
        else if(this.porcentaje<86){
          this.cambiarJiro(3);
        }
        else if(this.porcentaje<110){
          this.cambiarJiro(4);
        }
        else{

          // if(this.contadorEstrellas-1 >= 0){
            if(!this.corriendoDescuento){
              if(this.contadorEstrellas-1 >= 0){
                if(this.contadorEstrellas != 0){
                  console.log('RESTA 1')
                  this.contadorEstrellas -= 1;

                  this.globo.setVisible(false);
                  this.globo2.setVisible(true);
                  this.emoji.setVisible(true);
                  this.mensaje.setVisible(false);
                  this.mensaje_tiempo.setVisible(false);
                  this.mensaje_perder.setVisible(true);

                  setTimeout(() => {
                    this.globo.setVisible(true);
                    this.globo2.setVisible(false);
                    this.emoji.setVisible(false);
                    this.mensaje.setVisible(true);
                    this.mensaje_tiempo.setVisible(true);
                    this.mensaje_perder.setVisible(false);
                  }, 3500);
                }      
              } 
              this.corriendoDescuento=true;
              if(this.checkSinSonido) this.sound.add(this.sonidoActivo === 2?('corneta_'+this.skinSeleccionada):'shhhh').play();
              setTimeout(()=>{
                this.corriendoDescuento=false;
              },5000)
            // }
            // this.contadorEstrellasAnt = this.contadorEstrellas;
          }
          this.cambiarJiro(5);
        }
        if(this.segundosTotales === 0 && this.segundosAnteriores > this.segundosTotales){
          this.formato_tiempo(this.segundosTotales, true);
          this.segundosAnteriores = this.segundosTotales;
        }
        else if(this.segundosAnteriores != this.segundosTotales){
          this.formato_tiempo(this.segundosTotales, false);
            this.tweens.addCounter({
              from: 0,
              to: 999,
              duration: 1000,
              ease: 'linear',
              onUpdate: tween =>
              {
                  const value = Math.round(tween.getValue());
                  this.Tiempo[3].setText(value);
              },
              onComplete: t=>{
                this.Tiempo[3].setText("000");
              }
            });
          this.segundosAnteriores = this.segundosTotales;
        }
        

      }
    }
  }
}
